import React, { useState } from "react";
import {
	Form,Dropdown
} from 'formsy-semantic-ui-react';
import {Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
// import CheckboxTree from "./PrevilegesTree.js";

import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";
const DialogBoxDetail = ({ obj, show, title, message, modalType, modalClose, buttonmsg, modalbuttofun, loading,
	currentChecked,currentExpanded,setCurrentCheck,setCurrentExpand }) => {
	let accessModuleList = obj.accessModuleList;
	const renderTree = () =>{
		const icons = {
			check: <MdCheckBox className="rct-icon rct-icon-check" />,
			uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
			halfCheck: (
			  <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
			),
			expandClose: (
			  <MdChevronRight className="rct-icon rct-icon-expand-close" />
			),
			expandOpen: (
			  <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
			),
			expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
			collapseAll: (
			  <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
			),
			parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
			parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
			leaf: <MdInsertDriveFile className="" />
		  };
		  console.log(this);
		  return (
			<CheckboxTree
			  nodes={accessModuleList}
			  checked={currentChecked}
			  expanded={currentExpanded}
			  onCheck={checked => onCheck(checked)}
			  onExpand={checked => onExpand(checked)}
			  icons={icons}
			/>
		  );
	}

	const onCheck = (checked)=>{
		setCurrentCheck(checked)
	}

	const onExpand = (checked)=>{
		console.log(checked)
		setCurrentExpand(checked)
	}
	
	
	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {loading && <div className="ui loading form"></div>}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						{(obj.modalmessage=="tree")?
						<Form className='treeDetails'  noValidate autoComplete="off" id="formManageUser" >
						<div className="modal-body">
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first">
										<div className="kt-section__body">
											<div className="form-group row">
												<div className="col-lg-12 col-form-label" style={{overflow:'auto',height:'193px',marginTop:'23px',paddingLeft: '0px'}}>
												{renderTree()}
												</div>
												</div>
												</div>
												</div>
												</div>
												</div>

						</Form>
							:<div style={{marginLeft:33,marginTop:13}}>{obj.modalmessage}</div>}
						<div className="modal-footer">
							<button type="button" onClick={() => modalClose(false, '')} style={{ display: buttonmsg.cancel != '' ? "block" : "none" }} className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
								<span>{buttonmsg.cancel}</span>
							</button>
							<button type="button" onClick={() => modalbuttofun({ modalType })} style={{ display: buttonmsg.submit != '' ? "block" : "none" }} className="btn linear-gradient yr-submitbtn btn-sm">
								{buttonmsg.submit}
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxDetail;
