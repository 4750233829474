export const countrieslist=[{
    key: 1,
    text: 'USA',
    value: 'usa'
},
{
    key: 2,
    text: "Canada",
    value: 'canada'
},{
    key:3,
    text:"Australia",
    value:'aus'
}];


export const statelist = {usa:[
    { key: "AL", text: "Alabama", value: "AL" },
    { key: "AK", text: "Alaska", value: "AK" },
    { key: "AS", text: "American Samoa", value: "AS" },
    { key: "AZ", text: "Arizona", value: "AZ" },
    { key: "AR", text: "Arkansas", value: "AR" },
    { key: "CA", text: "California", value: "CA" },
    { key: "CO", text: "Colorado", value: "CO" },
    { key: "CT", text: "Connecticut", value: "CT" },
    { key: "DE", text: "Delaware", value: "DE" },
    { key: "DC", text: "District Of Columbia", value: "DC" },
    { key: "FM", text: "Federated States Of Micronesia", value: "FM" },
    { key: "FL", text: "Florida", value: "FL" },
    { key: "GA", text: "Georgia", value: "GA" },
    { key: "GU", text: "Guam", value: "GU" },
    { key: "HI", text: "Hawaii", value: "HI" },
    { key: "ID", text: "Idaho", value: "ID" },
    { key: "IL", text: "Illinois", value: "IL" },
    { key: "IN", text: "Indiana", value: "IN" },
    { key: "IA", text: "Iowa", value: "IA" },
    { key: "KS", text: "Kansas", value: "KS" },
    { key: "KY", text: "Kentucky", value: "KY" },
    { key: "LA", text: "Louisiana", value: "LA" },
    { key: "ME", text: "Maine", value: "ME" },
    { key: "MH", text: "Marshall Islands", value: "MH" },
    { key: "MD", text: "Maryland", value: "MD" },
    { key: "MA", text: "Massachusetts", value: "MA" },
    { key: "MI", text: "Michigan", value: "MI" },
    { key: "MN", text: "Minnesota", value: "MN" },
    { key: "MS", text: "Mississippi", value: "MS" },
    { key: "MO", text: "Missouri", value: "MO" },
    { key: "MT", text: "Montana", value: "MT" },
    { key: "NE", text: "Nebraska", value: "NE" },
    { key: "NV", text: "Nevada", value: "NV" },
    { key: "NH", text: "New Hampshire", value: "NH" },
    { key: "NJ", text: "New Jersey", value: "NJ" },
    { key: "NM", text: "New Mexico", value: "NM" },
    { key: "NY", text: "New York", value: "NY" },
    { key: "NC", text: "North Carolina", value: "NC" },
    { key: "ND", text: "North Dakota", value: "ND" },
    { key: "MP", text: "Northern Mariana Islands", value: "MP" },
    { key: "OH", text: "Ohio", value: "OH" },
    { key: "OK", text: "Oklahoma", value: "OK" },
    { key: "OR", text: "Oregon", value: "OR" },
    { key: "PW", text: "Palau", value: "PW" },
    { key: "PA", text: "Pennsylvania", value: "PA" },
    { key: "PR", text: "Puerto Rico", value: "PR" },
    { key: "RI", text: "Rhode Island", value: "RI" },
    { key: "SC", text: "South Carolina", value: "SC" },
    { key: "SD", text: "South Dakota", value: "SD" },
    { key: "TN", text: "Tennessee", value: "TN" },
    { key: "TX", text: "Texas", value: "TX" },
    { key: "UT", text: "Utah", value: "UT" },
    { key: "VT", text: "Vermont", value: "VT" },
    { key: "VI", text: "Virgin Islands", value: "VI" },
    { key: "VA", text: "Virginia", value: "VA" },
    { key: "WA", text: "Washington", value: "WA" },
    { key: "WV", text: "West Virginia", value: "WV" },
    { key: "WI", text: "Wisconsin", value: "WI" },
    { key: "WY", text: "Wyoming", value: "WY" }
],
canada:[
    { key: "AB", text: "Alberta", value: "AB" },
    { key: "BC", text: "British Columbia", value: "BC" },
    { key: "MB", text: "Manitoba", value: "MB" },
    { key: "NB", text: "New Brunswick", value: "NB" },
    { key: "NL", text: "Newfoundland and Labrador", value: "NL" },
    { key: "NT", text: "Northwest Territories", value: "NT" },
    { key: "NS", text: "Nova Scotia", value: "NS" },
    { key: "NU", text: "Nunavut", value: "NU" },
    { key: "ON", text: "Ontario", value: "ON" },
    { key: "PE", text: "Prince Edward Island", value: "PE" },
    { key: "QC", text: "Quebec", value: "QC" },
    { key: "SK", text: "Saskatchewan", value: "SK" },
    { key: "YT", text: "Yukon Territory", value: "YT" }
],
aus:[
    { key: "NSW", text: "New South Wales", value: "NSW" },
    { key: "VIC", text: "Victoria", value: "VIC" },
    { key: "QLD", text: "Queensland", value: "QLD" },
    { key: "TAS", text: "Tasmania", value: "TAS" },
    { key: "SA", text: "South Australia", value: "SA" },
    { key: "WA", text: "Western Australia", value: "WA" },
    { key: "NT", text: "Northern Territory", value: "NT" },
    { key: "ACT", text: "Australian Capital Territory", value: "ACT" }
]

};

export const address_terms={usa:[
    "Full address",
    "Google fully verified address",
    "Address line 1",
    "Address line 2",
    "City",
    "State",
    "Zip code",
    "Country"
    ],
canada:[
    "Full address",
    "Google fully verified address",
    "Address line 1",
    "Address line 2",
    "City",
    "Province",
    "Postal code",
    "Country"
    ],
    aus:[
        "Full address",
        "Google fully verified address",
        "Address line 1",
        "Address line 2",
        "Locality",
        "State",
        "Postcode", 
        "Country"
        ]
};