export const scrollTop = (duration) => {
    if (document.scrollingElement.scrollTop === 0) return;
  
      const totalScrollDistance = document.scrollingElement.scrollTop;
      let scrollY = totalScrollDistance, oldTimestamp = null;
  
      function step (newTimestamp) {
          if (oldTimestamp !== null) {
              // if duration is 0 scrollY will be -Infinity
              scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration;
              if (scrollY <= 0) return document.scrollingElement.scrollTop = 0;
              document.scrollingElement.scrollTop = scrollY;
          }
          oldTimestamp = newTimestamp;
          window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
  };
  

  export const encrypt_url = (id)=>{
    // const encoded_url = Buffer.from(id).toString('base64')
    const encrypted_url = window.btoa(unescape(encodeURIComponent(id)));
    
    const encoded_url = (encrypted_url.replace('+',"-").replace("/","_")).replaceAll("=","")
    console.log("encoded_url",encoded_url)
    return encoded_url;
  }
  export const dcrypt_url = (str)=>{
    //  const decoded_url = Buffer.from(string,'base64')
    const dcrypt_url = decodeURIComponent(escape(window.atob(str)));
    const url = dcrypt_url.replace('-',"+").replace("_","/");
    if(str.length<4){
      return url
    }else{
      const decoded_url = url.padEnd(str.length%4,"=")
       console.log("decoded_url",decoded_url)
       return decoded_url;
    }
  }
