import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import ReactHtmlParser, {
	processNodes,
	convertNodeToElement,
	htmlparser2,
} from "react-html-parser";

function createMarkup(html) {
	return { __html: html };
}

const status = (status) => {
	if (status == "Active") {
		var str = '<div class="lightgreen" style={{ width: "200px" }}>Active</div>';
	} else if (status == "Pending") {
		var str = '<div class="orange" style={{ width: "200px" }}>Pending</div>';
	}
	return ReactHtmlParser(str);
};

// const from_name = (from_name) => {
//   //alert(from_name);
//   var abc = "string";
//   return abc;
// };

export const SMSDatatableRow = (props) => (
	<Table.Row>
		<Table.Cell>
			<div style={{ width: "50px" }}><Checkbox /></div>

		</Table.Cell>
		<Table.Cell>
			<div style={{ width: "300px" }}>{props.list.phone}</div>
		</Table.Cell>

		<Table.Cell>
			<div style={{ width: "300px" }}>
				{status(props.list.status)}
			</div>
		</Table.Cell>

		<Table.Cell style={{ textAlign: "left" }}>
			{props.list.status === 'Pending' &&
				<>
					<a
						href="#"
						class="btn-icon dropdown-toggle yr-managebtn yr-dropdown-toggle-icon"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						Manage{" "}
					</a>
					<div className="dropdown-menu dropdown-menu-right yr-custom-menu">
						<ul className="table-list-bg">
							<li>
								<a href="javascript:void(0);" onClick={(e) => props.deleteDomain('Open', props.list.auto_id)}>
									<i className="fa fa-trash"></i>
									<span>Delete</span>
								</a>
							</li>
						</ul>
					</div>
				</>
			}
		</Table.Cell>
	</Table.Row>
);

SMSDatatableRow.propTypes = {
	list: PropTypes.object.isRequired,
};
