import React from "react";
import "../../../../../Modal/dialog.css";

const DialogBoxDetailOnCSVUploadError = ({
  obj,
  show,
  title,
  message,
  modalType,
  modalCloseCSVError,
  buttonmsg,
  modalbuttofun,
  loading,
}) => {
  const feedErrorMsg = (e) => { 
    const csvFailArr = obj.csvFailArr;
    return csvFailArr.map(({ key,val, msg, row }, idx) => (
      <div class="form-group" style={{ paddingLeft: 10 }}>
        <p style={{marginTop: '10px'}}>{msg}: <b style={{ color: "#de0a0a" }}>{val}</b> (Row: <b style={{ color: "#de0a0a" }}>{row})</b></p>
      </div>
    ));
  };

  return (
    <React.Fragment>
      <div
        className={
          show
            ? "modal fade yr-upgrade-popup white-bg new-model-popup show"
            : "modal fade yr-upgrade-popup white-bg new-model-popup"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {loading && <div className="ui loading form"></div>}
          <div className="modal-content white-bg yr-campaigns-model">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => modalCloseCSVError(false, "")}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"CSV upload error ."}</h5>
            </div>
            <div
              className="modal-body"
              style={{ paddingLeft: 5, paddingRight: 5 }}
            >
              <div className="">
                <form class="kt-form">
                  <div class="kt-portlet__body">
                    <div
                      class="kt-section kt-section--first"
                      style={{ paddingTop: 10 }}
                    >
                      <div
                        class="kt-section__body"
                        style={{ maxHeight: "500px", overflow: "auto" }}
                      >
                        {feedErrorMsg()}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DialogBoxDetailOnCSVUploadError;
