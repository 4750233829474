import React from 'react';
import {
    ListItem,
    ListIcon,
    ListHeader,
    ListDescription,
    ListContent,
    List,
} from 'semantic-ui-react'

const MemberListNotification = (props) => {
    return (
        <div className='col-md-12 memberListBox'>
            {/* <div className='row'> */}
            <List relaxed className='row'>
                {props.lists.map((item, index) => (
                    <ListItem key={item.index} className='memberlistitem'>
                        <ListContent>
                            <ListDescription>
                                <div className="memberListSchedule">
                                    {props.priority &&
                                        <select value={item.priority ? item.priority: ''} onChange={(e) => props.handleSetPriority(e, item.id, item.scheduleId, index, item)} className='form-control' style={{ width: 'auto', height: 20, padding: 0 }}>
                                            <option value=''>#</option>
                                            {props.lists.map((item, index) => (
                                                <option key={`item` + item.id} value={index + 1}>{index + 1}</option>
                                            ))}

                                        </select>
                                    }
                                    <span>
                                        <input
                                            className={item.hasSchedule ? 'has' : 'not has'}
                                            checked={item.hasSchedule}
                                            type="checkbox"
                                            id={`list_${item.id}`}
                                            onChange={(e) => props.handleSelectMember(e, item.id, item.scheduleId, index, item)}
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor={`list_${item.id}`}>
                                            {item.name}
                                        </label>
                                    </span>
                                    <a className="" href="#" onClick={(e) => props.handleHourSelect(e, item.id, item.scheduleId, index, item)}>Adjust hours</a>
                                </div>
                            </ListDescription>
                        </ListContent>
                    </ListItem>

                ))}
            </List>
            {/* </div> */}
        </div>
    )
}

export default MemberListNotification;
