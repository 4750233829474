import React from "react";
import api from "../../../../api";
import "../../../Grid/grid.css";

import { Segment, Table, Pagination } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";

import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "../../ChatSetup/components/Modal";
import AdditionalCaptured from "./AdditionalCaptured";

import ViewDetails from "./ViewDetails";

import ChatStatus from "./ChatStatus";
import { CSVLink, CSVDownload } from "react-csv";
import Notes from "./Notes";
import { throwStatement } from "@babel/types";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const tableHeader = [
  {
    title: "Date",
    width: "3",
    sort: "created_at",
    // checkbox: true,
  },
  {
    title: ReactHtmlParser("Watched by<br> User Company"),
    width: "1",
    sort: "wby_company",
  },
  {
    title: ReactHtmlParser("Watched by<br> User Name"),
    width: "1",
    sort: "name",
  },
  {
    title: ReactHtmlParser("Watched by<br> Email"),
    width: "1",
    sort: "email",
  },
  {
    title: ReactHtmlParser("Watched by<br> Phone"),
    width: "3",
    sort: "phone",
  },
  {
    title: ReactHtmlParser("Watched by<br> User Type"),
    width: "3",
    sort: "user_type",
  },
  {
    title: ReactHtmlParser("Watched by<br> Account Type"),
    width: "1",
    sort: "wby_accounttype",
  },
  {
    title: ReactHtmlParser("Watch for <br>Company Name"),
    width: "2",
    sort: "wfor_company",
  },
  {
    title: "Module",
    width: "1",
    sort: "module",
  },
  {
    title: "Chat Info",
    width: "1",
    sort: "",
  },
  
];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "chatstatus",
  "datafrequency",
  "time_zone",
  "startdaterange",
  "enddaterange",
];

export default class DatatableList extends React.Component {
  constructor(props) {
    // console.log("MOHD HILAL",props)
    super(props);
    this.state = {
      lists: [],
      _sort: "log.auto_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      chatstatus: "",
      datafrequency: "",
      startdaterange: "",
      enddaterange: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      modalfooter: "",
      modalBody: "",
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      auto_id: "",
      modalTranscript: "",
      csvdata: [],
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
    console.log("statattata", this.state);
    if (filter == "datafrequency") {
      this.setState({
        startdaterange: "",
        enddaterange: "",
      });
    }
    //if (filter !== this.state.q) {
    this.loadData({ [filter]: val, _page: 1 });
    // }
  };

  onSubmitFilterDateReange = (parmas) => {
    this.loadData({
      datafrequency: parmas["datafrequency"],
      startdaterange: parmas["startdaterange"],
      enddaterange: parmas["enddaterange"],
      _page: 1,
    });
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
    });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
    params["time_zone"] = time_zone;
    params["forU"] = "user";
    params["filterOn"] = 0;
    if(this.props.userData.user_subaccount_id==""){
      params["user_id"] = this.props.userData.user_id;
      params["user_type"] = "ZU";
    } else {
      params["user_id"] = this.props.userData.user_id;
      params["user_type"] = "ZU";
    }
    
    
    // params["current_user_id"] = this.props.user.user_id;
    // params["current_user_type"] = "ZU";

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.sensitiveDataActivityLog.getLists(query).then((data) => {
        try {
      
          let datatableRowstemp;
          console.log( data.data.list)
          this.setState({ lists: data.data.list });

          if (data.totalrecord){
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                key={index}
                list={list}
                viewAdditionalCaptured={this.viewAdditionalCaptured}
                viewDetails={this.viewDetails}
                viewNotes={this.viewNotes}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  viewDetails = (auto_id) => {
    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );

    api.chatlist.viewDetails({ auto_id: auto_id }).then((data) => {
      try {
        this.setState({
          loading: false,
          modalstatus: true,
          modaltitle: "",
          modalfooter: "",
          modalbody: (
            <ViewDetails
              detaildata={data.data.data}
              auto_id={auto_id}
              updatenote={this.updateNote}
            ></ViewDetails>
          ),
          modalTranscript: (
            <ChatStatus
              chatRef="chat_log"
              auto_id={auto_id}
              selected_chat={data.data.chatstatus}
              list={[
                "Change Status",
                "Customer Contacted",
                "Customer Cancelled",
                "Customer Serviced",
              ]}
            />
          ),
        });
      } catch (err) {}
    });
  };

  updateNote = (auto_id, value) => {
    console.log("hii----->>>>>>>>>", auto_id + "----" + value);

    let datalist = this.state.lists;

    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );

    datalist[recordlist]["team_notes"] = value;
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
        />
      )),
      lists: datalist,
    });
  };

  viewAdditionalCaptured = (auto_id) => {
    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );

    let modaldata = this.state.lists[recordlist]["additionl_captured"];

    this.setState({
      loading: false,
      modalstatus: true,
      modaltitle: "Additional Captured Fields",
      modalfooter: "",
      modalbody: (
        <AdditionalCaptured
          additional_captured={modaldata}
        ></AdditionalCaptured>
      ),
      modalTranscript: false,
    });
  };

  viewNotes = (auto_id) => {
    try {
      let notes_data = "";
      this.setState({
        loading: false,
        modalstatus: true,
        modaltitle: "Team Notes",
        modalfooter: "",
        modalbody: (
          <Notes
            auto_id={auto_id}
            commentform="false"
            tableref="chat_log"
            updatenote={this.updateNote}
          ></Notes>
        ),
        modalTranscript: false,
      });
    } catch (err) {}
  };

  modalFooter = (type) => {};

  modalClose = () => {
    this.setState({
      modalstatus: false,
    });
  };

  modalFooter = (id, type) => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.confirmDelete(this.state.modaldata.id)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
  };

  modalBody = () => {
    if (this.state.modalType == "job_description") {
      this.getJobDescriptionView(this.state.auto_id);
    }

    if (this.state.modalType == "additional_captured") {
      this.getAdditionalCapturedView(this.state.auto_id);
    }

    if (this.state.modalType == "notes") {
      this.getTeamNotesView(this.state.auto_id);
    }
  };

  getJobDescriptionView = (id) => {};
  getAdditionalCapturedView = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.auto_id === id);
    console.log(recordlist);
    let modaldata = this.state.lists[recordlist]["additionl_captured"];
    console.log(modaldata);
    Object.keys(modaldata).map(function () {});
  };
  getTeamNotesView = (id) => {};

  downloadCSV = (event, done) => {
    let params = {};
    params["chatstatus"] = this.state.chatstatus;
    params["datafrequency"] = this.state.datafrequency;
    params["strsearch"] = this.state.q;
    params["startdaterange"] = this.state.startdaterange;
    params["enddaterange"] = this.state.enddaterange;

    let list = this.state.lists;
    let selected_arr = [];
    list.filter(function (key) {
      if (key["checked"] == true) {
        selected_arr.push(key["auto_id"]);
      }
    });

    this.setState({ loading: true });

    params["selectallcheckbox"] = selected_arr.length > 0 ? "false" : "true";
    params["chatidscheck"] = JSON.stringify(selected_arr);
    api.chatlist.getCSVData(params).then((data) => {
      try {
        this.setState(
          {
            csvdata: data.data.data,
          },
          function () {
            setTimeout(
              function () {
                document.querySelector("#chatcsv").click();
                this.setState({ loading: false });
              }.bind(this),
              1000
            );
          }
        );
      } catch (err) {}
    });
  };

  checkAll = (e) => {
    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list["checked"] = e.target.checked;
    });
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
        />
      )),
      lists: datalist,
    });
  };

  checkboxChangeEvent = (auto_id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

    if (datalist[recordlist]["checked"]) {
      datalist[recordlist]["checked"] = false;
    } else {
      datalist[recordlist]["checked"] = true;
    }

    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
        />
      )),
      lists: datalist,
    });

    //  let array=this.state.checkedboxes;
    // const index = this.state.checkedboxes.indexOf(auto_id);
    // if (index > -1) {
    //   array.splice(index, 1);
    // }else{
    //   array.push(auto_id)
    // }

    // this.setState({
    //   checkedboxes:array
    // });
  };

  render() {
    return (
      <div id="sensitiveDataActivityLogParent" >
        <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            datafrequency: this.state.datafrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
          onSubmitFilterDateReange={this.onSubmitFilterDateReange}
          csvdata={this.state.csvdata}
          dowloadcsv={this.downloadCSV}
        />
        <Segment >
          {this.state.loading && <div className="ui loading form"></div>}

          <DatatableTable
            lists={this.state.lists}
            datatableHeader={tableHeader}
            datatableRows={this.state.datatableRows}
            totalCount={this.state.totalCount}
            totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
            currentPage={this.state._page}
            onChangePage={this.onChangePage}
            column={this.state._sort}
            direction={DatatableList.directionConverter(this.state._order)}
            handleSort={this.handleSort}
            onChangeLimit={this.onChangeLimit}
            limit={this.state._limit.toString()}
            checkAll={this.checkAll}
          />
        </Segment>
        <DatatablePageSizeSelect
          limit={this.state._limit.toString()}
          onChangeLimit={this.onChangeLimit}
        />{" "}
        Showing 1 - {this.state._limit.toString()} of {this.state.totalCount}
        <Pagination
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          activePage={this.state._page}
          onPageChange={this.onChangePage}
        />
        <Modal
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
        />
        <CSVLink
          id="chatcsv"
          data={this.state.csvdata}
          filename="chat.csv"
          className="hidden"
          target="_blank"
        />
      </div>
    );
  }
}
