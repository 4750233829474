import React from "react";
import {
    setPageTitle,
    toggleNavigation,
    toggleBreadcrum
} from "../../../../store/Page";
import "../../../Modal/dialog.css";
import api from "../../../../api";
import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import CustomErrorField from "../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link, Redirect } from "react-router-dom";
import util_funs from "../../../../util_funs";

class DetailDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading:false,
        domain_id:'',
        domain_detail:'',
        txt_records:'',
        cname_records:'',
        copied:false,
        userToaster: false,
        userToasterMsg: "",
        userToasterColor: "",
    }
  }

  componentDidMount() {
      
    const {
      setPageTitle,
      toggleBreadcrum,
      toggleNavigation,
    } = this.props;
    setPageTitle({ title: "Contacts", navigationName: 'marketing', subHeaderTitle:"Engage" });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.setState({domain_id: util_funs.dcrypt_url(this.props.match.params.id)},()=>{
        this.loadData();
    })
   
 }

 componentDidUpdate(prevProps, prevState, snapshot) {
 
}


loadData=()=>{
    api.ConfigSetting.getDomainDetail({ 'domain_id': this.state.domain_id }).then((data) => {
        try {
          if(data.status===401){
            console.log(this.props.history)
            this.props.history.push('/notAllowed')
          }
          this.setState({
            loading: false,
            domain_detail:data.domain_detail,
            txt_records:data.txt_records,
            cname_records:data.cname_records
  
  
          }, function () {
             
          });
  
        } catch (err) {
          console.log(err);
        }
  
      });
}

validateDomain=()=>{
   this.setState({ loading: true})
    api.ConfigSetting.validateDomain({ 'domain_id': this.state.domain_id }).then((data) => {
        try {
  
         
          this.setState({
            loading: false,
            domain_detail:data.domain_detail,
            txt_records:data.txt_records,
            cname_records:data.cname_records
  
  
          }, function () {
              if(data.domain_detail.domain.state=='active'){
                this.showToaster("green", data.message);  
              }else{
                this.showToaster("red", 'Once you make the above DNS changes it can take 24-48hrs for those changes to propagate. We will eail you to let you know once your domain is verified.');
              }
            
            utilFunc.scrollTop(800);
          });
  
        } catch (err) {
          console.log(err);
        }
  
      });
}


showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };


  render() {
    const { errors } = this.state;
   
    return (
        <div >
            <div className="kt-header__bottom">
	<div className="kt-container" >

<div className="yr-pagetitle d-flex justify-content-between">
	<div className="left yr-titleSelect kt-margin-t-10 kt-margin-b-10">
  <Link
                  to={"/marketing/listdomain"}
                  id="listdomain"
                  class="btn linear-gradient yr-add-new float-right kt-font-bold"
                >
                  {" "}
                  <i class="fas fa-chevron-left"></i>Back to configuration
                  settings
                </Link>
	</div>	
</div>

</div>
</div>
        <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                
                <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-ameplaybooks">

                <Message
                color={
                  this.state.userToasterColor
                    ? this.state.userToasterColor
                    : "teal"
                }
                style={{
                  display: this.state.userToaster ? "block" : "none",
                  marginTop: 30,
                }}
              >
                {this.state.userToasterMsg}
              </Message>
              {this.state.loading && <div className="ui loading form"></div>} 
                  
                    <div className="row">
                        <div className="col-lg-12">
                          
                            <div className="row">
                                <div className="col-lg-12 kt-margin-t-20 kt-margin-b-40">
                                    <div className="kt-margin-t-20 ">
                                        <h3 className="kt-font-bolder">1. Go To Your DNS Provider</h3>
                                        <p className="kt-margin-t-30">Go to the DNS providing that you use to manage mail1.candysendy.com and add the following DNS records.</p>
                                        <p className="yr-linkcolor">Common providers include GoDaddy, NameCheap, Network Solutions Rackspace Email & Apps, Rackspace Cloud DNS, Amazon Route 53 and Digital Ocean.</p>
                                    </div>
                                </div>
                              
                                <div className="col-lg-12 kt-margin-t-20 kt-margin-b-20" style={{borderBottom: "1px solid #ccc"}}>
                                    <div className="kt-margin-t-20">
                                        <h3 className="kt-font-bolder">2. Add DNS Records For Sending</h3>
                                        <p className="kt-margin-t-30">TXT records (known as SPF & DKM) are <strong>required to send and received email</strong></p>
                                        <div className="table-scroll">
                                            <table className="table yr-config-setting">
                                                <thead>
                                                    <tr>
                                                        <th className="yr-linkcolor">Status</th>
                                                        <th>Type</th>
                                                        <th>Hostname</th>
                                                        <th>Enter This Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.txt_records!='' && typeof this.state.txt_records!='undefined' && this.state.txt_records.map((obj,index)=>{
                                                        return (
                                                         <tr>
                                                         <td><i className={obj.valid!='unknown' ? 'fa fa-check green' : 'fa fa-times red'}></i></td>
                                                         <td>{obj.record_type}</td>
                                                         <td>{obj.name}</td>
                                                         <td style={{wordBreak:'break-all',width:"50%"}}>
                                                         {this.state.copied=='key'+index ? <span style={{color: 'red'}} className='hIdMSX'>Copied.</span> : null}
                                                             
                                                          {obj.value} 
                                                        
                                                         <CopyToClipboard text={obj.value}
                                                            onCopy={() => this.setState({copied: 'key'+index},()=>setTimeout(()=>{ this.setState({copied:false}) },3000))}>
                                                             <span className='cursorpointer'><i class="la la-clone"></i></span>
                                                        </CopyToClipboard>
                                                         </td>
                                                     </tr>
                                                        )
                                                    })} 


                                                  
                                                   
                                                    							
                                                </tbody>
                                                
                                            </table>
                                        </div>
                                        
                                    </div>
                                </div>
                              
                            
                                <div className="col-lg-12 kt-margin-t-20 kt-margin-b-20" style={{borderBottom: "1px solid #ccc"}}>
                                    <div className="kt-margin-t-20">
                                        <h3 className="kt-font-bolder">3. Add DNS Records For Tracking</h3>
                                        <p className="kt-margin-t-30">The CName record is nessesary for <strong>tracking opens, clicks, and unsubcribers.</strong></p>
                                        <div className="table-scroll">
                                            <table className="table yr-config-setting">
                                                <thead>
                                                    <tr>
                                                        <th className="yr-linkcolor">Status</th>
                                                        <th>Type</th>
                                                        <th>Hostname</th>
                                                        <th>Enter This Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.cname_records!='' && typeof this.state.cname_records!='undefined' && this.state.cname_records.map((obj,index)=>{
                                                        return (
                                                         <tr>
                                                         <td><i className={obj.valid!='unknown' ? 'fa fa-check green' : 'fa fa-times red'}></i></td>
                                                         <td>{obj.record_type}</td>
                                                         <td>{obj.name}</td>
                                                         <td style={{wordBreak:'break-all',width:"50%"}}>
                                                         {this.state.copied=='key_cname' ? <span style={{color: 'red'}} className='hIdMSX'>Copied.</span> : null}
                                                        {obj.value} 
                                                         <CopyToClipboard text={obj.value}
                                                            onCopy={() => this.setState({copied: 'key_cname'})}>
                                                            <span className='cursorpointer'><i class="la la-clone"></i></span>
                                                        </CopyToClipboard>
                                                        
                                                         </td>
                                                     </tr>
                                                        )
                                                    })} 
                                                    
                                                </tbody>
                                                
                                            </table>
                                        </div>
                                        
                                    </div>
                                </div>
                               
                                <div className="col-lg-12 kt-margin-t-20 kt-margin-b-20">
                                    <div className="kt-margin-t-20">
                                        <h3 className="kt-font-bolder">4. Wait For Your Domain To Verify</h3>
                                        <p className="kt-margin-t-20 kt-margin-b-40">Once you make the above DNS changes <strong>it can take 24-48hrs</strong> for those changes to propagate. We will eail you to let you know once your domain is verified.</p>	
                                    </div>
                                    <div className="kt-margin-t-30">
                                        <button type="submit" className="btn linear-gradient yr-submitbtn" onClick={()=>this.validateDomain()}> Click to Validate Records</button>
                                    </div>
                                </div>
                            </div>
                   
                        </div>										
                    </div>
                  
                </div>

                
            </div>
        </div>
        </div>
    
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
    setPageTitle,
    toggleNavigation,
    toggleBreadcrum
  
})(DetailDomain);
