import React from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination } from 'semantic-ui-react';

import { DatatablePageSizeSelect } from './DatatablePageSizeSelect';
import { DatatableTableHeader } from './DatatableTableHeader';

export const DatatableTable = props => {
  if (!props.lists) {
    return <React.Fragment />;
  }
  return (
    <React.Fragment>
 
 <table className ="ui celled selectable sortable table table-striped- table-hover team-member-list dataTable no-footer">
        <DatatableTableHeader
          column={props.column}
          direction={props.direction}
          handleSort={props.handleSort}
          header={props.datatableHeader}
        />
        <Table.Body>{props.datatableRows}</Table.Body>
        <Table.Footer>
          <Table.Row>
          <Table.HeaderCell colSpan="3">
            <DatatablePageSizeSelect  limit={props.limit} onChangeLimit={props.onChangeLimit} /> Showing 1 - {props.limit} of {props.totalCount} 
            </Table.HeaderCell>
           
            <Table.HeaderCell colSpan="6">
              <Pagination
                totalPages={props.totalPages}
                activePage={props.currentPage}
                onPageChange={props.onChangePage}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </table>
    </React.Fragment>
  );
};

DatatableTable.propTypes = {
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  limit: PropTypes.string.isRequired,
};
