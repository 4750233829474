import axios from "../../config/axios";

const actcrm = {
    list: (params) =>
        axios
            .post("/integration/actcrm/list", params)
            .then((resp) => (resp ? resp.data : null)),

    deleteCredential: (params) =>
        axios
            .post("/integration/actcrm/delete_credentials", params)
            .then((resp) => (resp ? resp.data : null)),

    locationList: (params) =>
        axios
            .post("/integration/actcrm/locations", params)
            .then((resp) => (resp ? resp.data : null)),

    createNew: (params) =>
        axios
            .post("/integration/actcrm/create_new", params)
            .then((resp) => (resp ? resp.data : null))
            .catch((err) => err),

    getActCredentialInfo: (params) =>
        axios
            .post("/integration/actcrm/getActCredentialInfo", params)
            .then((resp) => (resp ? resp.data : null)),
    getDatabase: (params) =>
        axios
            .post("/integration/actcrm/getDatabase", params)
            .then((resp) => (resp ? resp.data : null))
            .catch((err) => err),

};

export default actcrm;