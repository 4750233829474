import React from "react";
import { connect } from "react-redux";
import { Table } from 'semantic-ui-react';
import "../../Grid/grid.css";
import "./TranScriptChat.css";
// import DesignRules from './DesignRules';
import Notes from './Notes';
import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
const queryString = require("query-string");

class ViewNotes extends React.Component {
    constructor(props) {
        super(props);
       
    }
    componentDidMount() {

    }
    
    deleteNode = ( id ) => {
        this.props.deleteNotes(id);
    }
    rederNotes = () => {
        return this.props.data.map( (item,index) => 
            <div class="notes">
                <img src={this.props.chatlogo} alt={this.props.comapny} className="mr-2"/>
                <div className="notesDesctiption">
                    <div className="companyNameDate">
                        <span className="name mr-2">{this.props.comapny}</span>
                        <span className="date mr-2">{item.created_at}</span>
                        <span className="deleteIcon text-danger"  onClick={(event) => this.deleteNode(item.id)}><i className="fa fa-trash"></i></span>
                    </div>
                    <div className="innernotes pr-2">{item.comment}</div>
                </div>
            </div>
        )
    }

    render() {
        const { auto_id }=this.props
        return (
            <div>
            <div className="ui loading form chatstatusloadingdiv" style={{display:"none"}}></div>
                {this.props.data && this.rederNotes()}
            </div>
            
        );
    }
}
export default ViewNotes
