import React from 'react';
import api from "../../../../api";
import "../../../Grid/grid.css";
import { Segment, Table } from 'semantic-ui-react';
import { DatatableTable } from './DatatableTable';
import { DatatableFilter } from './DatatableFilter';
import DialogBoxAddEdit from './DialogBoxAddEdit';
import DialogBoxDelete from './DialogBoxDelete';
import DialogBoxEmpty from './DialogBoxEmpty';
import { DatatableRow } from './DatatableRow';
import utilFunc from "../../../../util_funs";

const tableHeader = [
  {
    "title": 'Action',
    "width": '6',
    "sort": '',
    'checkbox': true

  },
  {
    "title": 'Title',
    "width": '6',
    "sort": 'quick_text_title',
  },
  {
    "title": 'Message',
    "width": '6',
    "sort": 'quick_text_desc',
  },

  {
    "title": 'Action',
    "width": 'mh-qt-Action',
    "sort": '',
  },

]
const queryParams = ['_limit', '_order', '_sort', 'q', '_page'];
export default class DatatableList extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      lists: [],
      _sort: 'created_date',
      _page: 1,
      _order: 'desc',
      _limit: 10,
      q: '',
      totalCount: "0",
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modalstatusDelete: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      idArr: [],
      modalstatusEmpty: false

    };
  }

  componentDidMount() {
    this.loadData({});
  }

  static directionConverter(order) {
    if (order === 'asc') {
      return 'ascending';
    } else if (order === 'desc') {
      return 'descending';
    } else {
      return null;
    }
  }

  handleSort = clickedColumn => {
    const { _sort, _order } = this.state;

    let newOrder = _order === 'asc' ? 'desc' : 'asc';
    if (_sort !== clickedColumn) {
      newOrder = 'asc';
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = filter => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {

    if (data.modalType == 'Delete') {
      this.onDeleteAction();
    }
    if (data.modalType == 'Popup') {
      this.setState({
        modalstatus: false,
        modaltitle: '',
        modalmessage: '',
        modalbuttonmsg: {},
        modalType: ''
      });
    }
  };

  addQuickText = () => {
    document.getElementById('formManageQuickText').reset();
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: 'Add Quick Text',
      modalType: 'Add',
      modaldata: {},
      selectedOptions: []
    });
  };



  editQuickText = id => {
    document.getElementById('formManageQuickText').reset();
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let modaldata = this.state.lists[recordlist];
    let editDateModule = [];
    let moduleList = this.state.accessModuleList;
    moduleList.forEach((item, index) => {
      Object.keys(item).forEach(function (key) {
        if (modaldata.linksetting.includes(key))
          editDateModule.push(key);
      });
    });
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: 'Edit Quick Text',
      modalType: 'Edit',
      modaldata: modaldata,
      selectedOptions: editDateModule
    });
  };

  deleteQuickText = id => {
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    this.state.idArr.push(id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatusDelete: true,
      modaltitle: 'Confirm your action',
      modalmessage: 'Are you sure you want to delete?',
      modalbuttonmsg: { "submit": 'Yes', "cancel": 'No' },
      modalType: 'Delete',
      modaldata: modaldata,
      idArr: this.state.idArr
    });
  };

  deleteAllQuickText = () => {
    var checkedIds = [];
    for (var i = 0; i < this.state.lists.length; i++) {
      if (this.state.lists[i].checked == true)
        checkedIds.push(this.state.lists[i].id)
    }
    if (checkedIds.length == 0) {
      this.setState({
        modalstatus: false,
        modalstatusEmpty: true,
        modaltitle: 'Alert',
        modalmessage: '',
        modalbuttonmsg: {},
        modalType: '',
        modaldata: {},
        selectedOptions: []
      });
    } else {

      this.setState({
        modalstatusDelete: true,
        modaltitle: 'Confirm your action',
        modalmessage: 'Are you sure you want to delete?',
        modalbuttonmsg: { "submit": 'Yes', "cancel": 'No' },
        modalType: 'Delete',
        modaldata: {},
        idArr: checkedIds
      });
      // this.onDeleteAction(checkedIds);
    }
  }

  onDeleteAction = (idArr) => {
    this.setState({ modalFormLoader: true });
    api.liveChat.deleteQuickText({
      ids: idArr,
      user_id: this.props.sessionUser.user_id,
      _sort: this.state._sort,
      _page: this.state._page,
      _order: this.state._order,
      _limit: this.state._limit,
      q: this.state.q,
    }).then(data => {
      let datatableRowstemp;
      if (data.data.totalrecord) {
        datatableRowstemp = data.data.data.list.map((list, index) => (<DatatableRow key={index} list={list} channgeEvent={this.checkboxChangeEvent} editQuickText={this.editQuickText} deleteQuickText={this.deleteQuickText} preOpensetting={this.preOpensetting} />));
      } else {

        datatableRowstemp = <Table.Row><Table.Cell colSpan={3} textAlign="center">No record found.</Table.Cell></Table.Row>;
      }
      this.setState({
        datatableRows: datatableRowstemp,
        totalCount: data.data.totalrecord,
        lists: data.data.data.list,
        modalFormLoader: false,
        modalstatusDelete: false,
      });
      var w = '';
      if (idArr.length == 1)
        w = "Quick Text has been deleted successfully.";
      else
        w = "Quick Text has been deleted successfully.";
      this.props.showToaster('green', w);
      document.getElementById("quickText").scroll(1000, 0);
      this.props.quickTextAddEditDelete()


      // const newState = Object.assign({}, this.state, params, {
      //   loading: false,
      // });
      // this.setState(newState);
    });
  };

  preOpensetting = (id) => {
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let access_selected = this.state.lists[recordlist].linksetting;
    let access_selected_lists_html = '';
    access_selected_lists_html = access_selected.map((item, value) =>
      <p key={item} style={{ textTransform: "capitalize", color: "#6c7293", fontWeight: "400" }}>{item.replace(/_/g, ' ')}</p>
    );
    this.setState({
      modalstatus: true,
      modaltitle: 'Setting/Privileges',
      modalmessage: access_selected_lists_html,
      modalbuttonmsg: { "submit": 'Ok', "cancel": '' },
      modalType: 'Popup'
    });
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: '',
      modalmessage: '',
      modalbuttonmsg: {},
      modalType: '',
      modaldata: {},
      selectedOptions: []
    });
  };

  modalCloseDelete = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusDelete: false,
      modaltitle: '',
      modalmessage: '',
      modalbuttonmsg: {},
      modalType: '',
      modaldata: {},
      selectedOptions: []
    });
  };

  modalCloseEmpty = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusEmpty: false,
      modaltitle: '',
      modalmessage: '',
      modalbuttonmsg: {},
      modalType: '',
      modaldata: {},
      selectedOptions: []
    });
  };


  checkAll = (e) => {

    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list['checked'] = e.target.checked;
    })
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow key={index} list={list} channgeEvent={this.checkboxChangeEvent} editQuickText={this.editQuickText} deleteQuickText={this.deleteQuickText} preOpensetting={this.preOpensetting} />
      )),
      lists: datalist,

    });

  }

  checkboxChangeEvent = (id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex(
      list => list.id === id
    );
    if (datalist[recordlist]['checked']) {
      datalist[recordlist]['checked'] = false
    } else {
      datalist[recordlist]['checked'] = true
    }
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow key={index} list={list} channgeEvent={this.checkboxChangeEvent} editQuickText={this.editQuickText} deleteQuickText={this.deleteQuickText} preOpensetting={this.preOpensetting} />
      )),
      lists: datalist,

    });
  }


  onValidSubmit = (e) => {
    let sendDate = {
      "title": e.title,
      "message": e.message,
      user_id: this.props.sessionUser.user_id,
      _sort: this.state._sort,
      _page: this.state._page,
      _order: this.state._order,
      _limit: this.state._limit,
      q: this.state.q,
    };
    if (this.state.modalType == 'Add') {
      this.setState({ modalFormLoader: true });
      api.liveChat.addQuickText(sendDate).then(data => {
        this.setState({ modalFormLoader: false });
        try {
          this.loadData({ _page: 1 })
          this.setState({
            modalstatusAddEdit: false,
            modaltitle: '',
            modalmessage: '',
            modalbuttonmsg: {},
            modalType: '',
            modalErrorMsg: false,
            modaldata: {},
          });

          this.props.showToaster('green', 'Quick Text has been added successfully.');
          document.getElementById("quickText").scroll(1000, 0);
          this.props.quickTextAddEditDelete()
        } catch (err) {
          console.log(err);
        }
      });
    }
    if (this.state.modalType == 'Edit') {
      this.setState({ modalFormLoader: true });
      sendDate['id'] = this.state.modaldata.id;
      api.liveChat.updateQuickText(sendDate).then(data => {
        try {
          this.setState({ modalFormLoader: false });
          this.loadData({ _page: 1 })
          this.setState({
            modalstatusAddEdit: false,
            modaltitle: '',
            modalmessage: '',
            modalbuttonmsg: {},
            modalType: '',
            modalErrorMsg: false,
            modaldata: {},
          });
          this.props.showToaster('green', 'Quick Text has been edited successfully.');
          document.getElementById("quickText").scroll(1000, 0);
          this.props.quickTextAddEditDelete()

        } catch (err) {
          console.log(err);
        }

      });
    }
  }

  loadData = params => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });
    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');

    let totalCountQuery = '';
    if (params.q !== '') {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.liveChat.quickTextList(query).then(data => {
        let accessModuleStore = [];

        try {
          data.user_access.forEach((item, index) => {
            item.forEach((item2, index2) => {
              accessModuleStore.push(item2);
            });
          });
        } catch (err) {
          console.log(err);
        }
        let datatableRowstemp;
        console.log("hi"+data.data.totalrecord)
        if (data.data.totalrecord) {
          datatableRowstemp = data.data.data.list.map((list, index) => (<DatatableRow key={index} list={list} channgeEvent={this.checkboxChangeEvent} editQuickText={this.editQuickText} deleteQuickText={this.deleteQuickText} preOpensetting={this.preOpensetting} />));
        } else {
          datatableRowstemp = <Table.Row><Table.Cell colSpan={4} textAlign="center">No record found.</Table.Cell></Table.Row>;
        }
        this.setState({
          datatableRows: datatableRowstemp,
          totalCount: data.data.totalrecord,
          lists: data.data.data.list,
          accessModuleList: accessModuleStore
        });


        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });

    });

  };

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
          checkAll={this.checkAll}
        />

        <DialogBoxAddEdit
          obj={this.state}
          modalClose={this.modalClose}
          onValidSubmit={this.onValidSubmit}
          width="400px"
          height="200px"
        />
        <DialogBoxDelete
          obj={this.state}
          modalClose={this.modalClose}
          modalCloseDelete={this.modalCloseDelete}
          onDeleteAction={this.onDeleteAction}
          width="400px"
          height="200px"
        />

        <DialogBoxEmpty
          obj={this.state}
          modalClose={this.modalClose}
          modalCloseEmpty={this.modalCloseEmpty}
          onDeleteAction={this.onDeleteAction}
          width="400px"
          height="200px"
        />
      </Segment>
    );
  }
}
