import React from "react";
import {
    setPageTitle,
    toggleNavigation,
    toggleBreadcrum
} from "../../../../../store/Page";
import "../../../../Modal/dialog.css";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import CustomErrorField from "../../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link ,Redirect} from 'react-router-dom';

import '../../../Marketing/yr-style.css'
import Modal from "../../../ChatSetup/components/Modal";
class EmailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true, 
        pid:'', 
        errors:[],
        list_load:false,
        list:[],
        modalstatus:false,
        modaltilte: '', 
        modalbody:'',
        modalfooter:'',
        modalindex:'',
        emailtitle:'',
        modalloading:false,
        from_emails:'',
        edittitle:'',
        modalid:'',
        active_id:'',
        editor_update:false,
    }
  }

  componentDidMount() {
      
   
    this.loadData();
 }

 componentDidUpdate(prevProps, prevState, snapshot) {
   
    if (this.props.indextime != prevProps.indextime) {
        
        this.loadData();
    }
}

loadData=()=>{
   
    this.setState({
        list:this.props.emaillist.list,
        list_load:true,
        pid:this.props.pid,
        from_emails:this.props.emaillist.from_email,
        active_id:this.props.active_id,
        editor_update:this.props.editor_update
    },function(){
        
    })
   
}


addTemplate=(type,name,modalid)=>{
  
  let edittitle='';
  let emailmodaltitle=''
  if(type=='edit' && name!=''){
    edittitle=name;
  }
  
  if(type=='add' && this.state.editor_update==true){
    emailmodaltitle='Alert';
  }

  if(type=='delete'){
    emailmodaltitle='Confirm your action';
  }
  
   this.setState({
    modalstatus: true, 
    modalindex:type,
    emailtitle:'',
    errors:[],
    edittitle:edittitle,
    modalid:modalid,
    modaltitle:emailmodaltitle
   },function(){
    let  modalbody='';
    if(type=='add' && this.state.editor_update==true){
       modalbody='You have not save the changes please save other wise the changes will removed'
    }else{
     modalbody =this.modalBody(type)
  
    
    }
    this.setState({ modalbody: modalbody });
   })
}

copyTemplate=(id)=>{
  api.playbookdetail.copyPlaybookTemplate({ temp_id:id,pid:this.state.pid}).then((data) => {
    this.setState({list:data.data.list,modalloading:false,modalid:''},function(){
      this.modalClose('')
      this.props.updateList(data.data.list,data.data.insert_id)
      
      this.props.addNewTemplate(data.data.details);
  })

});
}

modalClose = (index) => {
    this.setState({
      modalstatus: false,
      modalid:'',
    });
  };

  onChangeInput=(type,data)=>{
    this.setState({emailtitle:data.target.value})
  }


  modalFooter = (index) => {
     
    if(index=='add' || index=='edit'){
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose(index)}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Close</span>
          </button>
          <button
            type="button"
            onClick={() => this.savePlaybookTemplate()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Save</span>
          </button>
        </div>
      );
    }else{
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose('')}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.onDeleteAction(this.state.modalid)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
    }
  };

  modalBody = (tab) => {
    
    if(tab=='add' || tab=='edit'){
      //alert( this.state.rename)
      let time=Date.now();
      //this.setState({rename:})
     const {emailtitle}=this.state
      return (
        <div className="col-lg-12 col-md-12 col-sm-12">
        <label className="form-control-label">Title <span>*</span></label>
        <input type="text" className={
                                        Object.keys(this.state.errors).length > 0 && typeof this.state.errors['title_data'] !='undefined'  
                                            ? "form-control is-invalid focusError"
                                            : "form-control"
                                    } key={time} placeholder="Enter Title" defaultValue={this.state.edittitle} onChange={(e) => this.onChangeInput('rename',e)} />
        { Object.keys(this.state.errors).length > 0 && typeof this.state.errors['title_data'] !='undefined'  &&
        <CustomErrorField message={this.state.errors['title_data']}   id="help-error" className="error invalid-feedback" />
         }
      </div>
      )
    }
    if(tab=='delete'){
      return "Are you sure you want to delete it?"
    }
  };



  savePlaybookTemplate=()=>{
     
    let error=[];
    if(this.state.emailtitle==''){
        error['title_data']='This field is required';
    }
    if(Object.keys(error).length>0){
      
      
      this.setState({errors:error},function(){
        let modalbody =this.modalBody('add')
        this.setState({modalbody:modalbody})
       
      })
    }else{
     
     this.setState({modalloading:true})
     
      api.playbookdetail.savePlaybookTemplate({ 'pid': this.state.pid ,'title':this.state.emailtitle,modalid:this.state.modalid}).then(data => {
        try {

          this.setState({list:data.data.list,modalloading:false,modalid:''},function(){
              this.modalClose('')
              this.props.updateList(data.data.list,data.data.insert_id)
              this.props.addNewTemplate(data.data.details);

          })
        } catch (err) {
            console.log(err);
        }
    });
  }
  }


  onDeleteAction = () => {
    this.setState({ modalFormLoader: true });
    var mol_id=this.state.modalid;
    
    api.playbookdetail.deletePlaybookTemplate({ modalid: this.state.modalid ,pid:this.state.pid}).then((data) => {
          this.setState({list:data.data.list,modalloading:false,modalid:''},function(){
            this.modalClose('')
            let new_id=null;
            if(this.state.active_id==mol_id){
               let current = typeof data.data.list[0] != 'undefined' ? data.data.list[0] : null;
               new_id=typeof current.id != 'undefined' ? current.id : null
            }
           
            this.props.updateList(data.data.list,new_id)
        })
      
    });
  };

  openNextRule=()=>{
    // this.setState({
    //   modalstatus: true, 
    //   modalindex:'nextemailrule',
    //   emailtitle:'',
    //   errors:[],
    //   edittitle:'',
    //   modalid:modalid,
    //   modaltitle:'Template next rule'
    //  },function(){
    //   let  modalbody='';
    //   modalbody =this.modalBody(type)
    //   this.setState({ modalbody: modalbody });
    //  })
  }

  changeFromEmail=(e)=>{
   this.props.updateEmailChanges(e.target.value);
  }

  render() {
    const { errors } = this.state;
     
    return (
        <div className="yr-chat-round-box box-bg">
                        <div className="row">
                            <div className="col-lg-12 d-flex  mb-3">
                                <div className="yr-nowrap kt-font-bold" style={{lineHeight: "38px"}}>
                                    Send emails from:
                                </div>

                                <select className="form-control ml-3" onChange={(e)=>this.changeFromEmail(e)} value={this.props.sendmailfrom}>
                                <option value="">Select</option>
                                {this.state.from_emails.length>0 && this.state.from_emails.map((obj,index)=>{
                                    return (
                                    <option key={index} value={obj.id}>{obj.email}</option>
                                   
                                    )
                                })}
                                </select>
                            </div>
                            <div className="col-lg-12 text-center">
                                <button type="button"
                                        className="btn linear-gradient btn-sm btn-clean btn-icon" onClick={()=>this.addTemplate('add','','')}>
                                    <img src="/assets/media/icons/plus.svg" alt="" style={{width: "100%"}}/>
                                </button>
                                {/* <a className='add-gradient mb-3'  style={{ color: '#087ab6' }} onClick={() => this.openNextRule()} > Next Email Rule</a> */}
                            </div>
                            {/* <div className="col-lg-10 d-flex">
                                <button type="button"
                                        className="btn linear-gradient btn-clean btn-bold move-btn">
                                    Move
                                </button>
                                <select className="form-control ml-3">
                                    <option>Below</option>
                                    <option>Above</option>
                                </select>
                                <select className="form-control ml-3">
                                    <option>Branch #</option>
                                    <option>Branch 1</option>
                                </select>
                            </div> */}
                        </div>
                        {typeof this.state.list !='undefined' && this.state.list.length > 0 && this.state.list.map((obj,y)=>{
                        return (
                          
                        <div className={
                         this.state.active_id == obj.id 
                              ? "yr-chat-round-box inner-box active_branch"
                              : "yr-chat-round-box inner-box"
                      }  key={y} >
                            <header className="box-head mb-2">
                                <div className="small-title cursorpointer" onClick={()=>this.props.openAttr(obj.id)} title={obj.title}>
                                    <span className="kt-badge kt-badge--success kt-badge--square pre-number-box " >{y+1}</span>{obj.title}
                                </div>
                                <div className="right-action-btns">
                                    <button type="button" className="btn linear-gradient btn-icon" title="Edit" onClick={()=>this.addTemplate('edit',obj.title,obj.id)}><i className="far fa-edit"></i></button>
                                    <button type="button" className="btn linear-gradient btn-icon" title="Copy" onClick={()=>this.copyTemplate(obj.id)}><i className="far fa-copy"></i></button>	
                                    {y>0 &&																
                                    <button type="button" className="btn linear-gradient btn-icon" title="Delete" onClick={()=>this.addTemplate('delete','',obj.id)}><i className="fas fa-trash-alt"></i></button>
                                    }
                                    </div>
                            </header>
                            <section className="body-text mb-3" onClick={()=>this.props.openAttr(obj.id)}>
                                {/* Welcome to EM Bank. What service do you need? */}
                            </section>
                            {/* <footer>
                                <button className="btn linear-gradient yr-round-btn">HVAC</button>
                                <button className="btn linear-gradient yr-round-btn">Plumbing </button>
                                <button className="btn linear-gradient yr-round-btn">Electrical</button>
                            </footer> */}
                        </div>
                        )
                        })}
                       
                        <Modal
                                      show={this.state.modalstatus}
                                      footer={this.modalFooter(this.state.modalindex)}
                                      onclose={this.modalClose}
                                      title={this.state.modaltitle}
                                      body={this.state.modalbody}
                                      loading={this.state.modalloading}
                         />

                           


                    </div>
    
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
    setPageTitle,
    toggleNavigation,
    toggleBreadcrum
  
})(EmailList);
