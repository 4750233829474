import axios from "../../config/axios";

const facebook = {
  getSubscribedPages: (params) =>
    axios
      .post("/integration/facebook/getSubscribedPages", params)
      .then((resp) => resp.data),
  getFacebookPages: (params) => {
    axios.post("/integration/facebook/showFBPages", params);
  },

  checkFbPages: (params) =>
    axios
      .post("/integration/facebook/checkFbPages", params)
      .then((resp) => (resp ? resp.data : null)),

  updateFbPageInfo: (params) =>
    axios
      .post("/integration/facebook/updateFbPageInfo", params)
      .then((resp) => (resp ? resp.data : null)),

  deleteFbPage: (params) =>
    axios
      .post("/integration/facebook/deleteFbPageInfo", params)
      .then((resp) => (resp ? resp.data : null)),

  fbWhiteListDomain: (params) =>
    axios
      .post("/integration/facebook/fbwhitelisteddomain", params)
      .then((resp) => (resp ? resp.data : null)),

  updateDomain: (params) =>
    axios
      .post("/integration/facebook/updateDomain", params)
      .then((resp) => (resp ? resp.data : null)),

  deletefbDomain: (params) =>
    axios
      .post("/integration/facebook/deleteFbDomain", params)
      .then((resp) => (resp ? resp.data : null)),
};

export default facebook;
