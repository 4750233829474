import { createSlice } from "@reduxjs/toolkit";

const pageSlice = createSlice({
  name: "page",
  initialState: {
    pageTitle: "",
    navigation: true,
    toggleBreadcrum: true,
    breadcrum: null,
    selectedMenu: false,
    subHeaderTitle: "Conversations",
    navigationName: "profile",
    message: null,
    toggleSubMenuSidebar: false,
  },
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload.title;
      state.subHeaderTitle = action.payload.subHeaderTitle
        ? action.payload.subHeaderTitle
        : "Conversations";
      state.navigationName = action.payload.navigationName
        ? action.payload.navigationName
        : "profile";
    },
    

    toggleNavigation: (state, action) => {
      state.navigation = action.payload.toggle;
    },
    toggleBreadcrum: (state, action) => {
      state.toggleBreadcrum = action.payload.toggle;
    },
    setBreadCrum: (state, action) => {
      state.breadcrum = action.payload.breadcrum;
    },
    setMenu: (state, action) => {
      state.selectedMenu = action.payload.selectedMenu;
    },
    setPageLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setMessage: (state, action) => {
      state.message = action.payload.message;
    },
    setNavigationName: (state, action) => {
      state.navigationName = action.payload.navigationName;
    },
    setSubHeaderTitle: (state, action) => {
      state.subHeaderTitle = action.payload.subHeaderTitle;
    },
    toggleSubMenuSidebar: (state, action) => {
      state.toggleSubMenuSidebar = action.payload.toggle;
    },
    toggleinnerPageLoader: (state, action) => {
      state.innerPageLoader = action.payload.toggle;
    },
  },
});

export const {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
  setMenu,
  setPageLoading,
  setMessage,
  setBreadCrum,
  setNavigationName,
  setSubHeaderTitle,
  toggleSubMenuSidebar,
   toggleinnerPageLoader
} = pageSlice.actions;
export default pageSlice.reducer;
