import axios from "../config/axios";

const postcard = {

  addTemplate: (params) =>
    axios
      .post(`/marketing/Postcard/addTemplate`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  onload: (params) =>
    axios
      .get(`/marketing/Postcard/onload`, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  list: (params) =>
    axios
      .get(`/marketing/Postcard/list?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
 
  deleteTemplate: (params) =>
    axios
      .post(`/marketing/Postcard/deleteTemplate`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  onloadEdit: (params) =>
    axios
      .get(`/marketing/Postcard/onloadEdit?`+ params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  editTemplate: (params) =>
    axios
      .post(`/marketing/Postcard/editTemplate`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
 
  backurl: (params) =>
    axios
      .post(`/marketing/Postcard/backurl`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  savePostcard: (params) =>
      axios
        .post(`/marketing/Postcard/savePostcard`, { ...params })
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),


};

export default postcard;
