import React, { useRef,useState, useEffect } from 'react';
import { render } from 'react-dom';
import config from "../../../../../config/";
import EmailEditor from 'react-email-editor';
const FooterText = (props) => {
    const emailEditorRef2 = useRef(null);
  
    const exportHtml = () => {
      emailEditorRef2.current.editor.exportHtml((data) => {
        const { design, html } = data;
        console.log('exportHtml', html);
      });
    };



    
   

  
    const onLoad = () => {
      
     // emailEditorRef.current.editor.loadDesign(JSON.parse(props.footer_contentJson));
    //  emailEditorRef.current.editor.addEventListener('design:updated', function(updates) {

    //   // Design is updated by the user
     

    };
    

    const saveDesign = () => {
      alert('Design')
      emailEditorRef2.current.editor.saveDesign((design) => {
        console.log('saveDesign', design);
        alert('Design JSON has been logged in your developer console.');
      });
    };


    useEffect(() => {
    
      // Update the document title using the browser API

      emailEditorRef2.current.editor.loadDesign(JSON.parse(props.footer_contentJson));
      
      emailEditorRef2.current.editor.setBodyValues({
        backgroundColor: "#ffffff",
        contentWidth: "500px", // or percent "50%"
        fontFamily: {
          label: "Helvetica",
          value: "'Helvetica Neue', Helvetica, Arial, sans-serif"
        },
        preheaderText: "Hello World"
      });

      emailEditorRef2.current.editor.addEventListener('design:updated', function(updates) {
       
       // alert('hi')
        // Design is updated by the user
        emailEditorRef2.current.editor.exportHtml(function(data) {
          var json = data.design; // design json
          var html = data.html; // design html
          emailEditorRef2.current.editor.saveDesign(function(design) {
          props.updateContentNewEditorFooter(html,design)
          });
          // Save the json, or html here
        })
      })
        
    });



  
    return (
      
      <div>
        <div>
          {props.loading &&
         <div className="ui loading form"></div>
          }
        </div>
        
        <EmailEditor
          ref={emailEditorRef2}
          projectId={config.unlayerProjectID}
          onLoad={onLoad}
          appearance= {{
            theme: 'light',
            panels: {
              tools: {
                dock: 'left'
              }
            },
            customCSS: [
              ".abbasi {background-color: yellow;}"
            ],
            
          }}
        />
        
      </div>
    );
  };
  
  export default FooterText;
