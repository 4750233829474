import React from 'react';

const AddTeamMember = () => {
    return(
        <div className="modal fade yr-upgrade-popup white-bg new-model-popup" id="kt_modal_6" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content white-bg">
                <button type="button" className="close linear-gradient" data-dismiss="modal" aria-label="Close">✕</button>
                <div className="modal-header">
                    <h2 className="modal-title">Add a Team Member</h2>						
                </div>
                <div className="modal-body">
                    <form className="kt-form">
                    <div className="kt-portlet__body">
                        <div className="kt-section kt-section--first">									
                        <div className="kt-section__body">
                            <div className="form-group row">
                            <label className="col-lg-4 col-form-label">Team Member Name :</label>
                            <div className="col-lg-8">
                                <input type="text" className="form-control" required />												
                            </div>
                            </div>
                            <div className="form-group row">
                            <label className="col-lg-4 col-form-label">Phone Number :</label>
                            <div className="col-lg-8">
                                <input type="text" className="form-control" required />												
                            </div>
                            </div>
                            <div className="form-group row">
                            <label className="col-lg-4 col-form-label">Email :</label>
                            <div className="col-lg-8">
                                <input type="email" className="form-control" required />												
                            </div>
                            </div>										
                            <div className="form-group row">
                            <label className="col-lg-12 col-form-label">Receive review notifications via text or email? (Optional)</label>
                            <div className="col-lg-12">
                                <div className="kt-checkbox-inline">
                                <label className="kt-checkbox">
                                    <input type="checkbox" /> Text
                                    <span />
                                </label>
                                <label className="kt-checkbox">
                                    <input type="checkbox" /> Email
                                    <span />
                                </label>													
                                </div>
                            </div>
                            </div>
                        </div>									
                        </div>
                    </div>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                        <div className="row">										
                            <div className="col-lg-12 d-flex justify-content-end">
                            <button type="reset" className="btn linear-gradient yr-cancelbtn linear-gradient-cancel mg-r"><span> Cancel </span></button>
                            <button type="submit" className="btn linear-gradient yr-submitbtn">Submit</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </form>
                </div>					
                </div>
            </div>
        </div>

    )
}


export default AddTeamMember;