import React, { useState, useEffect } from "react";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../../validation";
import DatePicker from "react-datepicker";
import "../../../../Modal/dialog.css";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import "react-datepicker/dist/react-datepicker.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";


const AddContactVia = ({ obj,title, onClose, loading,userdata,addEditModelopen }) => {
    const [option, setOption] = useState("static");
	const [showAddModel, setshowAddModel] = useState(false);
	const [currentContactLabelData, setCurrentContactLabelData] = useState([]);
	const [loader, setloader] = useState(false);
    const [buckettitle, setbuckettitle] = useState("");
    const setOptionValue = e => {
        setOption(e)
    }

    const openContactAddEditHere = e => {
		openContactAddEdit(option);
        
    }
	const openContactAddEdit = (opt) => {
		setloader(true);
		var submitBtnName = "Save";
		api.marketing
			.getEngageContactLabel({
				pid:obj.pid,
				user_id: userdata.user_id,
			})
			.then((data) => {
				onClose();
				setloader(false);
				console.log("labels",data,opt);
				setCurrentContactLabelData(data.data);
                setbuckettitle(data.title);
				setshowAddModel(true);
				addEditModelopen(data.data,opt,data.title);
				
			});
		// this.setState({ AddContactRadioStateinitially: opt });
	};

    const modalCloseContactViaBoxHere = () => {
        onClose();
        setOption("static");
    }
    return (
        <React.Fragment>
            <div
                className={obj.addContactModal ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdrop"
                aria-hidden="true"
                style={{ display: obj.addContactModal ? "block" : "none" }}
                id="modalContactViaID"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    {loading && <div className="ui loading form"></div>}
                    <div className="modal-content yr-campaigns-model">
                        <button
                            type="button"
                            className="close linear-gradient"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => onClose()}
                        >
                            X
                        </button>
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form">
                                <div className="kt-portlet__body">
                                    <div className="kt-section kt-section--first">
                                        <div className="kt-section__body">
                                            <div className="form-group row">
                                                <label className="col-lg-12 col-form-label">Please select one of the below options</label>
                                                <div className="col-lg-12">
                                                    <div className="kt-radio-inline">
                                                        <div className="mb-1">
                                                            <label className="kt-radio inalign pl-0">
                                                                <input type="radio" onClick={() => setOption("static")} name="opt" checked={(option == "static") ? "checked" : ""} value="left" />
                                                                <span></span>
                                                            </label><span className="align-label ml-3">Static field </span>
                                                        </div>
                                                        <div className="mb-1">
                                                            <label className="kt-radio inalign pl-0">
                                                                <input type="radio" name="opt" onClick={() => setOption("csv")} checked={(option == "csv") ? "checked" : ""} value="right" />
                                                                <span></span>
                                                            </label><span className="align-label ml-3">Import according to the fields being used</span>
                                                        </div>
                                                        
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-lg-12 d-flex justify-content-end">
                                                <button type="reset" onClick={() => modalCloseContactViaBoxHere()} className="btn linear-gradient-cancel yr-cancelbtn mg-r" style={{ color: 'black' }}><span> Cancel </span></button>
                                                <button type="button" onClick={() => openContactAddEditHere()} className="btn linear-gradient yr-submitbtn" data-toggle="modal" data-target="#kt_modal_8" data-dismiss="modal" aria-label="Close">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};



export default AddContactVia;