import React from "react";
import { Card, Segment } from "semantic-ui-react";

class ScreenShots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  openImage = (url) => {
    window.open(url, "__blank");
  };
  displayImages = (images) => {
    return images.map((image, index) => {
      return (
        <Card
          color="grey"
          style={{ cursor: "pointer" }}
          onClick={(image) => this.openImage(images[index])}
          image={image}
          key={index}
        />
      );
    });
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };
  render() {
    const { images, size } = this.props;

    return (
      <Segment>
        <Card.Group itemsPerRow={size ? size : 2} centered={true}>
          {this.state.loading && <div className="ui loading form"></div>}
          {this.displayImages(images)}
        </Card.Group>
      </Segment>
    );
  }
}

export default ScreenShots;
