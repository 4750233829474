import React from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const ChatGraph = ({data}) => (
  
  <ResponsiveContainer width="100%" height="100%">
    <LineChart data={data}
      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
      <CartesianGrid strokeDasharray="5 5" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="Started" stroke="#8884d8" activeDot={{ r: 5 }} />
      <Line type="monotone" dataKey="Completed" stroke="#82ca9d" activeDot={{ r: 5 }} />
      <Line type="monotone" dataKey="Live Chat" stroke="#ff03dd" activeDot={{ r: 5 }} />
      
    </LineChart>
  </ResponsiveContainer>
);

export default ChatGraph;

