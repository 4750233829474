import axios from "../../config/axios";

const servicetitan = {
    list: (params) =>
        axios
            .post("/integration/serviceTitan/list", params)
            .then((resp) => (resp ? resp.data : null)),

    deleteCredential: (params) =>
        axios
            .post("/integration/serviceTitan/delete_credentials", params)
            .then((resp) => (resp ? resp.data : null)),

    createNew: (params) =>
        axios
            .post("/integration/serviceTitan/create_new", params)
            .then((resp) => (resp ? resp.data : null))
            .catch((err) => err),

    updateStStatus: (params) =>
        axios
            .post("/integration/serviceTitan/updateSTStatus", params)
            .then((resp) => (resp ? resp.data : null))
            .catch((err) => err),

    saveFinalStatus: (params) =>
        axios
            .post("/integration/serviceTitan/saveSTApps", params)
            .then((resp) => (resp ? resp.data : null))
            .catch((err) => err),

    getActCredentialInfo: (params) =>
        axios
            .post("/integration/serviceTitan/getServiceTitanCredential", params)
            .then((resp) => (resp ? resp.data : null)),
    
    apilist: (params) =>
        axios
            .post("/integration/serviceTitan/apilist", params)
            .then((resp) => (resp ? resp.data : null)),

    servieTitanProviderList: () => 
        axios
            .post("/integration/serviceTitan/getbookingproviders")
            .then( (resp) => resp.data)
            .catch( (err) => console.log(err)),

    saveFinalconfig: (params) => 
        axios
            .post("/integration/serviceTitan/saveFinalconfig", params)
            .then( (resp) => resp.data)
            .catch( (err) => console.log(err)), 
    getFinalconfig: () => 
        axios
            .get("/integration/serviceTitan/getGlobalConfig")
            .then( (resp) => resp.data)
            .catch( (err) => console.log(err)),                
    



};

export default servicetitan;