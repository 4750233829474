import axios from "../config/axios";

const chatsetup = {
	saveHideShowData: (formData) =>
		axios.post("/chatsetup/showhideurl/save", { data: formData }),

	getBotHideShowList: () =>
		axios
			.post("/chatsetup/showhideurl/list", {})
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),

	getChatBotHoursData: () =>
		axios
			.post("/chatsetup/Chatbothours/gethoursdata", {})
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),

	getCurrentTime: (data) =>
		axios
			.post("/chatsetup/Chatbothours/currentTime", data)
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),

	saveTimeZoneData: (data) =>
		axios
			.post("/chatsetup/Chatbothours/saveuserchatbothours", data)
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),


	saveWorkingHoursData: (data) =>
		axios
			.post("/chatsetup/Workinghours/saveuserchatbothours", data)
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),

	getWorkingHoursData: () =>
		axios
			.post("/chatsetup/Workinghours/gethoursdata", {})
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),

	getStatistics: (params) =>
		axios
			.post("/chatdashboard/chatDashboard/getStatistic", params)
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),
	exportstats: (params) =>
		axios
			.post("/chatdashboard/chatDashboard/exportstats", params)
			.then((resp) => (resp ? resp.data : null))
			.catch((err) => console.log(err)),
	
		getBtnLog: (params) =>
			axios
				.post("/chatdashboard/chatDashboard/getChatLogInfo", params)
				.then((resp) => (resp ? resp.data : null))
				.catch((err) => console.log(err)),
	getTotalMinutesData: (params) =>
	axios
		.get(`/chatdashboard/VoiceDashboard/getPhoneCallMinutes?` + params, {})
		.then((resp) => resp.data)
		.catch((err) => console.log(err)),

	getTotalCallHandled: (params) =>
	axios
		.get(`/chatdashboard/VoiceDashboard/getTotalCallHandled?` + params, {})
		.then((resp) => resp.data)
		.catch((err) => console.log(err)),

	getTotalParseData: (params) =>
		axios
			.get(`/chatdashboard/VoiceDashboard/getParseDataGraph?` + params, {})
			.then((resp) => resp.data)
			.catch((err) => console.log(err)),					
};

export default chatsetup;
