import React from "react";
import { Link, withRouter } from "react-router-dom";

class ChatSetupSubNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: "design",
    };
  }
  redirectUser = (link) => {

    this.setState({ activeLink: link });
    this.props.history.push("/redirect?tab=" + link);

  };
  render() {
    const { display } = this.props;
    return (
      <div
        style={{ display: display ? "block" : "none" }}
        className="kt-menu__submenu kt-menu__submenu--classic kt-menu__submenu--left"
      >
        <ul className="kt-menu__subnav">
          <li
            className={`kt-menu__item  ${this.state.activeLink === "design"
                ? " kt-menu__item--active"
                : ""
              }`}
            aria-haspopup="true"
          >
            {" "}
            <a to="#" className="kt-menu__link linear-gradient linear-gradient-text" onClick={(link) => this.redirectUser("design")}>
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Design / Rules</span>
            </a>
          </li>
          <li className={`kt-menu__item  ${this.state.activeLink === "pagelocation"
              ? " kt-menu__item--active"
              : ""
            }`} aria-haspopup="true">
            {" "}
            <a to="#" className="kt-menu__link " onClick={(link) => this.redirectUser("pagelocation")}>
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Edit Page Location</span>
            </a>
          </li>
          {/* <li className="kt-menu__item " aria-haspopup="true">
            {" "}
            <a to="#" className="kt-menu__link ">
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">
                Sensitive Data Activity Log
              </span>
            </a>
          </li> */}
          <li
            className={`kt-menu__item  ${this.state.activeLink === "pagerules"
                ? " kt-menu__item--active"
                : ""
              }`}
            aria-haspopup="true"
          >
            {" "}
            <a
              className="kt-menu__link "
              onClick={(link) => this.redirectUser("pagerules")}
            >
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Page Rules / Hours</span>
            </a>
          </li>
          <li
            className={`kt-menu__item  ${this.state.activeLink === "reviewchat"
                ? " kt-menu__item--active"
                : ""
              }`}
            aria-haspopup="true"
          >
            {" "}
            <a
              className="kt-menu__link "
              onClick={(link) => this.redirectUser("reviewchat")}
            >
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Review via chat</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(ChatSetupSubNavigation);








