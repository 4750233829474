import React from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
// import DesignRules from './DesignRules';
import api from "../../../api";


import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap-daterangepicker/daterangepicker.css';
const queryString = require("query-string");

class ChatFilter extends React.Component {

constructor(props) {
    super(props);
   
    this.state = {
        chatstatus: 'all',
        daterange: 'lastthirtydays',
        selectedtext:'Choose Date Range ',
        rangepicker:false,
        csvdata:[],
        serach:'',
        filter:'',
        isOpen: false


    };
    }
    openModal= ()=> {
        this.setState({isOpen: true})
      }

chatStatusChange=(e)=>{
    this.setState({
        chatstatus:e.target.value
    })
    
        this.props.onSubmitFilter('chatstatus',e.target.value);
    
    
}

chatTimeChange=(e)=>{
    
    if(e.target.value=='daterange'){
       
        this.setState({
            rangepicker:true,
            daterange:e.target.value
        },function(){
            document.querySelector(".showrangepicker").click();
        })
        
        
    }else{
        this.setState({rangepicker:false,daterange:e.target.value});
        this.props.onSubmitFilter(e.target.value,{rangepicker:false,daterange:e.target.value});
    }
    
}
applyDateRange=(event, picker)=>{
   
    this.setState({
        'selectedtext':picker.startDate.format('MM/DD/YYYY') +" - " + picker.endDate.format('MM/DD/YYYY')
    });
    let range={};
    range['chatfrequency']='daterange'
    range['start']=picker.startDate.format('MM/DD/YYYY')
    range['end']=picker.endDate.format('MM/DD/YYYY')
    this.props.onSubmitFilter('daterange',{rangepicker:true,daterange:range});
}
handleClick = (e) => {
    this.datepicker.click();
  }



render() {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final=month+"/"+date+"/"+year;
    
    console.log("newupdate",this.props.csvdata);
    return (
<div className="kt-form kt-form--label-right">

    <div className="col-xl-12 order-2 order-xl-1">
        <div  className="row">
           <div className="col-xl-2  col-lg-2">Show me stats from</div>
            <div className="col-xl-6  col-lg-6 col-sm-12" style={{marginTop:'-11px'}}>
                <div className="row align-items-center">
                    <div className=" kt-margin-b-20-tablet-and-mobile" style={{width:'140px'}}>
                        <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                                <label></label>
                            </div>
                            <div className="kt-form__control yr-time-ShowHide">
                            <span class="custom-arrow">
                                <select className="form-control bootstrap-select arrow-color mat-style yr-time-ShowHide" value={this.state.daterange} name="chatfrequency" id="kt_form_type" onChange={(e)=>this.chatTimeChange(e)}>
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="pastsevendays">Past 7 days</option>
                                    <option value="lastthirtydays">Last 30 days</option>
                                    <option value="pastmonth">Past Month</option>
                                    <option value="pastyear">Past Year</option>
                                    <option value="alltime">All Time</option>
                                    <option value="daterange">Date Range</option> 
                                </select>
                                </span>
                            </div>
                        </div>
                    </div> 
                
                {this.state.rangepicker &&  <div className="tbl-header-opt-date kt-margin-b-20-tablet-and-mobile ml-4">
                    <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                                <label></label>
                            </div> 
                            <DateRangePicker
                                initialSettings={{ startDate: final }}
                                onApply={(event, picker)=>this.applyDateRange(event, picker)}
                            >
                                <div className="showrangepicker">
                                
                            <div className="form-control arrow-color" style={{cursor:"pointer", border:0,borderRadius:0,borderBottom:"1px solid #cecece",height:"35px",padding:"0 !important",appearance:"none"}}>{this.state.selectedtext} </div> 
                            </div>
                            </DateRangePicker>
                </div></div>}
                </div>
            </div>
            {/* <div className="col-xl-3 col-lg-3" style={{marginTop:'-10px'}}>
                  {this.state.rangepicker &&  <div className="tbl-header-opt-date kt-margin-b-20-tablet-and-mobile">
                    <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                                <label></label>
                            </div> 
                            <DateRangePicker
                                initialSettings={{ startDate: final }}
                                onApply={(event, picker)=>this.applyDateRange(event, picker)}
                            >
                                <div className="showrangepicker">
                                
                            <div className="form-control arrow-color mat-style" style={{cursor:"pointer"}}> {this.state.selectedtext} </div> 
                            </div>
                            </DateRangePicker>
                </div></div>}
                </div> */}
                    
                  
                  <div className="col-xl-3 col-lg-3  text-right" style={{marginTop:'-11px'}}><div className="kt-margin-b-20-tablet-and-mobile">
                        <button type="button" className="btn linear-gradient yr-submitbtn" onClick={()=>this.props.doownloadCSV()}>
                            Export CSV</button>
                     </div></div>
        </div>

    </div>
 

</div>



);
    }
}
export default ChatFilter