import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import LinesEllipsis from 'react-lines-ellipsis'


export const DatatableRow = props => (
  <Table.Row>
    <Table.Cell>    <label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false}  id={props.list.id} onChange={()=>props.channgeEvent(props.list.id)}></input>&nbsp;<span></span></label></Table.Cell>
    <Table.Cell><span title={getTitle1(props.list.quick_text_title)}  class="textEllip">{ReactHtmlParser(props.list.quick_text_title)}</span></Table.Cell>
    <Table.Cell><span title={getTitle2(props.list.quick_text_desc)} class="textEllipDesc">{ReactHtmlParser(props.list.quick_text_desc)}</span></Table.Cell>
    
    <Table.Cell><a id={props.list.id} className='btn btn-icon' onClick={() => props.editQuickText(props.list.id)} title='Edit'><i className='la la-edit blue'></i></a><a onClick={() => props.deleteQuickText(props.list.id)} title='Delete' className='btn btn-icon gray'><i className='la la-trash'></i></a></Table.Cell>
  </Table.Row>
);

const getTitle1 = e=>{
    return(
      (e.length>=13)?e:""
          
    )
  }

  const getTitle2 = e=>{
    return(
      (e.length>=55)?e:""
          
    )
  }

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
