import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";

import { counter } from "@fortawesome/fontawesome-svg-core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import util_funs from '../../../../../util_funs'
import { Multiselect } from 'multiselect-react-dropdown';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
const options = [];
const regex = new RegExp("^[a-zA-Z0-9 ]+$");
export class DatatableContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      yourteamdata:[],
      team_id: "",
      team_name: "",
      teamlist: "",
      //team_name:'cc',
      filterValid: true,
      
    };
    this.getyourcallteam();
   
    
  }
  
  componentDidMount() {
    this.getyourcallteam();
}
componentDidUpdate(prevProps) {
   if(this.props.indextime!=prevProps.indextime){
            this.getyourcallteam();
  }
}
handleOnChange = (event, { name, value }) => {
    if (value !== "" && !regex.test(value)) {
      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == "") {
      this.props.onSubmitFilter("q", value);
    }
  };

  handleOnClick = () => {
    //alert('Test By Rohit..');
    let value = this.state.filter;
    if (value.trim() !== "") {
      this.props.onSubmitFilter("q", value);
    }
  };

  chatTimeChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });
    if (e.target.value == "daterange") {
      this.setState(
        {
          rangepicker: true,
          daterange: e.target.value,
        },
        function () {
          document.querySelector(".showrangepicker").click();
        }
      );
    } else {
      this.setState({ rangepicker: false, daterange: e.target.value });
      this.props.onSubmitFilter("chatfrequency", e.target.value);
    }
  };

  applyDateRange = (event, picker) => {
    this.setState({
      selectedtext:
        picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    });
    let range = {};
    range["chatfrequency"] = "daterange";
    range["startdaterange"] = picker.startDate.format("MM/DD/YYYY");
    range["enddaterange"] = picker.endDate.format("MM/DD/YYYY");
    this.props.onSubmitFilterDateReange(range);
  };
  handleClick = (e) => {
    this.datepicker.click();
  };

  //@Rohit
  getyourcallteam = () => {
    
    this.setState({
      loading: false, 
      yourteamdata:this.props.yourteamdata,
  },()=>{
    //document.querySelector('.newtestttt > div > button').addEventListener("click", this.addclick);
  });
  
  };
  
  // addclick=()=>{
  //   alert('hi')
  //   //alert(document.querySelector('.css-11unzgr').length);
  //   let team_id="'#teammemnemebclick'";
  //   //document.querySelector('.css-11unzgr').insertAdjacentHTML('beforeend', '<div onclick="function hi(){document.querySelector('+team_id+').click()};hi()" id="two">two</div>');
  
  // }
  onChange(value, event) {

    //alert('hi');
    console.log("Rohit Onchange");
   console.log(value);
    this.props.onSubmitFilter("team",value)
  }
 
  //@Ankit
  teamChange = (value, event) => {
    const TeamMember = value;
    const TeamMemberData = TeamMember.map((TeamMember) => TeamMember.value);
    this.setState({
      team: TeamMemberData,
    });
    this.props.onSubmitFilter("team", TeamMemberData);
    
  };

render() {
    const { filter } = this.state;
    const tmlist = this.state.yourteamdata;
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final = month + "/" + date + "/" + year;

    return (
    <div className="col-md-12">
      {/* <div className="kt-header__bottom"> */}
      <div className=""> 
      <div className="kt-container" style={{backgroundColor:"#fff"}}>
      <div className="yr-pagetitle d-flex justify-content-between">
          <div className="left yr-titleSelect kt-margin-t-10 kt-margin-b-10 flex-wrap justify-content-center">
          <Link to={'../playBookBranchWiseStats/'+util_funs.encrypt_url(this.props.playbookId)} className="btn linear-gradient yr-add-new float-right flex-m-n mb-3"> 
 <i className="fas fa-chevron-left" style={{fontSize:"12px"}}></i>Back to my email detail stats</Link> 
            <div className="yr-allselect">
                <span>Detailed stats: { this.props.playbookname }: { this.props.playbookbranch } </span>
            </div>
          </div>
      </div>
     </div>
     </div> 
     <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
     <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-details-analy">
              <div className="row">
                 <div className="col-lg-4">
                    <div className="yr-deliverrates">
                       <h3>Delivery rates</h3>
                       <div>
                          <table className="border">
                             <tbody>
                                <tr>
                                   <td colspan="2">
                                      <div className="yr-loader-area">
                                         <strong>{this.props.total_delivered_email_per}</strong>
                                         <hr className=""/>
                                         <div className="text-right">
                                            <strong>{this.props.total_delivered_email}</strong> emails delivered so far <strong>{this.props.total_send_email}</strong> total
                                         </div>
                                      </div>
                                   </td>
                                </tr>
                                <tr>
                                   <td>
                                      <div className="yr-dr-box">
                                         <h3>{this.props.total_delivered_email_per}</h3>
                                         <strong>Delivered</strong>
                                         <p><b>{this.props.total_delivered_email}</b> emails</p>
                                         <a href="" className="" data-toggle="modal" onClick={()=>this.props.getPlayBookData('2')}>More details</a> 
                                      </div>
                                   </td>
                                   <td>
                                      <div className="pd-15">
                                        <p>{this.props.total_soft_bounced_email_per} Soft-bounced <span className="info-icon"><i className="fas fa-question-circle"></i>
                                            <span className="clicktoshow" style={{ fontWeight:600 }}><strong><b>{this.props.total_soft_bounced_email}</b>  emails opened so far <b>{this.props.total_send_email}</b> total</strong></span>
                                            </span>
                                         </p>
                                         <p>{this.props.total_hard_bounced_email_per} Hard-bounced <span className="info-icon"><i className="fas fa-question-circle"></i>
                                         <span className="clicktoshow" style={{ fontWeight:600 }}><strong><b>{this.props.total_hard_bounced_email}</b>  emails opened so far <b>{this.props.total_send_email}</b> total</strong></span>
                                            </span>
                                         </p>
                                        </div>
                                   </td>
                                </tr>
                             </tbody>
                          </table>
                       </div>
                    </div>
                 </div>
                 <div className="col-lg-8">
                    <div className="yr-contactengagement">
                       <h3>Contact engagement</h3>
                       <ul>
                          <li>
                             <div className="yr-dr-box">
                                <h3>{this.props.total_open_email_per}</h3>
                                <strong>Opened <span className="info-icon"><i className="fas fa-question-circle"></i>
                                <span className="clicktoshow"><b>{this.props.total_open_email}</b>  emails opened so far <b>{this.props.total_send_email}</b> total</span>
                                </span>
                                </strong>
                                <p><b>{this.props.total_open_email}</b> emails</p>
                                <a href="" className="" data-toggle="modal" onClick={()=>this.props.getPlayBookData('0')}>More details</a> 
                             </div>
                          </li>
                          <li>
                             <div className="yr-dr-box">
                                <h3>{this.props.total_clicks_email_per}</h3>
                                <strong>Clicked <span className="info-icon"><i className="fas fa-question-circle"></i>
                                <span className="clicktoshow"><b>{this.props.total_clicks_email}</b>  emails clicked so far <b>{this.props.total_send_email}</b> total</span>
                               </span></strong>
                                <p><b>{this.props.total_clicks_email}</b> emails</p>
                                <a href="" className="" data-toggle="modal" onClick={()=>this.props.getPlayBookData('1')}>More details</a> 
                             </div>
                          </li>
                          <li>
                             <div className="yr-dr-box">
                                <h3>{this.props.total_spam_email_per}</h3>
                                <strong>Marked as spam <span className="info-icon"><i className="fas fa-question-circle"></i>
                                <span className="clicktoshow"><b>{this.props.total_spam_email}</b>  emails spam so far <b>{this.props.total_send_email}</b></span>
                                </span></strong>
                                <p><b>{this.props.total_spam_email}</b> emails</p>
                                <a href="" className="" data-toggle="modal" onClick={()=>this.props.getPlayBookData('3')}>More details</a> 
                             </div>
                          </li>
                       </ul>
                    </div>
                 </div>
              </div>
              <div className="row">
                 <div className="col-lg-12">
                  <div className="kt-portlet--tabs yr-mt-10">
                       <div className="kt-portlet__head">
                          <div className="kt-portlet__head-toolbar">
                             <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-success yr-detailedtab" role="tablist">
                                
                             <li className="nav-item">
                                   <a className={ this.props.tabval==0 ? 'nav-link active pointer' : 'nav-link pointer' } onClick={()=>this.props.getPlayBookData('0')} data-toggle="tab" role="tab" aria-selected="true">
                                   Opened  <span>{this.props.total_open_email}</span></a>
                                </li>
                                <li className="nav-item">
                                   <a className={ this.props.tabval==1 ? 'nav-link active pointer' : 'nav-link pointer' } onClick={()=>this.props.getPlayBookData('1')} data-toggle="tab" role="tab" aria-selected="false">
                                   Clicked  <span>{this.props.total_clicks_email}</span>
                                   </a>
                                </li>
                                <li className="nav-item">
                                 <a  className={ this.props.tabval==2 ? 'nav-link active pointer' : 'nav-link pointer' } onClick={()=>this.props.getPlayBookData('2')} data-toggle="tab" role="tab" aria-selected="false">
                                   Delivered <span>{this.props.total_delivered_email}</span>
                                   </a>
                                </li>
                               
                                 <li className="nav-item">
                                   <a className={ this.props.tabval==3 ? 'nav-link active pointer' : 'nav-link pointer' } onClick={()=>this.props.getPlayBookData('3')} data-toggle="tab"  role="tab" aria-selected="false">
                                   Spam <span>{this.props.total_spam_email}</span>
                                   </a>
                                </li>
                                <li className="nav-item">
                                   <a className={ this.props.tabval==4 ? 'nav-link active pointer' : 'nav-link pointer' } onClick={()=>this.props.getPlayBookData('4')} data-toggle="tab"  role="tab" aria-selected="false">
                                   Soft Bounced <span>{this.props.total_soft_bounced_email}</span>
                                   </a>
                                </li>
                                <li className="nav-item">
                                   <a className={ this.props.tabval==5 ? 'nav-link active pointer' : 'nav-link pointer' } onClick={()=>this.props.getPlayBookData('5')} data-toggle="tab"  role="tab" aria-selected="false">
                                   Hard Bounced <span>{this.props.total_hard_bounced_email}</span>
                                   </a>
                                </li>
                             </ul>
                          </div>
                       </div>
                       <div className="clearfix"></div>
                    </div>
                 </div>
              </div>
           </div>
          </div>
     </div>
  </div>
</div> 
    );
  }
}

DatatableContactPage.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  teamlist: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};
