import React from "react";
import {
    Form, Dropdown, TextArea
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import './Popup.css';
import 'semantic-ui-css/semantic.min.css';
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";
import utilFunc from "../../../../util_funs";
import DialogBoxAddEditLocations from './DialogBoxAddEditLocations';
import DialogBoxLocationsConfirmation from './DialogBoxLocationsConfirmation';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';


validationRule.isRequired();
validationRule.usPhoneLength();
validationRule.newPinAndOldPinNotSame();
const mapStyles = {
    height: "350px",
    width: "100%"
};

const defaultCenter = {
    lat: 33.703967, lng: -112.351835
}
const locations = [
    {
        location: {
            lat: 33.703967,
            lng: -112.351835
        },
    },

];
class DialogBoxLocationSettings extends React.Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    constructor(props) {
        super(props)
        this.state = {
            show: props.obj.locationSettingModalShow,
            title: props.obj.modaltitle,
            modalFormLoader: props.modalFormLoader,
            modalstatusNotiAandMiscData: props.modalstatusNotiAandMiscData,
            message: "none",
            color: "green",
            msg: "",
            message2: "none",
            message3: "none",
            memberLogo: "",
            imgError: "none",
            loading: "none",
            isd_code: "",
            jumpinMsg: "",
            switchValue1: "",
            switchValue2: "",
            switchValue3: "",
            switchValue4: "",
            switchValue5: "",
            switchValue6: "",
            switchValue7: "",
            switchValue8: "",
            pin: "",
            cpin: "",
            pinMessage: "Pin Setting has been saved successfully.",
            pinMessageState: "none",
            locationSettingAddEditModalShow: false,
            id: "",
            confirmationPopup: false,
            confirmationPopupTitle: "",
            confirmationPopupMsg: "",
            type: "",
            loc_id: "",
            selectedMName: "",
            selectedMLoc: {}


        }


        let modalstatusNotiAandMiscData = this.props.modalstatusNotiAandMiscData;

    }

    changeSwitch = (e) => {
        var val = "";
        if (e.target.value == 'ON') {
            val = "OFF";
        } else {
            val = "ON";
        }
        this.setState({ loading: "block" })
        api.manageUser
            .teamMemberProfileSwitchSave({ id: this.props.id, value: val, which: "recieve_all_chat_if_not_in_current_location" })
            .then((resp) => {

                this.setState({
                    message: "block",
                    msg: "Setting has been saved successully.",
                    loading: "none",

                })
                var ref = this;
                setTimeout(function () {
                    ref.setState({
                        message: "none",
                        msg: ""
                    })
                }, 5000)

            })
            .catch((e) => {
                console.log(e);
            });


        this.props.onValidSubmitSwitchFromLocation(val)
    }

    openLocationSettingPopupWithAddEdit = e => {
        this.setState({
            locationSettingAddEditModalShow: true,
            id: this.props.id,
            sessionUser: this.props.sessionUser,

        })
    }

    closeLocationSettingPopupWithAddEdit = e => {
        this.setState({
            locationSettingAddEditModalShow: false,
        })
    }

    componentDidMount = () => {
        // this.setState({loading:"block"})


    }

    deleteLocationConfirmationPopup = (loc_id, type) => {
        this.setState({
            confirmationPopup: true,
            loc_id: loc_id,
            sessionUser: this.props.sessionUser,
            confirmationPopupTitle: "Confirm your action",
            confirmationPopupMsg: "Are you sure you want to delete?",
            type: type

        })

    }

    deleteLocationConfirmationPopupClose = e => {
        this.setState({
            confirmationPopup: false,
        })
    }

    deleteLocationNow = (id, type) => {
        this.props.refreshLocationSetting();

        this.setState({
            confirmationPopup: false,
        })
    }

    renderStateLocation() {
        const userState = this.props.userState;
        var l = userState.length
        if (l == 0) {
            return (
                <div class="mh-info_2">
                    <div class="map-zip">
                        <span>No information available for state.</span>
                    </div>

                </div>
            )
        } else {
            return userState.map(
                (
                    {
                        state,
                        name,
                        id
                    },
                    idx
                ) =>

                    <div class="mh-info_2" style={{ marginBottom: 0 }}>
                        <div class="map-zip">
                            <span>State</span>
                            <en id="editzipcode" wraper_val={name}>{name}</en>
                            <span style={{ marginLeft: 20 }}><a onClick={() => this.deleteLocationConfirmationPopup(id, "state")} title='Delete' className='btn btn-icon gray'><i style={{ color: "red" }} className='la la-trash'></i></a></span>
                        </div>

                    </div>

            );
        }
    }

    renderZipLocation() {
        const locationDataResult = this.props.locationDataResult;
        var l = locationDataResult.length
        if (l == 0) {
            return (
                <div class="mh-info_2">
                    <div class="map-zip">
                        <span>No information available for zip.</span>
                    </div>

                </div>
            )
        } else {
            return locationDataResult.map(
                (
                    {
                        zipcode,
                        willingTravel,
                        travel_pref_mode,
                        id
                    },
                    idx
                ) =>

                    <div class="mh-info_2">
                        <div class="map-zip">
                            <span>Zip Code</span>
                            <span id="mh-zipCodeLocation" wraper_val={zipcode}>{zipcode}</span>
                            <span style={{ marginLeft: 20 }}><a onClick={() => this.deleteLocationConfirmationPopup(id, "zip")} title='Delete' className='btn btn-icon gray'><i style={{ color: "red" }} className='la la-trash'></i></a></span>

                        </div>
                        <div class="map-travel">
                            <span>Travel Preference</span>
                            <en id="editwillingTravel" wraper_val={willingTravel}>{(willingTravel != 0) ? willingTravel + " Miles" : "Yet to set"} </en>
                        </div>
                    </div>

            );
        }
    }

    afterLocationAddEditDelete = (type, name) => {
        if (name == "zip" && type == "add") {
            var msg = "Zip location has been added successfully."
        }

        if (name == "zip" && type == "delete") {
            var msg = "Zip location has been deleted successfully."
        }

        if (name == "state" && type == "add") {
            var msg = "State location has been added successfully."
        }

        if (name == "state" && type == "delete") {
            var msg = "State location has been deleted successfully."
        }

        this.setState({
            message: "block",
            msg: msg
        })
        var ref = this;
        setTimeout(function () {
            ref.setState({
                message: "none",
                msg: msg
            })
        }, 5000)
    }
    componentDidMount() {

    }


    componentDidUpdate(prevProps) {
        if (this.props.id != prevProps.id) {
            //this.drawMap()
        }

    }

    drawMap = e => {

    }

    onSelect = e => {
        this.setState({
            selectedMName: e.name,
            selectedMLoc: e.location
        })


    }

    setSelected = e => {
        this.setState({
            selectedMName: "",
            selectedMLoc: {}
        })
    }

    render() {
        const errorLabel = <div className="red" />
        return (
            // (this.props.loadNow)?
            <React.Fragment>
                {<div style={{ display: this.state.loading }} class="ui loading form mh-loading-custom"></div>}
                <div
                    id="notificationLocationSetingModalPopup"
                    className={this.props.obj.locationSettingModalShow ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.obj.locationSettingModalShow ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "90%" }} role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.props.closeLocationsetting()}
                            >
                                X
                            </button>
                            <div className="modal-header">
                                <h5 className="modal-title">Location Settings</h5>
                            </div>

                            {this.state.modalFormLoader && <div className="ui loading form"></div>}
                            <div className="modal-body" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                <div class="col-lg-12">
                                    <Message
                                        color={
                                            this.state.color
                                        }
                                        style={{
                                            display: this.state.message,
                                        }}
                                    >{this.state.msg}</Message>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mh-prof-title">Company Location</div>
                                            <div class="map-section" style={{ width: "100%", marginTop: "0px" }}>
                                                <div id="map_canvas" style={{ width: "100%", height: "300px" }}>
                                                    <LoadScript
                                                        googleMapsApiKey='AIzaSyCTdtzDSSjnAEZR8JY8d4eEERwBhlbiYcg'>
                                                        {this.props.obj.locationDataGmap && <GoogleMap
                                                            mapContainerStyle={mapStyles}
                                                            zoom={8}
                                                            center={defaultCenter}>
                                                            {
                                                                this.props.obj.locationDataGmap.map(item => {
                                                                    return (
                                                                        <Marker key={item.name} position={item.location}
                                                                            onClick={() => this.onSelect(item)}

                                                                        />
                                                                    )
                                                                })
                                                            }

                                                            {
                                                                this.state.selectedMName &&
                                                                (
                                                                    <InfoWindow
                                                                        position={this.state.selectedMLoc}
                                                                        clickable={true}
                                                                        onCloseClick={() => this.setSelected()}
                                                                    >
                                                                        <p>{this.state.selectedMName}</p>
                                                                    </InfoWindow>
                                                                )
                                                            }


                                                        </GoogleMap>}
                                                    </LoadScript>

                                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d41724.794723746614!2d-122.99227581241134!3d49.185387891944394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sIsland!5e0!3m2!1sen!2sin!4v1470381579788"
                                                        width="100%" height="100%" frameborder="0" style={{ border: 0 }} allowfullscreen></iframe> */}
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-6">

                                            <div className="form-group row" style={{ marginBottom: 0 }}>
                                                <div class="col-lg-12" style={{ marginBottom: 10 }}>
                                                    <button class="btn linear-gradient yr-add-new float-right" onClick={() => this.openLocationSettingPopupWithAddEdit()} >Add User Locations</button>
                                                </div>
                                                <label className="col-10 col-form-label"> Receive all chat notifications even if they aren't in this current location</label>
                                                <div className="col-2">

                                                    <span className={this.props.recieve_all_chat_if_not_in_current_location == "1" ? "kt-switch kt-switch-sm kt-switch--success chat-cus-check" : "kt-switch kt-switch-sm kt-switch--error chat-cus-check"}>
                                                        <label>
                                                            <input name="recieve_all_chat_if_not_in_current_location" type="checkbox" value={this.props.recieve_all_chat_if_not_in_current_location == "1" ? "ON" : "OFF"} onClick={(e) => this.changeSwitch(e)} onClick={(e) => this.changeSwitch(e)} checked={this.props.recieve_all_chat_if_not_in_current_locationChecked} />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <div class="col-lg-6" style={{ height: 250, overflow: "auto" }}>
                                                    {this.renderZipLocation()}



                                                </div>
                                                <div class="col-lg-6">
                                                    {this.renderStateLocation()}
                                                    {/* <div class="mh-info_2" style={{ height: 250, overflow: "auto" }}>
                                                        <div class="map-zip">
                                                            <span>State</span>
                                                            Arizona
                                                    </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="kt-portlet__foot foot">
                                <div className="kt-form__actions">
                                    <div className="row">
                                        <div className="col-lg-12 d-flex justify-content-end">
                                            <button onClick={() => this.props.closeLocationsetting()} type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
                                                <span>Cancel</span>
                                            </button>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <DialogBoxAddEditLocations
                    obj={this.state}
                    title={this.state.modaltitle}
                    id={this.state.id}
                    sessionUser={this.props.sessionUser}
                    closeLocationSettingPopupWithAddEdit={this.closeLocationSettingPopupWithAddEdit}
                    refreshLocationSetting={this.props.refreshLocationSetting}
                    afterLocationAddEditDelete={this.afterLocationAddEditDelete}
                    locationState={this.props.obj.locationState}


                />
                <DialogBoxLocationsConfirmation
                    obj={this.state}
                    deleteLocationConfirmationPopupClose={this.deleteLocationConfirmationPopupClose}
                    deleteLocationNow={this.deleteLocationNow}
                    refreshLocationSetting={this.props.refreshLocationSetting}
                    afterLocationAddEditDelete={this.afterLocationAddEditDelete}
                    locationState={this.props.obj.locationState}

                />
            </React.Fragment>
        );
    };
}

export default DialogBoxLocationSettings;
