import React from "react";
import api from "../../../../../../api";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { counter } from "@fortawesome/fontawesome-svg-core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Multiselect } from 'multiselect-react-dropdown';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
const options = [];
const regex = new RegExp("^[a-zA-Z0-9 ]+$");

export class DatatableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      yourteamdata:[],
      team_id: "",
      team_name: "",
      teamlist: "",
      //team_name:'cc',
      filterValid: true,
      disableClassName : "mh-ena kt-form__control yr-arrow"

      
    };
    this.getyourcallteam();
    
  }
  
  componentDidMount() {
    this.getyourcallteam();
}

componentDidUpdate(prevProps) {

  if(this.props.indextime!=prevProps.indextime){
            
    this.getyourcallteam();
  }
}
handleOnChange = (event, { name, value }) => {
    if (value !== "" && !regex.test(value)) {
      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == "") {
      this.props.onSubmitFilter("q", value);
    }
  };

  handleOnClick = () => {
    //alert('Test By Rohit..');
    let value = this.state.filter;
    if (value.trim() !== "") {
      this.props.onSubmitFilter("q", value);
    }
  };

  chatTimeChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });
    if (e.target.value == "daterange") {
      this.setState(
        {
          rangepicker: true,
          daterange: e.target.value,
        },
        function () {
          document.querySelector(".showrangepicker").click();
        }
      );
    } else {
      this.setState({ rangepicker: false, daterange: e.target.value });
      this.props.onSubmitFilter("chatfrequency", e.target.value);
    }
  };

  applyDateRange = (event, picker) => {
    this.setState({
      selectedtext:
        picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    });
    let range = {};
    range["chatfrequency"] = "daterange";
    range["startdaterange"] = picker.startDate.format("MM/DD/YYYY");
    range["enddaterange"] = picker.endDate.format("MM/DD/YYYY");
    this.props.onSubmitFilterDateReange(range);
  };
  handleClick = (e) => {
    this.datepicker.click();
  };

  //@Rohit
  getyourcallteam = () => {
    let data=JSON.stringify(this.props.yourteamdata)
    this.setState({
      loading: false, 
      yourteamdata:(this.props.yourteamdata.length==0)?null:data,
      disableClassName:(this.props.yourteamdata.length==0)?"kt-form__control yr-arrow mh-dis first--checklist":"mh-ena kt-form__control yr-arrow first--checklist"
  },()=>{
    //document.querySelector('.newtestttt > div > button').addEventListener("click", this.addclick);
  });
  
  };
  
  // addclick=()=>{
  //   alert('hi')
  //   //alert(document.querySelector('.css-11unzgr').length);
  //   let team_id="'#teammemnemebclick'";
  //   //document.querySelector('.css-11unzgr').insertAdjacentHTML('beforeend', '<div onclick="function hi(){document.querySelector('+team_id+').click()};hi()" id="two">two</div>');
  
  // }
  onChange(value, event) {

    //alert('hi');
    console.log("Rohit Onchange");
   console.log(value);
    this.props.onSubmitFilter("team",value)
  }
 
  //@Ankit
  teamChange = (value, event) => {
    const TeamMember = value;
    const TeamMemberData = TeamMember.map((TeamMember) => TeamMember.value);
    this.setState({
      team: TeamMemberData,
    });
    this.props.onSubmitFilter("team", TeamMemberData);
    
  };

  onPress = ()=>{
    alert("hi");
  }

render() {
    const { filter } = this.state;
    const tmlist = this.state.yourteamdata;
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final = month + "/" + date + "/" + year;

    return (
      <div className="kt-form kt-form--label-right yr-manageUser-table">
        <div className="row align-items-center">
          <div className="col-xl-12 order-2 order-xl-1">
            <div className="row align-items-center"> 
              
              <div className="col-md-12 kt-margin-b-20-tablet-and-mobile" >
                <div className="row align-items-center" style={{justifyContent: "flex-end"}}>
                  <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                <div className="kt-input-icon kt-input-icon--left">
                  <Form onSubmit={this.handleOnClick}>
                    <Form.Input
                      style={{ marginLeft: 4 }}
                      name="filter"
                      className="search-input-mu"
                      value={filter}
                      onChange={this.handleOnChange}
                    />
                  </Form>
                  <span
                    className="kt-input-icon__icon kt-input-icon__icon--left"
                    style={{ cursor: "pointer" }}
                    onClick={this.handleOnClick}
                  >
                    <span>
                      <i className="la la-search"></i>
                    </span>
                  </span>
                  <span className="yr-searchPlace-holder">Search</span>
                </div>
                </div>
                  <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile call-list-team-member">
                    <div className="kt-form__group kt-form__group--inline">
                      <div className="kt-form__label">
                        <label></label>
                      </div>
                      <div id="teamMemberCallList" className={this.state.disableClassName}>
                        <ReactMultiSelectCheckboxes
                          placeholderButtonLabel="Team Member"
                          options={this.state.yourteamdata}
                          onChange={this.teamChange}
                          setState={this.state.yourteamdata}
                          onClick = {this.onPress}
                            />
                          <a>&nbsp;</a>
                      </div>
                    </div>
                  </div>
                  <div className="addicon">
                   <div className="pointer" title="Add Team Member" onClick={()=>this.props.viewteam()} ><img src="../../assets/images/plus-blue.svg" alt="" width="10" height="10"/></div>
                  </div>
                  <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                    <div className="kt-form__group kt-form__group--inline">
                      <div className="kt-form__label">
                        <label></label>
                      </div>
                      <div className="kt-form__control yr-time-ShowHide">
                        <span class="custom-arrow">
                          <select
                            className="form-control bootstrap-select arrow-color mat-style call-list-option yr-time-ShowHide"
                            value={this.state.daterange}
                            name="chatfrequency"
                            id="kt_form_type"
                            onChange={(e) => this.chatTimeChange(e)}
                          >
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="pastsevendays">Past 7 days</option>
                            <option value="pastmonth">Past Month</option>
                            <option value="pastyear">Past Year</option>
                            <option value="alltime" selected>All Time</option>
                            <option value="daterange">Date Range</option>
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="kt-margin-b-20-tablet-and-mobile" id="mh-CallList">
                    <button
                      type="button"
                      className="btn linear-gradient yr-submitbtn calllist-btn"
                      onClick={() => this.props.downloadCSV()}
                     >
                      Export CSV
                    </button>
                  </div>
                  {this.state.rangepicker && (
                    <div className="col-xl-12 order-2 order-xl-1">
                      <div className="tbl-header-opt-date kt-margin-b-20-tablet-and-mobile yr-d-float-right">
                        <div className="kt-form__group kt-form__group--inline">
                          <div className="kt-form__label">
                            <label></label>
                          </div>

                          <DateRangePicker
                            initialSettings={{ startDate: final }}
                            onApply={(event, picker) =>
                              this.applyDateRange(event, picker)
                            }
                          >
                            <div className="showrangepicker">
                              <div
                                className="form-control arrow-color mat-style"
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {this.state.selectedtext}{" "}
                              </div>
                            </div>
                          </DateRangePicker>

                          
                          {/* <div className="kt-form__control">
                   
                    <select className="form-control bootstrap-select arrow-color mat-style" id="kt_form_type">
                        <option value="">05/04/2020 - 06/05/2020</option>
                        <option value="1">05/02/2020 - 06/02/2020</option>
                    </select>
                </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DatatableFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  teamlist: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};
