import React from "react";
import "../../../../../Modal/dialog.css";
import api from "../../../../../../api";
import { Table } from 'semantic-ui-react';

const DialogContactDetail = ({ show, title, msgErr, viewteam, responsemsgdone, closeNotesteam, notetmist, teamDelete, veiwnotesteam, assignNotesteam, message, modalType, modalClose, buttonmsg, modalbuttofun, loading, tmnotesdata, addNotesteam ,getTeamMembers,loadData, updateMessageErr, updateMessage}) => {
    
	notetmist = tmnotesdata; 
	console.log(tmnotesdata);
	let error_msg = "";

    addNotesteam=()=> {

		   //alert('HI');
           var team_member_name  = document.getElementById("notes_team_member").value;

		   if(team_member_name == ""){
			   //errorMsg('please enter team member');

			   error_msg = "please enter team member";
			   updateMessageErr("This field is required");
		
			}else{

				
				api.marketing.addteam({'team_member_name': team_member_name}).then((data) => {
				console.log(data)
					try {   
						if (data.Errmsgcode == 0){
							updateMessageErr("This team member already exists");
						}else{
							//alert('addNotesteam');
							document.getElementById("notes_team_member").value = "";
							viewteam();
							updateMessage("Team member added successfully.");
							//modalClose(true, '');
							//this.getyourcallteam();
							//getTeamMembers();
							//loadData({});
                            //updateMessage("Team member added successfully.");
							
						}
				} catch (err) {
					console.log(err);
				}
			});
	}
	 }
	 closeNotesteam=()=> {

		document.getElementById("notes_team_member").value = '';
		getTeamMembers();
		loadData({});
		modalClose(true, '');
	 }
	


	return (
		
		<React.Fragment>
			<div
				className={show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup team-model"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none", "z-index": "117", overflow:"visible" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
				  {loading && <div className="ui loading form"></div>}
					<div className="modal-content white-bg yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => closeNotesteam()}
						>
							X
						</button>
						<div className="modal-body">
						<h1 className="modal-title-team-member">Manage Team Members</h1>
						<hr/>
						 <div className="kt-portlet__body">
						      <div className="kt-section kt-section--first">									
									<div className="kt-section__body mt-4">
									  <div className="row">
										<div className="col-lg-12 d-flex justify-content-center mt-4">
										     <div className="col-lg-8">
												<input type="text" id="notes_team_member"  placeholder="Team member name" class="form-control-notes"/>
												<div style={{color: "red"}} id="error_msg">{msgErr}</div>
											</div>
											<div className="col-lg-4">
											   <button type="submit" className="btn linear-gradient yr-submitbtn calllist-btn" onClick={()=>addNotesteam()}>Add</button>
											</div>
										 </div>
												{/* <button type="reset" className="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={()=>modalClose(false, '')}><span>No</span></button> */}
										
										 <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mt-4 list-team-membar">
										 
										 { //Check if message failed
											  (responsemsgdone == "Team member added successfully.")
											     ? <div className="col-lg-12 ui green message del-msg-padding">{responsemsgdone}</div>
											   : <div></div>
											}
										  
										   { //Check if message failed
											  (title == "Team member deleted successfully.")
											     ? <div className="col-lg-12 ui green message del-msg-padding">{title}</div>
											   : <div></div>
											}
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center mt-4 list-team-membar">
									    <div className="well-team col-lg-12 col-md-12 col-sm-12 form-group" style={{paddingLeft:20,paddingRight:20}}>
											  { tmnotesdata && Object.keys(notetmist).map(function (key){
															 //console.log(key);
                                    					return ( 

															
																<div className="col-lg-12 d-flex justify-content-center mt-4 team-list">
																{/* <div className="col-lg-1">
																	<label class="kt-checkbox kt-checkbox--single">
																	<input type="checkbox" value={notetmist[key].tm_id}/>&nbsp;<span></span></label>
																</div> */}
																
																<div className="col-lg-9">{notetmist[key].tm_name}</div>
																		<div className="col-lg-3" style={{width:'15px', height:'15px'}}>
																			<i className="filter-del call-notes-red  la la-trash pointer del" title="Delete" onClick={()=>teamDelete(notetmist[key].tm_id)}></i> 
																		</div>
																</div>
                                    					      )
                                					   })}

													   {(tmnotesdata)?"":"No team member found."}
											       </div>
											   </div>
										   </div>
									   </div>									
								   </div>
							   </div>
							</div>
						</div>
					</div>
				</div>
		</React.Fragment>
	);
};

export default DialogContactDetail;