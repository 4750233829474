import React from "react";
import {
	Form, Dropdown
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import "../../../Modal/dialog.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const DialogBoxConfirmation = ({ obj, DialogBoxConfirmationClose,DialogBoxConfirmation, onDialogBoxConfirmationTransfer,onDialogBoxConfirmationMarkAsClosed }) => {
	let show = DialogBoxConfirmation.show;
	let yesOn = DialogBoxConfirmation.yesOn;
	let title = DialogBoxConfirmation.modaltitle;
	let value = DialogBoxConfirmation.value;
	let message = DialogBoxConfirmation.message;
	let modalFormLoader = obj.modalFormLoader;
	const errorLabel = <div className="red" />


	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" ,zIndex: 99999999}}
			>
				<div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "590px", maxHeight: "294px" }} role="document">
					<div className="modal-content" >
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => DialogBoxConfirmationClose(false, 'formManageUser')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<Form noValidate autoComplete="off" id="formManageUser" >
							{modalFormLoader && <div className="ui loading form"></div>}
							<div className="modal-body" >
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first" style={{marginLeft:"19px"}}>
										{ReactHtmlParser(message)}
									</div>
								</div>
							</div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<button type="reset" onClick={() => DialogBoxConfirmationClose(false)} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> {(yesOn=="block")?"No":"Ok"} </span></button>
											<button style={{display:yesOn}} type="submit"onClick={() =>  onDialogBoxConfirmationMarkAsClosed(value)} className="btn linear-gradient yr-submitbtn">Yes</button>
										</div>
									</div>
								</div>
							</div>
						</Form>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxConfirmation;
