import React from "react";
import { connect } from "react-redux";
import { Rating, Image, Message } from "semantic-ui-react";

import api from "../../../api";
import "./ConnectReviewSites.css";
import GoogleLogin from "react-google-login";
//import FacebookLogin from 'react-facebook-login';
import {
	Form,Dropdown,TextArea, Input
} from 'formsy-semantic-ui-react';

import config from "../../../config";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
} from "../../../store/Page";
import { Link } from "react-router-dom";
import GoogleReview from "./GoogleReview";
const queryString = require("query-string");

class ConnectReviewSites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fbData: null,
      pageData: null,
      selectedPage: null,
      pages: [],
      faceBookLogin: true,
      pageModal: false,
      subscribedPages: [],
      loading: false,
      message: null,
      reputationStart: 0,
      reputationType: "",
      ReviewAllCount: 0,
      StarCount: 0,
      AvgRating: 0,
      star_one: "star_custom_crs",
      star_two: "star_custom_crs",
      star_three: "star_custom_crs",
      star_four: "star_custom_crs",
      star_five: "star_custom_crs",
      reviewToaster: false,
      reviewToasterMsg: "",
      reviewToasterColor: "",
      subscribedLink:[]
    };
    this.FB = window.FB;
    this.avgRating();
    this.ReviewAllCount();
    this.linkList()
  }



  onValidSubmitReviewsLink= (e) =>{
    this.setState({
      loading: true,
    })
    this.setState({
      submitButton:1
    })


    const sendData  = {"link":e.link}
    api.reviews.addReviewsLinkFacebook(sendData).then(data => {
      try {
        this.setState({
          phone:'',
          submitButton:0,
          loading: false,
        })
        this.linkList()
        this.showToasterReviews('green', 'Review link has been added successfully.');
        document.getElementById("reviewForm").reset();
      }catch(err){
        console.log(err)
      }

    })

  }

  DeleteLink(id){
    this.setState({
      loading: true,
    })
    this.setState({
      submitButton:1
    })
    const sendData  = {"id":id}
    api.reviews.deleteReviewsLinkFacebook(sendData).then(data => {
      try {
        this.setState({
          submitButton:0,
          loading: false,
        })
        this.showToasterReviews('green', 'Review link has been deleted successfully.');
        this.linkList()
      }catch(err){
        console.log(err)
      }

    })

  }


  showToasterReviews = (color, msg) => {
    this.setState({
      reviewToaster: true,
      reviewToasterMsg: msg,
      reviewToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewToaster: false });
      }.bind(this),
      3000
    );
  };


  ReviewAllCount() {
    api.reviews.ReviewAllCount().then((response) => {
      try {
        this.setState({
          ReviewAllCount: response.data,
        });
      } catch (err) {
        console.log(err);
      }
    });
  }

  changeStar() {
    let starCount = this.state.StarCount;
    let AvgRating = this.state.AvgRating;
    console.log(starCount);
    for (let index = 1; index <= starCount; index++) {
      if (index == 1) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_one: "star_custom_crs half",
          });
        } else {
          this.setState({
            star_one: "star_custom_crs on",
          });
        }
      }
      if (index == 2) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_two: "star_custom_crs half",
          });
        } else {
          this.setState({
            star_two: "star_custom_crs on",
          });
        }
      }
      if (index == 3) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_three: "star_custom_crs half",
          });
        } else {
          this.setState({
            star_three: "star_custom_crs on",
          });
        }
      }
      if (index == 4) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_four: "star_custom_crs half",
          });
        } else {
          this.setState({
            star_four: "star_custom_crs on",
          });
        }
      }
      if (index == 5) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_five: "star_custom_crs half",
          });
        } else {
          this.setState({
            star_five: "star_custom_crs on",
          });
        }
      }
    }
  }
  avgRating() {
    api.reviews.avgReviewAll().then((response) => {
      try {
        let respons = response.AvgRating;
        if ((respons >= 1) & (respons < 2) || respons < 1) {
          this.setState({
            reputationType: "Very Poor",
          });
        } else if ((respons >= 2) & (respons < 3)) {
          this.setState({
            reputationType: "Poor",
          });
        } else if ((respons >= 3) & (respons < 4)) {
          this.setState({
            reputationType: "Average",
          });
        } else if ((respons >= 4) & (respons < 5)) {
          this.setState({
            reputationType: "Good",
          });
        } else if ((respons >= 5) & (respons < 6)) {
          this.setState({
            reputationType: "Healthy",
          });
        }
        console.log(response);
        this.setState({
          AvgRating: response.AvgRating,
          StarCount: response.Star_count,
        });
        this.changeStar();
      } catch (err) {
        console.log(err);
      }
    });
  }

  signup(res) {
    const googleresponse = {
      Name: res.profileObj.name,
      email: res.profileObj.email,
      token: res.googleId,
      Image: res.profileObj.imageUrl,
      ProviderId: "Google",
    };
    api.socialLogin.googleLogin(googleresponse).then((data) => {});
  }

  responseFacebook = (response) => {
    console.log(response);
  };

  facebookLogin = () => {
    
    const obj = this;
    this.FB.login(
      function (response) {
        if (response.authResponse) {
          this.FB.api(
            `/oauth/access_token?grant_type=fb_exchange_token&client_id=${config.facebookAppId}&client_secret=${config.facebookClientSecret}&fb_exchange_token=` +
              response.authResponse.accessToken,
            function (response3) {
              this.responseAT = response3;
              this.FB.api(
                "/me/accounts?access_token=" +
                  this.responseAT.access_token +
                  "&fields=picture{url},access_token,category,category_list,id,name,tasks",
                function (response1) {
                  console.log(response1);
                  if (response1.data) {
                    if (response1.data.length === 0) {
                      obj.setState(
                        {
                          errorMessage: "You dont have any page to connect.",
                        },
                        () => {
                          setTimeout(() => {
                            obj.setState({ errorMessage: null });
                          }, 5000);
                        }
                      );
                    } else {
                      const formatedPageList = response1.data;

                      obj.setState({
                        fbData: response,
                        pageData: formatedPageList,
                        selectedPage: {
                          page_name: formatedPageList[0].name,
                          page_id: formatedPageList[0].id,
                          page_access_token: formatedPageList[0].access_token,
                        },
                        pages: response1.data,
                        faceBookLogin: false,
                        pageModal: true,
                      });
                    }
                  }
                }
              );
            }
          );
        }
      },
      {
        scope: "email,pages_show_list,pages_messaging,manage_pages",
        return_scopes: true,
      }
    );
  };

  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setSubHeaderTitle,
      setNavigationName,
      user,
    } = this.props;
    setPageTitle({
      title: "Connect Review Site",
      subHeaderTitle: "Review Management",
    });
    setSubHeaderTitle({ subHeaderTitle: "Review Management" });
    setNavigationName({ navigationName: "reviewManagement" });
    toggleNavigation({ toggle: true });
    const params = queryString.parse(location.search);

    if (user && user.user_id) {
      this.setState({ loading: true });
      api.reviews
        .getSubscribedPages({ userId: user.user_id })
        .then((resp) => {
          if (resp) {
            this.setState({ loading: false, subscribedPages: resp.pageList });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
    this.setState({

      loading:false
   });
  }
  togglePageModal(obj) {
    obj.setState({ pageModal: false });
  }
  loadConnectPages() {
    return (
      <div
        className={this.state.pageModal ? "modal fade show " : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.pageModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.togglePageModal(this)}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Facebook Pages"}</h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">{this.loadFacebookPages()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  loadPages = () => {
    return this.state.subscribedPages.map((page, index) => {
      console.log(page);
      return (
        <div className="page-item" id="" key={index}>
          <div
            className="page-item-container"
            style={{ fontSize: "15px", color: "#a0a0a0" }}
          >
            {page.fbpagename}
          </div>
          <div className="action-block yr-block">
            <button
              onClick={(accessToken, pageId, autoId) =>
                this.confirmUnsubscribePage(
                  page.page_access_token,
                  page.page_id,
                  page.id
                )
              }
              className="btn linear-gradient"
              style={{ margin: "10px 10px 10px 0" }}
            >
              Disconnect
            </button>
          </div>
        </div>
      );
    });
  };

  linkList = ()=>{
    api.reviews.LinkListFacebook().then(data => {
      this.setState({
        subscribedLink:data.subscribedLink
      })
    }) 
  }
  loadLink = () => {
  
  return this.state.subscribedLink.map((page, index) => {
    return (
      <div className="page-item" id="" key={index}>
<div className="row">
    <div className="col-lg-9">
      
         <input name = "link" 
          disabled = {true}
          value = {page.link}
          className =  "form-control"
          />																		
            </div>		
            <div className="col-lg-3 d-flex justify-content-end">
           <button onClick={() => this.DeleteLink(page.id)}  className="btn linear-gradient zy-save-btn">Remove</button>
         </div>
      </div>
      </div>
    );

});
  };


  removePage = (pageId) => {
    const subscribedPages = [];
    this.state.subscribedPages.map((page) => {
      if (page.page_id !== pageId) {
        subscribedPages.push(page);
      }
    });

    this.setState({ subscribedPages });
  };
  unSubscribePage = (accessToken, pageId, autoId) => {
    this.setState({
      loading: true,
      pageData: null,
      unsubscribePageInfo: null,
    });

    this.FB.api(
      "/" + pageId + "/subscribed_apps",
      "DELETE",
      {
        subscribed_fields: "['feed', 'mention', 'name', 'picture']",
        App: config.facebookAppId,
        access_token: accessToken,
      },
      (response) => {
        console.log(response)
        if (!response.success) {
          alert("Sorry! You are not authorized to delete this App.");
        } else {
          api.reviews
            .deleteFbPage({ delete_id: autoId })
            .then((resp) => {
              this.removePage(pageId);
              if (resp && resp.message !== "") {
                this.setState({ loading: false, message: resp.message }, () => {
                  setTimeout(() => {
                    this.setState({ message: null });
                  }, 5000);
                });
              } else {
                this.setState({ loading: false });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loading: false });
            });
        }
      }
    );
  };
  confirmUnsubscribePage = (accessToken, pageId, autoId) => {
    this.unSubscribePage(accessToken, pageId, autoId);
  };

  handleChange = () => {
    var e = document.getElementById("fbPages");
    var selectedPageID = e.options[e.selectedIndex].value;
    if (this.state.pages.length > 0) {
      const selectedPageInfo = this.state.pages.map((page) => {
        if (page.id === selectedPageID) {
          this.setState({
            selectedPage: {
              page_name: page.name,
              page_id: page.id,
              page_access_token: page.access_token,
            },
          });
        }
      });
    }
  };
  loadFacebookPages() {
    let { pageData } = this.state;
    return pageData && pageData.length > 0 ? (
      <React.Fragment>
        <div id="lastMessage" className="yr-mainTitle">
          Choose a Page to Connect Your Bot to
        </div>
        <div id="pagesBar" className="yr-cplans">
          <p className="req-tos">
            {" "}
            By connecting your page you accept
            <a
              href="https://www.zyratalk.com/terms"
              style={{ margin: "15px 0 12px 5px" }}
              target="_blank"
            >
              terms of service
            </a>
          </p>
          <div className="yr-sml-h" style={{ margin: "0 0 0 246px" }}>
            {" "}
            YOUR FACEBOOK PAGES{" "}
          </div>

          <div className="pages-list">
            <div className="page-item" style={{ margin: "35px" }}>
              <div className="page-item-container">
                <div
                  className="page-item-image"
                  style={{
                    backgroundImage: `url("/assets/images/fb-flag.png")`,
                  }}
                ></div>

                <span className="page-item-text ng-binding">
                  <select
                    className="form-control"
                    id="fbPages"
                    name="fbPages"
                    onChange={this.handleChange}
                  >
                    {pageData.map((page, index) => {
                      return (
                        <option value={page.id} key={index}>
                          {page.name}
                        </option>
                      );
                    })}
                  </select>
                </span>
                <div className="" style={{ float: "right" }}>
                  <button
                    onClick={this.subscribeToZyra}
                    className="btn linear-gradient yr-submitbtn btn-sm"
                  >
                    Connect to page
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      ""
    );
  }

  updateFbPageInfo = () => {
    const { page_name, page_access_token, page_id } = this.state.selectedPage;
    api.reviews
      .updateFbPageInfo({
        page_name,
        page_access_token,
        page_id,
        fb_user_id: this.state.fbData.authResponse.userID,
      })
      .then((resp) => {
        if (resp) {
          const { user } = this.props;
          const message = resp.message;

          api.reviews
            .getSubscribedPages({ userId: user.user_id })
            .then((resp) => {
              if (resp) {
                this.setState(
                  {
                    subscribedPages: resp.pageList,
                    pageData: null,
                    fbData: null,
                    selectedPage: null,
                    pages: [],
                    message,
                    faceBookLogin: true,
                    loading: false,
                    pageModal: false,
                  },
                  () => {
                    console.log(this.state.subscribedPages);
                    setTimeout(() => {
                      this.setState({ message: null });
                    }, 5000);
                  }
                );
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loading: false });
            });
        }
      });
  };
  suscribePages = (pageId) => {
    const page_access_token = this.state.selectedPage.page_access_token;
    const obj = this;
    this.setState({ loading: true });
    this.FB.login(
      function (response) {
        this.FB.api(
          "/" + pageId + "/subscribed_apps",
          "POST",
          {
            subscribed_fields:
              "['feed', 'mention', 'name', 'picture','messaging_postbacks','messages']",
            App: config.facebookAppId,
            access_token: page_access_token,
          },
          function (response) {
            if (!response.success) {
              alert("Sorry! You are not authorized to use this App.");
            } else {
              obj.updateFbPageInfo();
            }
          }
        );
      },
      {
        scope: "pages_messaging,manage_pages",
        return_scopes: true,
        auth_type: "rerequest",
      }
    ); //permissions for facebook
  };
  subscribeToZyra = () => {
    if (Object.keys(this.state.selectedPage).length > 0) {
      this.suscribePages(this.state.selectedPage.page_id);
    }
  };

 
  render() {
    const responseGoogle = (response) => {
      console.log(response);
    };
    const errorLabel = <div className="red"/>
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content kt-grid__item kt-grid__item--fluid">
          <div className="yr-body-p yr-mt-20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet shadow-none">
                  <div className="kt-portlet__body yr-pd0-lr">
                    <div className="row px-lg-5">
  
                      <div className="col-lg-6">
                        <div className="yr-chat-round-box pb-5 mg-l-0 mx-lg-4 yr-shadow">
                          <h4 className="mb-4 conect-header">
                            Click below to connect your Facebook Business page 
                            with{" "}
                            <span className="con-highlight">ZyraTalk</span>
                          </h4>

                          <Image
                            centered
                            src="/assets/images/facebook.svg"
                            as="a"
                            onClick={this.facebookLogin}
                            style={{ cursor: "pointer" }}
                          />
                          
                          {this.state.message && (
                            <Message color={"green"}>
                              {this.state.message}
                            </Message>
                          )}
                          {this.state.loading && (
                            <div className="ui loading form"></div>
                          )}
                          {this.state.subscribedPages.length > 0 && (
                            <div>
                              <div className="yr-fbBox" id="yr-fbBox-main" style = {{marginTop:20}}>
                                <div
                                  className="yr-sml-h"
                                  style={{ fontSize: "15px" }}
                                >
                                  Already subscribed facebook pages{" "}
                                </div>
                                {this.state.subscribedPages.length > 0 &&
                                  this.loadPages()}
                              </div>
                              <br />
                            </div>
                          )}
                          


                        <div>
                        <Message
                          color={
                            this.state.reviewToasterColor
                              ? this.state.reviewToasterColor
                              : "green"
                          }
                          style={{
                            display: this.state.reviewToaster ? "block" : "none",
                            marginTop: 30,
                          }}
                        >
                          {this.state.reviewToasterMsg}
                        </Message>
                          <h4 style={{textAlign:"center"}}>OR</h4>
                        <h4 className="mb-4 conect-header">
                            If you haven't signed in, please add your Facebook review link below  
                            
                          </h4>
                          <div className ="requestReviewForm">
                          <Form noValidate autoComplete="off" id ="reviewForm" onValidSubmit={this.onValidSubmitReviewsLink}>
                          <div className="row">
                          <div className="col-lg-9">
                                                <Form.Input name = "link" 
                                                validations="isRequired" 
                                                validationErrors={{
                                                  isRequired: "This field is required.",
                                                 
                                                }} 
                                                 errorLabel={errorLabel} />																		
                                            </div>		

                     
                                            <div className="col-lg-3 d-flex justify-content-end">
                                                <button type = "submit" disabled = {this.state.submitButton ===1 ? true : false} className="btn linear-gradient zy-save-btn">Add Link</button>
                                            </div>
                            </div>
                            </Form>
                         </div>

                            {this.state.subscribedLink.length > 0 && (
                            <div>
                              <div className="yr-fbBox" id="yr-fbBox-main" style = {{marginTop:20}}>
                                <div
                                  className="yr-sml-h"
                                  style={{ fontSize: "15px" }}
                                >
                                  Here are the links you have already added:{" "}
                                </div>
                                {this.state.subscribedLink.length > 0 &&
                                  this.loadLink()}
                              </div>
                              <br />
                            </div>
                          )}


                        </div>
        
                        
                        </div>
                        
                        
                      </div>

                      <div className="col-lg-6">
                        <div className="yr-chat-round-box pb-5 mg-l-0 mx-lg-4 yr-shadow">
                      
                          <GoogleReview 
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row px-lg-5">
 
                    <div className="col-lg-6">
                    {this.state.pageModal && this.loadConnectPages()}
                        <div className="yr-chat-round-box mg-l-0 mx-lg-4 yr-shadow">
                          <h4 className="mb-4 conect-header">
                            The overall reputation of your company is:{" "}
                            <span className="con-highlight green">
                              {this.state.reputationType}
                            </span>
                          </h4>
                          <div className="stars">
                            <span className={this.state.star_one}></span>
                            <span className={this.state.star_two}></span>
                            <span className={this.state.star_three}></span>
                            <span className={this.state.star_four}></span>
                            <span className={this.state.star_five}></span>
                          </div>
                          <h5 className="mb-5 mt-3 ml-2 review-des">
                            {this.state.ReviewAllCount} reviews
                          </h5>
                          <p className="mt-3 ml-2 rep-details">
                            This is from All review source
                          </p>
                        </div>
                            </div>
                      {/* <div className="col-lg-6">
                        <div className="yr-chat-round-box mg-l-0 mx-lg-4 yr-shadow">
                          <h4 className="mb-4 conect-header">
                            Here is a snapshot of your BBB reputation
                          </h4>
                          <Rating
                            maxRating={5}
                            defaultRating={5}
                            icon="star"
                            size="huge"
                          />
                          <h5 className="mb-5 mt-3 ml-2 review-des">
                            32 reviews
                          </h5>
                          <p className="mt-3 ml-2 rep-details">
                            <Link to="#">Click here</Link> to see more details
                            on your BBB profile
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
})(ConnectReviewSites);
