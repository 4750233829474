import React, { useState, useEffect, useRef } from 'react';
import GridList from "../Grid";
import api from "../../api";
import { Segment, Message, Flag } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import ConfirmDialog from "../Modal/ConfirmDialog";
import validationRule from "../../validation";
import utilFunc from "../../util_funs";
import { Multiselect } from "multiselect-react-dropdown";


validationRule.isRequired();

const DAY_LIST = [
    { name: 'Sunday', id: '0' },
    { name: 'Monday', id: '1' },
    { name: 'Tuesday', id: '2' },
    { name: 'Wednesday', id: '3' },
    { name: 'Thursday', id: '4' },
    { name: 'Friday', id: '5' },
    { name: 'Saturday', id: '6' },
];

const ServiceTitan = () => {
    const formRef = useRef();
    const [defaultState, setdefaultState] = useState({
        loading: false,
        modal: false,
        successMessage: null,
        errorMessage: null,
        dataLoading: null,
        listdata: true,
        id: 0
    });
    const [apilist, setapilist] = useState([]);
    useEffect(() => {
        getDataSource().then((res) => {
            if (res.totalCount > 0) {
                setdefaultState({
                    ...defaultState,
                    listdata: false
                })
            }
        }).catch((err) => { console.log(err); });
    }, []);

    const [refreshGrid, setrefreshGrid] = useState(false);
    const [serviceTitanInfo, setserviceTitanInfo] = useState({});
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [model, setModel] = useState(false);
    const [model2, setModel2] = useState(false);

    // column

    const columnLists = [
        { text: "S.No", key: "id", sort: false },
        { text: "Client id", key: "st_client_id", sort: false },
        { text: "Client secret", key: "st_client_secret", sort: false },
        { text: "App key", key: "st_app_key", sort: false },
        { text: "Tenet id", key: "st_tenet_id", sort: false },
        {
            text: "Action",
            key: "action",
            sort: false,
            event: [
                {
                    key: "editCredentials",
                    params: ["id"],
                    func: (id) =>
                        editCredentials(id),
                },
                {
                    key: "deleteCredentials",
                    params: ["id"],
                    func: (id) => confirmAction(id),
                },
            ],
        },
    ];

    // data list 
    const getDataSource = (params) => api.servicetitan.list(params);
    // console.log('getDataSource', getDataSource);

    //editCredentials

    const editCredentials = (id) => {
        if (id) {
            api.servicetitan.getActCredentialInfo({ id }).then((resp) => {
                if (resp && resp.servicetitaninfo) {
                    
                    const { servicetitaninfo } = resp;
                    console.log(servicetitaninfo);
                    setserviceTitanInfo(servicetitaninfo);
                    createNew();
                }
            });
        }
    }

    const confirmAction = (id) => {
        setdefaultState({
            ...defaultState,
            id: id,
            loading: true,
            listdata: false
        });
        setConfirmDialog(true);
    }

    const deleteCredentials = () => {
        if (defaultState.id > 0) {
            setConfirmDialog(false);
            api.servicetitan.deleteCredential({ id: defaultState.id })
                .then((resp) => {
                    console.log(resp);
                    if (resp && resp.message) {
                        setrefreshGrid(true);
                        setdefaultState({
                            ...defaultState,
                            successMessage: resp.message,
                            loading: false,
                            id: 0,
                        });
                        utilFunc.scrollTop(800);
                        setrefreshGrid(false);
                        setTimeout(() => {
                            setdefaultState({
                                ...defaultState,
                                successMessage: null,
                                loading: false,
                                listdata: true
                            });
                        }, 4000);
                    }else if (resp.errorMessage) {
                        setdefaultState({
                            ...defaultState,
                            errorMessage: resp.errorMessage,
                            loading: false,
                            id: 0,
                        });
                    }
                })
                .catch((err) => {
                    setdefaultState({
                        ...defaultState,
                        loading: false,
                        id: 0,
                    });
                })
        }
    }

    const toggleConfirmModal = () => {
        setdefaultState({
            ...defaultState,
            id: 0,
            loading: false,
        })
        setConfirmDialog(false);
    }

    const createNew = () => {
        // setdefaultState({
        //     ...defaultState,
        //     loading: true,
        // });
        setModel(true);
        // api.servicetitan.apilist()
        //     .then((res) => {
        //         console.log(res);
        //         if (res.status === 'true') {
        //             setapilist(res.apilist);
        //             setModel(true);
        //             setdefaultState({
        //                 ...defaultState,
        //                 loading: false,
        //             });
        //         } else {
        //             setdefaultState({
        //                 ...defaultState,
        //                 loading: false,
        //                 errorMessage: 'Something went wrong.'
        //             });
        //         }
        //     }).catch((err) => {
        //         setdefaultState({
        //             ...defaultState,
        //             loading: false,
        //             errorMessage: 'Something went wrong.'
        //         });
        //         console.log(err);
        //     });
    }

    // toggel model

    const toggleModal = () => {
        setdefaultState({
            ...defaultState,
            selectedDrodown: 0,
            loading: false,
        })
        setModel(!model);
        setserviceTitanInfo({});
    }

    const toggleInstructionModal = () => {
        setModel2(false);
    }

    // submit form 

    const submitForm = () => {
        formRef.current.submit();
    }



    const AddCredential = ({ defautlInfo }) => {
        const [formLoading, setFormLoading] = useState(false);
        const [apiliststatus, setapiliststatus] = useState(false);
        // state for form data
        const [formData, setFormData] = useState({
            user_client_id: defautlInfo ? defautlInfo.user_client_id : '',
            user_client_secret: defautlInfo ? defautlInfo.user_client_secret : '',
            user_app_key: defautlInfo ? defautlInfo.user_app_key : '',
            user_tenet_id: defautlInfo ? defautlInfo.user_tenet_id : '',
            // apilist: defautlInfo && defautlInfo.apilist ?  JSON.parse(defautlInfo.apilist) : [],
            id: defautlInfo ? defautlInfo.id : '0',
        });

        const handleOnChange = (e) => {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        }

        const onSelect = (selectedList, selectedItem) => {
            setFormData({
                ...formData,
                apilist: selectedList
            });
            setapiliststatus(false);
        }

        const onRemove = (selectedList, removedItem) => {
            setFormData({
                ...formData,
                apilist: selectedList
            });
            setapiliststatus(false);
        }



        const handleSubmit = () => {
            // console.log('jh');
            const data = formData;
            console.log(data);
            // if (data.apilist === undefined || data.apilist?.length === 0) {
            //     console.log(data.apilist, data);
            //     setapiliststatus(true);
            //     return;
            // }
            setFormLoading(true);
            api.servicetitan.createNew({ data })
                .then((resp) => {
                    if (resp && resp.message) {
                        setrefreshGrid(true);
                        setdefaultState({
                            ...defaultState,
                            successMessage: resp.message,
                            id: 0,
                            loading: false,
                            listdata : false
                        });
                        setFormLoading(false);
                        setModel(false);
                        setserviceTitanInfo({});
                        setrefreshGrid(false);
                        setTimeout(() => {
                            setdefaultState({
                                ...defaultState,
                                loading: false,
                                successMessage: null,
                                listdata : false
                            });
                        }, 4000);
                    } else if (resp.errorMessage) {

                        setdefaultState({
                            ...defaultState,
                            errorMessage: resp.errorMessage,
                            id: 0,
                            loading: false
                        });
                        setModel(false);
                        setFormLoading(false);
                        setserviceTitanInfo({});
                        setTimeout(() => {
                            setdefaultState({
                                ...defaultState,
                                loading: false,
                                errorMessage: null,
                            });
                        }, 4000);
                    }
                })
                .catch((err) => {
                    setdefaultState({
                        ...defaultState,
                        actcrmid: 0,
                        loading: false
                    });
                    setTimeout(() => {
                        setModel(false);
                    }, 500);
                    setserviceTitanInfo({});
                    console.log(err);
                })

        }

        const errorLabel = <div className="red" pointing={"true"} />;
        return (
            <div
                className={model ? "modal fade show " : "modal fade"}
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdrop"
                aria-hidden="true"
                style={{ display: model ? "block" : "none" }}
            >
                {formLoading && <div className="ui loading form"></div>}
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close linear-gradient"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={toggleModal}
                        >
                            X
                        </button>
                        <div className="modal-header">
                            <h5 className="modal-title">{"ServiceTitan"}</h5>
                        </div>
                        <div className="modal-body">
                            <Form
                                noValidate
                                autoComplete="off"
                                onValidSubmit={handleSubmit}
                                className="kt-form kt-form--label-right"
                                ref={formRef}
                                style={{ margin: "10px 10px 0 0" }}
                            // loading={formLoading}
                            >
                                {/* <div className="form-group row">
                                    <div className='col-lg-12'>
                                        <label className="col-form-label text-left">Select which data you want to fetch</label>
                                    </div>

                                    <div className="col-lg-12">
                                        <Multiselect
                                            options={apilist} // Options to display in the dropdown
                                            selectedValues={formData.apilist ?? []} // Preselected value to persist in dropdown
                                            onSelect={(selectedList, selectedItem) => onSelect(selectedList, selectedItem)} // Function will trigger on select event
                                            onRemove={onRemove} // Function will trigger on remove event
                                            displayValue="api_title" // Property name to display in the dropdown options
                                            closeIcon="circle"
                                            avoidHighlightFirstOption={true}
                                        // ref={this.multiselectRef}
                                        />
                                        <div style={{ display: apiliststatus ? 'block' : 'none' }} className="red">{"This field is required"}</div>
                                    </div>
                                </div> */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Client id</label>
                                    <div className="col-lg-9">
                                        <Form.Input
                                            type="text"
                                            autoComplete="off"
                                            name="user_client_id"
                                            value={formData.user_client_id}
                                            placeholder="Client id"
                                            onChange={handleOnChange}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: "This field is required.", }}
                                            errorLabel={errorLabel}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Client secret</label>
                                    <div className="col-lg-9">
                                        <Form.Input
                                            type="text"
                                            autoComplete="off"
                                            name="user_client_secret"
                                            value={formData.user_client_secret}
                                            placeholder="Client secret"
                                            onChange={handleOnChange}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: "This field is required.", }}
                                            errorLabel={errorLabel}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Tenet id</label>
                                    <div className="col-lg-9">
                                        <Form.Input
                                            type="text"
                                            autoComplete="off"
                                            name="user_tenet_id"
                                            value={formData.user_tenet_id}
                                            placeholder="Tenet id"
                                            onChange={handleOnChange}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: "This field is required.", }}
                                            errorLabel={errorLabel}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Application key</label>
                                    <div className="col-lg-9">
                                        <Form.Input
                                            type="text"
                                            autoComplete="off"
                                            name="user_app_key"
                                            value={formData.user_app_key}
                                            placeholder="Application key"
                                            onChange={handleOnChange}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: "This field is required.", }}
                                            errorLabel={errorLabel}
                                        />
                                    </div>
                                </div>

                            </Form>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={submitForm}
                                type="button"
                                className="btn linear-gradient yr-submitbtn btn-sm"
                                disabled={formLoading}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }



    return (
        <>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet__body kt-portlet__body--fit">
                    {model && <AddCredential defautlInfo={serviceTitanInfo} />}
                    {defaultState.successMessage && (
                        <Message color={"green"}>{defaultState.successMessage}</Message>
                    )}
                    {defaultState.errorMessage && (
                        <Message color={"red"}>{defaultState.errorMessage}</Message>
                    )}
                    {defaultState.loading && <div className="ui loading form"></div>}
                    {confirmDialog && (
                        <ConfirmDialog
                            show={confirmDialog}
                            toggleModal={deleteCredentials}
                            closeAction={toggleConfirmModal}
                            title="Confirm your action"
                            message={"Do you really want to delete this credential ?"}
                        />
                    )}
                    <div className="yr-manageUser-table">
                        <Segment>
                            <div className="keep-integration act--integration">
                                <GridList
                                    dataSource={(params) => getDataSource(params)}
                                    dataColumns={columnLists}
                                    refreshGrid={refreshGrid}
                                >
                                   
                                        <div className="col-md-8 kt-margin-b-20-tablet-and-mobile">
                                            
                                            <button
                                                style={{ marging: "0 0 15px 0", float: "right" }}
                                                type="button"
                                                className="btn linear-gradient yr-submitbtn"
                                                onClick={() => setModel2(true)}
                                            >
                                                Instructions Video
                                            </button>
                                            {defaultState.listdata &&
                                            <button
                                                style={{ marging: "0 0 15px 0", float: "right" }}
                                                type="button"
                                                className="btn linear-gradient yr-submitbtn"
                                                onClick={createNew}
                                            >
                                                Create New
                                            </button>
                                            }
                                        </div>
                                </GridList>
                            </div>
                        </Segment>
                    </div>
                </div>
            </div>
        </div>
        <div
            className={model2 ? "modal fade show " : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: model2 ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="close linear-gradient"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={toggleInstructionModal}
                    >
                        X
                    </button>
                    <div className="modal-body">
                    <iframe width="100%" height="480" style={{aspectRatio: '16/9'}} src="https://www.youtube.com/embed/1BGQmwmKzdU" title="Service Titan Integration" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

export default ServiceTitan;