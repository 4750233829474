import React from "react";
import { Table } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class DataRow extends React.Component {
  displayRow = (dataRow) => {
    if (dataRow.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan={this.props.columnLength} textAlign="center">
            No record found.
          </Table.Cell>
        </Table.Row>
      );
    }
    return dataRow.map((row, rowIndex) => {
      return (
        <Table.Row key={rowIndex}>
          {Object.keys(row).map((itemKey, index) => {
            return (
              <Table.Cell key={index}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: row[itemKey],
                  }}
                ></div>
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    });
  };

  render() {
    const { dataRow } = this.props;
    return <Table.Body>{this.displayRow(dataRow)}</Table.Body>;
  }
}

export default DataRow;
