import React, { useState } from "react";
import {
	Form,DropDown
} from 'formsy-semantic-ui-react';
import {Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
// import CheckboxTree from "./PrevilegesTree.js";

import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";


validationRule.isRequired();  
validationRule.minCustomLength(6); 

const DialogBoxAddEdit = ({ obj, modalClose, onValidSubmit,   currentExpanded, setCurrentCheck, setCurrentExpand,hipaaLoginEmailList,hipaaLoginPhoneList }) => {
	let show = obj.modalstatusAddEdit;
	let title = obj.modaltitle;
	let modalErrorMsg = obj.modalErrorMsg;
	let modalErrorMsgLegacy = obj.modalErrorMsgLegacy;
	let modaldata = obj.modaldata;
	let modalFormLoader = obj.modalFormLoader;
	let selectedOption = obj.selectedOptions;
	let accessModuleList = obj.accessModuleList;
	const [currentChecked,setCurrentChecked]=useState("")
	const [currentCheckedType,setCurrentCheckedType]=useState("")
	const [name,setName]=useState("")
	const [pin,setPin]=useState("")
	const [readonly,setReadonly] = useState(false)
	const [accountType,setAccountType] = useState("sub")
	
	const errorLabel = <div className="red"/>
					

	const onCheck = (checked)=>{
		setCurrentCheck(checked)
	}

	const onExpand = (checked)=>{
		console.log(checked)
		setCurrentExpand(checked)
	}

	const modalCloseHere = (t,fid) =>{
		// setCurrentCheck([]);
		// setCurrentExpand([])
		modalClose(false, 'formManageUser')
	}

	const handleInput =()=>{

	}

	const onValidSubmitPre = e =>{
		onValidSubmit(currentChecked,name,pin,currentCheckedType,accountType);
		document.getElementById("email").value="";
	}
		

	const changeElmVal = e=>{
		// alert(e.target.name)
		if(e.target.name=="email"){
			setCurrentCheckedType(e.target.type);
			setCurrentChecked(e.target.value)

			setCurrentCheckedType(e.target.type);
			if(e.target.value.includes("@@@")){
				setReadonly(true)
				var name = e.target.value.split("--")[1];
				setName(name)
				setAccountType("main")
			} else {
				setReadonly(false)
				setAccountType("sub")

			}

		}
		if(e.target.name=="pin")
			setPin(e.target.value)
		if(e.target.name=="name")
			setName(e.target.value)
	}

	



	return (
		
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseHere(false, 'formManageUser')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<Form  noValidate autoComplete="off" id="formManageHipaaLoginUser" onValidSubmit={onValidSubmitPre}>
						    {modalFormLoader && <div className="ui loading form"></div>}
							<div className="modal-body">
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first">
									<Message color='red' style={{ display: modalErrorMsg ? "block" : "none" }}>This account is already exists.</Message>
										<div className="kt-section__body">
											<div className="form-group row">
												<label className="col-lg-5 col-form-label">Full Name :</label>
												<div className="col-lg-7">
													<Form.Input readOnly={readonly} onChange={changeElmVal} fluid autoComplete="off" name="name" value={name} placeholder=''
														validations='isRequired' validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel} />
												</div>
											</div>
											<div className="form-group row  emailSelectHipaaLoginUser">
												<label className="col-lg-5 col-form-label">Email  :</label>
												<div className="col-lg-7">
												<select id="email" name="email" className="form-control" onChange={changeElmVal}>
													{hipaaLoginEmailList.map(item => (
														<option
														type="email"
														key={item.key}
														value={item.key}
														>
														{item.value}
														</option>
													))}{hipaaLoginPhoneList.map(item => (
														<option
														type="phone"
														key={item.key}
														value={item.key}
														>
														{item.value}
														</option>
													))}
													</select>
													<Form.Input  name="emailSelect" type="hidden" value={currentChecked} validations={{
														customValidation: (values, value) => !(!value || value.length < 1), 
													}}
														
														validationErrors={{
														customValidation: 'This field is required.',
														}}
														errorLabel={errorLabel}
														/>
												</div>
											</div>
											<div className="form-group row" style={{marginTop:"1px"}}>
												<label className="col-lg-5 col-form-label">Please create a 4 number PIN  :</label>
												<div className="col-lg-7">
												<Form.Input onChange={changeElmVal} name="pin" type="password" value={pin} validations={{
													customValidation: (values, value) => !(!value || value.length < 1), 
													customValidation1: (values, value) => !(value.length > 4 || value.length < 4), 
													customValidation2: (values, value) => (Number.isInteger(Number(value))), 
												}}
													
													validationErrors={{
														customValidation: 'This field is required.',
														customValidation1: 'Pin should be 4 characters long.',
														customValidation2: 'Pin should be numeric.',
													}}
													errorLabel={errorLabel}
													/>
												</div>
											</div>
											
											<div className="form-group row accessModule">
												<label className="col-lg-5 col-form-label">  </label>
												<div className="col-lg-7">
												
												</div>
											</div>
											
										</div>
									</div></div></div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<Form.Input name="subaccount_id" type="hidden" value={modaldata.id} />
											<button type="reset" onClick={() => modalCloseHere(false, 'formManageUser')} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Cancel </span></button>
											<button type="submit" className="btn linear-gradient yr-submitbtn">Submit</button>
											

										</div>
									</div>
								</div>
							</div>
						</Form>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxAddEdit;
