import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function createMarkup(html){
  return {__html: html};
}

export const DatatableRow = props => (
 
  <Table.Row >
    
    <Table.Cell >{props.list.chat_from ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span style={{overflow: "hidden" ,display:"block",maxWidth: "70px",textOverflow: "ellipsis",lineHeight: "3px"}} className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill" title={props.list.referrer_page} >{props.list.referrer_page}</span></span> :'' }</Table.Cell>
    <Table.Cell >{props.list.traffic_medium ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span style={{overflow: "hidden" ,display:"block",maxWidth: "70px",textOverflow: "ellipsis",lineHeight: "3px"}} className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill" title={props.list.traffic_medium} >{props.list.traffic_medium}</span></span> :'' }</Table.Cell>
    
    <Table.Cell >{props.list.date_added?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div style={{width:"90px"}} dangerouslySetInnerHTML={{__html: props.list.date_added }}></div>:""}</Table.Cell>
    <Table.Cell >{props.list.job_description?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.job_description }}></div>:""}</Table.Cell>
    <Table.Cell >{props.list.user_name?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.user_name }}></div>:""}</Table.Cell>
   
    
    <Table.Cell>{props.list.zip_code?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.zip_code}}></div>:""}</Table.Cell>

    <Table.Cell>{props.list.phone_number?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.phone_number}}></div>:""}</Table.Cell>

    <Table.Cell>{props.list.chat_email?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.chat_email}}></div>:""}</Table.Cell>

    <Table.Cell style={{whiteSpace: "nowrap"}}>{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.host)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_source)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_medium)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_campaign)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_content)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.ip_address)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.ga_id)}  ></span>}</Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
