import React from "react";
import { Segment } from "semantic-ui-react";
import {
  toggleBreadcrum,
  setPageTitle,
  
} from "../../store/Page";

import { connect } from "react-redux";
class GtmTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false, 
    }; 
    this.props.toggleBreadcrum({ toggle: false });
  }
   
  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content kt-grid__item kt-grid__item--fluid">
          <div className="yr-body-p kt-margin-t-20">
              <div className="row">
                  <div className="col-md-12">
                      <h3>About Google Tag Manager</h3>
                      <p>Google Tag Manager is a tag management system (TMS) that allows you to quickly and easily update tracking codes and related code fragments collectively known as tags on your website or mobile app. Once the small segment of Tag Manager code has been added to your project, you can safely and easily deploy analytics and measurement tag configurations from a web-based user interface</p>
                      <p>When Tag Manager is installed, your website or app will be able to communicate with the Tag Manager servers. You can then use Tag Manager's web-based user interface to set up tracking tags, establish triggers that cause your tag to fire when certain events occur, and create variables that can be used to simplify and automate your tag configurations.</p>
                      
                      <h3>Google Tag Manager Setup</h3>
                      <p>Steps for Creating Google Tag Manager</p>
                      <ul>
                          <li>Step 1 – On your Google Tag manager account, first, go to variables, click “configure” and make sure the “Click ID” box is checked.</li>
                          <li>Step 2 – Go to "Tags" tab on the left. Click on "New".</li>
                          <li>Step 3 – Click on "Tag configuration" then select "Custom HTML".</li>
                          <li>Step 4 – Put a javscript code like fb pixel, google analytics, or your own analytics snippet (not your chat code).</li>
                          <li>Step 5 – Below, click on "Triggering" and then click on the “+” icon for a new trigger.</li>
                          <li>Step 6 – Click on "Trigger configuration" and then click on "All Elements” with the “Some Clicks" button clicked.</li>
                          <li>Step 7 – Select "Click element" and choose “Click ID" for the first box. The second box needs to be “equals” and then the third box will be the ID you want to fire the event.</li>
                          <li>Step 8 – Add the following click ID's: “ChatStartID” (which will fire when a chat starts), “ChatEndID" (which will fire when a chat ends), and “callusID” (which will fire when someone clicks to call).</li>
                          <li>Step 9 –  Save the items.</li>
                      </ul> 
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};

export default connect(mapStateToProps, {
  toggleBreadcrum,
  setPageTitle,
  
})(GtmTracking); 
