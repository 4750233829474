import axios from "../../config/axios";

const zapier = {
  list: (params) =>
    axios
      .post("/integration/zapier/list", params)
      .then((resp) => (resp ? resp.data : null)),

  locationList: (params) =>
    axios
      .post("/integration/zapier/locations", params)
      .then((resp) => (resp ? resp.data : null)),

  createNew: (params) =>
    axios
      .post("/integration/zapier/create_new", params)
      .then((resp) => (resp ? resp.data : null))
      .catch((err) => err),

  getZapierCredentionalInfo: (params) =>
    axios
      .post("/integration/zapier/get_zapier_credentional", params)
      .then((resp) => (resp ? resp.data : null)),

  deleteZapierCredential: (params) =>
    axios
      .post("/integration/zapier/delete_credentials", params)
      .then((resp) => (resp ? resp.data : null)),
};

export default zapier;
