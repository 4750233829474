import React from "react";
import "../../../../../Modal/dialog.css";

const DialogBoxDetail = ({ show, title, message, modalType, modalClose, buttonmsg, modalbuttofun, loading }) => {
	return (
		<React.Fragment>
			<div
				className={show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
				   {loading && <div className="ui loading form"></div>}
					<div className="modal-content white-bg yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<div className="modal-body"><div className="">
						<form class="kt-form">
							<div class="kt-portlet__body">
								<div class="kt-section kt-section--first">
									<div class="kt-section__body">
										<div class="form-group">
											<span class="yr-iconSec"><i class="fa fa-repair"></i></span>
											<h4>AC Repair follow up now</h4>
											<p>Email flow starts 6 monthes after "JOB_COMPLETION_DATE"</p></div>
										<div class="form-group">
											<span class="yr-iconSec"><i class="fa fa-marketing"></i></span>
											<h4>Marketing flow</h4>	
											<p>Email flow starts immediately after contacts are added to the bucket</p>
										</div>
										<div class="form-group">
											<span class="yr-iconSec"><i class="fa fa-giftbox"></i></span>
											<h4>Birthday flow</h4>
											<p>Email flow starts on the same calendatate as the "BIRTHDAY" column</p>
										</div>
										<div class="form-group">
											<span class="yr-iconSec"><i class="fa fa-coupon"></i></span>
											<h4>Coupon flow</h4>
											<p>Email flow starts 45 months after "JOB_COMPLETION_DATE"</p>
										</div>										
										
									</div>									
								</div>
							</div>
						</form>
							</div></div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxDetail;