import React from "react";
import { Link } from "react-router-dom";

const Notification = ({ user }) => {
	return !user ? (
		""
	) : (
		<div className="kt-header__topbar-item dropdown">
			
		</div>
	);
};

export default Notification;
