import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const regex = new RegExp('^[a-zA-Z0-9 ]+$');

export class DatatableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      filterValid: true,
    };
  }

  handleOnChange = (event, { name, value }) => { 
    if (value !== '' && !regex.test(value)) {
      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == '') {
      this.props.onSubmitFilter(value);
    }
  };

  handleOnClick = (e) => { 
    e.preventDefault()
    let value = this.state.filter;
    if (value.trim() !== '') {
      this.props.onSubmitFilter(value);
    }
  };

  handleOnSubmit = ()=>{
    alert("hi")
  }

  render() {
     const { filter } = this.state;
   
    return (

      <div className="form-group row mx-0">                                                        
      <div className="kt-input-icon kt-input-icon--left yr-search col-5">
      <form onSubmit={this.handleOnClick}>

      <div className="form-group row mx-0">                                                        
                <div className="kt-input-icon kt-input-icon--left yr-search">
                <Form.Input
                    style={{ marginLeft: 4}}                    
                    name="filter"
                    className="form-control"
                    value={filter}
                    onChange={this.handleOnChange}
                  />
                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                        <span><i className="search icon"></i></span>
                    </span>
                    <span className="yr-searchPlace-holder">Search</span>
                </div>
                                                                                                                     
            </div>
      
      
          </form>

          <span className="kt-input-icon__icon kt-input-icon__icon--left">
              {/* <span><i className="search icon"></i></span> */}
          </span>
          
      </div>
      <div className="col-2">
          <button onClick={this.handleOnClick} type="button" className="btn linear-gradient btn-secondary yr-submitbtn search-btn">Search</button>   
      </div>                                                                                                            
  </div>

      // <div className="kt-form kt-form--label-right kt-margin-t-10 kt-margin-b-10">
      //   <div className="row align-items-center">
      //     <div className="col-xl-8 order-2 order-xl-1">
      //       <div className="row align-items-center">
      //         <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
      //           <div className="kt-input-icon kt-input-icon--left">
      //           <Form onSubmit={this.handleOnClick}>
      //             <Form.Input
      //               style={{ marginLeft: 4 }}                    
      //               name="filter"
      //               className="search-input-mu"
      //               value={filter}
      //               onChange={this.handleOnChange}
      //             />
      //             </Form>
      //             <span className="kt-input-icon__icon kt-input-icon__icon--left" style={{cursor:"pointer"}} onClick={this.handleOnClick} >
      //               <span>
      //                 <i className="la la-search"/>
      //               </span>                            
      //             </span>
      //             <span className="yr-searchPlace-holder">Search</span>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>

    );
  }
}

DatatableFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};
