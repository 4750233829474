import React, { useState } from 'react'
import classes from './LiveChat.module.css';


const InputNumber = ({ itemData, handleCustomInput, removeCustomInput, addCustomInput }) => {
    console.log(typeof itemData.currentFocus);
    return (
        <div className={classes.livechat__formadd_cutom}>
            <input
                // size='large'
                placeholder='Add number'
                value={itemData.value}
                onChange={handleCustomInput}
                name={`custom_${itemData.id}`}
                autoFocus={itemData.currentFocus}
                className={`${classes.livechat__addcustom_form_input} ${itemData.inputError ? classes.livechat__addcustom_input_error : ''}`}
                maxLength={13}
            />
            <div className={classes.livechat__addcustom_button}>
                <button onClick={() => removeCustomInput(itemData.id)}>
                    <i className='fa fa-trash' style={{ color: 'red', fontSize: 16 }}></i>
                </button>
                <button onClick={addCustomInput}>
                    <i className='fa fa-plus' style={{ color: 'green', fontSize: 16 }}></i>
                </button>
            </div>

        </div>
    )
}

export default React.memo(InputNumber)