import React from 'react';


const CkEditorTextbox = () => {
    return(
        <textarea>vfdvfdvvvvvvvvvvvvvvvvvvvvvv</textarea>
    )
}


export default CkEditorTextbox;