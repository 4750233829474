import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
class Filter extends React.Component {
    constructor( props){
        super( props );
    }
    changeFilter = ( event,data ) => {
        this.props.changeFilter( data.value , "_query");
    }
    changeFilterType = ( event ) => {
        if( event.target.value !== "daterange" ){
            if(event.target.value == "All"){ this.props.changeFilter( "", "_queryType" );}
            this.props.changeFilter( event.target.value, "_queryType" );
        }else{
            document.querySelector('.showrangepicker').click();
        }
    }
    applyDateRange = (event,picker) => {
        this.props.changeDateRangeParams( picker.startDate.format('MM/DD/YYYY'), picker.endDate.format('MM/DD/YYYY'));
    }

    render(){
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let final=month+"/"+date+"/"+year;
        return(
            <React.Fragment>
            <div className="kt-form kt-form--label-right kt-margin-t-10 kt-margin-b-10">
                <div className="d-flex justify-content-end align-items-center">
                    <DateRangePicker
                        initialSettings={{ startDate: final }}
                        onApply={(event, picker)=>this.applyDateRange(event, picker)}
                    >
                        <div className="showrangepicker">
                            <div className="form-control border-bottom-0 arrow-color mat-style" style={{cursor:"pointer"}}></div> 
                    </div>
                    </DateRangePicker>
                    {/* <Dropdown  placeholder='Select Filter'
                        selection
                        options={[
                                { key: "0", value: "all_time", text: "All Product" },
                                { key: "1", value: "SMS", text: "sms" },
                                { key: "2", value: "Email", text: "Email" },
                                { key: "3", value: "PostCard", text: "postcard" },
                            ]}
                            defaultValue={this.props.query}
                            onChange={ this.changeFilter }
                    /> */}
                    <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile" style={{minWidth: 'unset', marginRight: '1rem', maxWidth: 170}}>
                        <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                                <label></label>
                            </div>
                            <div className="kt-form__control yr-time-ShowHide">
                            <span class="custom-arrow">
                                <select className="form-control bootstrap-select arrow-color mat-style border rounded yr-time-ShowHide"  name="chatfrequency" id="kt_form_type" onChange={(event) => this.changeFilterType(event)}>
                                    <option value="All">All time </option>
                                    <option value="past_7_days">Past 7 Days</option>

                                    
                                    <option value="past_month">Past Month</option>
                                    <option value="past_year">Past Year</option>
                                    <option value="daterange">Date Range</option> 
                                </select>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <button type="button" class="btn linear-gradient fix-btn-size yr-submitbtn calllist-btn fix-btn-size" onClick={ () => this.props.getCsv()}>Export CSV</button>
                </div>
            </div>
            </React.Fragment>
        )
    }
}
export default Filter;