import React from "react";
import DatatableList from './DatatableList';
import api from "../../../../api";
import DialogBoxExternal from './DialogBoxExternal';
import utilFunc from "../../../../util_funs";


class ManageHipaaUser extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			getLegacyLiveNotificationMember: [],
			getLegacyCompleteNotificationMember: [],
			loading: false,
			modalstatus: false,
			modaltitle: "",
			modalmessage: "",
			modalbuttonmsg: "",
			modaldata: [],
			llnCount:0,
			lcnCount:0


		}
	}

	componentDidMount() {
		api.manageUser.getLegacyNotification({ "ref_user_id": this.props.sessionUser.user_id }).then(data => {
			this.setState({
				getLegacyLiveNotificationMember: data.data.data.liveAll,
				getLegacyCompleteNotificationMember: data.data.data.completedAll,
				llnCount:data.data.data.liveAll.length,
				lcnCount:data.data.data.completedAll.length
			})

			console.log("MOUNT",this.state)

		});
	}
	addHipaaUser = () => {
		this.child.addHipaaUserSubAccounts();
	};


	liveNotificationMemberFeed() {
		const { getLegacyLiveNotificationMember } = this.state;
		return getLegacyLiveNotificationMember.map(
			(
				{
					id,
					username,
					email_id,
					isd_code,
					phone_number,
					jump_in_question,
					profile_pic,

				},
				idx
			) => (
					<tr>
						<th><a onClick={() => this.deleteLegacyLiveChatMember(id)} title='Delete' className='btn btn-icon gray'><i className='la la-trash'></i></a></th>

						<td data-label="Name">{username}</td>
						<td data-label="Name">{email_id}</td>
						<td data-label="Name">{isd_code + " " + phone_number}</td>
						<td data-label="Name">{jump_in_question}</td>
						<td data-label="Name">{(profile_pic == "") ?
							<img height="50px" src={this.props.sessionUser.assetPath + "/layouts/layout2/img/default-user-icon-4.jpg"} />
							:
							<img height="50px" src={this.props.sessionUser.assetPath + "/images/chatlogo/" + profile_pic} />}</td>

					</tr>
				)
		);
	}

	completedNotificationMemberFeed = ()=>{
		const { getLegacyCompleteNotificationMember } = this.state;
		var index,
		id,
		email,
		isd_code,
		phone_number,
		table = '';
		return getLegacyCompleteNotificationMember.map(
			(
				{
					index,
					id,
					email,
					isd_code,
					phone_number,
					table,

				},
				idx
			) => (
					<tr>
						<th><a onClick={() => this.deleteLegacyCompletedChatMember({"index":index,"id":id,"table":table})} title='Delete' className='btn btn-icon gray'><i className='la la-trash'></i></a></th>
						<td data-label="Name">{(email=="")?isd_code + " "+ phone_number:email}</td>
						{/* <td data-label="Name">{isd_code + " " + phone_number}</td> */}

					</tr>
				)
		);
	}

	deleteLegacyCompletedChatMember = (obj)=>{
		this.setState({
			modalstatus: true,
			modaltitle: 'Confirm your action',
			modalmessage: 'Are you sure you want to delete?',
			modalbuttonmsg: { "submit": 'Yes', "cancel": 'No' },
			modalType: 'Delete',
			modaldata: { "refId": obj.id, "for": "CompleteNoti","index": obj.index,"table":obj.table}
		});
	}

	deleteLegacyLiveChatMember = (id) => {
		this.setState({
			modalstatus: true,
			modaltitle: 'Confirm your action',
			modalmessage: 'Are you sure you want to delete?',
			modalbuttonmsg: { "submit": 'Yes', "cancel": 'No' },
			modalType: 'Delete',
			modaldata: { "refId": id, "for": "LiveNoti" }
		});
		
	}

	modalClose = (id) => {
		this.setState({
			modalstatus: false,
		});
	}

	modalbuttofun = (obj) => {
		this.setState({
			modalstatus: false,
		});

		if (obj.modaldata.for== "LiveNoti") {
			this.deleteLegacyLiveChatMemberNow(obj.modaldata.refId)
		}

		if (obj.modaldata.for== "CompleteNoti") {
			this.deleteLegacyCompletedChatMemberNow(obj)
		}

	}
	deleteLegacyLiveChatMemberNow = (id) => {

		this.setState({
			loading:true
		})
		api.manageUser.deleteLegacyLiveChatMember({ "id": id}).then(data => {
		
			this.props.showUserToaster('green', 'Legacy live chat notification member has been deleted successfully.');
			utilFunc.scrollTop(800);
			this.state.getLegacyLiveNotificationMember = this.state.getLegacyLiveNotificationMember.filter(function (obj) {
                return obj.id !== id;
			  });
			  this.setState({
				loading:false,
				getLegacyLiveNotificationMember:this.state.getLegacyLiveNotificationMember,
				llnCount:this.state.getLegacyLiveNotificationMember.length
			})

		});
	}
	deleteLegacyCompletedChatMemberNow = (obj) => {
		this.setState({
			loading:true
		})
		api.manageUser.deleteLegacyCompleteChatMember({ "id": obj.modaldata.refId,"table":obj.modaldata.table}).then(data => {
		
			this.props.showUserToaster('green', 'Legacy completed chat notification member has been deleted successfully.');
			utilFunc.scrollTop(800);
			console.log(this.state.getLegacyCompleteNotificationMember)
			this.state.getLegacyCompleteNotificationMember = this.state.getLegacyCompleteNotificationMember.filter(function (object) {
                return object.index !== obj.modaldata.index;
			  });
			  this.setState({
				loading:false,
				getLegacyCompleteNotificationMember:this.state.getLegacyCompleteNotificationMember,
				lcnCount:this.state.getLegacyCompleteNotificationMember.length
			})


		});
	}
	render() {
		return (
			<div className="row">
				{this.state.loading && <div className="ui loading form"></div>}

				<div className="col-lg-12 col-md-12 col-sm-12">
					{/*begin: Search Form */}
					{/*end: Search Form */}
					<div className="kt-portlet__body kt-portlet__body--fit">
						{/*begin: Datatable */}
						<DatatableList ref={child => { this.child = child; }} showUserToaster={this.props.showUserToaster} sessionUser={this.props.sessionUser} />
						{/*end: Datatable */}
					</div>
					{/*begin: Search Form */}
					
				</div>
				<DialogBoxExternal
					obj={this.state}
					show={this.state.modalstatus}
					title={this.state.modaltitle}
					message={this.state.modalmessage}
					modalClose={this.modalClose}
					buttonmsg={this.state.modalbuttonmsg}
					modalbuttofun={this.modalbuttofun}
					modaldata={this.state.modaldata}
				/>
			</div>
		);
	};
}

export default ManageHipaaUser;
