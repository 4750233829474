import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { counter } from "@fortawesome/fontawesome-svg-core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Multiselect } from "multiselect-react-dropdown";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Link, Redirect } from "react-router-dom";
const options = [];
const regex = new RegExp("^[a-zA-Z0-9 ]+$");
export class DatatableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      yourteamdata: [],
      team_id: "",
      needToShow: 1,
      team_name: "",
      teamlist: "",
      //team_name:'cc',
      filterValid: true,
    };
    //this.getyourcallteam();
  }

  componentDidUpdate(prevProps) {
    if (this.props.indextime != prevProps.indextime) {
      this.getyourcallteam();
    }
  }

  handleOnChange = (event, { name, value }) => {
    if (value !== "" && !regex.test(value)) {
      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == "") {
      this.props.onSubmitFilter("q", value);
    }


    if(value != ''){
      this.setState({needToShow: 0})
    }else{
      this.setState({needToShow: 1})
    }
  };

  handleOnClick = () => {
    //alert('Test By Rohit..');
    let value = this.state.filter;
    if (value.trim() !== "") {
      this.props.onSubmitFilter("q", value);
    }
  };



  chatTimeChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });
    if (e.target.value == "daterange") {
      this.setState(
        {
          rangepicker: true,
          daterange: e.target.value,
        },
        function () {
          document.querySelector(".showrangepicker").click();
        }
      );
    } else {
      this.setState({ rangepicker: false, daterange: e.target.value });
      this.props.onSubmitFilter("chatfrequency", e.target.value);
    }
  };

  applyDateRange = (event, picker) => {
    this.setState({
      selectedtext:
        picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    });
    let range = {};
    range["chatfrequency"] = "daterange";
    range["startdaterange"] = picker.startDate.format("MM/DD/YYYY");
    range["enddaterange"] = picker.endDate.format("MM/DD/YYYY");
    this.props.onSubmitFilterDateReange(range);
  };
  handleClick = (e) => {
    this.datepicker.click();
  };

  //@Rohit
  getyourcallteam = () => {
    this.setState(
      {
        loading: false,
        yourteamdata: this.props.yourteamdata,
      },
      () => {
        //document.querySelector('.newtestttt > div > button').addEventListener("click", this.addclick);
      }
    );
  };

applyDateRange_analytics = ( event, picker ) => {
	let dateRange = {startDate: picker.startDate.format("MM/DD/YYYY"),endDate : picker.endDate.format("MM/DD/YYYY")};
	this.props.onSubmitFilter("daterange", dateRange);
}

openDateFilterAnalytics = ( event ) => {
	if( event.target.value == 'daterange'){
		document.querySelector('.showrangepicker_analytics').click();
	}
	else if( event.target.value == 'past_7_days' ){
		let today = new Date();
		var start = new Date(new Date().setDate(today.getDate() - 7));
		var priorDate = new Date(new Date().setDate(today.getDate() - 1));

		let startDate = start.getFullYear() + "-" + ((start.getMonth()+1)).toString().padStart(2,'0') + "-" + (start.getDate().toString()).padStart(2,'0');
		let endDate = today.getFullYear() + "-" + ((today.getMonth()+1)).toString().padStart(2,'0') + "-" + (today.getDate().toString()).padStart(2,'0');

		let dateRange = {startDate: startDate,endDate : endDate};
		this.props.onSubmitFilter("daterange", dateRange);
	}else if( event.target.value == 'past_month' ){
		let date = new Date();
		let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
		let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

		let startDate = firstDay.getFullYear() + "-" + ((firstDay.getMonth()+1)).toString().padStart(2,'0') + "-" + (firstDay.getDate().toString()).padStart(2,'0');
		let endDate = lastDay.getFullYear() + "-" + ((lastDay.getMonth()+1)).toString().padStart(2,'0') + "-" + (lastDay.getDate().toString()).padStart(2,'0');

		let dateRange = {startDate: startDate,endDate : endDate};
		this.props.onSubmitFilter("daterange", dateRange);
	}else if(event.target.value ==  'past_year'){
		let date = new Date();
		let startDate = (date.getFullYear() - 1)+"-01-01";
		let endDate = (date.getFullYear() - 1)+"-12-31";
		let dateRange = {startDate: startDate,endDate : endDate};
		this.props.onSubmitFilter("daterange", dateRange);
	}if(event.target.value ==  'today'){
		let date = new Date();
		let startDate = date.getFullYear() + "-" + ((date.getMonth()+1)).toString().padStart(2,'0') + "-" + (date.getDate().toString()).padStart(2,'0');
		let endDate = date.getFullYear() + "-" + ((date.getMonth()+1)).toString().padStart(2,'0') + "-" + (date.getDate().toString()).padStart(2,'0');
		let dateRange = {startDate: startDate,endDate : endDate};
		this.props.onSubmitFilter("daterange", dateRange);
	}else if( event.target.value == 'All'){
		let dateRange = {startDate: null,endDate : null,all : 'all'};
		this.props.onSubmitFilter("daterange", dateRange);
	}
	
}
  render() {
    const { filter } = this.state;
	let newDate = new Date()
	let date = newDate.getDate();
	let month = newDate.getMonth() + 1;
	let year = newDate.getFullYear();
	let final=month+"/"+date+"/"+year;
    return (
      <div className="col-md-12">
        {/* <div className="kt-header__bottom"> */}
        <div className="">
          <div className="kt-container" style={{ backgroundColor: "#fff" }}>
            <div className="yr-pagetitle d-flex justify-content-between">
              <div className="left yr-titleSelect kt-margin-t-10 kt-margin-b-10 flex-wrap justify-content-center">
                <Link
                  to="../email"
                  className="btn linear-gradient yr-add-new float-right flex-m-n mb-3"
                >
                  <i
                    className="fas fa-chevron-left"
                    style={{ fontSize: "12px" }}
                  ></i>
                  Back to my email playbooks
                </Link>
                <div className="yr-allselect">
                  <span>Detailed stats: {this.props.playbookname}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-form kt-form--label-right kt-margin-t-30 kt-margin-b-30 yr-manageUser-table">
          <div className="row align-items-center justify-content-md-end">
            <div className="col-md-6">
              <div className="row align-items-center">
                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile">
                  <div
                    className="row align-items-center"
                    style={{ justifyContent: "flex-end" }}
                  >
                    { <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                      <div className="kt-input-icon kt-input-icon--left">
                        <Form onSubmit={this.handleOnClick}>
                          <Form.Input
                            style={{ marginLeft: 4 }}
                            name="filter"
                            className="search-input-mu"
                            value={filter}
                            onChange={this.handleOnChange}
                          />
                        </Form>
                        <span
                          className="kt-input-icon__icon kt-input-icon__icon--left"
                          style={{ cursor: "pointer",opacity: this.state.needToShow }}
                          onClick={this.handleOnClick}
                        >
                          <span>
                            <i className="la la-search"></i>
                          </span>
                        </span>
                        <span className="yr-searchPlace-holder" style={{opacity: this.state.needToShow}}>Search</span>
                      </div>
                    </div> }
                    {/* adding filter datewise for analytics starts*/}
                    <DateRangePicker
                        initialSettings={{ startDate: final }}
                        onApply={(event, picker)=>this.applyDateRange_analytics(event, picker)}
                    >
                        <div className="showrangepicker_analytics">
                            <div className="form-control border-bottom-0 arrow-color mat-style" style={{cursor:"pointer"}}></div> 
                    </div>
                    </DateRangePicker>
					<div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile" style={{minWidth: 'unset', marginRight: '1rem', maxWidth: 170}}>
                        <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                                <label></label>
                            </div>
                            <div className="kt-form__control yr-time-ShowHide">
                            <span class="custom-arrow">
                                <select className="form-control bootstrap-select arrow-color mat-style border rounded yr-time-ShowHide"  name="chatfrequency" id="kt_form_type" onChange={(event) => this.openDateFilterAnalytics(event)}>
                                    <option value="today">Todays </option>
                                    <option value="All">All time </option>
                                    <option value="past_7_days">Past 7 Days</option>
                                    <option value="past_month">Past Month</option>
                                    <option value="past_year">Past Year</option>
                                    <option value="daterange">Date Range</option> 
                                </select>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* adding filter datewise for analytics starts*/}
                    <div className="kt-margin-b-20-tablet-and-mobile">
                      <button
                        type="button"
                        className="btn linear-gradient btn-outline-info btn-icon-sm zy-outline yr-submitbtn fix-btn-size"
                        onClick={() => this.props.downloadCSV()}
                      >
                        Export CSV   
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DatatableFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  teamlist: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};
