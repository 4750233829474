import React from "react";
import { connect } from "react-redux";
import api from "../../../api";
import Comments from "./Comments";
import {
  setPageTitle,
  setBreadCrum,
  setPageLoading,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../store/Page";
import "./SupportComment.css";
import DialogBox from "../../Modal/DialogBox";
import { Message } from "semantic-ui-react";
import ScreenShots from "../../Pages/Settings/ScreenShots";
import CustomImageUploadField from "../../Form/CustomImageUploadField";
import util from "../../../util";
import config from "../../../config";
import utilFunc from "../../../util_funs";
import MessageDialog from "../../Modal/DialogBox";
import validationRule from "../../../validation";
import { Form } from "formsy-semantic-ui-react";
validationRule.isRequired();

class SupportComments extends React.Component {
  constructor(props) {
    super(props);
    this.props.toggleBreadcrum({ toggle: false });
    this.state = {
      fileModal: false,
      fileModalMessage: "",
      comment: "",
      ticketId: null,
      loading: false,
      toggleModal: false,
      message: "",
      commentLists: [],
      screenshotsModal: false,
      images: [],
      files: [],
    };
    this.formRef = React.createRef();
  }
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
  saveRecord = (data, obj) => {
    const tokenInfo = util.getAuthTokens();
    var xhr = new XMLHttpRequest();

    xhr.open(
      "POST",
      config.apiBaseURL + "/settings/support/save_comment",
      true
    );

    xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
    xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);

    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.response) {
          const resp = JSON.parse(xhr.response);
          if (obj.state.files.length > 0) {
            document.querySelector("#pictures").nextSibling.remove();
          }
          obj.setState(
            {
              loading: false,
              toggleModal: true,
              message: resp.message,
              comment: "",
            },
            () => {
              obj.formRef.current.reset();
              utilFunc.scrollTop(800);
              setTimeout(() => {
                obj.removeMessage();
              }, 5000);
              obj.loadCommentDetails(obj.props.match.params.id, false);
            }
          );
        }
      }
    };

    xhr.send(data);
  };
  removeMessage = () => {
    this.setState({ toggleModal: false, message: "", files: [] });
  };

  handleSubmit = () => {
    try {
      this.setState({ loading: true });

      let formData = new FormData(
        document.querySelector("#support-comment-form")
      );
      formData.delete("images[]");
      const timeZone = util.getTimeZone();
      formData.set("timeZone", timeZone);
      formData.set("ticketId", this.state.ticketId);

      if (this.state.files.length > 0) {
        for (let i = 0; i < this.state.files.length; i++) {
          formData.append("images[]", this.state.files[i]);
        }
      }

      this.saveRecord(formData, this);
    } catch (err) {
      console.log(err);
    }
  };
  componentDidMount() {
    this.props.toggleNavigation({ toggle: true });

    this.props.setPageTitle({
      title: "Support Tickets",
      subHeaderTitle: "Settings",
      navigationName: "comment",
    });

    setTimeout(() => {
      this.props.toggleBreadcrum({ toggle: true });
    }, 2000);

    try {
      if (this.props.match.params.id) {
        this.loadCommentDetails(this.props.match.params.id);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  loadCommentDetails = (ticketId, comment = true) => {
    this.setState({ loading: true });
    const timeZone = util.getTimeZone();

    api.ticket
      .getCommentList({ ticketId, timeZone })
      .then((resp) => {
        if (resp && resp.ticket_info) {
          const ticketInfo = resp.ticket_info;

          if (comment) {
            const breadcrum = {
              type: "comment",
              ticketNo: ticketInfo.ticket_no,
              summary: ticketInfo.summary,
              description: ticketInfo.content,
              email: ticketInfo.email,
              ticketDate: ticketInfo.updated_date,
            };
            this.props.setBreadCrum({ breadcrum });
          }

          this.setState({
            ticketId: ticketInfo.id,
            loading: false,
            commentLists: resp.comment_lists,
            images: ticketInfo.images,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  displayComments = () => {
    if (this.state.commentLists.length > 0) {
      return this.state.commentLists.map((comment, index) => {
        return <Comments comments={comment} key={index} />;
      });
    }
    return "";
  };

  previewImages = () => {
    if (this.state.images.length > 0) this.setState({ screenshotsModal: true });
  };
  closeModal = () => {
    this.setState({ fileModal: false, fileModalMessage: "" });
  };
  toggleModal = () =>
    this.setState({ screenshotsModal: false, toggleModal: false });

  addFiles = (file) => {
    const fsize = file.size;
    const fileSize = Math.round(fsize / 1024);
    // The size of the file.
    //
    if (fileSize >= 4096) {
      this.setState({
        fileModal: true,
        fileModalMessage:
          file.name +
          " image size is too large to upload use image size below 4MB.",
      });
    } else {
      let files = this.state.files;
      files.push(file);
      this.setState({ files });
    }
  };
  render() {
    const errorLabel = <div className="red" pointing={"true"} />;
    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p">
              {this.state.fileModal && (
                <MessageDialog
                  show={this.state.fileModal}
                  title="support"
                  message={this.state.fileModalMessage}
                  toggleModal={this.closeModal}
                />
              )}
              {this.state.toggleModal && (
                <Message color={"green"} style={{ marginTop: 30 }}>
                  {this.state.message}
                </Message>
              )}
              {this.state.loading && <div className="ui loading form"></div>}

              {this.state.screenshotsModal && (
                <DialogBox
                  show={this.state.screenshotsModal}
                  toggleModal={this.toggleModal}
                  title="Screenshots"
                  width="500px"
                  height="300px"
                >
                  <ScreenShots images={this.state.images} />
                </DialogBox>
              )}

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <br />
                  {this.state.images.length > 0 && (
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="field">
                        <label
                          style={{
                            color: "#9f9d9d",
                            fontWeight: "500",
                            marginBottom: "15px",
                          }}
                        >
                          Associated Files
                        </label>
                      </div>
                      <div className="ui minimal comments">
                        <ScreenShots images={this.state.images} size="5" />
                      </div>
                    </div>
                  )}
                  <div className="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
                    <Form
                      className="ui reply form"
                      noValidate
                      autoComplete="off"
                      onValidSubmit={this.handleSubmit}
                      ref={this.formRef}
                      encType="multipart/form-data"
                      id="support-comment-form"
                    >
                      <div className="field">
                        <label>Ticket Reply</label>
                        <Form.TextArea
                          autoComplete="off"
                          name="comment"
                          onChange={this.handleChange}
                          value={this.state.comment}
                          placeholder="Click to add comment"
                          validations="isRequired"
                          validationErrors={{
                            isRequired: "This field is required.",
                          }}
                          style={{
                            height: "12em",
                            minHeight: "8em",
                            maxHeight: "24em",
                            border: "",
                            borderRadius: 0,
                          }}
                          errorLabel={errorLabel}
                        />
                      </div>
                      <div className="field">
                        <label>You may upload image here.</label>
                        <div
                          className="kt-dropzone dropzone"
                          action="inc/api/dropzone/upload.php"
                          id="m-dropzone-one"
                        >
                          <div
                            className="kt-dropzone__msg dz-message needsclick"
                            id="drop-container"
                          >
                            <CustomImageUploadField
                              addFiles={(file) => this.addFiles(file)}
                            />
                            <h3 className="kt-dropzone__msg-title">
                              Click / Drag and Drop Images Here
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="text-right mt-4 mb-2">
                        <button
                          name="submit"
                          type="submit"
                          className="btn linear-gradient yr-submitbtn"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  {this.state.commentLists.length > 0 && (
                    <div className="ui minimal comments">
                      {this.displayComments()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  setBreadCrum,
  setPageLoading,
  toggleNavigation,
  toggleBreadcrum,
})(SupportComments);
