import React from "react";
import {
    Form, Dropdown, TextArea
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import './Popup.css';
import 'semantic-ui-css/semantic.min.css';
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";
import utilFunc from "../../../../util_funs";


validationRule.isRequired();
validationRule.usPhoneLength();
validationRule.newPinAndOldPinNotSame();

class DialogBoxAddEditLocations extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.obj.locationSettingAddEditModalShow,
			loading: "none",
			title:"Select how to distribute leads",
			popup:"within",
			within:"zip",
			zip:"",
			state:"",
			miles:"Yet to set",
			color1:"green",
			message1:"none",
			msg1:""

		}


	}
	
	onValidSubmit = e =>{
		if(this.state.within=="zip")
			var t = "Zip Codes";
		else
			var t = "States"
		this.setState({
			popup:this.state.within,
			title:t
		})
	}

	onValidSubmitZip = e =>{
		this.setState({loading:"block"})
		api.manageUser
            .addZipLocation({id:this.props.id,zip:this.state.zip,miles:this.state.miles})
            .then((resp) => {
				if(resp.data.status==false){
					if(resp.data.message=="zipNotFound"){
						this.setState({
							loading:"none",
							color1:"red",
							message1:"block",
							msg1:"Sorry! we are not available at this location."
						})
					} else if(resp.data.message=="alreadyExist"){
						this.setState({
							loading:"none",
							color1:"red",
							message1:"block",
							msg1:"This zip code is already added."
						})
					}
					var ref = this;
					setTimeout(function(){
						ref.setState({
							message1:"none",
						})
					},5000)
				} else {
					this.props.refreshLocationSetting();
					this.modalCloseHere("zip");
					this.props.afterLocationAddEditDelete("add","zip")

					this.setState({
						loading:"none",
						zip:"",
						
					})
				}
            })
            .catch((e) => {
                console.log(e);
            });
	}

	onValidSubmitState = e =>{
		this.setState({loading:"block"})
		api.manageUser
            .addStateLocation({id:this.props.id,state:this.state.state})
            .then((resp) => {
				if(resp.data.status==false){
					 if(resp.data.message=="alreadyExist"){
						this.setState({
							loading:"none",
							color1:"red",
							message1:"block",
							msg1:"This state is already added."
						})
					}
					var ref = this;
					setTimeout(function(){
						ref.setState({
							message1:"none",
						})
					},5000)
				} else {
					this.props.refreshLocationSetting();
					this.modalCloseHere("state");
					this.props.afterLocationAddEditDelete("add","state")

					this.setState({
						loading:"none",
						state:"",
						
					})
				}
            })
            .catch((e) => {
                console.log(e);
            });
	}

	modalCloseHere = e =>{
		if(e=="within"){
			document.getElementById("within").value="zip"
		}

		if(e=="zip"){
			document.getElementById("miles").value="Yet to set"
			document.getElementById("zip").reset()
			this.setState({
				popup:"within",
				title:"Select how to distribute leads",
				within:"zip",
				miles:"Yet to set"
			});
		}

		if(e=="state"){
			document.getElementById("state").value=""
			this.setState({
				popup:"within",
				title:"Select how to distribute leads",
				within:"zip",
				miles:"Yet to set"

			});
		}
		this.props.closeLocationSettingPopupWithAddEdit();
		
	}

	addZipLocation = e =>{

	}

	changeWithin = e =>{
		this.setState({
			within:e.target.value
		})
	}

	changeZip = e =>{
		this.setState({
			zip:e.target.value
		})
	}

	changeState = e =>{
		this.setState({
			state:e.target.value
		})
	}

	changeMiles = e =>{
		this.setState({miles:e.target.value})
	}



    render() {
        const errorLabel = <div className="red" />
        return (
            // (this.props.loadNow)?
            <React.Fragment>
                {<div style={{ display: this.state.loading }} class="ui loading form mh-loading-custom"></div>}
                <div
                    id="notificationSetingModalPopup"
                    className={this.props.obj.locationSettingAddEditModalShow ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.obj.locationSettingAddEditModalShow ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered"  role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.modalCloseHere(this.state.popup)}
                            >
                                X
						</button>
                            <div className="modal-header" style={{marginBottom:10}}>
								<h5 className="modal-title">{this.state.title}</h5>
                            </div>

                            {this.state.modalFormLoader && <div className="ui loading form"></div>}
							{(this.state.popup=="within")?
							<Form  noValidate autoComplete="off" id="firstForm" onValidSubmit={this.onValidSubmit}>
							<div className="modal-body" >
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first">
										<div className="kt-section__body">
										
										<div className="form-group row">
												<label className="col-lg-4 col-form-label">Send leads within :</label>
												<div className="col-lg-8" style={{marginTop:10}}>
												<select id="within" name="within" onChange={this.changeWithin} class="form-control">
													<option value="zip">Zip Wise</option>
													<option value="state">State Wise</option>
												</select>
												</div>
											</div>
											
										</div>
									</div></div></div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<button type="reset" onClick={() => this.modalCloseHere(this.state.popup)} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Cancel </span></button>
											<button type="submit" className="btn linear-gradient yr-submitbtn">Ok</button>
										</div>
									</div>
								</div>
							</div>
						</Form>:(this.state.popup=="zip")?
						
						<Form  noValidate autoComplete="off" id="zip" onValidSubmit={this.onValidSubmitZip}>
							<div className="modal-body">
								<div className="kt-portlet__body py-0">
								<Message
                                                        color={
                                                            this.state.color1
                                                        }
                                                        style={{
                                                            display: this.state.message1,
                                                        }}
                                                    >{this.state.msg1}</Message>
									<div className="kt-section kt-section--first">
										<div className="kt-section__body">
										<div className="form-group row">
												<label className="col-lg-4 col-form-label">Zip code : </label>
												<div className="col-lg-8">
												<Form.Input  fluid autoComplete="off" name="zip" id="zipForm"  placeholder='' 
												value={this.state.zip} placeholder=''
												validations='isRequired' onChange={this.changeZip} validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel}		 />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-lg-4 col-form-label">Send leads within :</label>
												<div className="col-lg-8" style={{marginTop:10}}>
												<select onChange={this.changeMiles} id="miles" name="miles" class="form-control">
													<option selected="selected"value="Yet to set"> Select </option>
													<option value="5">5 Miles</option>
													<option value="10" >10 Miles</option>
													<option value="15">15 Miles</option>
													<option value="20">20 Miles</option>
													<option value="25">25 Miles</option>
													<option value="30">30 Miles</option>
													<option value="40">40 Miles</option>
													<option value="50">50 Miles</option>
												</select>
												<Form.Input className="milesError"  type="hidden" autoComplete="off" name="zip"  placeholder='' 
												value={this.state.miles} placeholder=''
												validations='isRequired'  validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel}		 />
												</div>
											</div>
											
											<div className="form-group row">
												{/* <div className="col-lg-4 col-form-label"><a href="javascript:void(0);" onClick={() => this.addZipLocation()}>+ Click to add locations</a></div> */}
												<div className="col-lg-8" style={{marginTop:10}}>
												
												</div>
											</div>
											
											
										</div>
									</div></div></div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<button type="reset" onClick={() => this.modalCloseHere(this.state.popup)} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Cancel </span></button>
											<button type="submit" className="btn linear-gradient yr-submitbtn">Save</button>
										</div>
									</div>
								</div>
							</div>
						</Form>:
						
						<Form  noValidate autoComplete="off" id="state" onValidSubmit={this.onValidSubmitState}>
							<div className="modal-body">
								<div className="kt-portlet__body py-0">
								<Message
                                                        color={
                                                            this.state.color1
                                                        }
                                                        style={{
                                                            display: this.state.message1,
                                                        }}
                                                    >{this.state.msg1}</Message>
									<div className="kt-section kt-section--first">
										<div className="kt-section__body">
										
										<div className="form-group row">
												<label className="col-lg-4 col-form-label">State :</label>
												<div className="col-lg-8" style={{marginTop:10}}>
												<select onChange={this.changeState} id="state" name="state" class="form-control">
													<option value="">Select</option>
													{this.props.locationState.map(
														(
															{
																name,
																code,
																id
															},
															idx
														) =>
															
														<option value={code}>{name}</option>

													)}
												</select>
												<Form.Input className="milesError"  type="hidden" autoComplete="off" name="stateHidden"  placeholder='' 
												value={this.state.state} placeholder=''
												validations='isRequired'  validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel}		 />
												</div>
											</div>
											
											
											
											
										</div>
									</div></div></div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<button type="reset" onClick={() => this.modalCloseHere(this.state.popup)} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Cancel </span></button>
											<button type="submit" className="btn linear-gradient yr-submitbtn">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</Form>}
                            

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

export default DialogBoxAddEditLocations;
