import React, { useState } from 'react'
import { Message } from 'semantic-ui-react';
import "../../../../Modal/dialog.css";
import CustomErrorField from "../../../../Form/CustomErrorField";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const DialogBoxAdjustHour = (props) => {
    const selecteRangeBox = (hour, index, hourInOpen) => {
        if (hourInOpen) {
            return;
        }
        props.handleAvailableHour(hour, index);
    }
    // let newDate = new Date()
    // let date = newDate.getDate();
    // let month = newDate.getMonth() + 1;
    // let year = newDate.getFullYear();
    // let final=month+"/"+date+"/"+year;
    const handlePickerClick = (e) => {
        props.handleSettingForNextCycle(e);
        // if (e.target.value === 'date_range' && props.modalData?.setting_extends_for === 'date_range') {
        //     document.querySelector(".showdateRangePicker").click();
        // }
    }

    return (

        <div
            className={props.show ? "modal fade show" : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: props.show ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="close linear-gradient"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.modalClose('adjustHour')}
                    >
                        X
                    </button>
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                    </div>
                    {props.modalFormLoader && <div className="ui loading form"></div>}
                    <div className="modal-body">
                        <div className="kt-portlet__body py-0">
                            <div className="kt-section kt-section--first">
                                <div>
                                    <ul class="range-labels">
                                        {Object.keys(props.hourList).map((hour, index) => {
                                            let hourInOpen = false;
                                            const { open_hour_start_index, open_hour_end_index, working } = props.openHourData;
                                            if (open_hour_start_index && open_hour_end_index) {
                                                if (index >= open_hour_start_index && index <= open_hour_end_index && props.schedule_hour_type == 2) {
                                                    hourInOpen = true;
                                                }
                                            }
                                            if (props.schedule_hour_type == 2 && working) {
                                                hourInOpen = true;
                                            }
                                            return (
                                                <li key={index} className={hourInOpen ? "disabled" : ''} onClick={() => selecteRangeBox(hour, index, hourInOpen)}>
                                                    <label>{hour}</label>
                                                    <div className={props.modalData?.availableHour.includes(hour) ? 'rangeBox selected' : 'rangeBox'}></div>
                                                </li>
                                            )

                                        })}
                                    </ul>
                                    <div>
                                        <p style={{ fontWeight: 500 }}>Send notification &nbsp;
                                            <select name='' value={props.modalData?.notification_sent_times} onChange={(e) => props.handleNotificationSentTime(e)}>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                            </select>

                                            &nbsp; time
                                        </p>
                                        <div className='notification_sent_via_times'>
                                            {props.modalData?.notification_sent_times_data?.map((data, index) => (
                                                <div key={index} style={{ marginBottom: '10px', border: props.modalData?.error?.includes(index) ? '1px solid red' : '1px solid gray', padding: '10px', borderRadius: '5px' }}>
                                                    <span>Send #{data.sent_times} via &nbsp;</span>
                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                        {['email', 'text', 'phonecall'].map(option => (
                                                            <label key={option} style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    value={option}
                                                                    checked={(data.sent_times == 1) ? props.modalData.notify_via?.includes(option) : data.sent_via.includes(option)}
                                                                    onChange={(e) => props.handleSentViaChange(index, option, e.target.checked)}
                                                                    disabled={data.sent_times == 1 ? true : false}
                                                                />
                                                                <span style={{ marginLeft: '5px' }}>{option === 'phonecall' ? 'Phone Call' : option.charAt(0).toUpperCase() + option.slice(1)}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                    {props.modalData?.error?.includes(index) && <span style={{ color: 'red', fontSize: '12px' }}>Please select at least one method.</span>}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='schedule_extends'>
                                            <p style={{ fontWeight: 500 }}>Use this setting for &nbsp;
                                                <select name='' value={props.modalData?.setting_extends_for} onChange={(e) => handlePickerClick(e)}>
                                                    <option value='today'>selected date</option>
                                                    <option value='week'>next week</option>
                                                    <option value='month'>next month</option>
                                                    <option value='date_range'>date range</option>
                                                </select>
                                            </p>
                                            <div>
                                                {props.modalData?.setting_extends_for === 'date_range' &&
                                                    <DateRangePicker
                                                        onApply={(event, picker) => props.handleDateRange(event, picker)}
                                                    >
                                                        <div title='Click to open calendar' className='showdateRangePicker'>
                                                            <div className=''>{props.modalData.selectedText}</div>
                                                        </div>
                                                    </DateRangePicker>
                                                }
                                                {props.modalData?.setting_extends_for !== 'date_range' &&
                                                    <div className=''>{props.modalData.selectedText}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <div className="row">
                                <div className="col-lg-12 d-flex justify-content-end">
                                    <input name="subaccount_id" type="hidden" value={''} />
                                    <button type="reset" onClick={() => props.modalClose('adjustHour')} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Close </span></button>
                                    <button type='reset' className="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => props.clearHour()}><span>Clear</span></button>
                                    {/* <button type="button" onClick={() => props.saveAvailableHour()} className="btn linear-gradient yr-submitbtn">Submit</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DialogBoxAdjustHour
