import axios from "../../config/axios";

const marketsharp = {
  list: (params) =>
    axios
      .post("/integration/marketsharp/list", params)
      .then((resp) => (resp ? resp.data : null)),

  locationList: (params) =>
    axios
      .post("/integration/marketsharp/locations", params)
      .then((resp) => (resp ? resp.data : null)),

  createNew: (params) =>
    axios
      .post("/integration/marketsharp/create_new", params)
      .then((resp) => (resp ? resp.data : null))
      .catch((err) => err),

  getMarketsharpCredentials: (params) =>
    axios
      .post("/integration/marketsharp/getMarketsharpCredentials", params)
      .then((resp) => (resp ? resp.data : null)),

  deleteMarketsharpCredential: (params) =>
    axios
      .post("/integration/marketsharp/delete_credentials", params)
      .then((resp) => (resp ? resp.data : null)),
};

export default marketsharp;
