import React, { useState, useRef, useCallback, useMemo } from 'react'
import Button from '../../UI/button/Button';
import classes from './LiveChatTab.module.css';
import { Accordion, Icon, Checkbox, Form, Radio, TextArea, Input } from 'semantic-ui-react';
import EngageChat from './smschat/engagechat/EngageChat';
import Modal from '../ChatSetup/components/Modal';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import InputNumber from './InputNumber';
import api from '../../../api';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import UrlShortner from './UrlShortner';
//import { useDropzone } from 'react-dropzone';


const uid = () =>
    String(
        Date.now().toString(32) +
        Math.random().toString(16)
    ).replace(/\./g, '');

const generateMask = (phoneNumberValue) => {
    phoneNumberValue = "(" + phoneNumberValue;
    let l30 = phoneNumberValue.length;
    let p30 = phoneNumberValue.substring(0, 4);
    p30 = p30 + ")";

    let p31 = phoneNumberValue.substring(4, l30);
    let pp = p30 + p31;

    let l40 = pp.length;
    let p40 = pp.substring(0, 8);
    p40 = p40 + "-";

    let p41 = pp.substring(8, l40);
    let ppp = p40 + p41;
    let maxphonelength = 13;
    return ppp.substring(0, maxphonelength);
}



const LiveChatTab = (props) => {
    console.log('i am rendering');
    const [activeIndex, setActiveIndex] = useState(0);
    const handleActive = (e, titleProps) => {
        console.log("titalprops", titleProps);
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        console.log("dnew index", newIndex);
        setActiveIndex(newIndex);
    }
    const [showModel, setshowModel] = useState(false);
    const [modalLoading, setmodalLoading] = useState(false);
    const [modalTitalAdd, setmodalTitalAdd] = useState('');
    const [modalTitalView, setmodalTitalView] = useState('');
    const [customInput, setCustomInput] = useState([]);
    const [showModelRecipient, setshowModelRecipient] = useState(false);
    const [trumiaPhone, setTrumpiaPhone] = useState(null);
    const [fromNumber, setfromNumber] = useState('');
    const [validityinhour, setvalidityinhour] = useState('');
    const [shortnerBox, setshortnerBox] = useState(false);
    const [uploadCsvBox, setuploadCsvBox] = useState(false);
    const [selectedFile, setselectedFile] = useState(null);
    const [csvData, setcsvData] = useState(null);
    const [csvDisabled, setcsvDisabled] = useState(false);
    const [showDropDown, setshowDropDown] = useState(false);
    const [countSelectList, setcountSelectList] = useState(null);
    const [allListNumber, setallListNumber] = useState(null);
    const [listIndex, setlistIndex] = useState(1);
    const [selectedOption, setSelectedOption] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [uploadtemplate, setuploadtemplate] = useState('');
    const [errorsMmsUpload, seterrorsMmsUpload] = useState('');
    const [choosetemplate, setchoosetemplate] = useState('');
    const [csvError, setcsvError] = useState('');
    const [acceptTerms, setacceptTerms] = useState(false);
    const [recipientError, setrecipientError] = useState(false);
    const [textContentError, settextContentError] = useState(false);
    const [termAcceptError, settermAcceptError] = useState(false)
    

    let mulRef = useRef();



    const handleClick = async () => {
        setmodalLoading(true);
        setshowModel(true);
        setmodalTitalAdd('Send Message')
        api.liveChat.listSmsNumber({ type: 'livechat' })
            .finally(() => {
                setmodalLoading(false);
            })
            .then((data) => {
                if (data.status && data.message === 'Success') {
                    let phoneList = data.data.map((itm) => {
                        return {
                            key: itm.auto_id,
                            value: itm.phone,
                            text: generateMask(itm.phone)
                        }
                    });
                    const getPhone = api.liveChat.getChatListPhoneNumbers({ index: 1 });
                    getPhone.then((data) => {
                        console.log(data.data);
                        if (data.data.status) {
                            const dataList = data.data.data;
                            const newobj = dataList.map((item) => {
                                return {
                                    value: item.phone_number,
                                    checked: false,
                                    label: maskPhone(item.phone_number),
                                    id: uid()
                                }
                            });
                            setallListNumber(newobj);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                    setTrumpiaPhone(phoneList);

                }
                console.log('list sms number', data);
            })
            .catch((err) => {
                console.log(err);
            });


    }

    const modalClose = () => {
        setshowModel(false);
    }

    const closeViewModel = () => {
        setshowModelRecipient(false);
    }


    const validityInHour = [...Array(20).keys()].map((item) => {
        return {
            key: uid(),
            value: item + 1,
            text: item + 1
        }
    });



    const phoneFormat = (e) => {
        let phone = e.target.value;
        let p = phone;

        p = p.replace(/[^\d]*/gi, "");
        if (p.length < 3) {
            return p;
        } else if (p.length == 3) {
            let pp = p;
            let d4 = p.indexOf("(");
            let d5 = p.indexOf(")");
            if (d4 == -1) {
                pp = "(" + pp;
            }
            if (d5 == -1) {
                pp = pp + ")";
            }
            return pp;
        } else if (p.length > 3 && p.length < 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            return pp;
        } else if (p.length >= 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            let l40 = pp.length;
            let p40 = pp.substring(0, 8);
            p40 = p40 + "-";

            let p41 = pp.substring(8, l40);
            let ppp = p40 + p41;
            let maxphonelength = 13;
            let finalPhone = ppp.substring(0, maxphonelength);
            return finalPhone;
        }
    };
    const maskPhone = (p) => {
        p = "(" + p;
        let l30 = p.length;
        let p30 = p.substring(0, 4);
        p30 = p30 + ")";

        let p31 = p.substring(4, l30);
        let pp = p30 + p31;

        let l40 = pp.length;
        let p40 = pp.substring(0, 8);
        p40 = p40 + "-";

        let p41 = pp.substring(8, l40);
        let ppp = p40 + p41;
        let maxphonelength = 13;
        let finalPhone = ppp.substring(0, maxphonelength);
        return finalPhone;
    }



    const addCustomInput = () => {
        let customInputList = customInput;
        const inputObj = {
            value: '',
            id: uid(),
            currentFocus: false
        };
        const newData = [...customInputList, inputObj]
        console.log(newData);
        setCustomInput(newData);
    }

    const removeCustomInput = (id) => {
        let allData = customInput;
        let filteredData = allData.filter((item) => item.id !== id);
        console.log("filter", filteredData);
        setCustomInput(filteredData);

    }

    const handleCustomInput = (e) => {
        const inputId = e.target.name.split('_');
        const numberValue = phoneFormat(e);
        let allData = [...customInput];
        let setFalse = allData.map((itm) => {
            return {
                value: itm.value,
                id: itm.id,
                currentFocus: false
            }
        });
        let filteredData = setFalse.findIndex((item) => item.id === inputId[1]);
        setFalse[filteredData].value = numberValue;
        setFalse[filteredData].currentFocus = true;
        console.log(setFalse);
        setCustomInput(setFalse);
    }

    const viewRecipient = (e) => {
        e.preventDefault();
        setshowModelRecipient(true);
        setmodalTitalView('View selected recipient')
    }

    const ViewRecipient = () => {
        return (
            <div className="row">
                <div className="col-md-4">
                    <div className={classes.list_dataheading}>Number list</div>
                    <div className={classes.list_view}>
                        {selectedOption && selectedOption.map((data) => {
                            return <span key={data.id}>{data.label}</span>
                        })}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={classes.list_dataheading}>Csv Data</div>
                    <div className={classes.list_view}>
                        {csvData && csvData.map((data, i) => {
                            return <span key={i}>{data}</span>
                        })}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={classes.list_dataheading}>Custom input</div>
                    <div className={classes.list_view}>
                        {customInput && customInput.map((data, ind) => {
                            return <span key={ind}>{data.value}</span>
                        })}
                    </div>
                </div>
            </div>
        )
    }

    const selectFromNumber = (e) => {
        setfromNumber(e.target.value);
    }

    const renderOptionPopup = () => {
        const phoneList = trumiaPhone;
        return phoneList && phoneList.map((key) => {
            return <option key={key.value} value={key.value}>{key.text}</option>
        })
    }
    const renderValidity = () => {
        return [...Array(20).keys()].map((hourNo, index) => {
            return <option key={index + 1} value={hourNo + 1}>{hourNo + 1}</option>
        })
    }

    const selectValidity = (e) => {
        setvalidityinhour(e.target.value);
    }

    const handleOnCloseShortner = (e) => {
        setshortnerBox(!shortnerBox);
    }

    const uploadCsvOpen = () => {
        setcsvError('');
        setuploadCsvBox(!uploadCsvBox);
    }

    const onFileChange = (e) => {
        const allowedType = ['application/vnd.ms-excel', 'text/csv'];
        const fileInput = e.target.files[0];
        console.log(fileInput, fileInput.type);
        if (allowedType.includes(fileInput.type)) {
            setcsvError('');
            setselectedFile(fileInput);
        } else {
            setcsvError('Please upload only csv file.');
            setselectedFile(null);
            setcsvData(null);
        }
    }
    const onUploadFile = () => {
        setcsvDisabled(true);
        const file = selectedFile;
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
            console.log('file loading');
            const fileContent = reader.result;
            console.log(fileContent);
            const newData = CSVToArray(fileContent);
            processCsvFile(newData, () => {
                alert('h');
                setcsvDisabled(false);
            });
        }
        reader.onerror = () => {
            console.log('Something went wrong', reader.error);
        }
    }
    const formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        // let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        let match = cleaned.match(/^\(?([2-9][0-9][0-9])\)?[- ]?(\d{3})[- ]?(\d{4})$/);
        if (match) {
            return match[0]
        } else {
            return '';
        }
    };
    const CSVToArray = (data, delimiter = ',', omitFirstRow = false) => data
        .slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
        .split('\n')
        .map(v => v.split(delimiter));

    const processCsvFile = (csvDataList, cb) => {
        const nn = csvDataList.map((data) => formatPhoneNumber(data[0]));
        console.log(nn);
        const [...removedDuplicate] = new Set(nn);
        const removeEmpty = removedDuplicate.filter((rows) => rows !== '');
        if (removeEmpty.length > 0) {
            setcsvData(removeEmpty);
        } else {
            setcsvData(null);
        }
        cb();
    }



    const deleteSelectedCsv = () => {
        setselectedFile(null);
        setcsvData(null);
    }


    const handleDropdown = (e, w, r) => {
        if (r.source === 'click' || r.source === 'rootClose') {
            setshowDropDown(e);
        }
    }

    const handleDropItem = (eventKey, event) => {
        // e.preventDefault();
        let newData = [...allListNumber];
        const eventTarget = event.target;
        let getInputNode = null;
        if (eventTarget.nodeName === 'LABEL') {
            getInputNode = eventTarget.firstChild;
        } else if (eventTarget.nodeName === 'INPUT') {
            getInputNode = eventTarget;
        }
        if (getInputNode) {
            const selectedId = getInputNode.dataset.id;
            let getSelectedData = newData.findIndex((item) => item.id === selectedId);
            newData[getSelectedData].checked = !newData[getSelectedData].checked;
            let countSelected = newData.filter((item) => item.checked === true);
            if (countSelected.length > 0) {
                setcountSelectList(countSelected.length);
            } else {
                setcountSelectList(null);
            }
            setallListNumber(newData);
        }

    }

    const handleScrollList = async () => {
        const getScrollDiv = document.querySelector('.css-11unzgr');
        console.log(getScrollDiv);
        getScrollDiv.addEventListener('scroll', (e) => {
            const targetDiv = e.target;
            let y = targetDiv.scrollTop;
            console.log(y);
            let abs = Math.abs(targetDiv.scrollHeight - targetDiv.clientHeight - targetDiv.scrollTop) < 1;
            console.log(abs);
            if (abs) {
                setlistIndex(prevState => prevState + 1);
                const getPhone = api.liveChat.getChatListPhoneNumbers({ index: listIndex });
                getPhone.then((data) => {
                    console.log(data);
                }).catch((err) => {
                    console.log(err);
                })
            }
        });
    }

    function onChangeMulti(v, e) {
        console.log(v, e);
        this.setState(v);
    }

    const onremoveTemplate = (event) => {
        document.getElementById("uploadtemplateID").value = "";
        setuploadtemplate('');
        setchoosetemplate('');
        seterrorsMmsUpload('');
    }

    const onchangeTemplate = (event1) => {
        let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
        let mainfile = event1.target.files[0];
        console.log(mainfile.size);
        if (mainfile.size < '2000000') {
            var _URL = window.URL || window.webkitURL;
            var img = new Image();
            var objectUrl = _URL.createObjectURL(mainfile);
            var event = event1
            img.onload = function (event) {
                _URL.revokeObjectURL(objectUrl);
                if (this.width < 1875 && this.height < 1275) {
                    console.log('i am here in width');
                    if (imageMIME.includes(mainfile.type) == true && mainfile.type != '') {
                        seterrorsMmsUpload('');
                        let file = mainfile;
                        //let imgurl = URL.createObjectURL(event.target.files[0]);
                        //console.log(imgurl);
                        setuploadtemplate(file);
                        setchoosetemplate('');
                        var all = document.getElementsByClassName('postcard--listitem');
                        for (var i = 0; i < all.length; i++) {
                            all[i].style.pointerEvents = 'none';
                        }
                    } else {
                        seterrorsMmsUpload('Invalid file format. Only "jpg, jpeg, png, gif" files are supported.');
                    }
                } else {
                    seterrorsMmsUpload('Image size should not be greater than 1875px wide and 1275px tall.');
                }
            };
            img.src = objectUrl;
        } else {
            seterrorsMmsUpload('Image size should be less than 2 MB.');
        }

    };

    
    const saveSendSms = () => {
        if (!acceptTerms) {
            settermAcceptError(true);
        }
        if (uploadtemplate === '' && props.smsTextContent === '') {
            settextContentError(true);
        }else{
            settextContentError(false);
        }
        
    }

    const handleTermChange = (e) => {
        setacceptTerms(!acceptTerms);
        settermAcceptError(false);
    }

    const handleTextSmscontent = (e) => {
        props.handleSmsTextContent(e.target.value);
    }


    const ModelBody = () => {
        return (
            <div className='row'>
                <div className='col-md-12'>
                    <Form>
                        <div className={`col-md-12 ${classes.livechatformdiv}`}>
                            <Form.Field>
                                <label>From</label>
                                {trumiaPhone &&
                                    <div className="yr-selectArrow">
                                        <select className="form-control" name='fromnumber' value={fromNumber} onChange={selectFromNumber}>
                                            {renderOptionPopup()}
                                        </select>
                                    </div>
                                }
                            </Form.Field>
                        </div>
                        <div className={`col-md-12 ${classes.livechatformdiv}`}>
                            <Form.Field>
                                <label>To</label>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    // flexWrap: 'wrap'
                                }}>
                                    <div className={classes.chatlist_number}>

                                        {allListNumber &&
                                            <ReactMultiSelectCheckboxes
                                                options={allListNumber}
                                                ref={mulRef}
                                                value={selectedOption}
                                                setState={setSelectedOption}
                                                onChange={onChangeMulti}
                                                key={'multiselect'}
                                            />
                                        }
                                    </div>
                                    <Button handleClick={uploadCsvOpen} className={classes.liveChat_formbutton}>Upload CSV</Button>
                                    <Button className={classes.liveChat_formbutton} handleClick={addCustomInput}>Add Custom</Button>
                                </div>
                            </Form.Field>
                        </div>
                        {uploadCsvBox &&
                            <div className={`col-md-12 ${classes.livechatformdiv}`}>
                                <div className={classes.urlshortner__box}>
                                    <input
                                        type='file'
                                        name='file'
                                        onChange={onFileChange}
                                        pattern="^.+\.(xlsx|xls|csv)$"
                                        accept='.csv'
                                    />
                                    <a href='#'>Download example</a>
                                    {csvError && <span style={{color: 'red', marginLeft: 10}}>{csvError}</span>}
                                    {csvData &&
                                        <p>{selectedFile.name}</p>
                                    }
                                </div>
                                <div className={classes.csvupload_buttons}>
                                    <button
                                        type="button"
                                        onClick={uploadCsvOpen}
                                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                                        data-dismiss="modal"
                                    >
                                        <span>Close</span>
                                    </button>
                                    {csvData &&
                                        <button
                                            type="button"
                                            onClick={deleteSelectedCsv}
                                            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                                            data-dismiss="modal"
                                        >
                                            <span>Delete</span>
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        onClick={onUploadFile}
                                        className="btn linear-gradient linear-gradient yr-submitbtn btn-sm"
                                        disabled={csvDisabled}
                                    >
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        }
                        <div className={`col-md-12 ${classes.livechatformdiv}`}>
                            <Form.Field>
                                <label className={classes.livechatform_viewrecipient}>
                                    <a href='#' onClick={viewRecipient}>
                                        <i className='fa fa-eye' aria-hidden='true'></i>
                                        <span>View selected recipient's</span>
                                    </a>
                                </label>

                            </Form.Field>
                            {recipientError && <p className='text-danger'>Please select at least one recipient number.</p> }
                        </div>
                        {customInput &&
                            <div className={`col-md-12 ${classes.livechatformdiv}`}>
                                {customInput.map((item) => (
                                    <InputNumber
                                        itemData={item}
                                        key={item.id}
                                        addCustomInput={addCustomInput}
                                        removeCustomInput={removeCustomInput}
                                        handleCustomInput={handleCustomInput}
                                    />
                                ))}
                            </div>
                        }

                        <div className={`col-md-12 ${classes.livechatformdiv}`}>
                            <Form.Field>
                                <label>Validity in Hours</label>
                                <div className="yr-selectArrow">
                                    <select className="form-control" name='validityinhour' value={validityinhour} onChange={selectValidity}>
                                        {renderValidity()}
                                    </select>
                                </div>
                            </Form.Field>
                        </div>
                        <div className={`col-md-12 ${classes.livechatformdiv}`}>
                            <p style={{ fontWeight: 500 }}>If you are sending a link, please don't use rebrand.ly , bit.ly or any other third party url shortener. This may cause some issue in SMS delivery. <a href='#' onClick={handleOnCloseShortner}>Click here</a> to create your own url short link.</p>
                        </div>
                        {shortnerBox &&
                            <div className={`col-md-12 ${classes.livechatformdiv}`}>
                                <UrlShortner
                                    OnCloseShortner={handleOnCloseShortner}
                                />
                            </div>
                        }
                        <div className={`col-md-12 ${classes.livechatformdiv}`}>
                            <Form.Field>
                                <label>Message</label>
                                <textarea key='smstext' placeholder='Message' value={props.smsTextContent} onChange={handleTextSmscontent} rows={3} />
                            </Form.Field>
                           {textContentError  && <p className='text-danger'>Write some text or choose an image.</p> }
                        </div>
                        <div className="form-group row">
                            <label className="col-lg-4">Upload file :</label>
                            <div className="col-lg-4">
                                <div className="field">
                                    <div className="ui fluid input" style={{ display: 'inline' }}>
                                        <div class="logo-img" style={{ width: '33%', height: 150, backgroundColor: 'transparent' }}><img src={uploadtemplate == '' ? "assets/media/logos/upload-bg.png" : URL.createObjectURL(uploadtemplate)} alt="" /></div>
                                        {errorsMmsUpload != '' && (
                                            <div style={{ color: 'red' }}>{errorsMmsUpload}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <label className="col-lg-4">
                                <div class="d-flex">
                                    <div class="upload-btn-wrapper">
                                        <button class="btn linear-gradient zy-save-btn ml-2">Upload</button>
                                        <input type="file" name="uploadtemplate" id="uploadtemplateID" onChange={(e) => onchangeTemplate(e)} accept="image/jpg,image/png,image/jpeg,image/gif" />
                                    </div>
                                    {uploadtemplate && (<button type="reset" className="btn linear-gradient zy-cancel-btn ml-2" onClick={(e) => onremoveTemplate(e)}>Remove</button>)}
                                </div>
                            </label>
                        </div>
                        <div className={`col-md-12 ${classes.livechatformdiv}`}>
                            <Form.Field className={classes.livechat__checkterm}>
                                <Checkbox
                                    label={''}
                                    onChange={handleTermChange}
                                    checked={acceptTerms}
                                />
                                <a href='#'>
                                    By using our SMS functionality, you agree to our Disclaimer of Liability
                                </a>
                            </Form.Field>
                            {termAcceptError  && <p className='text-danger'>Please accept terms and condition.</p> }
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
    const modalFooter = (type = null) => {
        return (
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => modalClose()}
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    data-dismiss="modal"
                >
                    <span>Close</span>
                </button>
                <button
                    type="button"
                    onClick={() => saveSendSms()}
                    className="btn linear-gradient linear-gradient yr-submitbtn btn-sm"
                >
                    <span>Save</span>
                </button>
            </div>
        );

    }

    const modalFooterRecipent = () => {
        return (
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => closeViewModel()}
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    data-dismiss="modal"
                >
                    <span>Close</span>
                </button>
            </div>
        )
    }

    return (
        <div className={`${classes.livechat_tab_container}`}>
            <div className={`${classes.livechat_sendatext}`}>
                <Button
                    disable={false}
                    type="button"
                    handleClick={handleClick}
                >
                    Send a text
                </Button>
            </div>
            <div className={`${classes.smschat__container}`}>
                <Accordion>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={handleActive}
                    >
                        <Icon name='angle right' />
                        Engaged Customers
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <EngageChat />
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={handleActive}
                    >
                        <Icon name='angle right' />
                        Text with no reply
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <EngageChat />
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={handleActive}
                    >
                        <Icon name='angle right' />
                        Archived Chat
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <EngageChat />
                    </Accordion.Content>
                </Accordion>
            </div>
            <Modal
                show={showModel}
                footer={modalFooter()}
                onclose={modalClose}
                title={modalTitalAdd}
                body={<ModelBody />}
                loading={modalLoading}
            />
            <Modal
                show={showModelRecipient}
                footer={modalFooterRecipent()}
                onclose={closeViewModel}
                title={modalTitalView}
                body={<ViewRecipient />}
                loading={modalLoading}
            />
        </div>
    )
}

export default React.memo(LiveChatTab)