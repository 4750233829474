import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
export const DatatableRow = props => (
  <Table.Row>
    <Table.Cell>{props.list.user_type!='main'  ? <span><a ls_user_id={props.list.id} className='btn btn-icon' onClick={() => props.editUserSubAccounts(props.list.id)} title='Edit'><i className='la la-edit blue'></i></a><a onClick={() => props.deleteUserSubAccounts(props.list.id)} title='Delete' className='btn btn-icon gray'><i className='la la-trash'></i></a> </span> :'' }</Table.Cell>
    <Table.Cell>{props.list.email}</Table.Cell>
    <Table.Cell>{props.list.name}</Table.Cell>
    {/* <Table.Cell>{props.list.location ? props.list.location : "All"}</Table.Cell> */}
    <Table.Cell>
    <Link 
     to={{
      pathname: 'hippa-log',
      state:props.list.email
 }}>View Details</Link>
    </Table.Cell>
    <Table.Cell>{props.list.created_date}</Table.Cell>
    <Table.Cell><a href="javascript:void(0);" onClick={() => props.preOpensetting(props.list.id)}>View Details</a></Table.Cell>
    <Table.Cell><a href="javascript:void(0);" onClick={() => props.openNotificationSetting(props.list.id)}>View Details</a></Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
