import {
    scrollTop,
    dcrypt_url,
    encrypt_url
  } from "./Util_funs";
  
  export default {
    scrollTop,
    encrypt_url,
    dcrypt_url
  };
  