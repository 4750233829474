import React from "react";
import config from "../../config";
import "./youtube.css";
class YoutubeModal extends React.Component {
  toggleModal = () => this.props.toggleModal();
  render() {
    let { show, title, width, height, text, youtubeUrl } = this.props;
    if (!width) width = "1200px";
    if (!height) height = "590px";

    return (
      <React.Fragment>
        <div className="yr-videoBtn" style={{ paddingRight: "20px" }}>
          <a
            className="watch-video"
            data-toggle="modal"
            data-target="#zyratab8Youtube"
          >
            {text}
            <img
              src={config.imageUrl + "/assets/images/play-icon.png"}
              alt=""
            />
          </a>
        </div>

        <div
          className={show ? "modal fade show " : "modal fade"}
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ display: show ? "block" : "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: width }}
          >
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.toggleModal}
              >
                X
              </button>
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
              </div>
              <div className="modal-body" style={{ maxHeight: height }}>
                <iframe
                  defer
                  class="you-tube"
                  src={youtubeUrl}
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default YoutubeModal;
