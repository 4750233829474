export const transcriberLanguages = [
    { code: "", name: "Please select" },
    { code: "bg", name: "Bulgarian (bg)" },
    { code: "ca", name: "Catalan (ca)" },
    { code: "zh", name: "Chinese (zh)" },
    { code: "zh-CN", name: "Chinese (Simplified) (zh-CN)" },
    { code: "zh-Hans", name: "Chinese (Hans) (zh-Hans)" },
    { code: "zh-TW", name: "Chinese (Traditional) (zh-TW)" },
    { code: "zh-Hant", name: "Chinese (Hant) (zh-Hant)" },
    { code: "cs", name: "Czech (cs)" },
    { code: "da", name: "Danish (da)" },
    { code: "da-DK", name: "Danish (Denmark) (da-DK)" },
    { code: "nl", name: "Dutch (nl)" },
    { code: "nl-BE", name: "Dutch (Belgium) (nl-BE)" },
    { code: "en", name: "English (en)" },
    { code: "en-US", name: "English (US) (en-US)" },
    { code: "en-AU", name: "English (Australia) (en-AU)" },
    { code: "en-GB", name: "English (UK) (en-GB)" },
    { code: "en-NZ", name: "English (New Zealand) (en-NZ)" },
    { code: "en-IN", name: "English (India) (en-IN)" },
    { code: "et", name: "Estonian (et)" },
    { code: "fi", name: "Finnish (fi)" },
    { code: "fr", name: "French (fr)" },
    { code: "fr-CA", name: "French (Canada) (fr-CA)" },
    { code: "de", name: "German (de)" },
    { code: "de-CH", name: "German (Switzerland) (de-CH)" },
    { code: "el", name: "Greek (el)" },
    { code: "hi", name: "Hindi (hi)" },
    { code: "hi-Latn", name: "Hindi (Latin script) (hi-Latn)" },
    { code: "hu", name: "Hungarian (hu)" },
    { code: "id", name: "Indonesian (id)" },
    { code: "it", name: "Italian (it)" },
    { code: "ja", name: "Japanese (ja)" },
    { code: "ko", name: "Korean (ko)" },
    { code: "ko-KR", name: "Korean (South Korea) (ko-KR)" },
    { code: "lv", name: "Latvian (lv)" },
    { code: "lt", name: "Lithuanian (lt)" },
    { code: "ms", name: "Malay (ms)" },
    { code: "multi", name: "Multi (multi)" },
    { code: "no", name: "Norwegian (no)" },
    { code: "pl", name: "Polish (pl)" },
    { code: "pt", name: "Portuguese (pt)" },
    { code: "pt-BR", name: "Portuguese (Brazil) (pt-BR)" },
    { code: "ro", name: "Romanian (ro)" },
    { code: "ru", name: "Russian (ru)" },
    { code: "sk", name: "Slovak (sk)" },
    { code: "es", name: "Spanish (es)" },
    { code: "es-419", name: "Spanish (Latin America) (es-419)" },
    { code: "es-LATAM", name: "Spanish (Latin America) (es-LATAM)" },
    { code: "sv", name: "Swedish (sv)" },
    { code: "sv-SE", name: "Swedish (Sweden) (sv-SE)" },
    { code: "taq", name: "Tamasheq (taq)" },
    { code: "ta", name: "Tamil (ta)" },
    { code: "th", name: "Thai (th)" },
    { code: "th-TH", name: "Thai (Thailand) (th-TH)" },
    { code: "tr", name: "Turkish (tr)" },
    { code: "uk", name: "Ukrainian (uk)" },
    { code: "vi", name: "Vietnamese (vi)" }
];
