import React, { useState } from 'react';
import {
	Form, Dropdown
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import "../Modal/dialog.css";
import axios from "../../config/axios";
import api from "../../api";
const DialogBoxHipaaPin = ({ email,obj, modalClose, onValidHipaaSubmit, openHipaaAfterRegister }) => {
	
	const [pinErrorStyle,setPinErrorStyle] = useState("none");
	const [pinErrorStyleNonNumeric,setPinErrorStyleNonNumeric] = useState("none");
	
	const [pinErrorBox1Class,setPinErrorBox1Class] = useState("mh-pinInput")
	const [pinErrorBox2Class,setPinErrorBox2Class] = useState("mh-pinInput")
	const [pinErrorBox3Class,setPinErrorBox3Class] = useState("mh-pinInput")
	const [pinErrorBox4Class,setPinErrorBox4Class] = useState("mh-pinInput")

	const [nameErrorStyle,setNameErrorStyle] = useState("none");
	const [nameErrorBoxClass,setNameErrorBoxClass] = useState("")



	const [pin1,setPin1] = useState("");
	const [pin2,setPin2] = useState("");
	const [pin3,setPin3] = useState("");
	const [pin4,setPin4] = useState("");

	const[name,setName] = useState("");

	
	const elemRefs = [];
	const ref1 = React.createRef();
	const ref2 = React.createRef();
	const ref3 = React.createRef();
	const ref4 = React.createRef();
	elemRefs.push(ref1);
	elemRefs.push(ref2);
	elemRefs.push(ref3);
	elemRefs.push(ref4);

	
	let show = obj.modalstatusHIPAA;
	let title = obj.modaltitle;

	var type= obj.hipaaValidateObject.type;
	const [modalToasterMsg,setModalToasterMsg] = useState(false);
	const [msgOpacity,setMsgOpacity] = useState(1);
	const [modalToasterMsgText,setModalToasterMsgText] = useState("");
	const [modalToasterMsgClass,setModalToasterMsgClass] = useState("green");
	const [modalFormLoader,setModalFormLoader] = useState(false);
	let selectedOption = obj.selectedOptions;
	const errorLabel = <div className="red" />
	let passwordField = '';

	const autoTab = e => {
		const BACKSPACE_KEY = 8;
		const DELETE_KEY = 46;
		let tabindex = e.target.getAttribute("data-index") || 0;
		tabindex = Number(tabindex);
		let elem = null;
		if (e.keyCode === BACKSPACE_KEY) {
			elem = tabindex > 0 && elemRefs[tabindex - 1];
		} else if (e.keyCode !== DELETE_KEY) {
			elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1];
		}
		if (elem) {
			elem.current.focus();
		}
	};

	const checkPinValidation = (e) =>{
		var pass= true;
		if(pin1=="" || pin2=="" || pin3=="" || pin4==""){
			var pass= false;
			setPinErrorStyle("block")
		} else {
			setPinErrorStyle('none')
		}
		if(type=="register"){
			if(pin1!="" && pin2!="" && pin3!="" && pin4!=""){
				if(Number.isInteger(Number(pin1+pin2+pin3+pin4))){
					setPinErrorStyleNonNumeric("none")
				} else {
					var pass= false;
					setPinErrorStyleNonNumeric('block')
				}
			}
		}

		if(pin1==""){
			var pass= false;
			setPinErrorBox1Class("mh-pinInput mh-pinInput-redBorder")
		} else {
			setPinErrorBox1Class("mh-pinInput")
		}

		if(pin2==""){
			var pass= false;
			setPinErrorBox2Class("mh-pinInput mh-pinInput-redBorder")
		} else {
			setPinErrorBox2Class("mh-pinInput")
		}

		if(pin3==""){
			var pass= false;
			setPinErrorBox3Class("mh-pinInput mh-pinInput-redBorder")
		} else {
			setPinErrorBox3Class("mh-pinInput")
		}

		if(pin4==""){
			var pass= false;
			setPinErrorBox4Class("mh-pinInput mh-pinInput-redBorder")
		} else {
			setPinErrorBox4Class("mh-pinInput")
		}
		if(type=="register"){
			if(name==""){
				setNameErrorStyle("block");
			} else {
				setNameErrorStyle("none");
			}
		}

		if(pass){
			if(type=="register"){
				setModalFormLoader(true)
				api.user
				.addSubAccountOnFYI(
					{
						user_id:obj.user.user_id,
						type:"email",
						name:name,
						email:obj.user.email,
						password:pin1+pin2+pin3+pin4,
						url_type:"internal",
					})
				.then((resp) => {
					var hipaaValidateObject = obj.hipaaValidateObject;
					setModalFormLoader(false)
					// onValidHipaaSubmit(hipaaValidateObject.refId)
					modalCloseInThisComponent()
					openHipaaAfterRegister(obj.hipaaValidateObject.auto_id)
				})
				.catch((e) => {
					console.log(e);
				});
			} else {
				setModalFormLoader(true)
				var hipaaValidateObject = obj.hipaaValidateObject;
				hipaaValidateObject["pin"] = pin1+pin2+pin3+pin4;
				api.user
				.validatePin(hipaaValidateObject)
				.then((resp) => {
					if (resp.data && resp.data.status) {
						setModalFormLoader(false)
						onValidHipaaSubmit(hipaaValidateObject.refId)
						modalCloseInThisComponent()
					} else {
						setModalFormLoader(false)
						setModalToasterMsg(true)
						setModalToasterMsgClass("red")
						setModalToasterMsgText("Invalid PIN.")

						setTimeout(function(){
							setMsgOpacity(0)
							setTimeout(function(){
								setModalToasterMsg(false)
								setMsgOpacity(1)
							},300)
						},10000)
					}
				})
				.catch((e) => {
					console.log(e);
				});
			}
			

		}
		
	}
	const nameChange  = e=>{
		var val = e.target.value;
		setName(val)
	}
	const pinChange = e =>{
		var name = e.target.getAttribute("name");
		var val = e.target.value;
		if(name=="pin_0")
			setPin1(val)

		if(name=="pin_1")
			setPin2(val)

		if(name=="pin_2")
			setPin3(val)

		if(name=="pin_3")
			setPin4(val)		
	}

	const sendEmailForResetPin = e =>{
		setModalFormLoader(true)
		api.user
		.resetHipaaPin({ 
			user_id: obj.user.user_id,
			fun:"userInternalAccount",
			type: "main",
			dbtable:"ZU",
			device:"w"
		})
		.then((resp) => {
			setModalFormLoader(false)
			setModalToasterMsg(true)
			setModalToasterMsgClass("green")
			setModalToasterMsgText("An email has been sent to your account email id.")
			setTimeout(function(){
				setMsgOpacity(0)
				setTimeout(function(){
					setModalToasterMsg(false)
					setMsgOpacity(1)
				},300)
			},10000)
		})
		.catch((e) => {
			console.log(e);
		});
	}

	const modalCloseInThisComponent = e =>{
		setNameErrorStyle("none");
		setPinErrorStyle("none");
		setPinErrorStyleNonNumeric("none")
		setPinErrorBox1Class("mh-pinInput")
		setPinErrorBox2Class("mh-pinInput")
		setPinErrorBox3Class("mh-pinInput")
		setPinErrorBox4Class("mh-pinInput")
		setModalToasterMsg(false)
		setPin1("")
		setPin2("")
		setPin3("")
		setPin4("")
		setName("")
		modalClose();
	}
	
	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '529px' }} role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseInThisComponent()}
						>
							X
						</button>
						<div className="modal-header" >
							<h5 className="modal-title mh-h5-custom">{(type=="register")?"Please create a 4 number PIN":title}</h5>
						</div>
						<Form noValidate autoComplete="off" id="formHipaaPin"  >
							{modalFormLoader && <div className="ui loading form"></div>}
							<div className="modal-body">
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first">
										<Message 
										color={modalToasterMsgClass} style={{ display: modalToasterMsg ? "block" : "none",opacity: msgOpacity, transition: "opacity 1s" }}>{modalToasterMsgText}</Message>
										<div className="kt-section__body">
											{(type=="register")?<div className="form-group row">
												<label className="col-lg-4 col-form-label">Full Name:</label>
												<div className="col-lg-8 mh-customMarginPinRow">
												<input onChange={nameChange} value={name}  type="text" name="name" />
												<div style={{display:nameErrorStyle,marginTop:1}}  className="red">This field is required.</div>

												</div>

											</div>:""}
											
											<div className="form-group row">
												<label className="col-lg-4 col-form-label">Authorize Email:</label>
												<div className="col-lg-8 mh-customMarginPinRow">
												{email}
												</div>
											</div>
											<div className="form-group row ">
												<label className="col-lg-4 col-form-label">{(type=="register")?"Please create a 4 number PIN":"Pin"} :</label>
												<div className="col-lg-8">
													<div className="row" style={(type=="register")?{marginTop:13}:{}}>
														<div className="col-lg-12 d-flex">
														<div className="col-lg-3">
															<input onChange={pinChange} ref={ref1} value={pin1} onKeyUp={autoTab} data-index="0" maxLength="1" type="password" name="pin_0" className={pinErrorBox1Class} />
														</div>
														<div className="col-lg-3">
															<input onChange={pinChange} ref={ref2} value={pin2} onKeyUp={autoTab} data-index="1" maxLength="1" type="password" name="pin_1" className={pinErrorBox2Class} />
														</div>
														<div className="col-lg-3">
															<input onChange={pinChange} ref={ref3} value={pin3} onKeyUp={autoTab} data-index="2" maxLength="1" type="password" name="pin_2" className={pinErrorBox3Class} />
															</div>
															<div  className="col-lg-3">
																<input onChange={pinChange} ref={ref4} value={pin4} onKeyUp={autoTab} data-index="3" maxLength="1" type="password" name="pin_3" className={pinErrorBox4Class}/>
															</div>
														</div>
														<div style={{display:pinErrorStyle}}  className="red mh-pinError-cls">This field is required.</div>
														<div style={{display:pinErrorStyleNonNumeric}}  className="red mh-pinError-cls">Pin should be numeric.</div>
														
													</div>
												</div>
											</div>
											
											{(type=="register")?"":<div className="form-group row">
												<label className="col-lg-4 col-form-label"></label>
												<div className="col-lg-8 mh-customMarginPinRow">
												Forgot PIN <a onClick={() => sendEmailForResetPin()}
  															href="javascript:void(0);" >Click Here</a>
												</div>
											</div>}
										</div>
									</div>
								</div>
							</div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<button type="reset" onClick={() => modalCloseInThisComponent()} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Cancel </span></button>
											<button type="button" onClick={() => checkPinValidation()} className="btn linear-gradient yr-submitbtn">Ok</button>
										</div>
									</div>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxHipaaPin;
