import React from 'react';
import { Table } from "semantic-ui-react";

class DataColumns extends React.Component {
    constructor( props ){
        super(props)
        this.state = {
            column: null,
            order : null
        }
    }

    // sorting tabel 
    columnSorting = (column) => {
        if (column.sort === false) return false;
        this.setState({ column: column.key });
        let newOrder = null;
        if (this.state.order) {
            newOrder = this.state.order === "descending" ? "ascending" : "descending";
        } else {
            newOrder = "ascending";
        }
        this.setState({ order: newOrder }, () => {
            let order = newOrder === "descending" ? "desc" : "asc";
            this.props.sortElement(column.key, order);
        });
    };

    displayColumns = (dataColumns) => {
        return dataColumns.map((column, index) => {
            return (
                <Table.HeaderCell
                onClick={() => this.columnSorting(column)}
                key={index}
                style={{ width: column.width ? column.width : "" }}
                sorted={
                    column.sort !== false
                    ? this.state.column == column.key
                        ? this.state.order
                        : null
                    : null
                }
                >
                {column.checkbox && column.checkbox && (
                    <label className="kt-checkbox kt-checkbox--single">
                    <input type="checkbox" />
                    &nbsp;<span></span>
                    </label>
                )}
        
                {!column.checkbox && column.text}
                </Table.HeaderCell>
            );
        });
      };
    render(){
        const { dataColumns } = this.props;
        return(
            <React.Fragment>
                <Table.Header>
                <Table.Row>{this.displayColumns(dataColumns)}</Table.Row>
                </Table.Header>
            </React.Fragment>
        );
    }
}
export default DataColumns;