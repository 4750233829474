import React from "react";
import "../../../Modal/dialog.css";
import CustomErrorField from "../../../Form/CustomErrorField";

const DialogBox = (props) => {
	const { errors } = props.data;
	let vTitle = props.data.automationinfo.botdata.chatbotVariablesTitle ;
	return (
		<React.Fragment>
			<div
				className={props.data.dialogShowCustom ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: props.data.dialogShowCustom ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {props.data.dialogLoadingCustom && <div className="ui loading form"></div>}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => props.actionCustomQ('Close')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{props.data.dialogTitleCustom=='Add' ? 'Add New Question':'Edit Question'}</h5>
						</div>
						<div className="modal-body">
							<div className="px-4">
							{props.data.dialogTitleCustom=='Delete' ?
							(props.data.dialogMessageCustom):(
						    <div>
							{props.data.dialogTitleCustom=='Add'? (<div className="form-group row"> 
							<label className="col-lg-4 col-form-label" style={{marginTop: 0}}>Add question after :</label>
							 <div className="col-lg-8">
								 <div className="field">
									  <div className="ui fluid input">
									    <select  className="form-control" 
											name='questionAfter'  
											onChange={(e) => props.onchangeNewQuestion(e)} 
											value={props.data.questionAfter}>
											{ props.data.automationinfo.botdata.questionTitle && Object.keys(props.data.automationinfo.botdata.questionTitle).map(function (key){
												if(props.data.automationinfo.botdata.questionTitle[key]!='true'){
													return (   
													<option key={key} value={key}>{props.data.automationinfo.botdata.questionTitle[key]}</option>
													)
												}
											})}
                                        </select>  
									  </div>
								  </div>
							  </div>
						  </div>):(<div></div>)}	
							

						  <div className="form-group row"> 
							<label className="col-lg-12 col-form-label">Question :</label>
							 <div className="col-lg-12">
								 <div className="field">
									  <div className="ui fluid input" style={{display:'block'}}>
									  <textarea 
									  className={
										errors && errors['questionTitle']
											? "yr-ansDiv form-control is-invalid"
											: "yr-ansDiv form-control"
									   } 
									  rows="4" name='questionTitle' 
									  onChange={(e) => props.onchangeNewQuestion(e)} 
									  value={props.data.questionTitle} ></textarea> 
										{errors && errors.hasOwnProperty('questionTitle') && (
											<CustomErrorField
												message={errors['questionTitle']}
												className="error invalid-feedback"
											/>
										)}
									  </div>
								 </div>
							  </div>
						  </div>
						  

						  <div className="form-group row"> 
							<label className="col-lg-5 col-form-label"><a class="add-gradient form-group" style={{marginLeft: '35'}} onClick={() => props.actionButtonForQ('Add','')}>+Add button</a></label>
							<label className="col-lg-5 col-form-label" style={{marginTop: '15px'}}>Location</label>
						  </div>
						    { props.data.questionBtList && props.data.questionBtList.map((x, y) => {
								return(
									<div className="form-group row" key={y}> 
										<div className="col-lg-5">
											<input type="text" name={"text[" + y + "]"} 
											style={{border: '1px solid #1da0ff'}}
											 className={
												errors && errors["text[" + y + "]"]
													? "form-control is-invalid"
													: "form-control"
											}
											value={x.text} 
											onChange={(e) => props.onchangeNewQuestion(e,y,'text')}/>
										    {errors && errors.hasOwnProperty("text[" + y + "]") && (
												<CustomErrorField
													message={errors["text[" + y + "]"]}
													className="error invalid-feedback"
												/>
										    )}
										</div>
										<div className="col-lg-2"> 
												<span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
													<label style={{marginTop: 0}}>
													<input type="checkbox" name={"location[" + y + "]"} onChange={(e) => props.onchangeNewQuestion(e,y,'location')}  checked={x.location} />
													<span />
													</label>
												</span>
										</div>
										<div className="col-lg-1"> 
										<a title="Delete" class="btn btn-icon gray" onClick={() => props.actionButtonForQ('Delete',y,x)} ><i class="la la-trash"></i></a>
										</div>
									</div>
								)
						    })}
						  </div>
						  )}
							</div>
						</div>
						{ props.data.dialogTitleCustom=='Add' ? (
							<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => props.actionCustomQ('Close','')} data-dismiss="modal">
									<span>Cancel</span>
								</button>
								<button type="button" className="btn linear-gradient yr-submitbtn" onClick={() => props.actionCustomQ('Added','')} >
									Add
								</button>
							</div> ):( 
							<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => props.actionCustomQ('Close','')} data-dismiss="modal">
									<span>Cancel</span>
								</button>
								<button type="button" className="btn linear-gradient yr-submitbtn" onClick={() => props.actionCustomQ('Edited','')}>
									Edit
								</button>
							</div>)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBox;
