import React from 'react';
import classes from "./BusinessCategories.module.css";
export default function ComapnyOverview( props ){
    const handleComapnyOverView  = (event) => {
        props.setValue(event.target.value,"companyOverview");
    }
    return (
        <>
            <div className='col-md-12'>
                <div className="box-title">Company Overview</div>
                <div className='form-group'>
                    <textarea  
                        className={`${classes.companyOverviewTextarea}`}
                        value={props.companyOverview ?? props.companyOverview}
                        onChange={handleComapnyOverView}
                    >
                    </textarea>
                </div>
            </div>
        </>
    )
}