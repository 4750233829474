import React, { useEffect, useState } from "react";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../../../validation";
import "../../../../../Modal/dialog.css";
import api from "../../../../../../api";
const SubscribeStatus = ({ modalShow, dataList, emailAddress, closeModal}) => {
   

    return (
        <React.Fragment>
            <div
                className={modalShow ? "modal fade show" : "modal fade"}
                style={{ display: modalShow ? "block" : "none" }}
                id="AddEditContactModal"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close linear-gradient"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeModal}
                        >
                            X
                        </button>
                        <div className="modal-header">
                            <h5 className="modal-title">{emailAddress} subscription details</h5>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive">
                            <table className="table table-bordered personal--table">
                                <thead>
                                    <tr>
                                        <th width={'50%'}>Subscribed Domains</th>
                                        <th width={'50%'}>Unsubscribed Domains</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{ 
                                            dataList.subscribed !== undefined && dataList.subscribed.length > 0 &&  dataList.subscribed.map(el => (
                                                <span className="email_span">{el}</span>
                                            )) 
                                            }</td>
                                        <td>{ 
                                            dataList.unsubscribed !== undefined && dataList.unsubscribed.length > 0 &&  dataList.unsubscribed.map(el => (
                                                <span className="email_span">{el}</span>
                                            )) 
                                            }</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SubscribeStatus;
