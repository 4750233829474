import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "semantic-ui-css/semantic.min.css";

import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";

import Root from "./root";

// store.subscribe(() => {
//   console.log("Store changes : ", store.getState());
// });
//"basename={"/zyratalk/premium"}
ReactDOM.render(
  <Provider store={store}>
    <Router basename={"/"}>
      <Root />
    </Router>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
