import React from 'react';
import { Table, Segment, Divider,Loader } from "semantic-ui-react";
import DataColumns from './DataColoums';
import DataRow from './DataRows';
import Filter from './Filter';
import config from '../../../config';
import util from "../../../util";
import Paginate from './Paginate';
import billing from "../../../api/billing";
import './DataTable.css'
import { CSVLink, CSVDownload } from "react-csv";
import util_funs from '../../../util_funs';

class Datatable extends React.Component{
    constructor( props ){
        super( props );
        this.state = {
            error:"",
            dataLists: [],
            totalPages: null,
            totalRecord: null,
            popupLoaderStatus:false,
            currentPage: 1,
            loading: true,
            csvdata:[],
            params: {
                _limit    : config.dataTableSettings.pageLimit,
                _order    : null,
                _sort     : null,
                _page     : 1,
                timeZone  : util.getTimeZone(),
                _query    : "all_time",
                _queryType: "all",
                dateTo    : null,
                dateFrom  : null
            },
          };
          this.csvLink = React.createRef();
    }

    componentDidMount(){
        this.loadData();
    }

    changeParams = (data,feild) => {
        let params;
        if(feild =='_page' || feild == '_limit'){
            params = {...this.state.params,[feild]:data};
        }else{
            params = {...this.state.params,[feild]:data,dateTo:"",dateFrom:""};
        }
        this.setState({
            ...this.state,
            params:params
        },() => this.loadData() )
    }

    changeDateRangeParams = ( dateFrom,dateTo  ) => {
        let params = {...this.state.params,dateTo:dateTo,dateFrom:dateFrom};
        this.setState({
            ...this.state,
            params:params
        },() => this.loadData() )
    }

    /* getCsv = () => {
        this.setState({ loading: true });
        let data = {...this.state.params,userId : this.props.userid};
        billing.ExportBillingCsv( data ).then( ( resp ) => {
            if( typeof resp == "object" ){
                if(resp.status ){
                   // alert("pass");
                this.setState({csvdata:resp.data}, () => {
                    setTimeout(() => {
                      this.setState({ loading: false });
                      document.getElementById("chatcsvqq").click();
                    }, 2000);
                  });
                }else{
                    //alert("failure");
                }
            }
        });
    } */

    sortElement = ( sort,order) => {
        let params = {...this.state.params,_sort:sort,_order:order};
        this.setState({
            ...this.state,
            params:params
        },() => this.loadData() )
    }

    loadData = ( dataParams = null ) => {
        let response = null;
        this.setState({ loading: true });
        let data = {...this.state.params,userId : this.props.userid};
        billing.GetAllInvoices( data )
        .then(
            ( resp ) => {
                if( typeof resp == "object" ){
                    if( resp.status ){
                        this.setState({
                            ...this.state,
                            error : "",
                            totalRecord :   resp.totalCount,
                            dataLists   :   resp.data,
                            currentPage :   resp.page,
                            totalPages  :   Math.ceil(resp.totalCount/resp.count),
                            params      :   {...this.state.params,_limit:resp.count,_sort:resp.sort,_order:resp.order,_page:resp.page},
                            loading     :   false,
                            error       :   "",
                        });
                    }else{
                        this.setState({
                            ...this.state,
                            loading             :   false,
                            error               : "No Data Found",
                            params              : {...this.state.params,_limit:10,_sort:null,_order:null,_page:1},
                        })
                    }
                }else{
                    this.setState({
                        ...this.state,
                        loading     :   false,
                        error: "Something wents wrong Please try again ...."
                    })
                }
            }
        );
    }

    render(){
        let {dataColumns} = this.props;
        let startPoint = ((this.state.currentPage -1 ) * this.state.params._limit ) + 1;
        if(this.state.error != "") {}
        return(
            <React.Fragment>
                <Filter 
                    query = {this.state.params._query}
                    query_type = {this.state.params._queryType}
                    changeFilter = {this.changeParams}
                    changeDateRangeParams = {this.changeDateRangeParams}
                    // getCsv = {this.getCsv}
                />

                { !this.state.error ? <Table customTable celled  selectable sortable evenOddTable>
                    <DataColumns
                        dataColumns={dataColumns}
                        sortElement = {this.sortElement}
                    />
                    {Object.keys(this.state.dataLists).length != 0 && (
                        <DataRow
                            columnLength={this.state.dataLists.length}
                            dataRow={this.state.dataLists}
                            invoiceDetail = {this.props.invoiceDetail}
                            startPoint={startPoint}

                        />
                    )}
                    {Object.keys(this.state.dataLists).length == 0 && (
                        <caption style={{textAlign: 'center', width:' 100%', paddingTop: '.5rem', paddingBottom: '.5rem'}}>No invoices found. </caption>
                    )}
                    {/* pagination */}
                    {
                        this.state.totalRecord > 0 ? (
                            <Paginate
                                totalPages={this.state.totalPages}
                                changePage={this.changeParams}
                                currentPage={this.state.currentPage}
                                totalRecord={this.state.totalRecord}
                                pageLimit={this.state.params._limit}
                                changePageSize={this.changeParams}
                                columnLength={dataColumns.length}
                                startPoint={startPoint}
                            />
                        ) : (
                            <Paginate 
                                columnLength={this.state.dataLists.length}
                                changePage={this.changeParams}
                            />
                        )}
                    </Table> : 
                    <React.Fragment>
                        <Table customTable celled selectable sortable>
                        <DataColumns
                            dataColumns={dataColumns}
                            sortElement = {this.sortElement}
                        />
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <div style={{textAlign: 'center', padding: 10}}>{this.state.error}.</div>
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </React.Fragment>
                }
                {/* <Loader 
                popupLoaderStatus={this.state.popupLoaderStatus}
                    style={{ display: "block",position:"fixed" }} 
                /> */}
         {this.state.loading && <Loader style={{ display: "block",top:"72%" }} />}       
        {/* <CSVLink
          id="chatcsvqq"
          data={this.state.csvdata}
          filename="monthly-usage.csv"
          className="block"
          target="_blank"
        />     */}
            </React.Fragment>
        )
    }
}
export default Datatable;