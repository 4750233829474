import { apiCallBegan, apiCallSuccess, apiCallFailed } from "../api";
import axios from "../../config/axios";

const api = ({ dispatch }) => (next) => async (action) => {
	if (action.type !== undefined) {
		if (action.type !== apiCallBegan.type) return next(action);

		const { method, url, data, onSuccess, onStart, onError } = action.payload;

		if (onStart) dispatch({ type: onStart });

		try {
			const response = await axios.request({
				url,
				method,
				data,
			});
			if (response) {
				dispatch(apiCallSuccess(response.data));

				if (onSuccess) {
					dispatch({ type: onSuccess, payload: response.data });
				}
			}
		} catch (error) {
			dispatch(apiCallFailed(error.message));
			if (onError)
				dispatch({
					type: onError,
					payload: error.message,
					statusCode: error.response ? error.response.status : null,
				});
		}
	}
};

export default api;
