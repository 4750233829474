import React from 'react'

const TermsAndConditionSms = () => {
  return (
    <div>
        <p>You agree to use the ZyraTalk SMS functionality known as the “Services” in accordance with all applicable guidelines, as well as all state and federal laws that the Services are subject to. In order to confirm your compliance with this Disclaimer of Liability, the “Disclaimer”, so as to ensure that ZyraTalk is complying with national and international laws, we may, from time to time, periodically check the messages and broadcasts that you send.</p>

        <p>You represent and warrant that the owners of the phone numbers you initiate messages or broadcasts to through the ZyraTalk Services have consented or otherwise opted-in to the receipt of such messages and broadcasts as required by any applicable law or regulation. You agree that you will include clear opt-out/unsubscribe information in your messages or broadcasts when required to do so by any applicable law or regulation, and that you will otherwise adhere to the Consumer Best Practices Guidelines promulgated by the Mobile Marketing Association, if it is applicable to your messages.</p>

        <p>You further agree that any individuals requesting Do-Not-Call (“DNC”) status shall be immediately placed on your DNC accounts list and you further agree that you will not initiate any further messages or broadcasts to any individuals after they request DNC status.</p>

        <p>You agree to familiarize yourself with and abide by all applicable local, state, national, and international laws and regulations pertaining to your use of our Services. You are solely responsible for all acts or omissions that occur under your account, including, without limitation, the content of the messages and broadcasts that you create and initiate through our Services. The Telephone Consumer Protection Act (TCPA), Federal Trade Commission and Federal Communications Commission rules, the National DNC list registry rules, and various state laws, rules, and regulations place restrictions on certain types of phone calls and text messages. Without limiting the foregoing, you agree to familiarize yourself with the legal requirements triggered by any messages, calls, broadcasts, and campaigns transmitted through the ZyraTalk Services by visiting the following websites:</p>
        <p>Federal Trade Commission, <a target='_blank' href='https://www.ftc.gov'>https://www.ftc.gov</a></p>
        <p>Federal Communications Commission, <a target='_blank' href='https://www.fcc.gov/'>https://www.fcc.gov/</a></p>
        <p>National Do Not Call (DNC) Registry, <a target='_blank' href='https://www.donotcall.gov'>https://www.donotcall.gov</a></p>

        <p>ZyraTalk is in no way attempting to interpret any laws, rules, or regulations. This information is provided merely as a courtesy, and it is not intended to replace your responsibility to familiarize yourself with and abide by the legal requirements pertaining to your messages, broadcasts, and campaigns prior to using the ZyraTalk Sites or Services. You are ultimately responsible to make your own informed decisions regarding your messages, broadcasts, and campaigns.</p>

        <p>You accept that our Services are provided for professional use only, and you agree that your use of our Services shall not include:</p>
        <ul style={{paddingLeft : 26, listStyle: 'disc'}}>
            <li>Sending unsolicited marketing messages or broadcasts (i.e., spam);</li>
            <li>Sending any prohibited calls to life-line services, such as hospitals, fire, police, 911, or utility- related telephone numbers;</li>
            <li>Using strings of numbers, as it is unlawful to engage two or more lines of a multi-line business;</li>
            <li>Harvesting, or otherwise collecting, information about others without their consent;</li>
            <li>Misleading others as to the identity of the sender of your messages or broadcasts by creating a false identity, impersonating the identity of someone/something else, or by providing contact details that do not belong to you;</li>
            <li>Transmitting, associating, or publishing any unlawful, racist, harassing, defamatory, abusive, threatening, demeaning, immoral, harmful, vulgar, obscene, pornographic, or otherwise objectionable material of any kind;</li>
            <li>Transmitting any material that may infringe upon the intellectual property rights of third parties, including trademarks, copyrights, and right of publicity;</li>
            <li>Transmitting any material that contains viruses, trojan horses, worms, time bombs, cancel-bots, or any other harmful or deleterious programs;</li>
            <li>Interfering with, or disrupting, networks connected to the Services or violating the regulations, policies, or procedures of such networks;</li>
            <li>Interfering with another's use and enjoyment of our Services;</li>
            <li>Engaging in any other activity that ZyraTalk believes could subject it to criminal or civil liability.</li>
        </ul>
        <p>ZyraTalk will not be liable for any misuse of the Services by you. ZyraTalk is not responsible for the views and opinions contained in any of your messages or broadcasts. You further agree that ZyraTalk is, under no circumstances, responsible for the contents and/or accuracy of your messages or broadcasts and that ZyraTalk will only transmit them on a basis of good faith that you use the Services in accordance with this Disclaimer.</p>
    </div>
  )
}

export default TermsAndConditionSms