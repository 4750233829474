import axios from "../../config/axios";

const manageTeam = {
  getLists: (params) =>
    axios
      .get(`/reviewmanagement/manageteam/list?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  getTeam: (params) =>
    axios
      .get(`/reviewmanagement/manageteam/team`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      getTeamNew: (params) =>
      axios
        .get(`/reviewmanagement/manageteam/teamnew`, { ...params })
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

  editTeam: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/edit`,  params,{
        headers: {
            'content-type': 'multipart/form-data'
        }
      })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addTeam: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/add`, params,{
        headers: {
            'content-type': 'multipart/form-data'
        }
        })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteTeam: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/delete`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  ResendDeviceKey: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/ResendDeviceKey`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkEmail: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/checkEmail`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkPhone: (params) =>
    axios
      .post(`/reviewmanagement/manageteam/checkPhone`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  callCountryCode: (params) =>
    axios
      .get(`/reviewmanagement/manageteam/callCountryCode`)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
};

export default manageTeam;
