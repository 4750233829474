import React, { useState } from "react";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import "../../../Modal/dialog.css";

const modalReplyEditReview = ({
  obj,
  modalClose,
  onValidSubmit,
}) => {
  let show = obj.modalstatusAddEdit;
  let title = obj.modaltitle;
  let modalErrorMsg = obj.modalErrorMsg;
  let modaldata = obj.modaldata;
  let modalFormLoader = obj.modalFormLoader;
  const errorLabel = <div className="red" />;
  const mystyle = {
    padding: "3.25rem",
  };
  let redButton = {
    marginTop: '-11px'
  };
  return (
    <React.Fragment>
      <div
        className={show ? "modal fade show" : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => modalClose(false, "formManageUser")}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
            </div>
            <Form
              noValidate
              autoComplete="off"
              id="formManageUser"
              onValidSubmit={onValidSubmit}
            >
              {modalFormLoader && <div className="ui loading form"></div>}
              <div className="modal-body" style={mystyle}>
                <div className="kt-portlet__body py-0">
                  <div className="kt-section kt-section--first">
                    <div className="kt-section__body">
                    {obj.modalType =='Reply' &&
                    <Form.TextArea rows="10"  name="reply" validations='isRequired' validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel} >
                    </Form.TextArea>
                    }
                    {obj.modalType =='Edit' &&
                    <Form.TextArea rows="10" value ={modaldata.reviews} name="reviews" validations='isRequired' validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel} >
                    </Form.TextArea>
                    }
                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-end">
                    {obj.modalType =='Reply' &&
                      <Form.Input
                        name="id"
                        type="hidden"
                        value={obj.review_id}
                      />
                    }
                    {obj.modalType =='Edit' &&
                      <Form.Input
                      name="id"
                      type="hidden"
                      value={modaldata.id}
                                        />
                    }
                      <button
                        type="reset" onClick={() => modalClose(false, "formManageUser")}
                        className="btn linear-gradient yr-cancelbtn linear-gradient-cancel mg-r"
                      >
                        <span> Cancel </span>
                      </button>
                      <button type="submit" className="btn linear-gradient yr-submitbtn">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default modalReplyEditReview;
