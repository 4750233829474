import React from 'react';
import Slider from "react-rangeslider";
import 'react-rangeslider/lib/index.css'

class EditPageLocation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cssdiv: false,
     
      direction: 'right',
      left: "",
      bottom: "0",
      reverseValue:0,
      reverseValueBottom:0,
      areadefaultval:  value=>{
        this.renderStyle("","")
      }
    };

  }

  cancelData =() =>{
    
    this.setState({ 
      cssdiv: false,
     
      direction: 'right',
      left: "",
      bottom: "0",
      reverseValue:0,
      reverseValueBottom:0,
      areadefaultval:  value=>{
        this.renderStyle("","")
      }
    });
}

  handleChangeReverse = (value) => {
    this.setState({
      reverseValue: value,
      cssdiv:true,
    },()=>{
      this.setState({
        areadefaultval:  this.renderStyle("",""),
        left:((value)/10)
      })
    })
  }

  handleChangeReverseBottom = (value) => {
    this.setState({
      reverseValueBottom: value,
      cssdiv:true,
    },()=>{
      this.setState({
        areadefaultval:  this.renderStyle("",""),
        bottom:((value)/10)
      })
    })
  }


  

  renderStyle = (value,margin) => {

    let dir1 = ( this.state.direction == 'right') ? 'right' : 'left';

    let dir2 = (this.state.direction == 'right' ) ? 'left' : 'right';
    
    let reactcode = '<style type=text/css>' +
      '\n  .BOT_chatcontainer_Add {' +
      '\n  ' + dir1 + ':' + this.state.reverseValue + 'px !important; ' +
      '\n  ' + dir2 + ':auto !important;' +
      '\n  bottom:  '+ this.state.reverseValueBottom + 'px !important;' +
      '\n }' +
      '\n .BOT_chatcontainer {' +
      '\n   ' + dir1 + ': 20px ;' +
      '\n   ' + dir2 + ': auto !important;' +
      '\n  bottom: 20px ;' +
      '\n }' +
      '\n @media only screen and (' +
      '\n  max-width: 480px){' +
      '\n   .BOT_chatcontainer { ' +
      '\n ' + dir1 + ': 0 ;' +
      '\n   ' + dir2 + ': auto !important;' +
      '\n bottom: 0 ;' +
      '\n  }' +
      '\n  }' +
      '\n</style>';
      
    return reactcode;

  }

  changeDirection = (data) => {
    //console.log("--",data);
    this.setState({ direction: data }, () => {
      this.setState({

        areadefaultval: this.renderStyle("",""),
      });
    });

  }
 

  render() {
    const {  reverseValue,reverseValueBottom,areadefaultval ,bottom,left} = this.state
    return (

      <div className="row">
        <div className="col-lg-4  col-md-4">
          <div className="yr-createTicket yr-chatleftbox min-height">
            <div className="form-group row">
              <div className="col-lg-4 px-0 alignment-title">Alignment</div>
              <div className="col-lg-8">
                <div className="kt-radio-inline" style={{ textAlign: 'right' }}>
                  <span className="align-label mr-3">Left</span>
                  <label className="kt-radio inalign">
                    <input type="radio" name="opt" defaultValue="left" defaultChecked={this.state.direction === 'left'} onClick={() => this.changeDirection('left')} />
                    <span />
                  </label>
                  <span className="align-label mr-3">Right</span>
                  <label className="kt-radio inalign">
                    <input type="radio" name="opt" defaultChecked={this.state.direction === 'right'} defaultValue="right" onClick={() => this.changeDirection('right')} />
                    <span />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="align-label">Margin From Right</label>
              <div className="col-12 pr-4">
                
              <div className='slider-horizontal'>
            <Slider
              min={0}
              max={150}
              value={reverseValue}
              orientation='horizontal'
              onChange={this.handleChangeReverse}
            />
                {/* <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={0} max={20} /> */}
              </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="align-label">Margin From Bottom</label>
              <div className="col-12 pr-4 slider orientation-reversed">
              <div className='slider-horizontal'>
            <Slider
              min={0}
              max={150}
              value={reverseValueBottom}
              orientation='horizontal'
              onChange={this.handleChangeReverseBottom}
            />
                {/* <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={0} max={20} /> */}
              </div>
                {/* <Slider value color="red" settings={this.settings_second} /> */}
                {/* <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={0} max={20} /> */}
              </div>
            </div>
            <div className="row">
              <label className="align-label">Generated CSS</label>
              <div className="col-xs-12" style={{ display: this.state.cssdiv ? 'block' : 'none' }}>
                <textarea id="generated_css" rows="10" className="form-control" defaultValue={areadefaultval}>




                </textarea>
                <p style={{ color: "red", paddingRight: "15px " }}><em>Note: This is an auto generated snippet of CSS code. This code still needs to be added into the head section of the page.</em></p>
              </div>

            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="yr-createTicket box-bg min-height" style={{ marginTop: 0 }}>
            <div className="web-title">Your Website
                </div>
            <div className="website-img mx-4 my-5">
              <img src="assets/media/logos/website.png" alt="" className="img-fluid" />
              <span id="logoIMG" style={this.state.direction == 'left' ? { bottom: "0px", left: "0px", marginLeft: left+"%", marginBottom: bottom+"%" } : { bottom: "0px", right: "0px", marginRight: left+"%", marginBottom: bottom+"%" }}>
                <img src="assets/media/logos/chat-logo.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default EditPageLocation;