import React from "react";
import ReactTooltip from "react-tooltip";

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      place: "top",
      type: "dark",
      effect: "float",
      condition: false,
    };
  }

  render() {
    const mgb = { marginBottom: "8px", width: "1000px" };
    const {
      ticketNo,
      summary,
      description,
      ticketDate,
      email,
    } = this.props.breadcrum;

    return (
      <div className="kt-header__bottom">
        <div className="kt-container">
          <div className="kt-subheader kt-grid__item" id="kt_subheader">
            <div className="row" style={{ width: 100 + "%" }}>
              <div className="col-md-6 col-sm-6">
                <div className="row" style={{ width: 100 + "%" }}>
                  <div className="col-md-4">
                    <div className="kt-subheader__main yr-body-p">
                      <h3 className="kt-subheader__title">Ticket Details</h3>
                    </div>
                    <div className="yr-pagetitle yr-body-p" style={mgb}>
                      <h2>{ticketNo}</h2>
                    </div>

                    <div className="kt-subheader__main yr-body-p">
                      <h3 className="kt-subheader__title">Summary</h3>
                    </div>
                    <div className="yr-pagetitle yr-body-p" style={mgb}>
                      <h2>{summary}</h2>
                    </div>

                    <div className="kt-subheader__main yr-body-p">
                      <h3 className="kt-subheader__title">Description</h3>
                    </div>
                    <div className="yr-pagetitle yr-body-p" style={mgb}>
                      <h2>{description}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="yr-entryDateTime text-right">
                  <div className="metadata">
                    <span
                      className="date"
                      style={{
                        fontWeight: "400",
                        color: "#a6a8ab",
                        fontSize: "1em",
                        paddingRight: "5px",
                      }}
                    >
                      Entry Date / Time: {ticketDate}
                    </span>
                  </div>
                  <div className="metaemail" style={{ margin: "10px" }}>
                    <span
                      className="email"
                      style={{
                        fontWeight: "400",
                        color: "#a6a8ab",
                        fontSize: "1em",
                        paddingRight: "5px",
                      }}
                    >
                      Customer: {email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Comment;
