import React from "react";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import "./dataTable.css";
import { Segment, Table } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import DialogBoxAddEdit from "./DialogBoxAddEdit";
import { DatatableRow } from "./DatatableRow";
import utilFunc from "../../../../../util_funs";

const tableHeader = [
  {
    title: "",
    width: "0",
    sort: "",
  },
  {
    title: "Photo",
    width: "0",
    sort: "",
  },
  {
    title: "Team Member",
    width: "3",
    sort: "member_name",
  },
  {
    title: "Date Added",
    width: "3",
    sort: "created_date",
  },
  {
    title: "Email",
    width: "1",
    sort: "",
  },
  {
    title: "Phone Number",
    width: "2",
    sort: "phone",
  },
  {
    title: "Device Key",
    width: "5",
    sort: "",
  },
  {
    title: "Review Request Sent",
    width: "1",
    sort: "	review_sent",
  },
  {
    title: "Reviews Received",
    width: "1",
    sort: "review_received",
  },
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
const APIURL = "http://localhost/zyratalk/api/premium/settings/manageuser/";
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "updated_date",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      notification_text: 0,
      notification_email: 0,
      duplicateEmailError: "",
      duplicatePhoneError: "",
      renderOption1:[],
      renderOption2:[],
      country_code:'+1',
      phone:'',
      src: null,
      crop: {
          unit: "%",
          width: 30,
          aspect: 1 / 1
      },
      croppedImageUrl: null,
      profile_pic:null,
      croppedImage:null,
      DeletePic:1,
      oldImage:1,
      DeletePicAdd:0,
      dataInPhone:""
    };
  }

  componentDidMount() {
    this.loadData({});
    this.country_code();
 
  }

  handleFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
        this.setState({src: fileReader.result,oldImage:0,DeletePicAdd:1,DeletePic:1 })
    }   
    fileReader.readAsDataURL(e.target.files[0])
}

onImageLoaded = image => {
  this.imageRef = image
}

handleRemovePic = (key) => {
  this.setState({ DeletePic:0 });
}
handleRemovePicAdd = (key) => {
  this.setState({ DeletePicAdd:0 });
}

onCropChange = (crop) => {
  this.setState({ crop });
}

onCropComplete = crop => {
  if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop)
      this.setState({ croppedImageUrl })
  }
}

getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");
  
  ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
   )

  const reader = new FileReader()
  canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'cropped.jpg')
      }
  })
}

dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
          
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  let croppedImage = new File([u8arr], filename, {type:mime});
  this.setState({croppedImage: croppedImage }) 
}

  country_code = (params) => {
  api.manageTeam.callCountryCode().then((data)=>{
    try{
      this.setState({renderOption1:data.data.defaultCountryCodeArr})
      this.setState({renderOption2:data.data.remailCountryCodeArr})
    }catch(err){
      console.log(err);
    }


  })
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter) => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteAction();
    }

    if (data.modalType == "ResendDeviceKey") {
      this.onResendDeviceKeyAction();
    }

    if (data.modalType == "Popup") {
      this.setState({
        modalstatus: false,
        modaltitle: "",
        modalmessage: "",
        modalbuttonmsg: {},
        modalType: "",
      });
    }
  };

  addteam = () => {
    document.getElementById("formManageUser").reset();
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: "Add Team Member",
      modalType: "Add",
      modaldata: {},
      selectedOptions: [],
    });
  };

  editTeam = (id) => {

    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    console.log(modaldata)
    let editDateModule = [];
    let moduleList = this.state.accessModuleList;
  
    moduleList.forEach((item, index) => {
      Object.keys(item).forEach(function (key) {
        if (modaldata.linksetting.includes(key)) editDateModule.push(key);
      });
    });
    this.setState({
      country_code:modaldata.country_code
    });

    this.setState({
      phone:modaldata.phone
    });
if(modaldata.notification_text=='1'){
    this.setState({
      notification_text:1
    });
  }else{
    this.setState({
      notification_text:0
    });
  }

  if(modaldata.notification_email=='1'){
    this.setState({
      notification_email:1
    });
  }else{
    this.setState({
      notification_email:0
    });
  }
    

    this.setState({
      modalstatusAddEdit: true,
      modaltitle: "Edit User",
      modalType: "Edit",
      modaldata: modaldata,
      selectedOptions: editDateModule,
    });
  };

  deleteTeam = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: modaldata,
    });
  };

  ResendDeviceKey = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to send it again ?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "ResendDeviceKey",
      modaldata: modaldata,
    });
  };

  onDeleteAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageTeam.deleteTeam({ id: this.state.modaldata.id }).then((data) => {
      this.setState({ modalFormLoader: false });
      try {
        let recordDeleted = this.state.lists.findIndex(
          (list) => list.id === data.data.id
        );

        let oldArr = this.state.lists;
        oldArr.splice(recordDeleted, 1);
        this.setState({
          datatableRows: this.state.lists.map((list, index) => (
            <DatatableRow
              key={index}
              list={list}
              userdata = {this.props.userdata}
              editTeam={this.editTeam}
              deleteTeam={this.deleteTeam}
              ResendDeviceKey={this.ResendDeviceKey}
              preOpensetting={this.preOpensetting}
            />
          )),
          modalstatus: false,
          modaltitle: "",
          modalmessage: "",
          modalbuttonmsg: {},
          modalType: "",
          modalErrorMsg: false,
        });
        this.props.showUserToaster(
          "green",
          "User has been deleted successfully."
        );
        utilFunc.scrollTop(800);
      } catch (err) {
        console.log(err);
      }
    });
  };

  onResendDeviceKeyAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageTeam
      .ResendDeviceKey({ id: this.state.modaldata.id })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          this.setState({
            modalstatus: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
          });
          this.props.showUserToaster(
            "green",
            "Device key has been sent successfully."
          );
          utilFunc.scrollTop(800);
        } catch (err) {
          console.log(err);
        }
      });
  };
  preOpensetting = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let access_selected = this.state.lists[recordlist].linksetting;
    let access_selected_lists_html = "";
    access_selected_lists_html = access_selected.map((item, value) => (
      <p
        key={item}
        style={{
          textTransform: "capitalize",
          color: "#6c7293",
          fontWeight: "400",
        }}
      >
        {item.replace(/_/g, " ")}
      </p>
    ));
    this.setState({
      modalstatus: true,
      modaltitle: "Setting/Privileges",
      modalmessage: access_selected_lists_html,
      modalbuttonmsg: { submit: "Ok", cancel: "" },
      modalType: "Popup",
    });
  };
  
  modalClose = (flag, fm) => {
    document.getElementById("notification_text").checked = false;
    document.getElementById("notification_email").checked = false;
    document.getElementById("isd_code_id_review").selectedIndex = 0;
    document.getElementById("profile_pic").value = "";
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
      duplicateEmailError: "",
      duplicatePhoneError: "",
      notification_text: 0,
      notification_email: 0,
      src:null,
      profile_pic:null,
      DeletePic:1,
      oldImage:1,
      DeletePicAdd:0
    });
  };


  modalDetailClose = (flag, fm) => {

    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  handleCheck = (key) => {
    const target = key.target;
    if (target.checked) {
      this.setState({
        [target.name]: 1,
      });
    } else {
      this.setState({
        [target.name]: 0,
      });
    }
  };

  handleDrop = (key) => {
    const target = key.target.value;
    this.setState({
      country_code:target,
    });

  };

  checkEmail = (key) => {
    let sendData = {
      email: key.target.value,
      modalType: this.state.modalType,
      id: this.state.modaldata.id,
    };
    api.manageTeam.checkEmail(sendData).then((data) => {
      try {
        console.log(data);
        if (data.data > 0) {
          this.setState({
            duplicateEmailError: "This email id is already exits",
          });
        } else {
          this.setState({
            duplicateEmailError: "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  checkPhone = (key) => {
    this.setState({dataInPhone:key.target.value})
    if(key.target.value.length==0)
      document.getElementById("notification_text").checked = false;


    let sendData = {
      phone: key.target.value,
      modalType: this.state.modalType,
      id: this.state.modaldata.id,
    };
    api.manageTeam.checkPhone(sendData).then((data) => {
      try {
        if (data.data > 0) {
          this.setState({
            duplicatePhoneError: "This phone number is already exits",
          });
        } else {
          this.setState({
            duplicatePhoneError: "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.setState({
      phone:key.target.value
    })
    this.phoneFormat(key)
  };
phoneFormat = (e) => {
    let phone = e.target.value;
    let p = phone;
    var obj = this;

    p = p.replace(/[^\d]*/gi, "");
    if (p.length < 3) {
      setTimeout(function () {
        obj.setState({ phone: p });
      }, 100);
    } else if (p.length == 3) {
      let pp = p;
      let d4 = p.indexOf("(");
      let d5 = p.indexOf(")");
      if (d4 == -1) {
        pp = "(" + pp;
      }
      if (d5 == -1) {
        pp = pp + ")";
      }
      obj.setState({ phone: pp });
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      setTimeout(function () {
        obj.setState({ phone: pp });
      }, 100);
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      setTimeout(function () {
        obj.setState({ phone: finalPhone });
      }, 100);
    }
  };

  onValidSubmit = (e) => {


    var sendData = new FormData();
    sendData.append('email', e.email);
    sendData.append('member_name', e.member_name);
    sendData.append('notification_text', this.state.notification_text);
    sendData.append('notification_email',  this.state.notification_email);
    sendData.append('country_code',this.state.country_code);
    sendData.append('phone',this.state.phone);
    sendData.append('file', this.state.croppedImage);
   
    if (this.state.modalType == "Add") {
      console.log(this.state.DeletePicAdd)
      sendData.append('DeletePicAdd', this.state.DeletePicAdd);
      this.setState({ modalFormLoader: true });
      api.manageTeam.addTeam(sendData).then((data) => {
        console.log(data)
        this.setState({ modalFormLoader: false });
        try {
          if (data.data.duplicate) {
            if (data.data.duplicate.email) {
              this.setState({
                duplicateEmailError: "This email id is already exits",
              });
            }

            if (data.data.duplicate.phone) {
              this.setState({
                duplicatePhoneError: "This phone number is already exits",
              });
            }
          } else {
            let oldArr = this.state.lists;
            oldArr.unshift(data.data);
            document.getElementById("profile_pic").value = "";
            this.setState({
              datatableRows: this.state.lists.map((list, index) => (
                <DatatableRow
                  key={index}
                  list={list}
                  userdata = {this.props.userdata}
                  editTeam={this.editTeam}
                  deleteTeam={this.deleteTeam}
                  ResendDeviceKey={this.ResendDeviceKey}
                  preOpensetting={this.preOpensetting}
                />
              )),
              totalCount: this.state.totalCount + 1,
              modalstatusAddEdit: false,
              modaltitle: "",
              modalmessage: "",
              modalbuttonmsg: {},
              modalType: "",
              modalErrorMsg: false,
              modaldata: {},
              selectedOptions: [],
              phone:'',
              src:null,
              profile_pic:null,
              DeletePicAdd:0
     
            });
            this.loadData({ _page:1 });
            this.props.showUserToaster(
              "green",
              "User has been added successfully."
            );
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
    if (this.state.modalType == "Edit") {
      this.setState({ modalFormLoader: true });
      sendData["id"] = e.id;
      
      sendData.append('deletePic',this.state.DeletePic);
      sendData.append('DeletePicAdd', this.state.DeletePicAdd);
      if(!this.state.country_code){
        sendData.append('country_code', document.getElementById('isd_code_id_review').value);
        
      }    
      sendData.append('id', e.id);
      api.manageTeam.editTeam(sendData).then((data) => {
        
        try {
          this.setState({ modalFormLoader: false });
          if (data.data.duplicate) {
            if (data.data.duplicate.email) {
              this.setState({
                duplicateEmailError:"This email id is already exits",
              });
            }

            if (data.data.duplicate.phone) {
              this.setState({
                duplicatePhoneError: "This phone number is already exits",
              });
            }
          } else {
            // let recordlist = this.state.lists.findIndex(
            //   (list) => list.id === data.data.id
            // );
            // let modaldata = data.data;
            // let updatedRecord = Object.assign([...this.state.lists], {
            //   [recordlist]: modaldata,
            // });
            this.loadData({ _page:1 });
            this.setState({
              modalstatusAddEdit: false,
              modaltitle: "",
              modalmessage: "",
              modalbuttonmsg: {},
              modalType: "",
              modalErrorMsg: false,
              modaldata: {},
              selectedOptions: [],
              phone:'',
              _page:1,
              src:null,
              profile_pic:null,
              DeletePic:1,
              oldImage:1,
              DeletePicAdd:0
            });
            this.props.showUserToaster(
              "green",
              "User has been edited successfully."
            );
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }

  };

  loadData = (params) => {
    
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
      let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
      let time_zone = clientTimeZone;

      params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.manageTeam.getLists(query).then((data) => {
        console.log(data.data.list)
        try {   
          let accessModuleStore = [];

          let datatableRowstemp;
          if (data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (
              <DatatableRow
                key={index}
                list={list}
                userdata = {this.props.userdata}
                editTeam={this.editTeam}
                deleteTeam={this.deleteTeam}
                ResendDeviceKey={this.ResendDeviceKey}
                preOpensetting={this.preOpensetting}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.totalrecord,
            lists: data.data.list,
            accessModuleList: accessModuleStore,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
        />
        
        <DialogBoxDetail
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalDetailClose={this.modalDetailClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
        />
        <DialogBoxAddEdit
          obj={this.state}
          modalClose={this.modalClose}
          onValidSubmit={this.onValidSubmit}
          handleCheck={this.handleCheck}
          checkEmail={this.checkEmail}
          checkPhone={this.checkPhone}
          handleDrop= {this.handleDrop}
          onImageLoaded= {this.onImageLoaded}
          onCropComplete= {this.onCropComplete}
          onCropChange= {this.onCropChange}
          handleFile= {this.handleFile}
          getCroppedImg= {this.getCroppedImg}
          handleRemovePic = {this.handleRemovePic}
          handleRemovePicAdd = {this.handleRemovePicAdd}
          userdata = {this.props.userdata}
        />
      </Segment>
    );
  }
}
