import React from "react";
import Hamburger from "./Hamburger";
import Logo from "./Logo";
import Notification from "./Notification";
import UserInfo from "./UserInfo";

class TopHeader extends React.Component {
  render() {
    const { subHeaderTitle, user } = this.props;
    var color = '';
    var textColor = "#159bd5 !important";
    if (user.customizedDashboard[0].header_color != '' && user.customizedDashboard[0].secondary_header_color != '') {
      color = 'linear-gradient(to right, ' + user.customizedDashboard[0].header_color + ',' + user.customizedDashboard[0].secondary_header_color + ')';
      textColor = "#fff !important";
    } else {
      color = '';
      textColor = "#159bd5 !important";
    }
    //alert(user.customizedDashboard[0].secondary_header_color);

    return (
      <div
        id="kt_header"
        className="kt-header  kt-header--fixed"
        data-kth
        eader-minimize="on"
      >
        <div className="kt-container kt-container--fluid yr-top-header"
          style={{ background: 'linear-gradient(to right, ' + user.customizedDashboard[0].header_color + ',' + user.customizedDashboard[0].secondary_header_color + ')' }}>
          <Hamburger
            pageTitle={subHeaderTitle ? subHeaderTitle : ""}
            user={user}
          />
          <Logo user={user} width="100px" />
          <div className="kt-header__topbar kt-grid__item">
            <Notification user={user} />
            <UserInfo user={user} from={"web"} />
          </div>
        </div>
        <style>
          {'.linear-gradient{ background: ' + color + ' !important; color: #fff !important;}'}
          {'.linear-gradient-diff{ background: ' + color + ' !important; color:' + textColor + ';}'}
          {'.table-list-bg > li > a:hover { color:#fff !important;}'}
          {'.linear-gradient-a > a { background: ' + color + ' !important;}'}
          {'.linear-gradient-a-color > a { color: ' + user.customizedDashboard[0].sidebar_icon_color + ' !important;}'}
          {'.kt-menu__link > span { color: ' + user.customizedDashboard[0].sidebar_icon_color + ' !important;}'}
          {'.kt-menu__link > span:hover { color: ' + user.customizedDashboard[0].sidebar_icon_color + ' !important;}'}
          {'.linear-gradient-cancel { background: ' + color + '!important;'}

        </style>
      </div>


    );


  }
}

export default TopHeader;
