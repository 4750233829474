import { Table } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

export function DatatableTableHeader(props) {
    
    const tableHeader = Object.values(props.header).map((head, index) => {
        console.log("prop12",head,index);
        if (head.sort != '') {
        return <Table.HeaderCell key={index} width={head.width} sorted={props.column === head.sort ? props.direction : null}
                >{head.title} </Table.HeaderCell>;
        } else {
        return <Table.HeaderCell key={index} width={head.width} >{head.title} </Table.HeaderCell>;
        }
    });
    return (
        <Table.Header>
            <Table.Row>
                {tableHeader}
            </Table.Row>
        </Table.Header>
    );
}
  