import React from "react";
import "../../../../Modal/dialog.css";

const DialogBoxDetail = ({ show, d_id, confrimmsg, modalCloseDelete, deleteData, loading }) => {
	return (
		<React.Fragment>
			<div
				className={show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					{loading && <div className="ui loading form"></div>}
					<div className="modal-content white-bg yr-campaigns-model px-0" style={{maxWidth: 600, margin:'0 auto'}}>
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseDelete(false, '')}
						>
							X
						</button>
						<div className="modal-header border-bottom-0"> 
							<h5 className="modal-title pt-0">Confirm your action</h5>
						</div>
						<div className="modal-body new-modal-body pb-4 px-4 ml-3">
							<form class="">
								<div class="d-flex align-items-end mb-3">
									{confrimmsg}
								</div>
								
								<div className="md-footer d-flex justify-content-end align-items-center mt-5">
									<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small text-uppercase" style={{minWidth: 80}} data-dismiss="modal">
										<span style={{paddingTop: 10, paddingBottom: 10}} onClick={() => modalCloseDelete(false, '')}>No</span></button>
									<button type="button" className="ml-2 btn linear-gradient yr-submitbtn btn-sm text-uppercase" style={{minWidth: 80}} onClick={() => deleteData(d_id)}>Yes</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxDetail;