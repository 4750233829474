import React from 'react'

const ZipcodeBulk = (props) => {
    console.log(props);
    const miles = props.data.send_lead_within == 'exact_match' ? 'Exact match with zip code' : `${props.data.send_lead_within} Miles`
    return (
        <li style={{position: 'relative'}}>
            <small className="showingDate">{props.data.created_at}</small>
            <div className="row">
                <div className='row col-md-8'>
                <div className="col-md-4">
                    <h5>Zip Code</h5>
                    <button className="btn add-link" onClick={() => props.onViewZipcode(props.data.id)}>View All</button>
                </div>
                <div className="col-md-4">
                    <h5>Email</h5>
                    <button className="btn add-link" onClick={() => props.onViewEmail(props.data.id)}>View All</button>
                </div>
                <div className="col-md-3">
                    <h5>Phone</h5>
                    {/* <p>{props.phoneNumber}</p> */}
                    <button className="btn add-link" onClick={() => props.onViewPhones(props.data.id)}>View</button>
                </div>
                </div>
                <div className="col-md-4" style={{'paddingLeft':'0px'}}>
                    <h5>Send lead within</h5>
                    <p>{miles}</p>
                </div>
            </div>
            <div className="yr-branchBtn yr-branchBtn2">
                <span title="Edit rule" onClick={() => props.onEditBulk(props.data.id)}>
                    <i className="fa fa-pencil"></i>
                </span>
                {!props.deleteprogress && <span title="Delete rule"  onClick={() => props.onDeleteBulk(props.data.id)}>
                    <i className="fa fa-trash"></i>
                </span>}
            </div>
        </li>
    )
}

export default ZipcodeBulk