import React from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";

// import DesignRules from './DesignRules';
import api from "../../../../../api";
import "../../../TranscriptChat/TranScriptChat.css";

import { Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
} from "../../../../../store/Page";
import { Form } from "semantic-ui-react";

const queryString = require("query-string");
const regex = new RegExp("^[a-zA-Z0-9 ]+$");

class ChatFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chatstatus: "all",
      daterange: "alltime",
      selectedtext: "Choose Date Range ",
      rangepicker: false,
      csvdata: [],
      serach: "",
      filter: "",
      isOpen: false,
      filter: "",
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  orderStatusChange = (e) => {
    this.setState({
      order_status: e.target.value,
    });

    this.props.handleSort(e.target.value, "asc");
  };

  chatStatusChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });

    this.props.onSubmitFilter("chatstatus", e.target.value);
  };

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      serach: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    console.log("---", this.state.serach);
    this.props.onSubmitFilter("q", this.state.serach);
  };
  chatTimeChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });
    if (e.target.value == "daterange") {
      this.setState(
        {
          rangepicker: true,
          daterange: e.target.value,
        },
        function () {
          document.querySelector(".showrangepicker").click();
        }
      );
    } else {
      this.setState({ rangepicker: false, daterange: e.target.value });
      this.props.onSubmitFilter("chatfrequency", e.target.value);
    }
  };
  handleClick = (e) => {
    this.datepicker.click();
  };

  handleOnChange = (event, { name, value }) => {
    if (value !== "" && !regex.test(value)) {
      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == "") {
      this.props.onSubmitFilter(value);
    }
  };

  handleOnClick = () => {
    let value = this.state.filter;
    if (value.trim() !== "") {
      this.props.onSubmitFilter(value);
    }
  };

  render() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final = month + "/" + date + "/" + year;
    const { filter } = this.state;

    return (
      <div class="kt-form kt-form--label-right kt-margin-t-30  yr-manageUser-table">
        <div class="row">
          <div class="col-md-6">
            <h3 class="sm-b-title">My Email Playbook</h3>
          </div>
          <div class="col-md-6">
            <div>
              <a
                onClick={(e) => this.props.createPlaybook("Add")}
                href="#"
                class="btn linear-gradient yr-add-new float-right cursorpointer"
                data-toggle="modal"
                data-target="#kt_modal_6"
              >
                Create Playbook
              </a>
            </div>
          </div>
          <div
            class="col-xl-8 order-2 order-xl-1 mt-3"
            style={{ marginBottom: 15 }}
          >
            <div class="row">
              <div class="col-md-12 kt-margin-b-20-tablet-and-mobile ">
                <div class="kt-portlet__head">
                  <div class="kt-portlet__head-toolbar">
                    <ul
                      class="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          onClick={(e) => this.props.getListByStatus("all")}
                          class="nav-link active"
                          data-toggle="tab"
                          href="#kt_portlet_base_demo_1_1_tab_content"
                          role="tab"
                        >
                          All
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          onClick={(e) => this.props.getListByStatus("Active")}
                          class="nav-link"
                          data-toggle="tab"
                          href="#kt_portlet_base_demo_1_2_tab_content"
                          role="tab"
                        >
                          <span>
                            <i class="fa fa-circle green"></i>
                          </span>
                          Active
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          onClick={(e) => this.props.getListByStatus("Stopped")}
                          class="nav-link"
                          data-toggle="tab"
                          href="#kt_portlet_base_demo_1_3_tab_content"
                          role="tab"
                        >
                          <span>
                            <i class="fa fa-circle red"></i>Stopped
                          </span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          onClick={(e) => this.props.getListByStatus("Draft")}
                          class="nav-link"
                          data-toggle="tab"
                          href="#kt_portlet_base_demo_1_4_tab_content"
                          role="tab"
                        >
                          <span>
                            <i
                              class="fab fa-firstdraft"
                              style={{ color: "#3f9ead" }}
                            ></i>
                          </span>{" "}
                          Draft
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          onClick={(e) =>
                            this.props.getListByStatus("Archived")
                          }
                          class="nav-link"
                          data-toggle="tab"
                          href="#kt_portlet_base_demo_1_4_tab_content"
                          role="tab"
                        >
                          <span>
                            <i
                              class="fas fa-archive"
                              style={{ color: "#3981b5" }}
                            ></i>
                          </span>
                          Archived
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="kt-form__group kt-form__group--inline">
                  {/* <div class="kt-form__label yr-shortBy">
                                <label class="kt-checkbox">
                                    <input type="checkbox" />
                                    <span></span>
                                </label>
                            </div> */}
                  <div class="kt-form__label">
                    <label class="yr-nowrap kt-font-bolder">Sort by</label>
                  </div>
                  <div class="kt-form__control" style={{ width: "100px" }}>
                    <select
                      class="form-control"
                      id="kt_form_status"
                      style={{ width: "auto" }}
                      onChange={(e) => this.orderStatusChange(e)}
                    >
                      <option value="title">Title</option>
                      <option value="created_date">Date</option>
                    </select>
                  </div>
                  <div className="kt-input-icon kt-input-icon--left">
                    <Form onSubmit={this.handleOnClick}>
                      <Form.Input
                        id="playBookSearchBar"
                        style={{ marginLeft: 4 }}
                        name="filter"
                        className="search-input-mu"
                        // value={filter}
                        onChange={this.handleOnChange}
                      />
                    </Form>
                    <span
                      className="kt-input-icon__icon kt-input-icon__icon--left"
                      style={{ cursor: "pointer" }}
                      onClick={this.handleOnClick}
                    >
                      <span>
                        <i className="la la-search" />
                      </span>
                    </span>
                    <span className="yr-searchPlace-holder">Search</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChatFilter;
