import React, { useState } from "react";
import "../../../../../Modal/dialog.css";
const DialogBoxContactViaZapierMsg = ({ obj,saveZapierAppData, offAgainAppMsg}) => {
	const modalCloseContactViaBoxZapierHereMsg = () => {
		saveZapierAppData(obj.switchValue)
		//setOption("static")
    }
    const offMsg = () => {
		offAgainAppMsg()
		//setOption("static")
    }
  return (
		<React.Fragment>
			<div
				className={obj.modalContactViaObjectZapierAppMsg.showMsg ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: obj.modalContactViaObjectZapierAppMsg.showMsg ? "block" : "none" }}
				id="modalContactViaID"
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{margin: '0 auto', maxWidth: 500}}>
					{obj.modalContactViaObjectZapierAppMsg.loading && <div className="ui loading form"></div>}
					<div className="modal-content yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => offMsg()}
						>
							X
						</button>
						<div className="modal-header" style={{ paddingBottom: '0px !important' }}>
                            <h5 class="modal-title pt-0">Alert</h5>
						</div>
						<div class="modal-body">
							
								<div id="AddEditContactFormModalBody" class="modal-body"
									style={{ padding: "0 3.25rem", maxHeight: "500px", overflow: "auto" }}>
									<div class="kt-portlet__body col-form-label">
                                    {obj.switchValue=="ON" ? "Enabling Zapier App will add contacts according to the keys mentioned underneath. The keys has to be mapped on the Zapier App platform according to the incoming value. Do you wish to continue?": "Disabling the Zapier App will stop the contacts to be added to the Bucket. Do you wish to continue?" }
									{obj.switchValue=="ON" && <p>email, first_name, last_name, phone_number, city, state, zip_code, country, address_line_1,address_line_2</p>}
									
                                    </div>
								</div>
								<div class="kt-portlet__foot" style={{paddingBottom: 25}}>
									<div class="kt-form__actions">
										<div class="form-group row">
											<div class="col-lg-12 d-flex justify-content-end align-items-center">
                                            <button  class="btn linear-gradient-cancel yr-cancelbtn" style={{ minWidth: 'unset' }} onClick={() => offMsg()}><span>No</span></button>
                                                <button type="reset" onClick={() => modalCloseContactViaBoxZapierHereMsg()} class="btn linear-gradient yr-submitbtn ml-4" style={{ minWidth: 'unset' }}><span>Yes</span></button>
											</div>
										</div>
									</div>
								</div>
							
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxContactViaZapierMsg;