import React from "react";
import TopHeaderMobile from "./TopHeaderMobile";
import Navigation from "../Navigation/Navigation";
import Breadcrum from "../Breadcrum/Breadcrum";

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      pageTitle,
      pageMainTitle,
      toggleNavigation,
      toggleBreadcrum,
      navigationName,
      subHeaderTitle,
      selectedBreadCrum,
      user,
    } = this.props;

    return (
      <React.Fragment>
        <TopHeaderMobile subHeaderTitle={subHeaderTitle} user={user} />
        
          {/* <Navigation
            toggleNavigation={toggleNavigation}
            navigationName={navigationName}
            user = {this.props.user}
          /> */}
       
      </React.Fragment>
    );
  }
}
export default MobileHeader;
