import React, { useState, useEffect } from 'react'
import api from '../../../../../api';
import CustomErrorField from '../../../../Form/CustomErrorField';
import { Message } from "semantic-ui-react";

const AddGtmTriggerId = ({ popupClose, branchId }) => {
    const [triggerValue, setTriggerValue] = useState('');
    const [error, seterror] = useState(false);
    const [loading, setloading] = useState(false);
    const [message, setmessage] = useState({
        userToasterColor: '',
        userToaster: false,
        userToasterMsg: ''
    });
    
    const handleInput = (e) => {
        setTriggerValue(e.target.value);
    }

    const submitGtmTrigger = (e) => {
        e.preventDefault();
        // if (triggerValue.trim().length === 0) {
        //     seterror(preState => true);
        //     return;
        // }
        saveGtm();
    }

    const getGtm = () => {
        setloading(prestate => true);
        api.botbuilder.getGtmTrigger({ branch_id: branchId })
        .then((res) => {
            if (res.status === true) {
                setTriggerValue(prestate => res.gtmvalue);
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setloading(prestate => false);
        })
    }
    useEffect(() => {
        getGtm();
    }, []);

    const saveGtm = () => {
        setloading(prestate => true);
        api.botbuilder.saveGtmTrigger({ branchId: branchId, gtmValue: triggerValue })
        .then((res) => {
            if (res.status === true) {
                setmessage(preState => {
                    return {
                        ...preState,
                        userToasterColor: 'green',
                        userToaster: true,
                        userToasterMsg: res.msg 
                    }
                });
                setTimeout(() => {
                    getGtm();
                }, 1000);
            }else{
                setmessage(preState => {
                    return {
                        ...preState,
                        userToasterColor: 'red',
                        userToaster: true,
                        userToasterMsg: res.msg 
                    }
                }); 
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setloading(prestate => false);
            setTimeout(() => {
                setmessage(preState => {
                    return {
                        ...preState,
                        userToasterColor: '',
                        userToaster: false,
                        userToasterMsg: '' 
                    }
                }); 
            }, 1500); 
        })
    }

    return (
        <>
            <form onSubmit={submitGtmTrigger}>
                <div className="yr-chat-round-box yr-closebtn">
                    <button type="button" class="close linear-gradient" onClick={() => popupClose()}>&#10006;</button>
                    <div className="box-title">Add GTM Trigger ID</div>
                    {loading && <div className="ui loading form"></div>}
                    <Message
                        color={message.userToasterColor ? message.userToasterColor : "teal"}
                        style={{ display: message.userToaster ? "block" : "none", marginTop: 30 }}
                    >
                        {message.userToasterMsg}
                    </Message>
                    <div className="form-group" style={{ marginBottom: 10 }}>
                        <input
                            type='text'
                            name='gtmtrigger'
                            className='form-control'
                            placeholder='GTM Trigger ID'
                            value={triggerValue}
                            onChange={handleInput}
                        />
                        {error &&
                            <CustomErrorField
                                message={'GTM Trigger ID is required.'}
                                className="error invalid-feedback d-inline"
                            />
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="reset" class="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => popupClose()} ><span> Close </span></button>
                        <button disabled={loading ? true : false} type="submit" className="btn linear-gradient yr-submitbtn">Save</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddGtmTriggerId