import React from "react";
import GridList from "../Grid";
import api from "../../api";
import { Segment, Message } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import ConfirmDialog from "../Modal/ConfirmDialog";
import validationRule from "../../validation";
import Switch from "react-switch";
import utilFunc from "../../util_funs";

validationRule.isRequired();

class MarketSharp extends React.Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      loading: false,
      location: 0,
      companyId: "",
      formId: "",
      source: "",
      locationLists: [],
      deafultLocation: false,
      modal: false,
      successMessage: null,
      errorMessage: null,
      dataLoading: null,
      marketsharpId: null,
      selectedDrodown: 0,
      confirmDialog: false,
      disableDefaultLocation: false,
      formLoading: false,
    };
    this.state = this.defaultState;

    this.formRef = React.createRef();
    this.columnLists = [
      { text: "S.No", key: "id", sort: false },
      { text: "Location", key: "loc.location" },
      { text: "Company ID", key: "MS.company_id" },
      { text: "Form ID", key: "MS.form_id" },
      { text: "Source", key: "MS.source" },

      { text: "Last Modified Date", key: "MS.updated_date" },
      {
        text: "Action",
        key: "action",
        sort: false,
        event: [
          {
            key: "editMarketSharpCredentials",
            params: ["marketSharpId"],
            func: (marketSharpId) =>
              this.editMarketSharpCredentials(marketSharpId),
          },
          {
            key: "deleteMarketSharpCredentials",
            params: ["marketSharpId"],
            func: (marketsharpId) => this.confirmAction(marketsharpId),
          },
        ],
      },
    ];
  }
  confirmAction = (marketsharpId) =>
    this.setState({ marketsharpId, confirmDialog: true });

  deleteMarketsharpCredentials = () => {
    if (this.state.marketsharpId > 0) {
      this.setState({ confirmDialog: false, loading: true });
      api.marketsharp
        .deleteMarketsharpCredential({
          marketsharpId: this.state.marketsharpId,
        })
        .then((resp) => {
          if (resp && resp.message) {
            this.setState(
              {
                successMessage: resp.message,
                refreshGrid: true,
                loading: false,
                marketsharpId: 0,
              },
              () => {
                setTimeout(() => {
                  this.setState({ refreshGrid: false });
                }, 500);
                setTimeout(() => {
                  this.setState({ successMessage: null });
                }, 5000);
              }
            );
          } else if (resp.errorMessage) {
            this.setState(
              {
                errorMessage: resp.errorMessage,
                loading: false,
                marketsharpId: 0,
              },
              () => {
                setTimeout(() => {
                  this.setState({ errorMessage: null });
                }, 5000);
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  editMarketSharpCredentials = (marketsharpId) => {
    if (marketsharpId) {
      api.marketsharp
        .getMarketsharpCredentials({ marketsharpId })
        .then((resp) => {
          if (resp && resp.credential) {
            const { credential } = resp;
            this.setState(
              {
                companyId: credential.company_id,
                formId: credential.form_id,
                source: credential.source,
                selectedDrodown: credential.location_id,
                location: credential.location_id,
                deafultLocation: credential.is_default > 0 ? true : false,
                marketsharpId,
              },
              () => {
                this.createNew();
              }
            );
          }
        });
    }
  };

  getDataSource = (params) => api.marketsharp.list(params);
  createNew = () => {
    this.setState({ loading: true });
    api.marketsharp
      .locationList()
      .then((resp) => {
        this.setState({ loading: false });
        if (resp && resp.locationList && resp.locationList.length > 0) {
          let locationLists = resp.locationList;
          let modal = true;
          let disableDefaultLocation = false;
          if (resp.haveDeafultLocation) {
            disableDefaultLocation = true;
          }
          this.setState({
            locationLists,
            modal,
            disableDefaultLocation,
          });
        } else {
          this.setState({
            modal: true,
          });
        }
      })
      .catch((err) => this.setState({ loading: false }));
  };
  handleSubmit = () => {
    const {
      deafultLocation,
      location,
      formId,
      companyId,
      source,
      marketsharpId,
    } = this.state;
    this.setState({ formLoading: true });
    api.marketsharp
      .createNew({
        deafultLocation,
        location,
        companyId,
        formId,
        source,
        marketsharpId,
      })
      .then((resp) => {
        if (resp && resp.message) {
          this.formRef.current.reset();
          this.clearState({
            successMessage: resp.message,
            formLoading: false,
          }).then(() => {
            this.setState({ refreshGrid: true }, () => {
              utilFunc.scrollTop(800);

              setTimeout(() => {
                this.setState({ refreshGrid: false });
              }, 500);
            });
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
        } else if (resp.errorMessage) {
          this.formRef.current.reset();
          this.clearState({
            errorMessage: resp.errorMessage,
            formLoading: false,
          }).then(() => {
            utilFunc.scrollTop(800);

            setTimeout(() => {
              this.setState({ errorMessage: null });
            }, 5000);
          });
        }
      })
      .catch((err) => {
        this.setState({ marketsharpId: 0, formLoading: false });
        console.log(err);
      });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  clearState = (data = null) => {
    return new Promise((resolve, reject) => {
      let elem = {
        modal: false,
        companyId: "",
        formId: "",
        marketsharpId: 0,
        source: "",
        selectedDrodown: 0,
        deafultLocation: false,
        location: 0,
      };

      if (data) {
        elem = { ...elem, ...data };
      }
      this.setState(elem, () => {
        resolve();
      });
    });
  };
  toggleModal = () => this.clearState();

  submitForm = () => this.formRef.current.submit();

  handleSwitch = (checked) => this.setState({ deafultLocation: checked });

  createCredentials = () => {
    console.log(">>>>>", this.state.selectedDrodown);
    const errorLabel = <div className="red" pointing={"true"} />;
    return (
      <div
        className={this.state.modal ? "modal fade show " : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.modal ? "block" : "none" }}
      >
        {this.state.formLoading && <div className="ui loading form"></div>}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Marketsharp"}</h5>
            </div>
            <div className="modal-body">
              <Form
                noValidate
                autoComplete="off"
                onValidSubmit={this.handleSubmit}
                className="kt-form kt-form--label-right"
                ref={this.formRef}
                style={{ margin: "10px 10px 0 0" }}
              >
                {this.state.locationLists.length > 0 && (
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      style={{ margin: 0 }}
                    >
                      Location :
                    </label>
                    <div className="col-lg-9">
                      <div className="field">
                        <div className="ui input">
                          <select
                            className="form-control"
                            name="location"
                            onChange={this.handleChange}
                          >
                            {this.state.locationLists.map((option, index) => {
                              return (
                                <option
                                  key={index}
                                  disabled={
                                    parseInt(option.marketsharp_id) > 0 &&
                                    this.state.selectedDrodown !== option.key
                                      ? true
                                      : false
                                  }
                                  style={{
                                    background:
                                      parseInt(option.marketsharp_id) > 0 &&
                                      this.state.selectedDrodown !== option.key
                                        ? "#cccaca"
                                        : "",
                                  }}
                                  value={option.key}
                                  selected={
                                    this.state.selectedDrodown === option.key
                                      ? true
                                      : false
                                  }
                                >
                                  {option.text}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Company ID :
                  </label>
                  <div className="col-lg-9">
                    <Form.Input
                      type="text"
                      autoComplete="off"
                      name="companyId"
                      placeholder="Company Id"
                      value={this.state.companyId}
                      onChange={this.handleChange}
                      validations="isRequired"
                      validationErrors={{
                        isRequired: "This field is required.",
                      }}
                      errorLabel={errorLabel}
                    />
                    <i style={{ color: "red" }}>Example: 3769</i>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Form ID :</label>
                  <div className="col-lg-9">
                    <Form.Input
                      type="text"
                      autoComplete="off"
                      name="formId"
                      value={this.state.formId}
                      placeholder="Form Id"
                      onChange={this.handleChange}
                      validations="isRequired"
                      validationErrors={{
                        isRequired: "This field is required.",
                      }}
                      errorLabel={errorLabel}
                    />
                    <i style={{ color: "red" }}>
                      Example: 1fc7cade-24e4-464b-9624-5ed9f75cd24f{" "}
                    </i>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Source :</label>
                  <div className="col-lg-9">
                    <Form.Input
                      type="text"
                      autoComplete="off"
                      name="source"
                      value={this.state.source}
                      placeholder="Source"
                      onChange={this.handleChange}
                      validations="isRequired"
                      validationErrors={{
                        isRequired: "This field is required.",
                      }}
                      errorLabel={errorLabel}
                    />
                    <i style={{ color: "red" }}>
                      Example: 1fc7cade-24e4-464b-9624-5ed9f75cd24f
                    </i>
                  </div>
                </div>

                {this.state.locationLists.length > 0 && (
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">
                      Include the location data for default marketsharp account?
                    </label>
                    <div className="col-lg-9" style={{ paddingTop: "30px" }}>
                      <Switch
                        label="Include the location data for default marketsharp account?"
                        onChange={this.handleSwitch}
                        checked={this.state.deafultLocation}
                        disabled={this.state.disableDefaultLocation}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        margin={20}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                )}
              </Form>
            </div>
            <div className="modal-footer">
              <button
                onClick={this.submitForm}
                type="button"
                className="btn linear-gradient yr-submitbtn btn-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  toggleConfirmModal = () =>
    this.setState({ confirmDialog: false, marketsharpId: 0 });
  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="kt-portlet__body kt-portlet__body--fit">
            {this.state.modal && this.createCredentials()}
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            {this.state.errorMessage && (
              <Message color={"red"}>{this.state.errorMessage}</Message>
            )}
            {this.state.loading && <div className="ui loading form"></div>}

            {this.state.confirmDialog && (
              <ConfirmDialog
                show={this.state.confirmDialog}
                toggleModal={this.deleteMarketsharpCredentials}
                closeAction={this.toggleConfirmModal}
                title="Confirm your action"
                message={"Do you really want to delete this credential ?"}
              />
            )}
            <div className="yr-manageUser-table">
              <Segment>
                <div className="marketsharp-integration">
                  <GridList
                    dataSource={(params) => this.getDataSource(params)}
                    dataColumns={this.columnLists}
                    refreshGrid={this.state.refreshGrid}
                  >
                    <div className="col-md-8 kt-margin-b-20-tablet-and-mobile">
                      <button
                        style={{ marging: "0 0 15px 0", float: "right" }}
                        type="button"
                        className="btn linear-gradient yr-submitbtn"
                        onClick={this.createNew}
                      >
                        Create New
                      </button>
                    </div>
                  </GridList>
                </div>
              </Segment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MarketSharp;
