import React from "react";
import api from "../../../../../../api";
import "../../../../../Grid/grid.css";
import "./dataTable.css";
import { Segment, Table } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import DatatableDeleteBox from "./DatatableDeleteBox";
import DialogBoxAddEdit from "./DialogBoxAddEdit";
import { DatatableRow } from "./DatatableRow";
import { CSVLink, CSVDownload } from "react-csv";
import utilFunc from "../../../../../../util_funs";
import DialogContactDetail from "./DialogContactDetail";
import DialogBoxContactVia from "./DialogBoxContactVia";
import DialogBoxContactViaZapier from "./DialogBoxContactViaZapier"; // added by rohit
import DialogBoxContactViaZapierApp from "./DialogBoxContactViaZapierApp"; // added by sagar
import DialogBoxContactViaZapierMsg from "./DialogBoxContactViaZapierMsg"; // added by rohit
import DialogBoxContactViaZapierAppMsg from "./DialogBoxContactViaZapierAppMsg"; // added by sagar
import { Message } from "semantic-ui-react";
import DialogBoxDetailOnCSVUploadError from "./DialogBoxDetailOnCSVUploadError";
import DialogBoxAssocCompaigns from "./DialogBoxAssocCompaigns";
import SubscribeUnSubscribe from "../../../PlayBookAnalytics/dataTables/subscribeUnSubscribe";
import SubscribeStatus from "./SubscribeStatus";
import { withRouter } from "react-router-dom";


const tableHeader = [
	{
		title: "",
		width: "",
		sort: "",
		checkbox: true,
	},
	{
		title: "",
		width: "3",
		sort: "",
	},
	{
		title: "Email",
		width: "25%",
		sort: "email",
	},
	// {
	//   title: "Phone",
	//   width: "3",
	//   sort: "name",
	// },

	{
		title: "Subscribe Status",
		width: "3",
		sort: "is_subscribed",
		align: "center",
	},
	{
		title: "",
		width: "3",
		sort: "",
	},
	{
		title: "Source",
		width: "3",
		sort: "source",
	},
];

const tableHeader2 = [
	{
		title: "",
		width: "",
		sort: "",
		checkbox: true,
	},
	{
		title: "",
		width: "3",
		sort: "",
	},
	{
		title: "First Name",
		width: "25%",
		sort: "",
	},
	{
		title: "Last Name",
		width: "25%",
		sort: "",
	},
	// {
	//   title: "Phone",
	//   width: "3",
	//   sort: "name",
	// },
	{
		title: "",
		width: "3",
		sort: "",
	},
	{
		title: "Source",
		width: "3",
		sort: "source",
	},
];

const tableHeaderSms = [
	{
		title: "",
		width: "",
		sort: "",
		checkbox: true,
	},
	{
		title: "",
		width: "3",
		sort: "",
	},
	{
		title: "First Name",
		width: "25%",
		sort: "",
	},
	{
		title: "Last Name",
		width: "25%",
		sort: "",
	},
	{
		title: "Phone Number",
		width: "25%",
		sort: "",
	},
	{
		title: "Source",
		width: "3",
		sort: "source",
	},
];

const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
const APIURL = "http://localhost/zyratalk/api/premium/settings/manageuser/";
class DatatableList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lists: [],
			_sort: "added_datetime",
			_page: 1,
			_order: "desc",
			_limit: 10,
			q: "",
			totalCount: 0,
			loading: false,
			modalstatus: false,
			modalstatusDelete: false,
			modaltitle: null,
			modalbuttonmsg: {},
			modalType: null,
			datatableHeader: null,
			datatableRows: null,
			modalErrorMsg: false,
			modalstatusAddEdit: false,
			modaldata: {},
			modalFormLoader: false,
			selectedOptions: [],
			accessModuleList: [],
			notification_text: 0,
			notification_email: 0,
			duplicateEmailError: "",
			duplicatePhoneError: "",
			duplicatePhoneErrorSms: "",
			renderOption1: [],
			renderOption2: [],
			renderOption3: [],
			renderOption4: [],
			country_code: "+1",
			country_code_sms: "+1",
			phone_number: "",
			sms_number: "",
			csvdata: [],
			src: null,
			crop: {
				unit: "%",
				width: 30,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
			profile_pic: null,
			croppedImage: null,
			DeletePic: 1,
			oldImage: 1,
			DeletePicAdd: 0,
			modaldetailstatus: false,
			modalContactVia: false,
			modalContactViaObject: {
				show: false,
				title: "Please select one of the below option",
				loading: false,
			},
			// added by rohit
			modalContactViaObjectZapier: {
				show: false,
				title: "Please select one of the below option",
				loading: false,
			},
			modalContactViaObjectZapierApp: {
				show: false,
				title: "",
				loading: false,
			},

			// added by rohit
			modalContactViaObjectZapierMsg: {
				showMsg: false,
				title: "Please select one of the below option",
				loading: false,
			},
			modalContactViaObjectZapierAppMsg: {
				showMsg: false,
				title: "",
				loading: false,
			},

			currentContactLabelData: [],
			userToaster: false,
			userToasterMsg: "",
			userToasterColor: "",
			modalstatusCSVfailure: false,
			csvFailArr: [],
			currentContactLabelDataOnEdit: [],
			currentContactLabelDetailDataOnEdit: [],
			campaign_data: [],
			modalcompaignstatus: false,

			modalstatusSubscribeUnSubscribe: false,
			modalSubscribeUnSubscribeTitle: "Subscribe Emails",
			modalSubscribeUnSubscribeBtTitle: "Subscribe",
			subscribeUnSubscribeObj: {},
			domainList: [],
			selectAtleastOne: false,
			currentChecked: [],
			subscribedUnSubscribedMsgOpen: false,
			subscribedUnSubscribedMsg: "",
			selectAtleastOneInPopup: "none",
			showMsg: true,
			selectedEmail: "",
			webhooks_title: "",
			//added by Sagar
			webhooks_url: "",
			webhooks_id: null,
			switchValue: "",
			orginalSwitchvalue:"",
			checkedOnOFF: "",
			/* Sagar End */
			webhook_msg: "",
			emailModalStatus: false,
			emailList: [],
			//is_subscribed: '',
			//source: '',
			btype: "",
			showMsg: true,
			stFlag: false,
			phoneNumberValue: '',
			filteredList: []
		};
		this.csvLink = React.createRef();
	}

	componentDidMount() {
		this.loadData({});
		api.marketing.getBucketData({}).then(data => {
			try {
				if (data.status)
					this.setState({
						stFlag: data.service_titan_avaialble ? true : false,
					});
			} catch (err) {
				console.log(err);
			}
		});

		this.country_code();
		api.marketing.getDomainList({}).then((data) => {
			this.setState({
				domainList: data.data,
			})

		});
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.type !== prevProps.type) {
			this.loadData({});
		}
	}

	handleFile = (e) => {
		const fileReader = new FileReader();
		fileReader.onloadend = () => {
			this.setState({
				src: fileReader.result,
				oldImage: 0,
				DeletePicAdd: 1,
				DeletePic: 1,
			});
		};
		fileReader.readAsDataURL(e.target.files[0]);
	};

	onImageLoaded = (image) => {
		this.imageRef = image;
	};

	handleRemovePic = (key) => {
		this.setState({ DeletePic: 0 });
	};
	handleRemovePicAdd = (key) => {
		this.setState({ DeletePicAdd: 0 });
	};

	onCropChange = (crop) => {
		this.setState({ crop });
	};

	onCropComplete = (crop) => {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
			this.setState({ croppedImageUrl });
		}
	};

	getCroppedImg(image, crop) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		const reader = new FileReader();
		canvas.toBlob((blob) => {
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				this.dataURLtoFile(reader.result, "cropped.jpg");
			};
		});
	}

	dataURLtoFile(dataurl, filename) {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let croppedImage = new File([u8arr], filename, { type: mime });
		this.setState({ croppedImage: croppedImage });
	}

	country_code = (params) => {
		api.manageTeam.callCountryCode().then((data) => {
			try {
				this.setState({ renderOption1: data.data.defaultCountryCodeArr });
				this.setState({ renderOption2: data.data.remailCountryCodeArr });
				this.setState({ renderOption3: data.data.defaultCountryCodeArr });
				this.setState({ renderOption4: data.data.remailCountryCodeArr });
			} catch (err) {
				console.log(err);
			}
		});
	};

	static directionConverter(order) {
		if (order === "asc") {
			return "ascending";
		} else if (order === "desc") {
			return "descending";
		} else {
			return null;
		}
	}

	handleSort = (clickedColumn) => {
		const { _sort, _order } = this.state;

		let newOrder = _order === "asc" ? "desc" : "asc";
		if (_sort !== clickedColumn) {
			newOrder = "asc";
		}

		this.loadData({
			_sort: clickedColumn,
			_page: 1,
			_order: newOrder,
		});
	};

	onChangeLimit = (event, data) => {
		if (data.value !== this.state._limit) {
			this.loadData({ _limit: data.value, _page: 1 });
		}
	};

	onSubmitFilter = (filter) => {
		console.log(filter);
		if (filter !== this.state.q) {
			this.loadData({ q: filter, _page: 1 });
		}
	};

	onSubmit_is_subscribed = (sub_val) => {
		this.setState({ is_subscribed: sub_val }, function () {
			this.loadData({});
		});
	};
	onSubmit_Source = (sourceVal) => {
		this.setState({ source: sourceVal }, function () {
			this.loadData({});
		});
	};

	onChangePage = (event, data) => {
		const { activePage } = data;
		if (activePage !== this.state._page) {
			this.loadData({ _page: activePage });
		}
	};

	modalbuttofun = (data) => {
		if (data.modalType == "Delete") {
			this.onDeleteAction();
		}

		if (data.modalType == "ResendDeviceKey") {
			this.onResendDeviceKeyAction();
		}

		if (data.modalType == "Popup") {
			this.setState({
				modalstatus: false,
				modaltitle: "",
				modalmessage: "",
				modalbuttonmsg: {},
				modalType: "",
			});
		}
	};

	addContact = (btype) => {
		let modalContactViaObject = {
			show: true,
			title: "Add contact via",
			loading: false,
		};
		this.setState({
			modalContactViaObject: modalContactViaObject,
			btype: btype
		});
	};
	addContactViaZapApp = (opt)=>{
		this.setState({loading:true});
		//api call for zapierApp
		this.modalCloseContactViaBox();
		try{

			api.marketing.getwebhookZapApp({
				bid: this.props.bid,
				user_id: this.props.userdata.user_id,
			})
			.then(data=>{
				this.setState({loading:false});
				let modalContactViaObjectZapierApp = {
					show: true,
					title: "Add contact via zapier (zap)",
					loading: false,
				};
				this.setState({
					modalContactViaObjectZapierApp: modalContactViaObjectZapierApp,
					// webhooks_title: data.webhooks_title,
					// webhooks_url: data.webhooks_url,
					// checkedOnOFF: data.checkedOnOFF,
					switchValue: data.switchValue,
					orginalSwitchvalue:data.switchValue
					// webhooks_id: data.webhooks_id,
				});

				// api.marketing.checkwebhooksZapApp({
				// 	bid: this.props.bid,
				// 	user_id: this.props.userdata.user_id,
				// 	val_on_off:"ON"
				// }).then(data=>{
				// 	this.setState({loading:false});
				// }).catch(err=>{
				// 	this.setState({loading:false});
				// 	console.log(err)
				// })

			})
		}catch(err){
			this.setState({loading:false});
			console.log(err)
		}
		
	  }
	// Added by rohit
	addContactZapier = (opt) => {
		this.setState({ loading: true });
		this.modalCloseContactViaBox();
		api.marketing
			.getwebhookZapier({
				bid: this.props.bid,
				user_id: this.props.userdata.user_id,
			})
			.then((data) => {
				this.setState({ loading: false });
				//alert(data.checkedOnOFF);
				//document.getElementById("AddEditContactFormModal").reset();
				let modalContactViaObjectZapier = {
					show: true,
					title: "Add contact via zapier",
					loading: false,
				};
				this.setState({
					modalContactViaObjectZapier: modalContactViaObjectZapier,
					webhooks_title: data.webhooks_title,
					webhooks_url: data.webhooks_url,
					checkedOnOFF: data.checkedOnOFF,
					switchValue: data.switchValue,
					webhooks_id: data.webhooks_id,
				});
			});
	};

	// Added by rohit
	saveWebhooks = (webhookTitle, webhooksId) => {
		//alert(webhookTitle);
		api.marketing
			.savecontactZapier({
				webhook_title: webhookTitle,
				webhook_id: webhooksId,
			})
			.then((data) => {
				//alert( data.msg );
				this.modalCloseContactViaZapier();
				this.showUserToaster("green", "Save successfully.");
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;

				/*  setTimeout(
				   function () {
					 this.setState({ userToasterMsg: false });
				   }.bind(this),
				   3000
				 ); */
			});
	};

	onChangesWebhooks = (e) => {
		//alert(e.target.value);
		this.setState({
			webhooks_title: e.target.value,
		});
	};

	editContact = (id) => {

		this.setState({ loading: true });
		let recordlist = this.state.lists.findIndex((list) => list.cid === id);
		let cId = this.state.lists.filter((list) => list.cid === id);
		let modaldata = this.state.lists[recordlist];
		console.log(modaldata, id, "edited data");
		let editDateModule = [];
		let moduleList = this.state.accessModuleList;

		moduleList.forEach((item, index) => {
			Object.keys(item).forEach(function (key) {
				if (modaldata.linksetting.includes(key)) editDateModule.push(key);
			});
		});
		this.setState({
			country_code: modaldata.country_code,
		});

		this.setState({
			phone_number: modaldata.phone_number,
		});
		if (modaldata.notification_text == "1") {
			this.setState({
				notification_text: 1,
			});
		} else {
			this.setState({
				notification_text: 0,
			});
		}

		if (modaldata.notification_email == "1") {
			this.setState({
				notification_email: 1,
			});
		} else {
			this.setState({
				notification_email: 0,
			});
		}

		if(this.props.type == 1 || this.props.type == 5 || this.props.type == 4 || this.props.type == 7){
			id = cId[0]['Email'] ? cId[0]['Email'] : cId[0]['email'];
		}else if (this.props.type == 3 || this.props.type == 6){
			id = (cId[0]['Phone_Number']) ? cId[0]['Phone_Number'] : cId[0]['Phone Number']; 
		}

		api.marketing
			.getContactLabelAndDataOnEdit({
				bid: this.props.bid,
				user_id: this.props.userdata.user_id,
				cid: id,
			})
			.then((data) => {
				console.log(data, "label data");
				this.setState({ loading: false });
				if (data.data.status === true) {
					this.setState({
						modalstatusAddEdit: true,
						modaltitle: "Edit Contact",
						modalType: "Edit",
						modaldata: modaldata,
						modaldata: { opt: "static", submitBtnName: "Update" },
						selectedOptions: editDateModule,
						currentContactLabelDataOnEdit: data.data.data.contact[0],
						currentContactLabelDetailDataOnEdit: data.data.data.contactDetails,
						phone_number: data.data.data.contact[0].phone,
						phoneNumberValue: data.data.data.contactDetails['Phone Number'],
					});
				} else {
					utilFunc.scrollTop(800);
					this.showUserToaster("green", 'Contact details not found.');
				}

			}).catch((err) => {
				this.setState({ loading: false });
				console.log(err);
				utilFunc.scrollTop(800);
				this.showUserToaster("red", 'Something went wrong.');
			})
	};

	deleteContact = (id) => {
		let recordlist = this.state.lists.findIndex((list) => list.cid === id);
		let modaldata = this.state.lists[recordlist];
		if(this.props.type == 1 || this.props.type == 5 || this.props.type == 4 || this.props.type == 7){
			modaldata.cid = modaldata['email'] ? modaldata['email'] : modaldata['Email'];
		}else if (this.props.type == 3 || this.props.type == 6){
			modaldata.cid = (modaldata['Phone_Number']) ? modaldata['Phone_Number'] : modaldata['Phone Number']; 
		}

		let delmessage = "Are you sure you want to delete?";
		if (this.state.lists.length == 1) {
			delmessage =
				"This is last contact on deleting this the bucket will we deleted.Are you sure you want to delete?";
		}
		this.setState({
			modalstatusDelete: true,
			modaltitle: "Confirm your action",
			modalmessage: delmessage,
			modalbuttonmsg: { submit: "Yes", cancel: "No" },
			modalType: "Delete",
			modaldata: modaldata,
		});
	};

	OtherDetails = (id) => {
		this.setState({
			modalstatus: true,
			modaltitle: "This bucket is associated with 4 campaigns",
			modalmessage: "Are you sure you want to delete?",
			modalbuttonmsg: { submit: "Yes", cancel: "No" },
			modalType: "Details",
		});
	};

	ResendDeviceKey = (id) => {
		let recordlist = this.state.lists.findIndex((list) => list.id === id);
		let modaldata = this.state.lists[recordlist];
		this.setState({
			modalstatus: true,
			modaltitle: "Confirm your action",
			modalmessage: "Are you sure you want to send it again ?",
			modalbuttonmsg: { submit: "Yes", cancel: "No" },
			modalType: "ResendDeviceKey",
			modaldata: modaldata,
		});
	};

	onDeleteAction = () => {
		this.setState({ modalFormLoader: true });
		api.marketing
			.deleteContact({ cid: this.state.modaldata.cid, bid: this.props.bid })
			.then((data) => {
				this.setState({ modalFormLoader: false });
				try {
					let recordDeleted = this.state.lists.findIndex(
						(list) => list.cid === data.data.cid
					);

					let oldArr = this.state.lists;
					oldArr.splice(recordDeleted, 1);
					if (oldArr.length == 0) {
						var link = document.getElementById("backtocontats");
						link.click();
					} else {
						this.setState(
							{
								modalFormLoader: false,

								modalstatusDelete: false,
								modaltitle: "",
								modalmessage: "",
								modalbuttonmsg: {},
								modalType: "",
								modalErrorMsg: false,
							},
							function () {
								this.loadData({});
								this.props.loadData();
							}
						);
						this.showUserToaster(
							"green",
							"User has been deleted successfully."
						);
						utilFunc.scrollTop(800);
					}
				} catch (err) {
					console.log(err);
				}
			});
	};

	onResendDeviceKeyAction = () => {
		this.setState({ modalFormLoader: true });
		api.manageTeam
			.ResendDeviceKey({ id: this.state.modaldata.id })
			.then((data) => {
				this.setState({ modalFormLoader: false });
				try {
					this.setState({
						modalstatus: false,
						modaltitle: "",
						modalmessage: "",
						modalbuttonmsg: {},
						modalType: "",
						modalErrorMsg: false,
					});
					this.props.showUserToaster(
						"green",
						"Device key has been sent successfully."
					);
					utilFunc.scrollTop(800);
				} catch (err) {
					console.log(err);
				}
			});
	};
	preOpensetting = (id) => {
		let recordlist = this.state.lists.findIndex((list) => list.id === id);
		let access_selected = this.state.lists[recordlist].linksetting;
		let access_selected_lists_html = "";
		access_selected_lists_html = access_selected.map((item, value) => (
			<p
				key={item}
				style={{
					textTransform: "capitalize",
					color: "#6c7293",
					fontWeight: "400",
				}}
			>
				{item.replace(/_/g, " ")}
			</p>
		));
		this.setState({
			modalstatus: true,
			modaltitle: "Setting/Privileges",
			modalmessage: access_selected_lists_html,
			modalbuttonmsg: { submit: "Ok", cancel: "" },
			modalType: "Popup",
		});
	};

	modalClose = (flag, fm) => {
		// document.getElementById("isd_code_id_review").selectedIndex = 0;
		// document.getElementById("isd_code_id_review_sms").selectedIndex = 0;
		this.setState({
			modalstatus: false,
			modalstatusAddEdit: false,
			modaltitle: "",
			modalmessage: "",
			modalbuttonmsg: {},
			modalType: "",
			modaldata: {},
			selectedOptions: [],
			duplicateEmailError: "",
			duplicatePhoneError: "",
			currentContactLabelData: [],
			currentContactLabelDetailDataOnEdit: [],
			phoneNumberValue: ''
		});
	};

	closeEmailModal = () => {
		this.setState({
			emailModalStatus: false,
		});
	};

	seeSubscribeStatus = (cid) => {
		api.marketing
			.getEmailSubscribeDomain({
				bid: this.props.bid,
				user_id: this.props.userdata.user_id,
				cid: cid,
			})
			.then((res) => {
				if (res.status && res.data) {
					this.setState({
						emailModalStatus: true,
						emailList: res.data,
						selectedEmail: cid,
					});
				}
			});
	};

	modalCloseDeleteBox = (flag, fm) => {
		this.setState({
			modalstatusDelete: false,
			modaltitle: "",
			modalmessage: "",
			modalbuttonmsg: {},
			modalType: "",
			modaldata: {},
		});
	};

	handleCheck = (key) => {
		const target = key.target;
		if (target.checked) {
			this.setState({
				[target.name]: 1,
			});
		} else {
			this.setState({
				[target.name]: 0,
			});
		}
	};

	handleDrop = (key) => {
		const target = key.target.value;
		this.setState({
			country_code: target,
		});
	};

	handleDropSms = (key) => {
		const target = key.target.value;
		this.setState({
			country_code_sms: target,
		});
	};

	checkEmail = (key, email1 = null) => {
		if (key.target.value.length == 0) {
			this.setState({
				duplicateEmailError: "",
			});
			return false;
		}
		let sendData = {
			email: key.target.value,
			modalType: this.state.modalType,
			id: this.state.modaldata.id,
			bid: this.props.bid,
			existing_email: email1,
		};
		api.marketing.checkEmail(sendData).then((data) => {
			try {
				if (data.data == 123) {
					this.setState({
						duplicateEmailError: "This email id is invalid",
					});
				} else if (data.data == 1) {
					this.setState({
						duplicateEmailError: "This email id is already exists",
					});
				} else {
					this.setState({
						duplicateEmailError: "",
					});
				}
			} catch (err) {
				console.log(err);
			}
		});
	};

	checkPhone = (key) => {
		let sendData = {
			phone_number: key.target.value,
			modalType: this.state.modalType,
			cid: this.state.modaldata.cid,
			bid: "1",
		};
		api.marketing.checkPhone(sendData).then((data) => {
			try {
				if (data.data > 0) {
					this.setState({
						duplicatePhoneError: "This phone number is already exits",
					});
				} else {
					this.setState({
						duplicatePhoneError: "",
					});
				}
			} catch (err) {
				console.log(err);
			}
		});
		this.setState({
			phone_number: key.target.value,
		});
		this.phoneFormat(key);
	};
	checkPhoneSms = (key) => {
		let sendData = {
			sms_number: key.target.value,
			modalType: this.state.modalType,
			cid: this.state.modaldata.cid,
			bid: "1",
		};
		api.marketing.checkPhoneSms(sendData).then((data) => {
			try {
				if (data.data > 0) {
					this.setState({
						duplicatePhoneErrorSms: "This phone number is already exits",
					});
				} else {
					this.setState({
						duplicatePhoneErrorSms: "",
					});
				}
			} catch (err) {
				console.log(err);
			}
		});
		this.setState({
			sms_number: key.target.value,
		});
		this.phoneFormatSms(key);
	};

	checkPhoneFormat = (e) => {
		this.setState({
			phoneNumberValue: e.target.value
		})
		this.phoneFormat(e);
	}

	phoneFormat = (e) => {
		let phone = e.target.value;
		let p = phone;
		var obj = this;
		console.log('pph is the', p);
		p = p.replace(/[^\d]*/gi, "");
		console.log('pph is the', p);
		if (p.length < 3) {
			setTimeout(function () {
				obj.setState({ phoneNumberValue: p });
			}, 100);
		} else if (p.length == 3) {
			let pp = p;
			let d4 = p.indexOf("(");
			let d5 = p.indexOf(")");
			if (d4 == -1) {
				pp = "(" + pp;
			}
			if (d5 == -1) {
				pp = pp + ")";
			}
			obj.setState({ phoneNumberValue: pp });
		} else if (p.length > 3 && p.length < 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			var pp = p30 + p31;

			setTimeout(function () {
				obj.setState({ phoneNumberValue: pp });
			}, 100);
		} else if (p.length >= 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			let l40 = pp.length;
			let p40 = pp.substring(0, 8);
			p40 = p40 + "-";

			let p41 = pp.substring(8, l40);
			let ppp = p40 + p41;
			let maxphonelength = 13;
			var finalPhone = ppp.substring(0, maxphonelength);
			setTimeout(function () {
				obj.setState({ phoneNumberValue: finalPhone });
			}, 100);
		}
	};

	phoneFormatSms = (e) => {
		let phone = e.target.value;
		let p = phone;
		var obj = this;

		p = p.replace(/[^\d]*/gi, "");
		if (p.length < 3) {
			setTimeout(function () {
				obj.setState({ sms_number: p });
			}, 100);
		} else if (p.length == 3) {
			let pp = p;
			let d4 = p.indexOf("(");
			let d5 = p.indexOf(")");
			if (d4 == -1) {
				pp = "(" + pp;
			}
			if (d5 == -1) {
				pp = pp + ")";
			}
			obj.setState({ sms_number: pp });
		} else if (p.length > 3 && p.length < 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			setTimeout(function () {
				obj.setState({ sms_number: pp });
			}, 100);
		} else if (p.length >= 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			let l40 = pp.length;
			let p40 = pp.substring(0, 8);
			p40 = p40 + "-";

			let p41 = pp.substring(8, l40);
			let ppp = p40 + p41;
			let maxphonelength = 13;
			let finalPhone = ppp.substring(0, maxphonelength);
			setTimeout(function () {
				obj.setState({ sms_number: finalPhone });
			}, 100);
		}
	};

	onValidSubmit = (sendData) => {
		if (this.state.modalType == "Add") {
			var $msg = "User has been added successfully.";
		} else {
			var $msg = "User has been updated successfully.";
		}
		this.loadData({});
		this.setState({
			modalstatusAddEdit: false,
			currentContactLabelData: [],
			currentContactLabelDetailDataOnEdit: [],
		});
		this.showUserToaster("green", $msg);
		utilFunc.scrollTop(800);
	};

	onValidSubmit2 = (sendData) => {

		this.loadData({});
		this.setState({
			modalstatusAddEdit: false,
			currentContactLabelData: [],
			currentContactLabelDetailDataOnEdit: [],
		});
		this.showUserToaster("green", sendData);
		utilFunc.scrollTop(800);
	};

	downloadCSV = (event, done) => {
		let params = {};
		params["_order"] = this.state._order;
		params["_sort"] = this.state._sort;
		params["bid"] = this.state.bid;
		params["q"] = this.state.q;
		params["source"] = this.state.source;

		let list = this.state.lists;
		let selected_arr = [];
		list.filter(function (key) {
			if (key["checked"] == true) {
				selected_arr.push(key["cid"]);
			}
		});

		let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
		let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
		let time_zone = clientTimeZone;
		params["cids"] = JSON.stringify(selected_arr);
		params["time_zone"] = time_zone;

		
			api.marketing.exportContactList(params).then((data) => {
				try {
					if(data.data.status){
						this.setState(
							{
								csvdata: data.data.data,
							},
							function () {
								setTimeout(
									function () {
										document.querySelector("#chatcsv").click();
										this.setState({ loading: false });
									}.bind(this),
									1000
								);
							}
						);
					}
					
				} catch (err) { }
			});
		
	};

	loadData = (params) => {
		console.log(this.props, "load bucket");
		const newState = Object.assign({}, this.state, params, { loading: false });

		queryParams.forEach(function (element) {
			if (!(element in params)) {
				params[element] = newState[element];
			}
		});
		// this.onSubmit_is_subscribed()
		// added by rohit
		if (typeof this.state.is_subscribed === "undefined") {
			params["is_subscribed"] = "";
		} else {
			params["is_subscribed"] = this.state.is_subscribed;
		}

		if (typeof this.state.source === "undefined") {
			params["source"] = "";
		} else {
			params["source"] = this.state.source;
		}

		params["bid"] = this.props.bid;
		params["cids"] = JSON.stringify([]);
		const esc = encodeURIComponent;
		const query = Object.keys(params)
			.map((k) => esc(k) + "=" + esc(params[k]))
			.join("&");

		let totalCountQuery = "";
		if (params.q !== "") {
			totalCountQuery = `q=${params.q}`;
		}

		console.log('query', query);
		this.setState(newState, () => {
			this.setState({ loading: true });
			api.marketing.getContactLists(query).then((data) => {
				try {
					if(data.status===401){
						this.props.history.push('/notAllowed');
					}
					let accessModuleStore = [];

					let datatableRowstemp;
					if (data.totalrecord) {
						this.setState({
							filteredList: data.data.list
						})
						datatableRowstemp = data.data.list.map((list, index) => (
							<DatatableRow
								key={index}
								list={list}
								bid={this.props.bid}
								userdata={this.props.userdata}
								editContact={this.editContact}
								deleteContact={this.deleteContact}
								viewContactDetail={this.viewContactDetail}
								ResendDeviceKey={this.ResendDeviceKey}
								preOpensetting={this.preOpensetting}
								channgeEvent={this.checkboxChangeEvent}
								seeSubscribeStatus={this.seeSubscribeStatus}
								type={this.props.type}
							/>
						));
					} else {
						this.setState({
							filteredList: []
						})
						datatableRowstemp = (
							<Table.Row>
								<Table.Cell colSpan={7} textAlign="center">
									No record found.
								</Table.Cell>
							</Table.Row>
						);
					}
					this.setState({
						datatableRows: datatableRowstemp,
						totalCount: data.totalrecord,
						lists: data.data.list,
						accessModuleList: accessModuleStore,
					});
					var totalSubscriber = 0;
					data.data.list.forEach(el => {
						totalSubscriber += el.is_subscribed == 1 ? 1 : 0 
					})
					this.props.isRefresh(data.totalrecord, totalSubscriber);
				} catch (err) {
					console.log(err);
				}
				const newState = Object.assign({}, this.state, params, {
					loading: false,
				});
				this.setState(newState);
			});
		});
	};

	viewContactDetail = (id, bid) => {
		this.setState({ loading: true });
		let cId = this.state.lists.filter((list) => list.cid === id);
		if(this.props.type == 1 || this.props.type == 5 || this.props.type == 4 || this.props.type == 7){
			id = cId[0]['Email'] ? cId[0]['Email'] : cId[0]['email'];
		}else if (this.props.type == 3 || this.props.type == 6){
			id = (cId[0]['Phone_Number']) ? cId[0]['Phone_Number'] : cId[0]['Phone Number']; 
		}
		api.marketing.getContactDetails({ cid: id, bid: bid }).then((data) => {
			console.log(data);
			try {
				// this.setState({loading:false,detail_data:data.data});
				this.setState(
					{
						modaltitle: "Details",
						loading: false,
						detail_data: data.data,
					},
					function () {
						this.setState({ modaldetailstatus: true });
					}
				);
			} catch (err) {
				console.log(err);
			}
		});
	};

	modalCloseContactBox = (flag, fm) => {
		this.setState({
			modaldetailstatus: false,
			modalcompaignstatus: false,
			modaltitle: "",
			modalmessage: "",
			modalbuttonmsg: {},
			modalType: "",
			modaldata: {},
		});
	};

	modalCloseContactViaBox = () => {
		let modalContactViaObject = {
			show: false,
			title: "Please select one of the below option",
			loading: false,
		};
		this.setState({
			modalContactViaObject: modalContactViaObject,
			btype: ""
		});
	};
	// Added by rohit
	modalCloseContactViaZapier = () => {
		let modalContactViaObjectZapier = {
			show: false,
			title: "",
			loading: false,
		};
		this.setState({
			modalContactViaObjectZapier: modalContactViaObjectZapier,
		});
	};
	modalCloseContactViaZapierApp = () => {
		// let modalContactViaObjectZapierAppMsg = {
		// 	showMsg: true,
		// 	title: "Add contact via zapier (App)",
		// 	loading: false,
		// };

		// this.setState({
		// 	modalContactViaObjectZapierAppMsg: modalContactViaObjectZapierAppMsg,
		// });

		let modalContactViaObjectZapierApp = {
			show: false,
			title: "",
			loading: false,
		};
		this.setState({
			modalContactViaObjectZapierApp: modalContactViaObjectZapierApp,
		});
	};

	modalOpenContactViaZapierApp = ()=>{
		let modalContactViaObjectZapierAppMsg = {
			showMsg: true,
			title: "Add contact via zapier",
			loading: false,
		};

		this.setState({
			modalContactViaObjectZapierAppMsg: modalContactViaObjectZapierAppMsg,
		});

	}

	// Added by rohit
	modalCloseContactViaZapierMsg = () => {
		let modalContactViaObjectZapierMsg = {
			showMsg: false,
			title: "",
			loading: false,
		};
		this.setState({
			modalContactViaObjectZapierMsg: modalContactViaObjectZapierMsg,
		});
	};
	saveZapierAppData = (switchValue) => {
		this.setState({loading:true})
		let modalContactViaObjectZapierAppMsg = {
			showMsg: false,
			title: "",
			loading: false,
		};
		let modalContactViaObjectZapierApp = {
			show: false,
			title: "",
			loading: false,
		}
		api.marketing.checkwebhooksZapApp({
			bid: this.props.bid,
			user_id: this.props.userdata.user_id,
			val_on_off:switchValue
		})
		.then((data) => {	
			this.setState({
				modalContactViaObjectZapierAppMsg: modalContactViaObjectZapierAppMsg,
				modalContactViaObjectZapierApp:modalContactViaObjectZapierApp,
			},()=>{
				this.setState({loading:false})
			});
		}).catch(err=>{
			this.setState({loading:false})
			console.log(err)
		});
	};

	// Added by rohit
	offAginMsg = () => {
		// alert('hi');
		this.modalCloseContactViaZapierMsg();
		document.getElementById("ctl00_bc_custom_element_43").click();
	};

	offAgainAppMsg = () => {
		// alert('hi');
		let modalContactViaObjectZapierAppMsg = {
			showMsg: false,
			title: "",
			loading: false,
		};
		this.setState({
			modalContactViaObjectZapierAppMsg: modalContactViaObjectZapierAppMsg,
			switchValue:this.state.orginalSwitchvalue
		});
	};

	changeSwitch = (e) => {
		let val = "";
		if (e.target.value == "ON") {
			val = "OFF";
			api.marketing
				.checkwebhooksUrl({
					bid: this.props.bid,
					user_id: this.props.userdata.user_id,
					val_on_off: val,
				})
				.then((data) => {
					this.setState({ switchValue: val, checkedOnOFF: "" });
				});
		} else {
			val = "ON";
			//alert('Hi');
			let modalContactViaObjectZapierMsg = {
				showMsg: true,
				title: "Add contact via zapier",
				loading: false,
			};
			api.marketing
				.checkwebhooksUrl({
					bid: this.props.bid,
					user_id: this.props.userdata.user_id,
					val_on_off: val,
				})
				.then((data) => {
					this.setState({
						switchValue: val,
						checkedOnOFF: "checked",
						modalContactViaObjectZapierMsg: modalContactViaObjectZapierMsg,
					});
				});
		}
	};
	// changeSwitchApp = (e) => {
	// 	let val = "";
	// 	if (e.target.value == "ON") {
	// 		val = "OFF";
	// 		api.marketing.checkwebhooksZapApp({
	// 			bid: this.props.bid,
	// 			user_id: this.props.userdata.user_id,
	// 			val_on_off:val
	// 		})
	// 		.then((data) => {
	// 				this.setState({ switchValue: val, checkedOnOFF: "" });
	// 		});
	// 	} else {
	// 		val = "ON";
	// 		//alert('Hi');
	// 		let modalContactViaObjectZapierAppMsg = {
	// 			showMsg: true,
	// 			title: "Add contact via zapier (App)",
	// 			loading: false,
	// 		};
	// 		api.marketing
	// 			.checkwebhooksZapApp({
	// 				bid: this.props.bid,
	// 				user_id: this.props.userdata.user_id,
	// 				val_on_off: val,
	// 			})
	// 			.then((data) => {
	// 				this.setState({
	// 					switchValue: val,
	// 					checkedOnOFF: "checked",
	// 					modalContactViaObjectZapierAppMsg: modalContactViaObjectZapierAppMsg,
	// 				});
	// 			});
	// 	}
	// };
	
	changeSwitchApp = (e) => {
		let val = "";
		if (e.target.value == "ON") {
			val = "OFF";
			this.setState({ switchValue: val, checkedOnOFF: "" });
		} else {
			val = "ON";
			this.setState({
				switchValue: val,
				checkedOnOFF: "checked",
			});
		}
	};


	/** by hilal */
	openContactAddEdit = (opt) => {
		this.setState({ loading: true });
		var submitBtnName = "Save";
		if (opt == "csv") var submitBtnName = "Submit";
		api.marketing
			.getContactLabel({
				bid: this.props.bid,
				user_id: this.props.userdata.user_id,
			})
			.then((data) => {
				this.modalCloseContactViaBox();
				this.setState({
					modalstatusAddEdit: true,
					modaltitle: "Add Contact",
					modalType: "Add",
					modaldata: { opt: opt, submitBtnName: submitBtnName },
					selectedOptions: [],
					currentContactLabelData: data.data,
					loading: false,
				});
				if (opt == "static")
					document.getElementById("AddEditContactFormModal").reset();
			});
		// this.setState({ AddContactRadioStateinitially: opt });
	};

	/** by Rohit */
	openContactAddEditZapier = (opt) => {
		this.setState({ loading: true });
		var submitBtnName = "Save";
		if (opt == "csv") var submitBtnName = "Submit";
		api.marketing
			.getContactLabel({
				bid: this.props.bid,
				user_id: this.props.userdata.user_id,
			})
			.then((data) => {
				this.modalCloseContactViaBoxZapier();
				this.setState({
					modalstatusAddEdit: true,
					modaltitle: "Add Contact",
					modalType: "Add",
					modaldata: { opt: opt, submitBtnName: submitBtnName },
					selectedOptions: [],
					currentContactLabelData: data.data,
					loading: false,
				});
				if (opt == "static")
					document.getElementById("AddEditContactFormModal").reset();
			});
	};

	showUserToaster = (color, msg) => {
		this.setState({
			userToaster: true,
			userToasterMsg: msg,
			userToasterColor: color,
		});
		setTimeout(
			function () {
				this.setState({ userToaster: false });
			}.bind(this),
			5000
		);
	};

	showCustomToaster = (color, msg) => {
		this.setState({
			userToaster: true,
			userToasterMsg: msg,
			userToasterColor: color,
		});
	};

	onValidSubmitCSV = (e) => {
		this.loadData({});

		this.setState({
			modalstatusAddEdit: false,
		});

		if (e.failStatus == false) {
			this.showUserToaster("green", "CSV User has been added successfully.");
			utilFunc.scrollTop(800);
		} else if (e.status == false && e.message == "limit") {
			this.showUserToaster("red", e.data);
			utilFunc.scrollTop(800);
		} else {
			if (e.failArray.length) {
				this.setState({
					modalstatusCSVfailure: true,
					modaltitle: "CSV upload error",
					modalmessage: "Are you sure you want to delete?",
					modalbuttonmsg: { submit: "Yes", cancel: "No" },
					modalType: "Details",
					csvFailArr: e.failArray,
				});
			}
		}
	};
	modalCloseCSVError = (e) => {
		this.setState({
			modalstatusCSVfailure: false,
		});
	};

	associateCampaigns = () => {
		this.setState({ loading: true });
		api.marketing
			.getassociateCampaigns({ bid: this.props.bid })
			.then((data) => {
				console.log(data);
				try {
					// this.setState({loading:false,detail_data:data.data});
					this.setState(
						{
							modaltitle: "Associated Campaigns",
							loading: false,
							campaign_data: data.data.list,
						},
						function () {
							this.setState({ modalcompaignstatus: true });
						}
					);
				} catch (err) {
					console.log(err);
				}
			});
	};

	checkboxChangeEvent = (cid) => {
		let datalist = this.state.lists;
		let recordlist = datalist.findIndex((list) => list.cid === cid);

		if (datalist[recordlist]["checked"]) {
			datalist[recordlist]["checked"] = false;
		} else {
			datalist[recordlist]["checked"] = true;
		}

		this.setState({
			datatableRows: datalist.map((list, index) => (
				<DatatableRow
					key={index}
					list={list}
					userdata={this.props.userdata}
					editContact={this.editContact}
					deleteContact={this.deleteContact}
					viewContactDetail={this.viewContactDetail}
					ResendDeviceKey={this.ResendDeviceKey}
					preOpensetting={this.preOpensetting}
					channgeEvent={this.checkboxChangeEvent}
					seeSubscribeStatus={this.seeSubscribeStatus}
					type={this.props.type}
				/>
			)),
			lists: datalist,
		});
	};

	checkAll = (e) => {
		let datalist = this.state.lists;
		datalist.map(function (list, index) {
			list["checked"] = e.target.checked;
		});
		this.setState({
			datatableRows: datalist.map((list, index) => (
				<DatatableRow
					key={index}
					list={list}
					userdata={this.props.userdata}
					editContact={this.editContact}
					deleteContact={this.deleteContact}
					viewContactDetail={this.viewContactDetail}
					ResendDeviceKey={this.ResendDeviceKey}
					preOpensetting={this.preOpensetting}
					channgeEvent={this.checkboxChangeEvent}
					seeSubscribeStatus={this.seeSubscribeStatus}
					type={this.props.type}
				/>
			)),
			lists: datalist,
		});
	};

	subscribeCandidate = (e) => {
		let list = this.state.lists;
		let selected_arr = [];
		list.filter(function (key) {
			if (key["checked"] == true) {
				selected_arr.push(key["cid"]);
			}
		});

		this.setSubscribeUnSubscribe(
			"S",
			selected_arr,
			"Subscribe Emails",
			"Subscribe"
		);
	};

	unSubscribeCandidate = (e) => {
		let list = this.state.lists;
		let selected_arr = [];
		list.filter(function (key) {
			if (key["checked"] == true) {
				selected_arr.push(key["cid"]);
			}
		});
		this.setSubscribeUnSubscribe(
			"US",
			selected_arr,
			"Unsubscribe Email",
			"Unsubscribe"
		);
	};

	setSubscribeUnSubscribe = (type, selected_arr, title, btTitle) => {
		this.setState({
			currentChecked: [],
		});
		if (selected_arr.length == 0) {
			this.setState({
				selectAtleastOne: true,
			});
			var ref = this;
			setTimeout(function () {
				ref.setState({
					selectAtleastOne: false,
				});
			}, 5000);
		} else {
			var params = {};
			params["cids"] = JSON.stringify(selected_arr);
			params["type"] = type;
			this.setState({
				subscribeUnSubscribeObj: params,
				modalstatusSubscribeUnSubscribe: true,
				modalSubscribeUnSubscribeTitle: title,
				modalSubscribeUnSubscribeBtTitle: btTitle,
				selectAtleastOneInPopup: "none",
			});
			// api.marketing.subscribeUnSubscribe(params).then((data) => {
		}
		// });
	};

	modalSubscribeUnSubscibeClose = (e) => {
		this.setState({
			modalstatusSubscribeUnSubscribe: false,
		});
	};
	
	setCurrentCheck = (checked) => {
		this.setState({
			currentChecked: checked,
			selectAtleastOneInPopup: "none",
		});
	};

	onValidSubscribeUnSubscribeSubmit = (e) => {
		if (e.domains.length > 0) {
			var params = {};
			params["bid"] = this.props.bid;
			params["domains"] = JSON.stringify(e.domains);
			params["cids"] = this.state.subscribeUnSubscribeObj.cids;
			params["type"] = this.state.subscribeUnSubscribeObj.type;
			this.setState({
				loading: true,
				modalstatusSubscribeUnSubscribe: false,
			});

			api.marketing.subscribeUnSubscribe(params).then((data) => {
				if (this.state.subscribeUnSubscribeObj.type == "S")
					var message = "Emails subscribed successfully.";
				else var message = "Emails unsubscribed successfully.";

				this.setState({
					subscribedUnSubscribedMsgOpen: true,
					subscribedUnSubscribedMsg: message,
				});
				this.loadData({});

				var ref = this;
				setTimeout(function () {
					ref.setState({
						subscribedUnSubscribedMsgOpen: false,
						subscribedUnSubscribedMsg: "",
					});
				}, 5000);
			});
		} else {
			this.setState({
				selectAtleastOneInPopup: "block",
			});
		}
	};

	render() {
		return (
			<Segment style={{ width: "100%", maxWidth: "1280px" }}>
				<Message
					color={
						this.state.userToasterColor ? this.state.userToasterColor : "green"
					}
					style={{
						display: this.state.userToaster ? "block" : "none",
						marginTop: 30,
					}}
				>
					{this.state.userToasterMsg}
				</Message>
				{this.state.loading && <div className="ui loading form"></div>}
				<DatatableFilter
					filter={this.state.q}
					totalCount={this.state.totalCount}
					onSubmitFilter={this.onSubmitFilter}
					onSubmit_is_subscribed={this.onSubmit_is_subscribed}
					onSubmit_Source={this.onSubmit_Source}
					addContact={this.addContact}
					addContactZapier={this.addContactZapier} // added by rohit
					loading={this.state.loading}
					csvdata={this.state.csvdata}
					downloadCSV={this.downloadCSV}
					associateCampaigns={this.associateCampaigns}
					selectAtleastOne={this.state.selectAtleastOne}
					subscribedUnSubscribedMsgOpen={
						this.state.subscribedUnSubscribedMsgOpen
					}
					subscribedUnSubscribedMsg={this.state.subscribedUnSubscribedMsg}
					subscribeCandidate={this.subscribeCandidate}
					unSubscribeCandidate={this.unSubscribeCandidate}
					type={this.props.type}
					lists={this.state.lists}
				/>
				<DatatableTable
					lists={this.state.lists}
					datatableHeader={(this.props.type == 1 || this.props.type == 4 ||this.props.type == 5 ||this.props.type == 7 ) ? tableHeader : this.props.type == 3 ? tableHeaderSms : tableHeader2}
					datatableRows={this.state.datatableRows}
					totalCount={this.state.totalCount}
					totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
					currentPage={this.state._page}
					onChangePage={this.onChangePage}
					column={this.state._sort}
					direction={DatatableList.directionConverter(this.state._order)}
					handleSort={this.handleSort}
					onChangeLimit={this.onChangeLimit}
					limit={this.state._limit.toString()}
					checkAll={this.checkAll}
					type={this.props.type}
				/>
				<DialogBoxDetail
					show={this.state.modalstatus}
					title={this.state.modaltitle}
					message={this.state.modalmessage}
					modalType={this.state.modalType}
					modalClose={this.modalClose}
					buttonmsg={this.state.modalbuttonmsg}
					modalbuttofun={this.modalbuttofun}
					loading={this.state.loading}
				/>
				<DatatableDeleteBox
					show={this.state.modalstatusDelete}
					title={this.state.modaltitle}
					message={this.state.modalmessage}
					modalType={this.state.modalType}
					modalCloseDeleteBox={this.modalCloseDeleteBox}
					buttonmsg={this.state.modalbuttonmsg}
					modalbuttofun={this.modalbuttofun}
					loading={this.state.loading}
					modalFormLoader={this.state.modalFormLoader}
				/>
				<CSVLink
					id="chatcsv"
					data={this.state.csvdata}
					filename="chat.csv"
					className="hidden"
					target="_blank"
				/>
				<DialogBoxAddEdit
					obj={this.state}
					type={this.props.type}
					modalClose={this.modalClose}
					onValidSubmit={this.onValidSubmit}
					onValidSubmit2={this.onValidSubmit2}
					onValidSubmitCSV={this.onValidSubmitCSV}
					handleCheck={this.handleCheck}
					checkEmail={this.checkEmail}
					checkPhone={this.checkPhone}
					checkPhoneSms={this.checkPhoneSms}
					handleDrop={this.handleDrop}
					handleDropSms={this.handleDropSms}
					onImageLoaded={this.onImageLoaded}
					onCropComplete={this.onCropComplete}
					onCropChange={this.onCropChange}
					handleFile={this.handleFile}
					getCroppedImg={this.getCroppedImg}
					handleRemovePic={this.handleRemovePic}
					handleRemovePicAdd={this.handleRemovePicAdd}
					userdata={this.props.userdata}
					defaultCountryCodeArr={this.props.userdata.defaultCountryCodeArr}
					remailCountryCodeArr={this.props.userdata.remailCountryCodeArr}
					bid={this.props.bid}
					checkNumberFormat={this.checkPhoneFormat}
					phoneNumberValue={this.state.phoneNumberValue}
				/>

				<DialogContactDetail
					show={this.state.modaldetailstatus}
					title={this.state.modaltitle}
					detail_data={this.state.detail_data}
					modalClose={this.modalCloseContactBox}
					loading={this.state.loading}
				/>

				<DialogBoxContactVia
					obj={this.state}
					modalCloseContactViaBox={this.modalCloseContactViaBox}
					openContactAddEdit={this.openContactAddEdit}
					addContactZapier={this.addContactZapier}
					addContactViaZapApp={this.addContactViaZapApp}
					isSt={this.state.stFlag}
					bucketSource={this.props.bucket_source}
				/>
				{/* Added by rohit */}

				<DialogBoxContactViaZapier
					obj={this.state}
					modalCloseContactViaZapier={this.modalCloseContactViaZapier}
					changeSwitch={this.changeSwitch}
					saveWebhooks={this.saveWebhooks}
					onChangesWebhooks={this.onChangesWebhooks}
					webhooks_title={this.state.webhooks_title}
					switchValue={this.state.switchValue}
					checkedOnOFF={this.state.checkedOnOFF}
					webhooks_url={this.state.webhooks_url}
					webhooks_id={this.state.webhooks_id}
				/>
				<DialogBoxContactViaZapierApp
					obj={this.state}
					modalCloseContactViaZapierApp={this.modalCloseContactViaZapierApp}
					modalOpenContactViaZapierApp = {this.modalOpenContactViaZapierApp}
					changeSwitchApp={this.changeSwitchApp}
					// saveZapierAppData={this.saveZapierAppData}
					// onChangesWebhooks={this.onChangesWebhooks}
					switchValue={this.state.switchValue}
					checkedOnOFF={this.state.checkedOnOFF}
					// webhooks_url={this.state.webhooks_url}
					// webhooks_id={this.state.webhooks_id}
				/>
				<DialogBoxContactViaZapierMsg
					obj={this.state}
					modalCloseContactViaZapierMsg={this.modalCloseContactViaZapierMsg}
					offAginMsg={this.offAginMsg}
				/>
				<DialogBoxContactViaZapierAppMsg
					obj={this.state}
					saveZapierAppData={this.saveZapierAppData}
					offAgainAppMsg={this.offAgainAppMsg}
				/>
				{/* Added by end */}

				<DialogBoxDetailOnCSVUploadError
					obj={this.state}
					show={this.state.modalstatusCSVfailure}
					title={this.state.modaltitle}
					message={this.state.modalmessage}
					modalType={this.state.modalType}
					modalCloseCSVError={this.modalCloseCSVError}
					buttonmsg={this.state.modalbuttonmsg}
					modalbuttofun={this.modalbuttofun}
					loading={this.state.loading}
				/>

				<DialogBoxAssocCompaigns
					show_detail={this.state.modalcompaignstatus}
					title={this.state.modaltitle}
					detail_data={this.state.detail_data}
					modalClose={this.modalCloseContactBox}
					loading={this.state.loading}
					campaign_data={this.state.campaign_data}
				/>
				<SubscribeStatus
					modalShow={this.state.emailModalStatus}
					dataList={this.state.emailList}
					emailAddress={this.state.selectedEmail}
					closeModal={this.closeEmailModal}
				></SubscribeStatus>

				<SubscribeUnSubscribe
					obj={this.state}
					modalSubscribeUnSubscibeClose={this.modalSubscribeUnSubscibeClose}
					onValidSubmit={this.onValidSubscribeUnSubscribeSubmit}
					currentChecked={this.state.currentChecked}
					currentExpanded={this.state.currentExpanded}
					setCurrentCheck={this.setCurrentCheck}
					setCurrentExpand={this.setCurrentExpand}
					selectAtleastOneInPopup={this.state.selectAtleastOneInPopup}
				/>
			</Segment>
		);
	}
}


export default withRouter(DatatableList)