import React, { useEffect } from 'react'
import { connect } from "react-redux";
import {
    setPageTitle,
    toggleNavigation,
    setBreadCrum,
    setMessage,
    toggleBreadcrum

} from "../../../store/Page";
import Instructions from '../../Integration/Instructions';

const ApiInstruction = (props) => {
    useEffect(() => {
        console.log("api in", props);
        const {
            setPageTitle,
            toggleNavigation,
            location,
            PageMessage,
            setMessage,
            setBreadCrum,
            toggleBreadcrum
        } = props;
        setPageTitle({ title: "Settings", subHeaderTitle: "Settings" });
        setBreadCrum({ breadcrum: null });

        toggleNavigation({ toggle: false });
        toggleBreadcrum({ toggle: false });
        return () => { }
    }, [])

    console.log("d sdfsa".props);
    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content kt-grid__item kt-grid__item--fluid">
                <div className="yr-body-p">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div>
                                <div className="kt-portlet shadow-none">
                                    <div className="kt-portlet__body yr-pd0-lr yr-mt-20 yr-pd0-mob">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="box-title mx-lg-3">
                                                    API Instructions
                                                </div>
                                                <div className="tab-content">
                                                    <Instructions />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        PageMessage: state.page.message,
        user: state.user.company,
    };
};
export default connect(mapStateToProps, {
    setPageTitle,
    toggleNavigation,
    setBreadCrum,
    setMessage,
    toggleBreadcrum
})(ApiInstruction);