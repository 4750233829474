import React from 'react'
import DatatableList from './SendingQueueTable/DatatableList'
export default class SendingQueue extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount =()=>{
        
    }

    render(){
        //console.log('this.props 3', this.props.playbook_id);
        return(
            <DatatableList 
            playbook_id={this.props.playbook_id} 
            branch_id={this.props.branch_id} 
            template_type={this.props.template_type}
            playbookStatus = {this.props.playbookStatus}>
            </DatatableList>
        )
    }
}