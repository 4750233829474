import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
    toggleBreadcrum
} from "../../../store/Page";


const Voice = lazy(() => import('../VoiceDashboard/VoiceDashboard'));
const Chat = lazy(() => import('../ChatDashboard/ChatDashboard'));

const Reporting = (props) => {
    const dispatch = useDispatch();
    const PageMessage = useSelector((state) => state.page.message);

    const [activeTab, setActiveTab] = useState('voice');

    useEffect(() => {
        dispatch(setPageTitle({ title: "Reporting", subHeaderTitle: 'Conversations' }));
        dispatch(toggleBreadcrum({ toggle: false }));
        dispatch(toggleNavigation({ toggle: true }));
        dispatch(setBreadCrum({ breadcrum: null }));
    }, [dispatch]);

    return (
        <div className="kt-container kt-body  kt-grid kt-grid--ver chat__dashboard" id="kt_body">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-content kt-grid__item kt-grid__item--fluid">
                    <div className="yr-body-p yr-mt-20">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div class="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-toolbar">
                                            <ul
                                                className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                                                role="tablist"
                                            >
                                                <li className="nav-item">
                                                    <a
                                                        className={`nav-link ${activeTab === 'voice' ? 'active' : ''}`}
                                                        data-toggle="tab"
                                                        role="tab"
                                                        onClick={() => setActiveTab('voice')}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        Voice
                                                    </a>
                                                </li>


                                                <li className="nav-item">
                                                    <a
                                                        className={`nav-link ${activeTab === 'chat' ? '' : ''}`}
                                                        data-toggle="tab"
                                                        style={{ cursor: "pointer" }}
                                                        role="tab"
                                                        onClick={() => setActiveTab('chat')}
                                                    >
                                                        Chat
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__body kt-portlet__body--fit">
                                    <div className="tab-content">
                                        <Suspense fallback={<div>Loading...</div>}>
                                            {activeTab === 'voice' && <Voice />}
                                            {activeTab === 'chat' && <Chat />}
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reporting