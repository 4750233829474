import React from "react";
import {
	Form,Dropdown
} from 'formsy-semantic-ui-react';
import {Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
validationRule.isRequired();  
validationRule.minCustomLength(6); 

const DialogBoxAddEdit = ({ obj, modalClose, onValidSubmit }) => {
	let show = obj.modalstatusAddEdit;
	let title = obj.modaltitle;
	let modalErrorMsg = obj.modalErrorMsg;
	let modaldata = obj.modaldata;
	let modalFormLoader = obj.modalFormLoader;
	let selectedOption = obj.selectedOptions;
	let options = [];
	
	const errorLabel = <div className="red"/>
	

	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" ,zIndex: 99999999}}
			>
				<div className="modal-dialog modal-dialog-centered"  style={{maxWidth:"590px",maxHeight:"294px"}} role="document">
					<div className="modal-content" >
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, 'formManageQuickText')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<Form  noValidate autoComplete="off" id="formManageQuickText" onValidSubmit={onValidSubmit}>
						    {modalFormLoader && <div className="ui loading form"></div>}
							<div className="modal-body" >
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first">
										<Message color='red' style={{ display: modalErrorMsg ? "block" : "none" }}>This email-id is already exists.</Message>
										<div className="kt-section__body">
											<div className="form-group row">
												<label className="col-lg-4 col-form-label">Title :</label>
												<div className="col-lg-8">
													<Form.Input fluid autoComplete="off" name="title" value={modaldata.quick_text_title} placeholder='Title'
														validations='isRequired' maxLength="50" validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel} />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-lg-4 col-form-label">Message  :</label>
												<div className="col-lg-8">
													<Form.Input fluid autoComplete="off" name="message" value={modaldata.quick_text_desc} placeholder='Message'
														validations='isRequired' validationErrors={{isRequired: 'This field is required.'}} errorLabel={errorLabel} />
												</div>
											</div>
											
										</div>
									</div></div></div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<Form.Input name="subaccount_id" type="hidden" value={modaldata.id} />
											<button type="reset" onClick={() => modalClose(false, 'formManageQuickText')} className="btn linear-gradient-cancel  yr-cancelbtn mg-r"><span> Cancel </span></button>
											<button type="submit" className="btn linear-gradient yr-submitbtn">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</Form>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxAddEdit;
