import React, { Component } from "react";
import api from "../../../api";
import { Link, Redirect } from "react-router-dom";
import config from "../../../config/";
import { Card, Message, Image } from "semantic-ui-react";
import { Form, Dropdown, TextArea, Input } from "formsy-semantic-ui-react";
import "./google-review.css";
import AutoWorkFlow from "./AutoWorkFlow";
import util_funs from "../../../util_funs";
class WorkFlow extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      accessToken: "",
      rm_update_text: "",
      rm_update_id: "",
      loading: true,
      rm_save_msg_code: "",
      rm_save_text: "",
      rm_del_msg_code: 0,
      tempVal:""
    };
  }
 
  createWorkflows = (action, rm_data, data = null) => {
    //alert(action);
    if (action == "Close") {
      this.setState({
        dialogTitle: "",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: false,

        errors: {},
        selectedValue: [],
        checkedValue: [],
        actionId: "",
      });
    }

    if (action == "Add") {
      this.setState({
        dialogTitle: "Add",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: true,

        errors: {},
        tempVal:""
      });
    }
    if (action == "Edit") {
      this.setState({
        dialogTitle: "Edit",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: true,
        errors: {},
        tempVal:""
      });
    }
    if (action == "Delete") {
      this.setState({
        dialogTitle: "Delete",
        dialogMessage: "Are you sure you want to delete it?",
        dialogLoading: false,
        dialogShow: true,

        errors: {},
        selectedValue: [],
        checkedValue: [],
      });
    }
  };
  rmupdateMessageErr(msgrm) {
    this.setState({
      rm_msg_err: msgrm,
    });
    setTimeout(
      function () {
        this.setState({ rm_msg_err: false });
      }.bind(this),
      5000
    );
  }
  nextWorkflows(reviews_type, rm_id) {
    //alert(reviews_type);
    let text_title = document.getElementById("rm_text_title").value;

    if (text_title == "") {
      ///alert(text_title);
      this.rmupdateMessageErr("This field is required");
    } else {
      //alert(text_title);
      api.reviews
        .addManuallyreview({
          text_tittle: text_title,
          reviews_type: reviews_type,
          type: "M",
          status: "1",
          rm_id: rm_id,
          tempVal:this.state.tempVal,
        })
        .then((response) => {
          try {
            if (reviews_type == 1) {
              this.createWorkflows("Close");
              this.setState({
                rm_dmsg: reviews_type,
                rm_save_msg_code: 4,
                rm_del_msg_code: 0,
                rm_save_text: text_title,
                rm_update_text: text_title,
                rm_update_id: response.id,
              });
              setTimeout(
                function () {
                  this.setState({ rm_dmsg: false, rm_save_msg_code: false });
                }.bind(this),
                5000
              );
            } else {
              this.createWorkflows("Close");
              this.setState({
                rm_msg: reviews_type,
                rm_save_msg_code: 5,
                rm_update_msg: "Updated successfully.",
                rm_update_text: text_title,
                rm_update_id: rm_id,
              });
              setTimeout(
                function () {
                  this.setState({
                    rm_update_msg: false,
                    rm_dmsg: false,
                    rm_save_msg_code: false,
                  });
                }.bind(this),
                5000
              );
            }
          } catch (err) {
            console.log(err);
          }
        });
    }
  }

  deleteWorkflows(rm_id) {
    api.reviews.deleteManuallyreview({ rm_id: rm_id }).then((response) => {
      try {
        this.createWorkflows("Close");
        this.setState({
          rm_del_msg: 3,
          rm_del_msg_code: 1,
          rm_update_text: "",
          rm_update_id: "",
        });
        setTimeout(
          function () {
            this.setState({
              rm_del_msg: false,
              dialogMessage: false,
              rm_update_id: false,
            });
          }.bind(this),
          3000
        );
      } catch (err) {
        console.log(err);
      }
    });
  }
  render() {
    const errorLabel = <div className="red" />;
    const { errors } = this.state; 
    return (
      <div>

        <div
          className="kt-container kt-body  kt-grid kt-grid--ver completedchatmain"
          id="kt_body"
          style={{ marginLeft: "70px", marginRight: "70px" }}
        >
          
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"></div>

          <div
            className={this.state.dialogShow ? "modal fade show" : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: this.state.dialogShow ? "block" : "none" }}
          >
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              style={{ maxWidth: 600 }}
            >
              {this.state.dialogLoading && (
                <div className="ui loading form"></div>
              )}
              <div className="modal-content">
                <button
                  type="button"
                  className="close linear-gradient"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => this.createWorkflows("Close")}
                >
                  X
                </button>
                <div className="modal-header">
                  <h5 className="modal-title">
                    {this.state.dialogTitle == "Add"
                      ? "Add Title"
                      : this.state.dialogTitle == "Edit"
                      ? "Edit Title"
                      : this.state.dialogTitle == "Delete"
                      ? "Confirm your action"
                      : "Alert"}
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="px-4">
                    {this.state.dialogTitle == "Edit" ? (
                      <div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Title :
                          </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div className="ui fluid input">
                                {
                                  //Check if message failed
                                  this.state.rm_update_text != "" ? (
                                    <input
                                      autocomplete="off"
                                      name=""
                                      id="rm_text_title"
                                      placeholder="Title"
                                      type=""
                                      defaultValue={this.state.rm_update_text}
                                    />
                                  ) : (
                                    <input
                                      autocomplete="off"
                                      name=""
                                      id="rm_text_title"
                                      placeholder="Title"
                                      type=""
                                      defaultValue={this.props.mrw_tittle}
                                    />
                                  )
                                }
                              </div>
                              {
                                //Check if message failed
                                this.state.rm_msg_err ==
                                "This field is required" ? (
                                  <div className="red">
                                    {this.state.rm_msg_err}
                                  </div>
                                ) : (
                                  <div></div>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      this.state.dialogMessage
                    )}
                    {this.state.dialogTitle == "Add" ? (
                      <div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Title :
                          </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div className="ui fluid input">
                                <input
                                  autocomplete="off"
                                  name=""
                                  id="rm_text_title"
                                  placeholder="Title"
                                  type=""
                                  defaultValue=""
                                />
                              </div>
                              {
                                //Check if message failed
                                this.state.rm_msg_err ==
                                "This field is required" ? (
                                  <div className="red">
                                    {this.state.rm_msg_err}
                                  </div>
                                ) : (
                                  <div></div>
                                )
                              }
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                          Choose template:
                          </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div className="ui fluid input">
                              <select 
                                  name = "tempVal"
                                  className={
                                    errors && errors["Branch"]
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  value={this.state.tempVal}
                                  onChange={(e) =>  this.setState({ 'tempVal': e.target.value })}
                                >
                                  <option value='0'>Select</option>
                                  {this.myRef.current!=null && this.myRef.current.state.defaultTemplate != "" &&
                                    typeof this.myRef.current.state.defaultTemplate != "undefined" &&
                                    this.myRef.current.state.defaultTemplate.map((obj, index) => {
                                      return (<option value={obj.mrw_id}>{obj.mrw_tittle}</option>)
                                    })
                                    } 
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                  </div>
                </div>
                {this.state.dialogTitle == "Add" ? (
                  <div className="modal-footer">
                   <div className="col-md-12" style={{ textAlign:"right", marginRight:6 }}>
                    <button
                      type="button"
                      className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                      onClick={(e) => this.createWorkflows("Close")}
                      data-dismiss="modal"
                    >
                      <span>Cancel</span>
                    </button>&nbsp;
                    <button
                      type="button"
                      className="btn linear-gradient yr-submitbtn btn-sm"
                      onClick={(e) => this.nextWorkflows(1)}
                    >
                      Save
                    </button>
                  </div>
                </div>
                ) : this.state.dialogTitle == "Edit" ? (
                  <div className="modal-footer">
                  <div className="col-md-12" style={{ textAlign:"right", marginRight:6 }}>
                    <button
                      type="button"
                      className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                      onClick={(e) => this.createWorkflows("Close")}
                      data-dismiss="modal"
                    >
                      <span>Cancel</span>
                    </button>&nbsp;
                    {this.state.rm_update_id != "" ? (
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) =>
                          this.nextWorkflows(0, this.state.rm_update_id)
                        }
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) =>
                          this.nextWorkflows(0, this.props.mrw_id)
                        }
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
                ) : this.state.dialogTitle == "Delete" ? (
                  <div className="modal-footer">
                  <div className="col-md-12" style={{ textAlign:"right", marginRight:6 }}>
                    <button
                      type="button"
                      className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                      onClick={(e) => this.createWorkflows("Close")}
                      data-dismiss="modal"
                    >
                      <span>No</span>
                    </button>&nbsp;

                    {this.state.rm_update_id != "" ? (
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) =>
                          this.deleteWorkflows(this.state.rm_update_id)
                        }
                      >
                        Yes
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) => this.deleteWorkflows(this.props.mrw_id)}
                      >
                        Yes
                      </button>
                    )}
                  </div>
                </div>
                ) : (
                  <div className="modal-footer">
                  <div className="col-md-12" style={{ textAlign:"right", marginRight:6 }}>
                    <button
                      type="button"
                      className="btn linear-gradient yr-submitbtn btn-sm"
                      onClick={(e) => this.createWorkflows("Close")}
                    >
                      Ok
                    </button>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <p>Workflows triggered by manually sending a review request</p>
        {/* Table Start */}
        {
          //Check if message failed
          this.state.rm_del_msg == 3 ? (
            <div className="col-lg-12 ui green message del-msg-padding">
              Title deleted successfully.
            </div>
          ) : (
            <div></div>
          )
        }
        {
          //Check if message failed
          this.state.rm_save_msg_code == 4 ? (
            <div className="col-lg-12 ui green message del-msg-padding">
              Title added successfully.
            </div>
          ) : (
            <div></div>
          )
        }

        {
          //Check if message failed
          this.state.rm_save_msg_code == 5 ? (
            <div className="col-lg-12 ui green message del-msg-padding">
              Title updated successfully.
            </div>
          ) : (
            <div></div>
          )
        }

        {
          //Check if message failed
          (this.props.mrw_tittle != "" || this.state.rm_save_text != "") &&
          this.state.rm_del_msg_code != 1 ? (
            <table class="ui celled selectable sortable table table-striped- table-hover team-member-list dataTable no-footer">
              <thead class="">
                <tr class="">
                  <th class="0 wide">Actions</th>
                  <th class="0 wide">Title</th>
                  <th class="0 wide">Status</th>
                </tr>
              </thead>
              <tbody class=""></tbody>
              <tfoot class="">
                <tr class="">
                  {
                    //Check if message failed
                    this.state.rm_update_id != "" ? (
                      <th class="">
                        <span>
                          <a
                            className="btn btn-icon"
                            title="Edit"
                            onClick={() =>
                              this.createWorkflows(
                                "Edit",
                                this.state.rm_update_id
                              )
                            }
                          >
                            <i className="la la-edit blue"></i>
                          </a>
                          <a
                            title="Delete"
                            className="btn btn-icon gray"
                            onClick={() =>
                              this.createWorkflows(
                                "Delete",
                                this.state.rm_update_id
                              )
                            }
                          >
                            <i className="la la-trash red"></i>
                          </a>
                        </span>
                      </th>
                    ) : (
                      <th class="">
                        <span>
                          <a
                            className="btn btn-icon"
                            title="Edit"
                            onClick={() =>
                              this.createWorkflows("Edit", this.props.mrw_id)
                            }
                          >
                            <i className="la la-edit blue"></i>
                          </a>
                          <a
                            title="Delete"
                            className="btn btn-icon gray"
                            onClick={() =>
                              this.createWorkflows("Delete", this.props.mrw_id)
                            }
                          >
                            <i className="la la-trash red"></i>
                          </a>
                        </span>
                      </th>
                    )
                  }

                  {
                    //Check if message failed
                    this.state.rm_update_text != "" ? (
                      <th class="">
                        <Link
                          className="blue"
                          to={
                            "/settings/manually-customization/" +
                            this.state.rm_update_id
                          }
                        >
                          {this.state.rm_update_text}
                        </Link>
                      </th>
                    ) : (
                      <th class="">
                        <Link
                          className="blue"
                          to={
                            "/settings/manually-customization/" +
                            util_funs.encrypt_url(this.props.mrw_id)
                          }
                        >
                          {this.props.mrw_tittle}
                        </Link>
                      </th>
                    )
                  }
                  <th class="">
                    <span>
                      <i class="fa fa-circle green"></i> Active
                    </span>
                  </th>
                </tr>
              </tfoot>
            </table>
          ) : (
            <a
              className="add-gradient form-group cursorpointer add-link"
              onClick={() => this.createWorkflows("Add")}
            >
              +Add New
            </a>
          )
        }
        <AutoWorkFlow ref= {this.myRef }></AutoWorkFlow>
      </div>
    );
  }
}
export default WorkFlow;
