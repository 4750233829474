import React from 'react'
import classes from './ChatWithNoReply.module.css';

const generateMask = (phoneNumberValue) => {
	phoneNumberValue = "(" + phoneNumberValue;
	let l30 = phoneNumberValue.length;
	let p30 = phoneNumberValue.substring(0, 4);
	p30 = p30 + ")";
  
	let p31 = phoneNumberValue.substring(4, l30);
	let pp = p30 + p31;
  
	let l40 = pp.length;
	let p40 = pp.substring(0, 8);
	p40 = p40 + "-";
  
	let p41 = pp.substring(8, l40);
	let ppp = p40 + p41;
	let maxphonelength = 13;
	return ppp.substring(0, maxphonelength);
  }

const getAvtar = (name) => { 
	let newa = name.match(/(\b\S)?/g).join("")
	.match(/(^\S|\S$)?/g)
	.join("").toUpperCase();
	console.log(newa.length);
	if (newa.length === 1) {
		newa += name.slice(-1).toUpperCase();
		console.log(name.slice(-1));
		console.log(newa);
	}
	return newa;
}

const ChatWithNoReply = (props) => {
	return (
		<div className={classes.chatwithnoreply_container}>
			{props.allTextSendList?.length > 0 ?
				props.allTextSendList.map((item, index) => (

					<div key={index} className={classes.chatwithnoreply_container_item}>
						<div className={classes.chatwithnoreply_container_item_1}>
							<div className={classes.chatwithnoreply_item_avtar}>
								{item.fullname ? getAvtar(item.fullname) : 'SV'}
							</div>
							<div className={classes.chatwithnoreply_item_description}>
								<div className={classes.chatwithnoreply_item_description_name}>
									{item.fullname ? item.fullname : 'Site Visitor'}
								</div>
								<div className={classes.chatwithnoreply_item_description_number}>
									{generateMask(item.to_number)}
								</div>
							</div>
							<div className={classes.chatwithnoreply_item_messagetime}>
								{item.created_date}
							</div>
							{/* <div className={classes.chatwithnoreply_item_action}>
								<button onClick={() => props.onChnageName(item.id, item.fullname ? item.fullname : '')}> 
									<i title='Update name' style={{ color: '#5a9c12' }} class="fa fa-pencil" aria-hidden="true"></i>
								</button>
							</div> */}
						</div>
					</div>
				))
				:
				<div className={classes.chatwithnoreply_container_item}>
					<div className={classes.chatwithnoreply_container_item_1}>
						No record found
					</div>
				</div>
			}
		</div>
	)
}

export default ChatWithNoReply