import React, { useState,useEffect } from "react";
import "../../../Modal/dialog.css";

const QRModal = ({ obj, show,modalClose, loader}) => {
	console.log(loader)
    const [loading, setloading] = useState(true);
	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {/* {loading && <div className="ui loading form"></div>} */}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">Enable 2 Factor Authentication</h5>
						</div>
						
						<div className="modal-body">
                        <div class="control-label col-xs-12"> Using an authenticator app 
                <strong> <a href="https://support.google.com/accounts/answer/1066447?hl=en" target="_blank">Google Authenticator</a></strong> and scan the QR code. </div>
<br/>
            <div class="text-center">
				{loader && <div class="ui loading form"></div>}
				<img src={obj.link}  id="qrcodeimg"  alt="" class="text-center img-responsive" style={{
  marginleft: 'auto',
  marginRight: 'auto',
  position:"relative", zIndex:"999",
  width: '40%'}} /></div>
 <br/>
    <div>If you having trouble using the QR code, select manual entry on your app, and enter your email and the code:</div>
    <div class="text-center" style={{color:'#fff',backgroundColor: '#67809F',margin:'0 auto',display:'flex', alignItems:"center", justifyContent:"center",width:'220px',fontSize:'20px',height:'28px'}}><strong id="scode">{obj.code}</strong></div>
												</div>

						
						
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default QRModal;
