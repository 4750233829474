import React from "react";
import ProfileNavigation from "./ProfileNavigation";
import SupportNavigation from "./SupportNavigation";
import LiveChatNotification from "./LiveChatNotification";
import ReviewNavigation from "./ReviewNavigation";

import Comment from "./Comment";
import WorkspaceNavigation from "./WorkspaceNavigation";
import AutomatedMarketing from "./AutomatedMarketing";
const Navigation = ({ toggleNavigation, navigationName, user }) => {
  console.log("navigation name", navigationName.trim());
  function displayNavigation() {
    if (navigationName.trim() === "profile") {
      return <ProfileNavigation user={user} />;
    } else if (navigationName.trim() === "support") {
      return <SupportNavigation user={user} />;
    } else if (navigationName.trim() === "liveChat") {
      return <LiveChatNotification user={user} />;
    } else if (navigationName.trim() === "comment") {
      return <Comment user={user} />;
    } else if (navigationName.trim() === "reviewManagement") {
      return <ReviewNavigation user={user} />;
    } else if (navigationName.trim() === "workspace") {
      return <ProfileNavigation user={user} />;
    } else if (navigationName.trim() === "marketing") {
      return <AutomatedMarketing user={user} />;
    }
  }
  return toggleNavigation && displayNavigation();
};

export default Navigation;
