import React from "react";
import {
	Form, Dropdown
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import "../../../Modal/dialog.css";

const DialogBoxEmpty = ({ obj, modalClose,modalCloseEmpty, onDeleteAction }) => {
	let show = obj.modalstatusEmpty;
	let title = obj.modaltitle;
	let modalErrorMsg = obj.modalErrorMsg;
	let modaldata = obj.modaldata;
	let modalFormLoader = obj.modalFormLoader;
	let selectedOption = obj.selectedOptions;
	let options = [];
	let IdArr = obj.idArr;
	const errorLabel = <div className="red" />


	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" ,zIndex: 99999999}}
			>
				<div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "590px", maxHeight: "294px" }} role="document">
					<div className="modal-content" >
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseEmpty(false, 'formManageUser')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<Form noValidate autoComplete="off" id="formManageUser" >
							{modalFormLoader && <div className="ui loading form"></div>}
							<div className="modal-body" >
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first">
										Please select atleast one record.
									</div>
								</div>
							</div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<button type="reset" onClick={() => modalCloseEmpty(false, 'formManageUser')} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Ok </span></button>
										</div>
									</div>
								</div>
							</div>
						</Form>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxEmpty;
