import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../../store/Page";
import "./contact.css";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import DatatableList from "./dataTables/DatatableList";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      bucketType: '',
      bucketList: [],
      tab: "tab1",
      bid: "",
      subscriber: 0,
      bucket_name: "",
      contacts: 0,
      bucket_source:0
    };
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Contacts",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });

    const decodedBid = utilFunc.dcrypt_url(this.props.match.params.id)
    this.setState({ bid: decodedBid }, function () {
      this.loadData();
    });
  }
  
  componentDidUpdate(prevProps,prevState) {
    if (this.props.type !== prevProps.type) {
			this.loadData({});
		}
  }

  loadData = () => {
    api.marketing
      // .getBucketDetail({ bid: this.props.match.params.id })
      .getBucketDetail({ bid: this.state.bid })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          this.setState({
            bucket_name: data.title,
            subscriber: data.data.subscriber,
            contacts: data.data.contacts,
            bucketType: data.bucket_type || 1,
            bucket_source: data.bucket_source || 0
          });
        } catch (err) {
          console.log(err);
        }
      });

    this.setState({
      loading: false,
    });
  };

  changeDataRefresh = (e,f) => {
    this.setState({ contacts: e, subscriber: f})
  }

  render() {
    const { errors, bucket_name, subscriber, contacts } = this.state;
    console.log("props",this.state.bucketType);
    const bid = utilFunc.dcrypt_url(this.props.match.params.id)
    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div class="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact2 yr-manageUser-table">
            {this.state.loading && <div className="ui loading form"></div>}
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="yr-mt-10">
                  <div class="bck-btn kt-font-bolder kt-margin-b-20">
                    <Link to={"/marketing/contacts"} id="backtocontats">
                      <i
                        aria-hidden="true"
                        class="angle caret icon fas fa-caret-left"   
                      ></i>
                      Back to all buckets
                    </Link>
                  </div>
                  <h2>{bucket_name}</h2>
                  <p class="kt-font-bold" dangerouslySetInnerHTML={{ __html: (this.state.bucketType == 1) ? `Your audience has <span class="blue">${(contacts == undefined) ? 0 : this.state.contacts }</span> contacts. <span class="blue">${(subscriber !== undefined) ? this.state.subscriber : 0 }</span> of these are subscribers.` : `Your audience has <span class="blue">${contacts !== undefined ? contacts : 0}</span> contacts.` } }>
                    
                  </p>
                  <div class="clearfix"></div>
                </div>
                <div class="kt-portlet__body kt-margin-t-30">
                  <div class="tab-content yr-manageContacts-2">
                    <DatatableList
                      ref={(child) => {
                        this.child = child;
                      }}
                      isRefresh={this.changeDataRefresh}
                      loadData={this.loadData}
                      bid={bid}
                      type={this.state.bucketType}
                      showUserToaster={this.showToaster}
                      userdata={this.props.user}
                      bucket_source={this.state.bucket_source}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(ContactList);
