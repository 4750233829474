import React from 'react';
import { Dropdown } from "semantic-ui-react";
import config from '../../../config';

class Paging extends React.Component {
    constructor(props) {
        super(props);
    }
    changePageSize = (event, data) => {
        if (data.value > 0) {
            this.props.onPageLimitChange(data.value);
        }
    };
    render(){
        const { limit } = this.props;
        return(
            <React.Fragment>
                {limit > 0 && (
                <Dropdown
                    options={config.dataTableSettings.limitOptions}
                    defaultValue={limit.toString()}
                    defaultValue={"10"}
                    onChange={this.changePageSize}
                />
                )}
            </React.Fragment>
        )
    }
}
export default Paging;