import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
 
function createMarkup(html){
  return {__html: html};
}

export const DatatableRow = props => (
  
  <Table.Row >
    {/* <Table.Cell>
    
    <label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false}  auto_id={props.list.auto_id} onChange={()=>props.channgeEvent(props.list.auto_id)}></input>&nbsp;<span></span></label>
    
    </Table.Cell> */}
     <Table.Cell>{props.list.dateAdded ? <span className="">{props.list.dateAdded}</span> :'' }</Table.Cell>
      <Table.Cell>{props.list.wby_company ? <span className="">{props.list.wby_company}</span> :'' }</Table.Cell>
    <Table.Cell>{props.list.name ? <span className="" >{props.list.name}</span> :'' }</Table.Cell>
    <Table.Cell>{props.list.email ? <span className="" title={props.list.email}>{props.list.email}</span> :'' }</Table.Cell>
    <Table.Cell>{props.list.phone ? <span className="">{props.list.phone}</span> :'' }</Table.Cell>
    <Table.Cell>{props.list.wby_usertype ? <span className=""><div dangerouslySetInnerHTML={{__html: props.list.wby_usertype}} /></span> :'' }</Table.Cell>
    <Table.Cell>{props.list.wby_accounttype ? <span className="">{props.list.wby_accounttype}</span> :'' }</Table.Cell>
    <Table.Cell>{props.list.wfor_company ? <span className="">{props.list.wfor_company}</span> :'' }</Table.Cell>
    <Table.Cell>{props.list.module ? <span className=""><div dangerouslySetInnerHTML={{__html: props.list.module}} /></span> :'' }</Table.Cell>
    <Table.Cell>{props.list.message ? ReactHtmlParser(props.list.message) :'' }</Table.Cell>
{/* 
    <Table.Cell style={{whiteSpace: "nowrap"}}>{props.list.date_added}</Table.Cell>
    <Table.Cell style={{whiteSpace: "nowrap",width:"200px",textOverflow: "ellipsis"}}>{props.list.job_description} </Table.Cell>
    <Table.Cell style={{whiteSpace: "nowrap"}}><a className="cursorpointer des-link" onClick={()=>props.viewDetails(props.list.auto_id)}>View Details</a></Table.Cell>
    <Table.Cell style={{width: "150px"}}>{props.list.user_name}</Table.Cell> */}
   
    
    {/* <Table.Cell>{props.list.zip_code}</Table.Cell>
    <Table.Cell>{props.list.phone_number}</Table.Cell> */}
    
    
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
