import React from 'react';
import { Dropdown } from 'semantic-ui-react'
import CustomErrorField from "../../../../Form/CustomErrorField";

class AddSkills extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            aiRules: [],
            categories: [],
            subcategories: [],
            intents: [],
            errors: [],
            copy_rules_branch_id: ''


        };

    }

    componentDidMount() {
        this.loadData();

    }

    componentDidUpdate(prevProps) {
        if (this.props.indextime != prevProps.indextime) {

            this.loadData();
        }

    }

    loadData = () => {


        // alert('hi');
        // if(this.props.button_action_id=='add'){
        //     this.setState({ aiRules: [{cat_id:'',sub_cat_id:'',intent_id:'',intent_branch_id:'',intent_status:false}] });
        // }else{
        //     this.setState({ aiRules: this.props.aiRules });
        // }
        this.setState({ aiRules: this.props.aiRules, errors: [] });
        new Promise(resolve => {

            let arr = [];
            arr.push({ "key": 'tttt11', 'text': 'Select Category', "value": '' })
            this.props.intent_data.categories.map((obj, index) => {
                arr.push({ "key": index, 'text': obj.name, "value": obj.id })
            })

            let subcategories = [];
            subcategories.push({ "key": 'tttt11', 'text': 'Select Sub Categories', "value": '', "cat_id": "" })
            this.props.intent_data.subcategories.map((obj, index) => {
                subcategories.push({ "key": "subcat" + index, 'text': obj.name, "value": obj.id, "cat_id": obj.cat_id })
            })

            let intents = [];
            intents.push({ "key": 'tttt11', 'text': 'Select Intents', "value": '', "cat_id": "", "sub_cat_id": "" })
            this.props.intent_data.intents.map((obj, index) => {
                intents.push({ "key": "intent" + index, 'text': obj.name, "value": obj.id, "cat_id": obj.cat_id, "sub_cat_id": obj.sub_cat_id })
            })
            let data = {
                categories: arr,
                subcategories: subcategories,
                intents: intents
            }

            resolve(data);


        }).then((data) => {


            this.setState({ categories: data.categories, subcategories: data.subcategories, intents: data.intents }, function () {
                if (this.props.button_action_id != 'add') {
                    let rules = this.state.aiRules;
                    var subcats = []
                    var intents = []
                    console.log(data.subcategories)
                    if (rules[0].cat_id != '' && rules[0].cat_id != 0) {
                        subcats = data.subcategories.filter((list) =>
                            list.cat_id == rules[0].cat_id
                        )
                        if (rules[0].sub_cat_id != '' && rules[0].sub_cat_id != 0) {
                            intents = data.intents.filter((list) => (list.cat_id == rules[0].cat_id && list.sub_cat_id == rules[0].sub_cat_id))
                        } else {
                            intents = data.intents.filter((list) => list.cat_id == rules[0].cat_id)
                        }


                        console.log(intents)


                    } else {
                        if (rules[0].sub_cat_id != '' && rules[0].sub_cat_id != 0) {
                            intents = data.intents.filter((list) => list.sub_cat_id == rules[0].sub_cat_id)
                        } else {
                            subcats = data.subcategories
                            intents = data.intents
                        }

                    }
                    rules[0].categories = data.categories
                    rules[0].subcategories = subcats
                    rules[0].intents = intents
                    this.setState({ aiRules: rules });
                }
            });
        })



    }
    addRules = () => {
        let arr = {
            cat_id: '',
            sub_cat_id: '',
            intent_id: '',
            intent_branch_id: '',

            intent_status: false,
            ai_rule_copy: '',
        };
        let push_arr = this.state.aiRules;
        push_arr.push(arr);
        this.setState({ aiRules: push_arr });
    }
    deleteAIrules = (id) => {
        let push_arr = this.state.aiRules;
        push_arr.splice(id, 1);
        this.setState({ aiRules: push_arr });
    }
    clickSearchIntent = (index) => {

        let push_arr = this.state.aiRules;
        push_arr[index].intent_status = true;
        push_arr[index].categories = this.state.categories;
        push_arr[index].subcategories = this.state.subcategories;
        push_arr[index].intents = this.state.intents;


        this.setState({ aiRules: push_arr });

    }


    getsubCategory = (e, data) => {

        let push_arr = this.state.aiRules;

        push_arr[data.keyindex].cat_id = data.value;
        push_arr[data.keyindex].sub_cat_id = '';
        push_arr[data.keyindex].intent_id = '';

        let subcat = this.props.intent_data.subcategories;
        let res = subcat.filter((word) => word.cat_id == data.value)
        //  console.log(res)
        //  console.log("3333333",this.props.intent_data.subcategories); 
        let subcategories = [];

        subcategories.push({ "key": 'tttt11', 'text': 'Select Sub Categories', "value": '' })
        res.map((obj, index) => {
            subcategories.push({ "key": index, 'text': obj.name, "value": obj.id })
        })
        push_arr[data.keyindex].subcategories = subcategories;


        let intents_list = this.props.intent_data.intents;
        let res2 = intents_list.filter((word) => word.cat_id == data.value)

        let intents = [];
        intents.push({ "key": 'tttt11', 'text': 'Select Intents', "value": '', "cat_id": "", "sub_cat_id": "" })
        res2.map((obj, index) => {
            intents.push({ "key": index, 'text': obj.name, "value": obj.id, "cat_id": obj.cat_id, "sub_cat_id": obj.sub_cat_id })
        })
        push_arr[data.keyindex].intents = intents;
        this.setState({ aiRules: push_arr });


    }


    getIntents = (e, data) => {

        let push_arr = this.state.aiRules;

        push_arr[data.keyindex].sub_cat_id = data.value;
        push_arr[data.keyindex].intent_id = '';
        let intents_list = this.props.intent_data.intents;
        let res2 = intents_list.filter((word) => word.sub_cat_id == data.value)

        let intents = [];
        intents.push({ "key": 'tttt', 'text': 'Select Intents', "value": '' })
        res2.map((obj, index) => {
            intents.push({ "key": index, 'text': obj.name, "value": obj.id, "cat_id": obj.cat_id, "sub_cat_id": obj.sub_cat_id })
        })
        push_arr[data.keyindex].intents = intents;
        this.setState({ aiRules: push_arr });

    }

    selectIntents = (e, data) => {
        let push_arr = this.state.aiRules;
        push_arr[data.keyindex].intent_id = data.value;
        this.setState({ aiRules: push_arr });
    }

    selectIntentBranch = (e, index) => {
        let push_arr = this.state.aiRules;
        push_arr[index].intent_branch_id = e.target.value;
        console.log(push_arr);
        this.setState({ aiRules: push_arr });
    }

    saveAIRules = () => {

        console.log(this.state.aiRules);
        let aiRules = this.state.aiRules;
        let data = [];
        let errors = [];
        new Promise(resolve => {

            aiRules.forEach((element, index) => {

                let intent_err = false;
                let branch_id_error = false;
                let intent_msg = '';
                if (this.state.copy_rules_branch_id == '' || this.state.copy_rules_branch_id == null) {
                    if (element.cat_id == '' && element.sub_cat_id == '' && element.intent_id == '') {
                        intent_err = true;
                        intent_msg = 'This field is required'

                    } else {
                        if (element.intent_id > 0) {
                            let recordlist = aiRules.filter(
                                (list, typeindex) => {

                                    if (list.intent_id == element.intent_id && index != typeindex) {

                                        return true
                                    } else {
                                        return false;
                                    }
                                }
                            );

                            if (recordlist != '') {
                                // alert(recordlist);
                                intent_err = true;
                                intent_msg = 'This intent already exist';

                            }


                            let recordlistintent = this.props.aiRulesList.filter(
                                (list) => {
                                    let cat_check_id = list.intent_id == 0 ? '' : list.intent_id;

                                    if (cat_check_id == element.intent_id) {

                                        return true
                                    } else {
                                        return false;
                                    }
                                }
                            );
                            //alert(recordlistintent)
                            if (recordlistintent != '') {

                                intent_err = true;
                                intent_msg = 'This selected intent is already exist in rules';

                            }

                        } else {

                            let recordlist = aiRules.filter(
                                (list, typeindex) => {

                                    if (list.cat_id == element.cat_id && list.sub_cat_id == element.sub_cat_id && list.intent_id == element.intent_id && index != typeindex) {

                                        return true
                                    } else {
                                        return false;
                                    }
                                }
                            );

                            if (recordlist != '') {

                                intent_err = true;
                                intent_msg = 'This set od data already exist';

                            }

                            console.log(element)

                            let recordlist2 = this.props.aiRulesList.filter(
                                (list) => {
                                    let cat_check_id = list.cat_id == 0 ? '' : list.cat_id;
                                    let subcat_check_id = list.sub_cat_id == 0 ? '' : list.sub_cat_id;
                                    let intentcat_check_id = list.sub_cat_id == 0 ? '' : list.sub_cat_id;

                                    if ((cat_check_id == element.cat_id) && subcat_check_id == element.sub_cat_id && intentcat_check_id == element.intent_id) {

                                        return true
                                    } else {
                                        return false;
                                    }
                                }
                            );

                            if (recordlist2 != '') {

                                intent_err = true;
                                intent_msg = 'This selected set of data already exist in data';

                            }


                        }
                        // check for duplicate
                    }

                    if (element.intent_branch_id == '') {
                        branch_id_error = true;

                    }

                } else {
                    if (element.cat_id == '' && element.sub_cat_id == '' && element.intent_id == '' && element.intent_branch_id == '') {

                    } else {
                        if (element.cat_id == '' && element.sub_cat_id == '' && element.intent_id == '') {
                            intent_err = true;
                            intent_msg = 'This field is required'

                        } else {
                            // check for duplicate
                        }

                        if (element.intent_branch_id == '') {

                            branch_id_error = true;

                        }
                    }

                }
                 // check for existing 
                 if (intent_err != '') {
                    let err = { blank_intent: intent_msg };
                    errors[index] = err;
                }

                if (branch_id_error) {
                    if (typeof errors[index] != 'undefined') {
                        errors[index].intent_branch_id = 'This field is required';
                    } else {
                        let err = { intent_branch_id: 'This field is required' };
                        errors[index] = err;
                    }
                }




            });
            resolve(errors);


        }).then((errors) => {

            if (errors.length == 0) {
                this.setState({ loading: true })

                this.props.saveAIRules(this.state.aiRules, this.state.copy_rules_branch_id, (data_parm) => {


                    this.setState({

                        loading: false
                    });
                    this.props.popupClose()
                    let scrolltop = document.querySelector("#airules").offsetTop - document.body.scrollTop;
                    window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
                });
            } else {
                this.setState({ errors: errors })
            }
            console.log(errors)
        })


    }

    updateAIRules = () => {

        let aiRules = this.state.aiRules;
        let data = [];
        let errors = [];


        new Promise(resolve => {

            aiRules.forEach((element, index) => {

                let intent_err = false;
                let branch_id_error = false;
                let intent_msg = '';

                if (element.cat_id == '' && element.sub_cat_id == '' && element.intent_id == '') {
                    intent_err = true;
                    intent_msg = 'This field is required'

                } else {
                    if (element.intent_id > 0) {

                        let recordlistintent = this.props.aiRulesList.filter(
                            (list) => {
                                let cat_check_id = list.intent_id == 0 ? '' : list.intent_id;

                                if (cat_check_id == element.intent_id && this.props.button_action_id != list.id) {

                                    return true
                                } else {
                                    return false;
                                }
                            }
                        );
                        // alert(recordlistintent)
                        if (recordlistintent != '') {

                            intent_err = true;
                            intent_msg = 'This selected intent is already exist in rules';

                        }

                    } else {

                        let recordlist2 = this.props.aiRulesList.filter(
                            (list) => {
                                let cat_check_id = list.cat_id == 0 ? '' : list.cat_id;
                                let subcat_check_id = list.sub_cat_id == 0 ? '' : list.sub_cat_id;
                                let intentcat_check_id = list.sub_cat_id == 0 ? '' : list.sub_cat_id;

                                if ((cat_check_id == element.cat_id) && subcat_check_id == element.sub_cat_id && intentcat_check_id == element.intent_id && this.props.button_action_id != list.id) {

                                    return true
                                } else {
                                    return false;
                                }
                            }
                        );

                        if (recordlist2 != '') {

                            intent_err = true;
                            intent_msg = 'This selected set of data already exist in data';

                        }


                    }
                    // check for duplicate
                }

                if (element.intent_branch_id == '') {
                    branch_id_error = true;

                }




                // check for existing 




                if (intent_err != '') {
                    let err = { blank_intent: intent_msg };
                    errors[index] = err;
                }

                if (branch_id_error) {
                    if (typeof errors[index] != 'undefined') {
                        errors[index].intent_branch_id = 'This field is required';
                    } else {
                        let err = { intent_branch_id: 'This field is required' };
                        errors[index] = err;
                    }
                }




            });
            resolve(errors);


        }).then((errors) => {

            if (errors.length == 0) {
                this.setState({ loading: true })

                this.props.updateAIRules(this.state.aiRules, this.props.button_action_id, (data_parm) => {


                    this.setState({

                        loading: false
                    });
                    this.props.popupClose()
                });
            } else {
                this.setState({ errors: errors })
            }
            console.log(errors)
        })
    }




    copyAIRules = (e) => {

        this.setState({ copy_rules_branch_id: e.target.value });

    }


    render() {
        const { errors, loading } = this.state
        console.log(this.state)
        return (
            <div>
                <div className="yr-chat-round-box yr-closebtn">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
                    <div className="add-branch-title mb-3">
                        Add New AI Rules
                    </div>
                    {this.state.loading && <div className="ui loading form"></div>}

                    {this.state.aiRules && this.state.aiRules.map((obj, index) => {
                        return (
                            <div className="row">
                                <div className="col-md-7">
                                    If Al recognizes
                                    {!obj.intent_status &&
                                        <span style={{ marginLeft: "12px" }} className="add-link d-inline-block" onClick={() => this.clickSearchIntent(index)}>click search intents</span>
                                    }

                                    {obj.intent_status &&
                                        <div className="padtop-10">
                                            <div className="form-group  marginbt5">
                                                <Dropdown key={"cat" + index} keyindex={index} name="cat" placeholder='Select Categories' value={obj.cat_id} search selection options={typeof obj.categories != undefined ? obj.categories : ''} onChange={this.getsubCategory} />

                                            </div>
                                            <div className="form-group  marginbt5">

                                                <Dropdown key={"subcat" + index} keyindex={index} name="subcat" placeholder='Select Sub Categories' value={obj.sub_cat_id} search selection options={typeof obj.subcategories != undefined ? obj.subcategories : ''} onChange={this.getIntents} />
                                            </div>
                                            <div className="form-group  marginbt5">

                                                <Dropdown key={"subcatintenrt" + index} keyindex={index} name="intents" placeholder='Select Intents' value={obj.intent_id} search selection options={typeof obj.subcategories != undefined ? obj.intents : ''} onChange={this.selectIntents} />
                                            </div>
                                        </div>
                                    }
                                    {/* <button className="btn add-link mt-5">+Add</button> */}
                                    <div style={{ display: 'none' }} className={
                                        errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('blank_intent')
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }></div>
                                    {errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('blank_intent') && (
                                        <CustomErrorField
                                            message={errors[index]['blank_intent']}
                                            className="error invalid-feedback"
                                        />
                                    )}

                                </div>
                                <div className="col-md-5">
                                    <div className="form-group mb-0">
                                        <label>Go to Branch</label>
                                        <select className={
                                            errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('intent_branch_id')
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } onChange={(e) => this.selectIntentBranch(e, index)} value={obj.intent_branch_id}>
                                            <option value="">Select Branch</option>
                                            {this.props.branchList && this.props.branchList.map((value, type) => {
                                                return (
                                                    <option value={value.branch_id}>{value.branch_name}</option>
                                                )
                                            })}
                                        </select>


                                        {errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('intent_branch_id') && (
                                            <CustomErrorField
                                                message={errors[index]['intent_branch_id']}
                                                className="error invalid-feedback"
                                            />
                                        )}

                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between">
                                            {/* <button className="btn add-link">+Create New Branch</button> */}
                                            {index > 0 &&
                                                <button type="button" title="Delete" className="btn btn-icon btn-sm cusrsorpointer" onClick={() => this.deleteAIrules(index)}><i className="la la-trash red" /></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })}
                    {this.props.button_action_id == 'add' &&
                        <div>
                            <div className="row">
                                <div className="col-md-7">

                                    <button className="btn add-link " onClick={() => this.addRules()}>+Add</button>
                                </div>
                                <div className="col-md-5">
                                    <button className="btn add-link" onClick={() => this.props.createNewBranch('skills')}>+Create New Branch</button>
                                </div>
                            </div>

                            <div className="form-group row my-4">
                                <label className="col-md-5 col-form-label">Use Al rules from branch</label>
                                <div className="col-md-7">
                                    <select className="form-control" onChange={(e) => this.copyAIRules(e)}>
                                        <option value="">Select Branch</option>
                                        {this.props.branchList && this.props.branchList.map((value, index) => {
                                            return (
                                                <option key={index} value={value.branch_id}>{value.branch_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="reset" class="btn yr-cancelbtn linear-gradient-cancel mg-r" onClick={() => this.props.popupClose()} ><span> Close </span></button>
                        {this.props.button_action_id == 'add' ?
                            <button type="button" className="btn linear-gradient yr-submitbtn" onClick={() => this.saveAIRules()}>Save</button>
                            : <button type="button" className="btn linear-gradient yr-submitbtn" onClick={() => this.updateAIRules()}>Save</button>
                        }
                    </div>
                </div></div>
        )
    }
}


export default AddSkills;