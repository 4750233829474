import React from 'react';
import { Table } from "semantic-ui-react";

class DataRows extends React.Component {
    constructor( props ){
        super( props )
    }
    dateformat=(date)=>{
		// console.log( "engage date",date );
		let months=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		let dt=new Date(date);
		let m=months[dt.getMonth()];
		let d=dt.getDate().toString().padStart(2, "0");
		let y=dt.getFullYear();
		let hour = (dt.getHours() > 12 ) ? dt.getHours() - 12 : dt.getHours();
		let minute = dt.getMinutes();
		let ampm = ( dt.getHours() >= 12 ) ? "PM" : "AM";
		return m+" "+d+" "+y + " - " + hour.toString().padStart(2, "0") + ":"+ minute.toString().padStart(2, "0") + " "+ ampm;
    }
    displayRow = (dataRow) => {
        if (dataRow.length === 0) {
          return (
            <Table.Row>
              <Table.Cell colSpan={this.props.columnLength} textAlign="center">
                No record found.
              </Table.Cell>
            </Table.Row>
          );
        }
        return dataRow.map((row, rowIndex) => {
          return (
            <Table.Row key={rowIndex}>
                <Table.Cell>{this.props.startPoint+rowIndex}</Table.Cell>
                <Table.Cell>{row.reason}</Table.Cell>
                <Table.Cell>{this.dateformat(row.transaction_time)}</Table.Cell>
                <Table.Cell>${Math.abs(row.amount)}</Table.Cell>
            </Table.Row>
          );
        });
    };
    render(){
        const { dataRow } = this.props;
        return <Table.Body>{this.displayRow(dataRow)}</Table.Body>;
    }
}
export default DataRows;