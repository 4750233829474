import React from "react";
import { Message } from 'semantic-ui-react';
import "../../Modal/dialog.css";
import './Popup.css';
import api from "../../../api";
import 'semantic-ui-css/semantic.min.css';
import DatatableList from "./BtnLog/DatatableList";

class ButtonDetails extends React.Component {
    constructor(props) {
        // console.log(props,"props......")
        super(props)
        this.state = {
            show: props.obj.showbtnDetails,
            title: props.modaltitle,
            loading:"none",
            button_id: "",
            btndata:[],
            
        }
    }

    
    componentDidMount = () => {
        // this.setState({ loading: "block" });
		// api.chatsetup.getBtnLog({button_id:"182844"}).then(res=>{
        //     if(res.status){
        //         console.log(res.status);
        //         this.setState({ loading: "none",btndata:res.data});
        //     }
        // }).catch(err=>{
        //     console.log(err);
        //     this.setState({ loading: "none" });
        // }
        
        // );
    }
    

    

     
    notiAndMiscmodalClose = () => {
        this.setState({
            imgError: "none",
            message: "none",
            loading: "none",
            switchValue1:"",
            switchValue2:"",
        })
        this.props.soundSettingModalClose(false, 'manageNotification')
    }
    random = () => {
        return (
            Math.random()
        )
    }

    

    render() {
        const errorLabel = <div className="red" />
        return (
            // (this.props.loadNow)?
            
            <React.Fragment>
                {<div style={{ display: this.state.loading }} class="ui loading form mh-loading-custom"></div>}
                <div
                    id="notificationSetingModalPopup"
                    className={this.props.obj.showbtnDetails ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.obj.showbtnDetails ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"80%"}}  role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.props.soundSettingModalClose()}
                            >
                                X
						</button>

                        <div className="modal-header">
							<h5 className="modal-title">{`Button tracking details for`} "<quote>{this.props.obj.btnId.name}</quote>"</h5>
						</div>
                            {this.state.modalFormLoader && <div className="ui loading form"></div>}
                            <div className="modal-body">
                                <div className="row" style={{ width: 100 + '%' }}>
                               
                                    <div className="col-lg-12 yr-chat-box-row">
                                       {(this.props.obj.btnId && this.props.obj.btnId!="") && <DatatableList btn_id={this.props.obj.btnId.id} btn_name={this.props.obj.btnId.name} />}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

export default ButtonDetails;
