import axios from "../config/axios";
import config from "../config/index";
const marketing = {

  savebucket: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/savebucket`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getbucket: (params) =>
    axios
      .get(`${config.apiBaseURL2}contacts/getbucket?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addfield: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/addfield`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  savefinal: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/savefinal`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateBucketName: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/updateBucketName`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteBucketName: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/deleteBucketName`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getBucketData: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getBucketData`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  /* start pramanshu */
  addQueueContactList: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/addQueueContactList`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkEmail: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/checkEmail`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


      checkEmail1: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/checkExistingEmail`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      checkBucketName: (params) =>
    axios
      .post(`${config.apiBaseURL2}checkBucketName`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
      

  checkPhone: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/checkPhone`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkPhoneSms: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/checkPhoneSms`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getContactLists: (params) =>
    axios
      .get(`${config.apiBaseURL2}contacts/getContactLists?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  editContact: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/editContact`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteContact: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/deleteContact`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  exportContactList: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/exportContactList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getContactDetails: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getContactDetails`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getBucketDetail: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getBucketDetail`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

    getVariable: (params) =>
    axios
        .post(`${config.apiBaseURL2}contacts/getVariable`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  saveVariable: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/saveVariable`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  savePostcardVariable: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/savePostcardVariable`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addVariable: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/addVariable`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  

  savecontactZapier: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/savecontactZapier`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getwebhookZapier: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getwebhookZapier`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  
  getwebhookZapApp:(params)=>
  axios
  .post(`${config.apiBaseURL2}contacts/getwebhookZapApp`, params)
  .then((resp) => resp.data)
  .catch((err) => console.log(err)),

  checkwebhooksZapApp:(params)=>
  axios
  .post(`${config.apiBaseURL2}contacts/checkwebhooksZapApp`, params)
  .then((resp) => resp.data)
  .catch((err) => console.log(err)),

  getContactLabel: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getContactLabel`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      getEngageContactLabel: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getEngageContactLabel`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  
      addContactList: (params) =>
      axios
        .post(`${config.apiBaseURL2}contacts/addContactList`, { ...params })
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
  
  addContactListByCSV: (formData) => axios.post(`${config.apiBaseURL2}contacts/addContactListByCSV`, formData)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)), 

    addqueueListByCSV: (formData) => axios.post(`${config.apiBaseURL2}contacts/addqueueListByCSV`, formData)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)), 

  // getContactLabel: (params) =>addqueueListByCSV
  //   axios
  //     .post(`/marketing/contacts/getContactLabel`, params)
  //     .then((resp) => resp.data)
  //     .catch((err) => console.log(err)),

  getContactLabelAndDataOnEdit: (formData) =>
    axios.post(`${config.apiBaseURL2}contacts/getContactLabelAndDataOnEdit`, formData),

  //sagar start
  listPlayBook: (params) =>
    axios
      .get(
        `${config.apiBaseURL2}getPlaybooks?` + params,
        {}
      )
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  //sagar end

  playbookinfo: (params) =>
    axios
      .get(`/marketing/playbook/playbookinfo`, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Sagar Start-> api for getting Bucket List
  getBucketList: (params = {}) =>
    axios
      .post(`${config.apiBaseURL2}getBucketList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //sagar Start-> api for getting Admin Template
  getAdminTepmlates: (params = {}) =>
    axios
      .get(
        `marketing/playbook/playbookinfo`,
        params
      )
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  // addplaybook: (params) =>
  //   axios
  //     .post(`/marketing/playbook/addplaybook`, params)
  //     .then((resp) => resp.data)
  //     .catch((err) => console.log(err)),

  //sagar start
  addplaybook: (params) =>
    axios
      .post(`${config.apiBaseURL2}addPlaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => err),

  editplaybook: (params) =>
    axios
      .post(`${config.apiBaseURL2}editPlaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteplaybook: (params) =>
    axios
      .post(`${config.apiBaseURL2}deletePlaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //sagar end

  uploadPluginAssets: (params) =>
    axios
      .post(`/marketing/playbook/uploadPluginAssets`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getUploadedPluginAssets: (params) =>
    axios
      .post(`/marketing/playbook/getUploadedPluginAssets`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteUploadPluginAsset: (params) =>
    axios
      .post(`/marketing/playbook/deleteUploadPluginAsset`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateActionPlaybook: (params) =>
    axios
      .post(`${config.apiBaseURL2}updateActionPlaybook`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  // Sagar start
  duplicatePlaybook: (params) =>
    axios
      .post(
        `${config.apiBaseURL2}duplicatePlaybook`,
        params
      )
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  changePlaybookStatus: (params) =>
    axios
      .post(
        `${config.apiBaseURL2}changePlaybookStatus`,
        params
      )
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  /* End of code */

  yourCallList: (params) =>
    axios
      .get(`/marketing/Calllist/yourCallList?` + params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  exportCallList: (params) =>
    axios
      .post(`/marketing/Calllist/exportCallList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  getyourNotes: (params) =>
    axios
      .post(`/marketing/Calllist/getyourNotes`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  addYourNotes: (params) =>
    axios
      .post(`/marketing/Calllist/addYourNotes`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  getTeammembar: (params) =>
    axios
      .post(`/marketing/Calllist/getTeammembar`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit 
  updateMass: (params) =>
    axios
      .post(`/marketing/Calllist/updateMass`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  //Rohit
  getCalllistteam: (params) =>
    axios
      .post(`/marketing/Calllist/getCalllistteam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  addteam: (params) =>
    axios
      .post(`/marketing/Calllist/addteam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  deleteYourNotes: (params) =>
    axios
      .post(`/marketing/Calllist/deleteYourNotes`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  getNotesemailcontent: (params) =>
    axios
      .post(`/marketing/Calllist/getNotesemailcontent`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  assignTeammembar: (params) =>
    axios
      .post(`/marketing/Calllist/assignTeammembar`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  updateCalllistteam: (params) =>
    axios
      .post(`/marketing/Calllist/updateCalllistteam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  /* End of code */
  //Rohit
  deleteTeam: (params) =>
    axios
      .post(`/marketing/Calllist/deleteTeam`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  /* End of code */

  //Rohit
  getAllteammembar: (params) =>
    axios
      .post(`/marketing/Calllist/getAllteammembar`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  playBookBranchWiseStats: (params) =>
    axios
      .get(`${config.apiBaseURL2}playBookBranchWiseStats?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Rohit
  exportplayBookBranchWiseStats: (params) =>
    axios
      .post(`${config.apiBaseURL2}exportplayBookBranchWiseStat`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  //Rohit
  playBookBranchWiseStatsDetail: (params) =>
    axios
      .post(`${config.apiBaseURL2}getPlaybookAnalyticsDetails` , params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getEmailStatus: (params) =>
    axios
      .post(`${config.apiBaseURL2}getEmailAnalyticsDetails`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),    
  //Rohit
  exportplayBookBranchWiseStatsDetail: (params) =>
    axios
      .post(`/marketing/Analiyticsdetails/exportplayBookBranchWiseStatsDetail`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  /* End of code */

  getassociateCampaigns: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getassociateCampaigns`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


  subscribeUnSubscribe: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/subscribeUnSubscribe`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getDomainList: (params) =>
    axios
      .post(`/marketing/Analiyticsdetails/getDomainList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  //Sagar
  getDomainList: (params) =>
    axios
      .get(`${config.apiBaseURL2}domainEmailList`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  /* End of code */

  deletecolumn: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/deletecolumn`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getTimezoneData: (params) =>
    axios
      .post(`/marketing/playbook/getTimezoneData`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getCurrentTime: (params) =>
    axios
      .post(`/marketing/playbook/currentTime`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkwebhooksUrl: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/checkwebhooksUrl`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getWebhooks: (params) =>
      axios
      .post(`${config.apiBaseURL2}Segment/getWebhooks`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

getSegment: (params) =>
  axios
    .post(`${config.apiBaseURL2}Segment/getSegment`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),

  updateWebhooks: (params) =>
        axios
      .post(`${config.apiBaseURL2}Segment/updateWebhooks`, params)
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),

  getAddsegment: (params) =>
          axios
      .post(`${config.apiBaseURL2}Segment/getAddsegment`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
  
editSegment: (params) =>
      axios
    .post(`${config.apiBaseURL2}Segment/editSegment`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

        

deleteData: (params) =>
  axios
    .post(`${config.apiBaseURL2}Segment/deleteData`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),

  addSegmentData: (params) =>
      axios
      .post(`${config.apiBaseURL2}Segment/addSegmentData`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

updateSegmentData: (params) =>
  axios
    .post(`${config.apiBaseURL2}Segment/updateSegmentData`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),

  changeOnOff: (params) =>
    axios
      .post(`${config.apiBaseURL2}Segment/changeOnOff`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  
  saveServiceTItanContact: (params) =>
    axios
      .post(`${config.apiBaseURL2}Contacts/saveServiceTitanContact`, params)
      .then(res => res.data)
      .catch(err => console.log(err)),
  
  updateServiceTItanContact: (params) =>
    axios
      .post(`/marketing/Contacts/updateServiceTitanContact`, params)
      .then(res => res.data)
      .catch(err => console.log(err)),
  
  getServiceTitanBucketHeading: (params) =>
    axios
      .post(`${config.apiBaseURL2}Contacts/getServiceTitanBucketHeading`, params)
      .then(res => res.data)
      .catch(err => console.log(err)),

  saveServiceTitanBucketTrigger: (params) =>
    axios
      .post(`/marketing/Contacts/saveServiceTitanBucketTrigger`, params)
      .then(res => res.data)
      .catch(err => console.log(err)),

  getSegmentRule: (params) =>
    axios
      .post(`${config.apiBaseURL2}Segment/getSegmentRule`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  
  getEmailSubscribeDomain: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/getEmailSubscribeDomain`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  saveServiceTitanBucketTrigger: (params) =>
    axios
      .post(`${config.apiBaseURL2}serviceTitan/saveServiceTitanBucketTrigger`, params)
      .then(res => res.data)
      .catch(err => console.log(err)),

  getServiceTitanBucketTrigger: (params) =>
    axios
      .post(`${config.apiBaseURL2}serviceTitan/getServiceTitanBucketTrigger`, params)
      .then(res => res.data)
      .catch(err => console.log(err)),
  
  getSegmentRule: (params) =>
    axios
      .post(`${config.apiBaseURL2}Segment/getSegmentRule`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  
      saveServiceTitanBucketTrigger: (params) =>
       axios
      .post(`${config.apiBaseURL2}serviceTitan/saveTrigger`, params)
      .then(res => res.data)
      .catch(err => console.log(err)),

  saveSmsVariables: (params) =>
    axios
      .post(`${config.apiBaseURL2}contacts/saveSmsVariables`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
      scheduledQueue: (params) =>
    axios
      .post(`${config.apiBaseURL2}scheduledQueue`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
};

export default marketing;
