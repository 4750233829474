import React from 'react'

export const ServiceTItanCustomize = ({ checkedToggleST, setting_respons, rm_bid, wh_send_type, wh_option_value, option_value_err, team_id_err, wh_option_type, wh_team_id, allteamlist, settingsUpdate, sendType, optionType, autoTeam, changeSwitchST, changeOptionValue}) => {
 
  return (
      <div className="yr-chat-round-box pb-5 mg-l-0 mx-lg-4">
          <div className="col-lg-11 ml-3">
              {setting_respons != "" ? (
                  <div className="col-lg-12 ui green message del-msg-padding">
                      {setting_respons}
                  </div>
              ) : (
                  <div></div>
              )}
              <div className="d-flex justify-content-between align-items-center">
                  <div
                      className="add-branch-title"
                      style={{
                          marginBottom: 30,
                          marginTop: 30,
                          fontWeight: 500,
                          marginLeft: "-10px",
                      }}
                  >
                      Service Titan
                  </div>
                  {/* switch button start here */}
                  <span >
                      <span>
                          <span className="mb-0 mr-3" style={{ position: 'relative', top: 5 }}>Turn {(checkedToggleST != undefined && checkedToggleST) ? "OFF" : "ON"}  to Service Titan data to be added</span>
                          <label className="mb-0">
                              <span
                                  className="kt-switch kt-switch-sm kt-switch--success chat-cus-check mr-4 pr-2">
                                  <label style={{ marginBottom: '-1.25rem' }} title="Turn on to Service Titan data to be added">
                                      <input
                                          name="modetype"
                                          type="checkbox"
                                          value={checkedToggleST ? 'ON' : 'OFF'}
                                          onClick={(e) => changeSwitchST(e)}
                                          checked={checkedToggleST}
                                          defaultChecked={checkedToggleST}
                                          title="Turn on to enable service titan data to be added"
                                      />
                                      <span />
                                  </label>
                              </span>
                          </label>
                      </span>
                  </span>
                  {/* switch button end here */}
              </div>
              <div className={checkedToggleST ? 'col-md-12 collapse show' : 'col-md-12 collapse'} id="serviceTitanCollapse">

                  {/* form start here */}
                  

                  <div className="px-4">
                      <div>
                          <div className="form-group row">
                              <input
                                  type="hidden"
                                  id="auto_branch_id"
                                  value={rm_bid}
                              ></input>
                              {/* Trigment */}
                              <div className="w-100 d-flex">
                                  <label
                                      className="col-lg-4.5 col-form-label"
                                      style={{
                                          marginBottom: 5,
                                          fontSize: "0.99rem",
                                          fontWeight: 500,
                                      }}
                                  >
                                      After webbook is triggered send a(n)
                                  </label>
                                  <div
                                      className="col-lg-2"
                                      style={{ marginBottom: 5 }}
                                  >
                                      <div className="">
                                          <div className="">
                                              <select
                                                  name="send_type"
                                                  value={wh_send_type}
                                                  id="send_type"
                                                  className="form-control inputdata"
                                                  onChange={e => sendType(e)}
                                                  style={{
                                                      fontSize: "0.99rem",
                                                      fontWeight: 400,
                                                  }}
                                              >
                                                  <option value="1">
                                                      Email
                                                  </option>
                                                  <option value="2">
                                                      Text
                                                  </option>
                                              </select>
                                          </div>
                                          <div></div>
                                      </div>
                                  </div>
                                  <label
                                      className="col-lg-5.5 col-form-label"
                                      style={{
                                          marginBottom: 5,
                                          fontSize: "0.99rem",
                                          fontWeight: 500,
                                      }}
                                  >
                                      with the information captured after
                                      &nbsp;&nbsp;
                                  </label>
                              </div>
                              <div
                                  className="col-lg-2 pl-0"
                                  style={{ marginBottom: 5 }}
                              >
                                  <div className="">
                                      <div className="">
                                          <input
                                              autocomplete="off"
                                              name=""
                                              id="option_value"
                                              placeholder=""
                                              type="number"
                                              min={1}
                                              className="form-control inputdata"
                                              defaultValue={wh_option_value}
                                              value={wh_option_value}
                                              onChange={(e) => changeOptionValue(e.target.value)}
                                          />
                                          <div style={{ color: "red" }}>
                                              {option_value_err}
                                          </div>
                                      </div>
                                      <div></div>
                                  </div>
                              </div>
                              <div
                                  className="col-lg-2 pl-0"
                                  style={{ marginBottom: 5 }}
                              >
                                  <div className="">
                                      <div className="">
                                          <select
                                              name="option_type"
                                              value={wh_option_type}
                                              id="option_type"
                                              className="form-control inputdata"
                                              onChange={(e) => optionType(e)}
                                              style={{
                                                  fontSize: "0.99rem",
                                                  fontWeight: 500,
                                              }}
                                          >
                                              <option value="H">Hours</option>
                                              <option value="D">Days</option>
                                              <option value="W">Weeks</option>
                                          </select>
                                      </div>
                                      <div></div>
                                  </div>
                              </div>
                              <label
                                  className="col-lg-1.5 col-form-label"
                                  style={{
                                      marginBottom: 1,
                                      fontSize: "0.99rem",
                                      fontWeight: 500,
                                  }}
                              >
                                  Send review as
                              </label>
                              <div
                                  className="col-lg-3"
                                  style={{ marginBottom: 1 }}
                              >
                                  <div className="">
                                      <div className="">
                                          <select
                                              value={wh_team_id}
                                              name="team_id"
                                              id="team_id"
                                              className="form-control inputdata"
                                              onChange={(e) => autoTeam(e)}
                                          >
                                              <option value="0">
                                                  Team member
                                              </option>
                                              {allteamlist &&
                                                  Object.keys(allteamlist).map(
                                                      function (key) {
                                                          return (
                                                              <option
                                                                  value={
                                                                      allteamlist[key].id
                                                                  }
                                                              >
                                                                  {
                                                                      allteamlist[key]
                                                                          .member_name
                                                                  }
                                                              </option>
                                                          );
                                                      }
                                                  )}
                                          </select>
                                      </div>
                                      <div style={{ color: "red" }}>
                                          {team_id_err}
                                      </div>
                                  </div>
                              </div>

                              <br />
                              <div
                                  className="col-lg-12 mt-4 pl-0"
                                  style={{
                                      paddingTop: "10px",
                                      fontSize: "0.99rem",
                                      fontWeight: 500,
                                  }}
                              >
                                  The linked sent, will the start the
                                  automation flow starting in branch 1.
                              </div>
                              <br />
                          </div>
                      </div>
                      <div>&nbsp;</div>
                      <div className="col-lg-12">
                          <div style={{ float: "right" }}>


                              <button
                                  type="submit"
                                  className="btn linear-gradient yr-submitbtn ml-4"
                                  onClick={settingsUpdate}
                                  style={{ height: 39, fontSize: 13 }}
                              >
                                  Save
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}
