//Sagar
import React, { Component } from 'react'
import {Table} from 'semantic-ui-react'
import { Form, Dropdown} from "formsy-semantic-ui-react";
import ConfirmDialog from "../../Modal/ConfirmDialog";

export class RequestFrombulk extends Component {
  constructor(props){
    super(props);
    this.state = {
     
    }
  }
 
  render() {
    return (
      <Form
        noValidate
        autoComplete="off"
        id="reviewFormBulk"
        onValidSubmit={this.props.onBulkRequestAReviewSubmit}
      >
        <div className="kt-form requestReviewForm">
          <div className="form-group ">
            <label className="col-form-label bold">
              Send review via:
            </label>
            <div className="">
              <Dropdown
                name="type"
                placeholder="Select"
                selection
                validations={{
                  customValidation: (values, value) =>
                    !(!value || value.length < 1),
                }}
                validationErrors={{
                  customValidation:
                    "Please select atleast one.",
                }}
                errorLabel={this.props.errorLabel}
                onChange={this.props.apendData}
                options={this.props.options_type}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-form-label bold">
              Send review as:
            </label>
            <div className="">
              <Dropdown
                name="team_id"
                placeholder="Select"
                selection
                validations={{
                  customValidation: (values, value) =>
                    !(!value || value.length < 1),
                }}
                validationErrors={{
                  customValidation:
                    "Please select atleast one.",
                }}
                autoComplete="off"
                errorLabel={this.props.errorLabel}
                options={this.props.teamData}
              />
            </div>
          </div>
      {(this.props.emailInputState==1 ||this.props.phoneInputState ==1) && <div>
        <div>
        <a href={`/assets/${this.props.emailInputState?"emailSample.csv":"phoneSample.csv"}`}> Download our sample CSV file.</a>
        </div>
        <div className="uploadfile">
          <label htmlFor="file-upload" className="custom-file-upload">
              <div>
              <i className="fa fa-cloud-upload"></i>
              </div>
              <div>Browse</div>
          </label>
          <input
              id="file-upload"
              name="uploadCSV"
              type="file"
              onChange={(e)=>this.props.userCSV(e)}
              accept="text/csv"
              onClick={(event) => {
              event.target.value = null
              }}
              style={{ display: "none" }}
          />
        </div>
        {this.props.type==="text/csv" && <p style={{color:"red", marginBottom:"20px"}}>{this.props.uploadCSV.name}</p>}
          {this.props.error.length>0 && (this.props.type==="")&& <div style={{width:'100%',marginHorizontal:"20px",height:'30px',alignItems:'center', color:"red"}}>{this.props.error}</div>}
          {<ConfirmDialog
                show={this.props.confirmationModalIsOpen}
                toggleModal={(e)=>this.props.deleteCustomer(e,this.props.idToBeDeleted)}
                closeAction={this.props.closeConfirmationModal}
                title="Confirm your action"
                message={"Do you really want to delete this contact from list ?"}
              />
          }
          
          {this.props.error.length>0 && (this.props.type==="text/csv" || this.props.type==="empty" ||this.props.errorType==="csvHeader")&& <div
              className={
                this.props.error.length>0 ? "modal fade show" : "modal fade"
              }
              data-backdrop="static"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="staticBackdrop"
              aria-hidden="true"
              style={{ display: this.props.error ? "block" : "none" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: 600 }}
              >
                {this.state.dialogLoading && (
                  <div className="ui loading form"></div>
                )}
                <div className="modal-content">
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.props.closeModal}
                  >
                    X
                  </button>
                  <div className="modal-header">
                    <h5 className="modal-title">CSV Errors</h5>
                  </div>
                  <div className="modal-body">
                    <div class="kt-section__body" style={{ maxHeight: '500px', overflow: 'auto' }}>
                      {this.props.displayErrors(this.props.error)}
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                      onClick={this.props.closeModal}
                      data-dismiss="modal"
                    >
                      <span>OK</span>
                    </button>

                  </div>

                </div>
              </div>
            </div>}

          {this.props.uploadCSV  && (this.props.type==="text/csv") && (this.props.errorType !=="csvHeader")  && <div style={{overflow:"scroll", height:"200px", border:'1px solid #dcdcdc', borderRadius:"10px", marginTop:"20px"}}>
            <Table celled>
              {/* overflowY:"scroll",overflowX:"scroll", padding:"1em 0" */}
              <Table.Header style={{top:0,zIndex:4,position:"sticky"}}>
                <Table.Row>
                  {this.props.fileHeaders.map(header=>
                  <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
                  )}
                  <Table.HeaderCell textAlign='center'>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.props.fileContent.length>0 ?this.props.fileContent.map((users,index)=>{
                  return  <Table.Row key={index}>
                    {Object.values(users).map((values)=>{
                      return <Table.Cell key={values}>{values}</Table.Cell>
                    }
                    )}
                    <Table.Cell textAlign='center'>
                    {/* <i className="la la-trash" data-toggle="tooltip" data-placement="top" title="Delete" style={{color:"red",cursor:"pointer"}} onClick={(e)=>this.props.deleteCustomer(e,index)}></i> */}
                    <i className="la la-trash" data-toggle="tooltip" data-placement="top" title="Delete" style={{color:"red",cursor:"pointer"}} onClick={(e)=>this.props.deleteConfirmationModal(e,index)}></i>
                    </Table.Cell>
                  </Table.Row>
                }
                ):
                 <Table.Row>          
                    <div className='text-danger mx-2 my-2'>
                      No Record Found.<br/>
                      Please Reupload A File.
                    </div>
                  </Table.Row>
                }
              </Table.Body>
            </Table>
          </div>}
      </div>}
          <div className="d-flex justify-content-end pt-4">
            <button
              type="submit"
              disabled={
              //  (this.state.submitButton === 1? true: false) && 
             ((this.props.fileContent.length===0?true:false) || (this.props.error.length>0?true:false) || this.props.errorType==="csvHeader")
              }
              className="btn linear-gradient zy-save-btn"
            >
              Send Request
            </button>
          </div>
        </div>
      </Form>
    )
  }
}

export default RequestFrombulk

/* Sagar End */