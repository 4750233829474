import axios from "../config/axios";
 
const reviewchat = {
        
  reviewchatinfo: (params) =>
    axios
      .post(`/chatsetup/reviewchat/reviewchatinfo`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  onchangelocation: (params) =>
    axios
      .post(`/chatsetup/reviewchat/onchangelocation`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),  

  locationadd: (params) =>
    axios
      .post(`/chatsetup/reviewchat/locationadd`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),  

  locationdelete: (params) =>
    axios
      .post(`/chatsetup/reviewchat/locationdelete`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),  

  removefile: (params) =>
    axios
      .post(`/chatsetup/reviewchat/removefile`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),  

  };

export default reviewchat;
