import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import util_funs from "../../../../../util_funs";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function createMarkup(html) {
  return { __html: html };
}

const status = (status) => {
  if (status == "active") {
    var str = '<div class="lightgreen" style={{ width: "200px" }}>Active</div>';
  } else if (status == "unverified") {
    var str = '<div class="orange" style={{ width: "200px" }}>Unverified</div>';
  }
  return ReactHtmlParser(str);
};

// const from_name = (from_name) => {
//   //alert(from_name);
//   var abc = "string";
//   return abc;
// };

export const DatatableRow = (props) => (
  <Table.Row>
    <Table.Cell>
    <span><a className='btn btn-icon' onClick={() => props.editTemplate(props.list.auto_id)} title='Edit'>
      <i className='la la-edit blue'></i>
      </a>
      <a onClick={() => props.deleteTemplate('Open',props.list.auto_id)} title='Delete' className='btn btn-icon gray'>
        <i className='la la-trash'></i>
      </a> 
    </span>
    </Table.Cell>
    <Table.Cell>
      <div>
      <a href={"/marketing/editpostcard/" + util_funs.encrypt_url(props.list.auto_id)}>{props.list.title}</a>
      </div>
    </Table.Cell>
    <Table.Cell><a href={props.list.image_url}  target="_blank"><img height='100px' width='150px' src={props.list.image_url}/></a></Table.Cell>
    <Table.Cell><a href='javascript:void(0);' onClick={() => props.updateBackPostcard(props.list)} >Click to view</a></Table.Cell>
    <Table.Cell>{props.list.status=='Y'?(<span>
                      <i class="fa fa-circle green"></i> Active
                    </span>):(<span>
                      <i class="fa fa-circle red"></i> Inactive
                    </span>)}
    </Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
