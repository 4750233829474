import { addValidationRule } from "formsy-react";
import Validator from "validator";
import axios from "../config/axios";
import api from "../api";

// for those validation which are not common

export const isRequired = () => {
  return addValidationRule("isRequired", function (values, value) {
    if (value === undefined) {
      return false;
    } else {
      if (value.trim() == "") {
        return false;
      } else {
        return true;
      }
    }
  });
};

export const isRequiredOldPass = () => {
  return addValidationRule("isRequiredOldPass", function (values, value) {
    if (value === undefined || value.trim() == "") {
      if (
        (values.newpassword != "" && values.newpassword !== undefined) ||
        (values.cnpassword != "" && values.cnpassword !== undefined)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });
};

export const isRequiredNewPass = () => {
  return addValidationRule("isRequiredNewPass", function (values, value) {
    if (value === undefined || value.trim() == "") {
      if (
        (values.oldpassword != "" && values.oldpassword !== undefined) ||
        (values.cnpassword != "" && values.cnpassword !== undefined)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });
};

export const isRequiredNewConPass = () => {
  return addValidationRule("isRequiredNewConPass", function (values, value) {
    if (value === undefined || value.trim() == "") {
      if (
        (values.oldpassword != "" && values.oldpassword !== undefined) ||
        (values.newpassword != "" && values.newpassword !== undefined)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });
};

export const newPasswordAndConfirmPasswordEqulaity = () => {
  return addValidationRule("newPasswordAndConfirmPasswordEqulaity", function (
    values,
    value
  ) {
    if (value === undefined || value.trim() == "") {
      return true;
    } else {
      if (values.newpassword != "" && values.newpassword !== undefined) {
        if (values.newpassword != value) {
          return false;
        }
      }
      return true;
    }
  });
};

export const isSelected = () => {
  return addValidationRule("isSelected", function (values, value) {
    if (value === undefined || value === "0") {
      return false;
    }
    return true;
  });
};

export const usPhoneLength = () => {
  return addValidationRule("usPhoneLength", function (values, value) {
    if (value !== undefined && value !== "") {
      if (value.trim().length < 13) {
        return false;
      } else {
        return true;
      }
    }
  });
};

export const usPhoneLengthTM = () => {
  return addValidationRule("usPhoneLengthTM", function (values, value) {
    if (value !== undefined && value !== "") {
      if (value.length < 13) {
        return false;
      } else {
        return true;
      }
    } else {
      return true
    }
  });
};

export const minCustomLength = (a) => {
  return addValidationRule("minCustomLength", function (values, value) {
    if (value.trim() == "" || value === undefined) {
      return true;
    } else {
      if (value.length < a) {
        return false;
      } else {
        return true;
      }
    }
  });
};

export const validNewPassword=(a)=>{
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  
  return addValidationRule("validNewPassword",function(values, value){
    if (
      (values.oldpassword != "" && values.oldpassword !== undefined) ||
      (values.cnpassword != "" && values.cnpassword !== undefined)
    ){
      if (value.trim() == "" || value === undefined) {
        return false
      }else{
        return regex.test(value)
      }
    }else{
      return true
    }
  })
}

export const isValidDomain = () => {
  return addValidationRule("isValidDomain", function (values, value) {
    if (Validator.isURL(value)) {
      return true;
    }
    return false;
  });
};

export const newPinAndOldPinNotSame = () => {
  return addValidationRule("newPinAndOldPinNotSame", function (
    values,
    value
  ) {
    if (value === undefined || value == "") {
      return true;
    } else {
      if (values.pin != "" && values.cpin !== undefined) {
        if (values.cpin != value) {
          return false;
        }
      }
      return true;
    }
  });
};
