import React from "react";
import ScreenShots from "../../Pages/Settings/ScreenShots";
import DialogBox from "../../Modal/DialogBox";
class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentImages: [],
    };
  }
  displayComment() {
    return this.props.comments.map((comment, index) => {
      return this.renderComment(comment, index);
    });
  }

  loadCommentImages = (images) => {
    if (images.length > 0) {
      this.setState({ commentImages: images });
    }
  };
  renderComment = (comment, index) => {
    if (comment.created_by === comment.ticket_created_by) {
      return (
        <div className="content" key={index}>
          <a className="author">{comment.display_name}</a>
          {comment.images.length > 0 && (
            <a
              className=""
              title="Attached files"
              style={{
                float: "right",
                color: "#087ab6",
                fontSize: "1em",
                cursor: "pointer",
              }}
              onClick={(images) => this.loadCommentImages(comment.images)}
            >
              View images
            </a>
          )}

          <div className="metadata">
            <span className="date">{comment.created_date}</span>
          </div>
          <div className="text">
            <p>{comment.comments}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="comments" key={index}>
          <div className="comment">
            <div className="content">
              <a className="author">{comment.display_name}</a>
              {comment.images.length > 0 && (
                <a
                  className=""
                  title="Attached files"
                  style={{
                    float: "right",
                    color: "#087ab6",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={(images) => this.loadCommentImages(comment.images)}
                >
                  View images
                </a>
              )}

              <div className="metadata">
                <span className="date">{comment.created_date}</span>
              </div>
              <div className="text">{comment.comments}</div>
            </div>
          </div>
        </div>
      );
    }
  };

  toggleModal = () => this.setState({ commentImages: [] });
  render() {
    return (
      <div className="comment">
        {this.state.commentImages.length > 0 && (
          <DialogBox
            show={this.state.commentImages.length > 0 ? true : false}
            toggleModal={this.toggleModal}
            title="Screenshots"
            width="600px"
            height="300px"
          >
            <ScreenShots images={this.state.commentImages} />
          </DialogBox>
        )}

        {this.displayComment()}
      </div>
    );
  }
}

export default Comments;
