import axios from "../config/axios";

const customize = {
  
  customizeinfo: (params) =>
    axios
      .get(`/chatsetup/designrules/customizeinfo`, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  edit: (params) =>
    axios
      .post(`/chatsetup/designrules/edit`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),    
};

export default customize;
