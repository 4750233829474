import React from "react";

import { withRouter } from "react-router-dom";
import { ListIcon } from "semantic-ui-react";

class Comment extends React.Component {
  navigateUser = () => {
    return this.props.history.push("/settings?tab=support");
  };

  navigateUser = () => {
    return this.props.history.push("/settings?tab=support");
  };
  render() {
    return (
      <div className="kt-header__bottom">
        <div className="kt-container" style={{ backgroundColor: "#fff" }}>
          <div
            className="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className="kt-header-menu kt-header-menu-mobile "
            >
              <div className="yr-mobpagetitle">Conversations :</div>
              <ul className="kt-menu__nav " style={{ padding: 22 }}>
                <li className="kt-menu__item--active linear-gradient" aria-haspopup="true">
                  <a
                    href="#"
                    onClick={(event) => this.toggleNavMenu(event, "live-chat")}
                    className="kt-menu__link kt-menu__toggle linear-gradient"
                  >
                    <div
                      className="kt-menu__link-text btn linear-gradient yr-submitbtn linear-gradient"
                      onClick={this.navigateUser}
                    >
                      <i
                        className="yr-faicon ticket-comment-fav-icon yr-navicon-ticketList"
                        style={{
                          marginRight: "0px",
                        }}
                      ></i>
                      {/* <button
                        type="button"
                        onClick={this.navigateUser}
                        className="btn yr-submitbtn"
                      >
                        Ticket List
                      </button> */}
                      Ticket List
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Comment);
