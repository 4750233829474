import React,{useState,useEffect} from "react";
import ConfirmDialog from "../../../../../Modal/ConfirmDialog";
import faqclasses from "./FAQs.module.css";
export default function FAQs( props ){
    const [addQuestionHTML,addQuestionProcess] = useState([]);
    const [faqError, setfaqError] = useState(false);
    useEffect(() => {
        addQuestionProcess(props.faqdata);
    },[props.faqdata]);
    const [modalDetail,modalDetailSet] = useState({modalDisp:false,title:"Add an additional question for FAQs"});
    const [inputQuestion,setInputQuestion] = useState("");
    // open question modal on click
    const openAddQuestionModel = () => {
        const modalDetail_temp = {...modalDetail,modalDisp:true}
        modalDetailSet(modalDetail_temp);
        setInputQuestion("");
        return;
    }
    // close modal on click
    const closeModal = () => {
        const modalDetail_temp = {...modalDetail,modalDisp:false}
        modalDetailSet(modalDetail_temp);
        setfaqError(false);
        return;
    }

    // hangle question 
    const handleInputQuestion = (event ) => {
        setfaqError(false);
        let modalDetail_temp = {...modalDetail}
        modalDetail_temp.title = "Add an additional question for FAQs";
        modalDetailSet(modalDetail_temp);
        setInputQuestion(event.target.value);
    }

    // handle Additional 
    const handleAdditionalFaqContext = ( e ) => {
        props.setValue(e.target.value,"AdditonalFaqContext");
    }
    // question modal body return
    const modelBody = ( type ) => {
        if(type == 'single'){
            return(
                <>
                <input className='form-control' placeholder='Enter Your Question' 
                defaultValue={inputQuestion} 
                onChange={handleInputQuestion}
                value={inputQuestion}
                />
                {faqError && <small className="text-danger">This field is required.</small> }
                </>
            )
        }else{
            return(
                <>
                    <textarea className={ `form-group ${faqclasses.inputTextare}`}
                    value={props.AdditonalFaqContext} 
                    onChange={(e) => handleAdditionalFaqContext(e)} >
                    </textarea>
                </>
            )
        }
    }

    // save question
    const saveQuestios = ( ) => {
        if(inputQuestion.trim() != ''){
            const temp_addquestion = [...addQuestionHTML];
            let newNode = {a:"",q:inputQuestion}
            temp_addquestion.push(newNode);
            addQuestionProcess(temp_addquestion);
            const modalDetail_temp = {...modalDetail,modalDisp:false}
            modalDetail_temp.title = "Add an additional question for FAQs";
            modalDetailSet(modalDetail_temp); 
        }else{
            setfaqError(true);
        }
    }

    //render additional questions
    const renderAdditionalQuestions = () => {
        if(addQuestionHTML){
            console.log('about', addQuestionHTML);
            return addQuestionHTML.map(
                
                (el,index) => {
                    el = (el && typeof el === 'string') ? JSON.parse(el) : el;
                    return (
                        
                        <div className={`${faqclasses.FAQ_section}`}>
                            <span>{el.q}</span>
                            <textarea className={ `form-group ${faqclasses.inputTextare}`}
                            value={el.a} 
                            onChange={(event) => setAnswer(event,index)}  >
                            </textarea>
                        </div>
                    )
                }
            );
        }else{
            return (<></>)
        }
    }
    

    // set answer for
    const setAnswer = (event,index) => {
        let temp_addQuestionHTML = [...addQuestionHTML]
        temp_addQuestionHTML[index] = (temp_addQuestionHTML[index] && typeof temp_addQuestionHTML[index] === 'string') ? JSON.parse(temp_addQuestionHTML[index]) : temp_addQuestionHTML[index];
        temp_addQuestionHTML[index].a = event.target.value;
        props.setValue(temp_addQuestionHTML,"YourFAQA");
    }

    return (
        <>
             <div className='row'>
                <div className='col-md-12'>
                    <div className="box-title">FAQs</div>
                </div>
                <div className="col-md-8">
                    {renderAdditionalQuestions()}
                    <div class="addNewFAQ">
                        <a className={`${faqclasses.addLink}`} onClick={() => openAddQuestionModel()} >
                            + Add an Additional FAQs
                        </a>
                    </div>
                    <h2>Additional FAQs Context </h2>
                    {modelBody("additonal")}
                </div>
                <ConfirmDialog
                    show={modalDetail.modalDisp}
                    toggleModal={saveQuestios}
                    closeAction={closeModal}
                    title={modalDetail.title}
                    message={modelBody('single')}
                />
            </div>
        </>
    )
}