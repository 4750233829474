import React from "react";
import { Table, Pagination } from "semantic-ui-react";
import { PagingOption } from "./PagingOption";

class Paginate extends React.Component {
  setPagination = (event, page) => {
    this.props.changePage(page.activePage);
  };
  displatEntries = () => {
    const { totalRecord, pageLimit, currentPage } = this.props;
    if (parseInt(totalRecord) > 0) {
      let number = parseInt(pageLimit * currentPage);
      let text = `Showing ${
        currentPage > 1 ? (currentPage - 1) * pageLimit + 1 : 1
      } to ${
        number > totalRecord ? totalRecord : number
      } of ${totalRecord} entries`;
      return text;
    }
    return "";
  };
  render() {
    let columnLen = Math.ceil(this.props.columnLength / 2);

    return (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={columnLen}>
            <PagingOption
              limit={this.props.pageLimit}
              onPageLimitChange={(pageLimit) =>
                this.props.changePageSize(pageLimit)
              }
            />{" "}
            {this.displatEntries()}
          </Table.HeaderCell>

          <Table.HeaderCell colSpan={columnLen}>
            {this.props.totalPages && (
              <Pagination
                totalPages={this.props.totalPages}
                onPageChange={this.setPagination}
                activePage={this.props.currentPage}
              />
            )}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  }
}

export default Paginate;
