import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
export const DatatableRow = props => (
  <Table.Row>
    <Table.Cell>{props.list.user_type!='main'  ? <span><a onClick={() => props.deleteHipaaUserSubAccounts(props.list.id)} title='Delete' className='btn btn-icon gray'><i className='la la-trash'></i></a> </span> :'' }</Table.Cell>
    <Table.Cell>{props.list.email}</Table.Cell>
    <Table.Cell>{props.list.phone}</Table.Cell>
    <Table.Cell>{props.list.name}</Table.Cell>
    <Table.Cell>
  <Link 
     to={{
      pathname: 'hippa-log',
      state:props.list.email
 }}>View Details</Link></Table.Cell>
    <Table.Cell>{props.list.created_date}</Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
