import React, {useEffect, useState,useRef,useCallback} from 'react'
import ContentEditable from "react-contenteditable";
//import Dropzone from './Dropzone.js';
import api from "../../../../../api";
import Modal from "../../../ChatSetup/components/Modal";
import "../../../../Modal/dialog.css";
import CustomErrorField from "../../../../Form/CustomErrorField";
import "./sms.css";
//import {useDropzone} from 'react-dropzone';
import { faBorderNone } from '@fortawesome/free-solid-svg-icons';


const SmsTemplate = ({ activetab,handleSmsData,errors,smsData,smsvars,pid,selectedEditor }) => {
    const imagetypes=['image/gif','image/jpeg','image/jpeg','image/png','image/svg+xml'];
    const videotypres=['video/mp4','video/mpeg'];
    const audiotypes=['audio/mpeg','audio/amr','audio/wav'];
    const contentEditableRef = useRef("");
    const [uploadtemplate, setUploadTemplate] = useState('');
    const [smsContent, setsmsContent] = useState("");
    const [txtlen, settxtlen] = useState(0);
    const [mmsError, setMMSError] = useState('');
    const [alt_variable, setalt_variable] = useState("");
    const [alt_value, setalt_value] = useState('');
    const [pdata, setpdata] = useState({});
    const [dataLoaded, setdataLoaded] = useState(false);
    const [modaltitle, setmodaltitle] = useState("");
    const [modalbody, setmodalbody] = useState("");
    const [modalfooter, setmodalfooter] = useState("");
    const [modalaltloading, setmodalaltloading] = useState(false);
    const [modalindex, setmodalindex] = useState("");
    const [modalaltstatus, setmodalaltstatus] = useState("");
    const [ errors_alt, seterrors_alt] = useState({});
    const [canEdit, setcanEdit] = useState(true);
    const [loading, setLoading] = useState(false);
   const [contenttype, setContentType] = useState('sms');
   
    
  
     
    useEffect(() => {
      
       setUploadTemplate(smsData.file);
     
      setTimeout(()=>{
      setsmsContent(smsData.smsContent);
     
        handleSmsData(smsData);
      },1000);
    }, [smsData.smsContent,smsData.file]);
    
    useEffect(() => {
      //var t=contentEditableRef.current.textContent;
      var t=contentEditableRef.current.innerHTML;
      // if(t.length>1500){
      //   t.substr(0, 1500);
      console.log(t);
         setsmsContent(t);
      //   // setcanEdit(false);
      // }
      let nc=t.length;
      settxtlen(nc);
    }, [smsContent]);

   
  
      const updateSmsContent=(e)=>{
            let data={};
            let ftxt=""
            let x=contentEditableRef.current.textContent;
            let t=e.target.value;
                console.log("smsContent",x.length)
                if(x?.length===0){
                  setsmsContent("")
                }else{
                  let r=Math.floor(x.length/135)==1 ? 135:155;
                  // if(x.length%r==0){
                  //   t=t+"<br/>";
                  // }
                  ftxt=t;
                  setsmsContent(t);
                }
               
            // if(e.target.value.length<=1500)
            data.smsContent=ftxt;
            data.file=uploadtemplate;
            handleSmsData(data);
            setpdata(data);
    }
   
    const onchangeVariable = (val, key, varName) => {
      let emailSub = smsContent;
      let emailSub2 = smsContent;
      emailSub =emailSub.replace('<br>', '');
      emailSub2 =emailSub.replace('<br>', '');
      let val3=`${val}`;
      let val2 =
      `<span class='alt_val_span' alt_var='${varName}' alt_key='${val.slice(2, -2)}'>${val}</span>`;
      //code by manoj ,removed last nbsp;
      // var n = emailSub.lastIndexOf("&nbsp;");
      // let sublen = emailSub.length;
      // if (emailSub.substring(sublen - 6).trim() == "&nbsp;") {
      //   emailSub = emailSub.substring(0, sublen - 7);
      // }
      //code by manoj ,removed last nbsp;
      
      let pdata={};
      let x=contentEditableRef.current.textContent;
      // if((x.length+val3.length)<=1500){
        setsmsContent(emailSub + val2);
        pdata.smsContent=emailSub2+val2;
        pdata.file=uploadtemplate;
        handleSmsData(pdata);
      // }
     
    }

    const deleteImg=()=>{
      setUploadTemplate('');
      var d={};
      d.smsContent=smsContent;
      d.file="";
      handleSmsData(d);
    }
    // update parent on image upload
    const updateImg=()=>{
      let d={};
      d.smsContent=smsContent;
      d.file=uploadtemplate
      console.log("ondrop",d);
      handleSmsData(d);
    }

    const onchangeTemplate = (event1) => {
      setLoading(true);
      let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp', 'image/pjpeg', 'image/webp', 'image/svg+xml'];
      if(typeof event1.target.files[0]!='undefined'){
      let mainfile = event1.target.files[0];
      if (mainfile.size < '512001') {
        if (imageMIME.includes(mainfile.type) == true && mainfile.type != '') {
        var _URL = window.URL || window.webkitURL;
        var img = new Image();
        var objectUrl = _URL.createObjectURL(mainfile);
        var event = event1
        img.onload = function (event) {
          _URL.revokeObjectURL(objectUrl);
          if (this.width < 641 && this.height < 1139) {
              setMMSError('');
              let file = mainfile;
              setUploadTemplate(URL.createObjectURL(file));
              getImageUrl(file, function(res){
                handleSmsData({file: res, smsContent: smsContent});
              });

              var all = document.getElementsByClassName('postcard--listitem');
              for (var i = 0; i < all.length; i++) {
                all[i].style.pointerEvents = 'none';
              }
             
            
          } else {
            setMMSError('Image size should not be greater than 640px wide and 1138px tall.');
          }
        };
        img.src = objectUrl;
        }else {
          setMMSError('Invalid file format. Only "jpg, jpeg, png, gif" files are supported.');
        }
      } else {
        setMMSError('Image size should be less than 512 KB.');
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    
    }
    const getImageUrl = (file,callback) => {
      //console.log(obj.state.uploadtemplate)
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var base64data = reader.result;
        callback(base64data);
      }
    }
    
    const onremoveTemplate = (event) => {
      document.getElementById("uploadtemplateID").value = "";
      setUploadTemplate('');
      setMMSError('')
      handleSmsData({smsContent:smsContent,file:''});
    }

 const countlen=()=>{
  let nc=160-smsContent.length;
  settxtlen(nc);
 }
 //editor change function 
 const onClickContentEditable = (e) => {
  
  if (e.target ===contentEditableRef .current) {
    return;
  }
  // an element in `ContentEditable` was clicked!
  if (e.target.className.indexOf("alt_val_span") > -1) {
     addAltValue(e.target.getAttribute("alt_key"), null, e.target.getAttribute('alt_var'));
     setalt_variable( e.target.getAttribute('alt_var'));
  }
  //close btn
  if (e.target.className.indexOf("close") > -1) {
    deleteImg();
    updateImg();
  }
};

//add alt data
const addAltValue = (key, callType = null, varName) => {
  setalt_value("");
  seterrors_alt({});

  //this.setState( { alt_value: "", modalid: key, errors_alt: {}, callType: callType },
  api.playbookdetail
        .getAltValue({
          branch_id: selectedEditor,
          hid: varName,
          pid: pid,
          callType: null,
        })
        .then((data) => {
          setmodalindex("alt_value");
          setalt_value((prevState)=>{return data.alter_value});
          setmodalaltstatus(true);
          setmodalaltloading(false);
          let modalbody = modalBody("alt_value",data.alter_value);
            setmodalbody(modalbody);
          
         
        });
   
  
};

const saveAltValue = () => {
  let error = [];
  if (alt_value == "") {
    error["alt_value"] = "This field is required";
  }
  if (Object.keys(error).length > 0) {
    seterrors_alt(error);
    let modalbody = modalBody("alt_value");
      setmodalbody(modalbody);
    
  } else {
    setmodalaltloading(true);
        
    api.playbookdetail
      .saveAltValue({
        pid: pid,
        alt_value: alt_value,
        hid: alt_variable,
        branch_id: selectedEditor,
        callType: null,
      })
      .then((data) => {
        try {
          setalt_value("");
          setmodalaltloading(false);
           modalClose("");
        } catch (err) {
          console.log(err);
        }
      });
  }
};


//end alt data
//model code
const modalClose = (index) => {
  setmodalaltstatus(false);
};

const onChangeInput = (type, data) => {
  setalt_value(data.target.value);
};

const modalFooter = (index) => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => modalClose(index)}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>Cancel</span>
        </button>
        <button
          type="button"
          onClick={() => saveAltValue()}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Save</span>
        </button>
      </div>
    );
  
}

const modalBody = (tab,val="") => {
    let time2 = Date.now();
    let alt_value1=alt_value;
    return (
      <div className="col-lg-12 col-md-12 col-sm-12">
        <label className="form-control-label">
          If variable is not present, replace with: <span></span>
        </label>
        <input
          type="text"
          className={
            Object.keys(errors_alt).length > 0 &&
              typeof errors_alt["alt_value"] != "undefined"
              ? "form-control is-invalid focusError"
              : "form-control"
          }
          key={time2}
          placeholder="Enter Title"
          defaultValue={val}
          name="alt_value"
          onChange={(e) => onChangeInput("alt_value", e)}
        />
        {Object.keys(errors_alt).length > 0 &&
          typeof errors_alt["alt_value"] != "undefined" && (
            <CustomErrorField
              message={errors_alt["alt_value"]}
              id="help-error"
              className="error invalid-feedback"
            />
          )}
      </div>
    );
  
}


//end model

    return (
     <>
     {loading && (
                        <div className="ui loading form"></div>
                      )}
     <div class="add-branch-title mb-3">Add/Edit Text</div>
     <div className="yr-email-s-line">
                                    <div style={{ width: "100%" }}>
                                      <div
                                        class="kt-input-icon kt-input-icon--right header-search kt-input-icon--right"
                                        style={{ width: "100%",paddingRight:"120px" }}
                                      >
                                        <ContentEditable
                                          innerRef={contentEditableRef}
                                          html={smsContent} // innerHTML of the editable div
                                          onChange={(e)=>updateSmsContent(e)} // handle innerHTML change
                                          tagName="div" // Use a custom HTML tag (uses a div by default)
                                          className="contenteditable"
                                          onInput={()=>countlen()}
                                          onClick={onClickContentEditable}
                                          disabled={!canEdit}
                                        />
                                     
                                      </div>
                                      {/* {errors &&
                                        errors.hasOwnProperty(
                                          "content"
                                        ) && (
                                          <div className="red">
                                            {errors["content"]}
                                          </div>
                                        )} */}
                                    </div>
                                    {/* <span>Email subject line</span> */}
                                    {Object.keys(smsvars).length>0 && <div
                                      className=""
                                      style={{
                                        paddingTop: "6px",
                                        position: "relative",
                                      }}
                                    >
                                      <a
                                        className="btn linear-gradient btn-icon dropdown-toggle yr-managebtn cursorpointer"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{position:"absolute",right:"5px"}}
                                      >
                                        Insert Variable{" "}
                                        <span>
                                          {/* <i className="fa fa-arrow-down"></i> */}
                                        </span>
                                      </a>
                                      <div
                                        className="dropdown-menu dropdown-menu-right yr-custom-menu"
                                        style={{
                                          minWidth: "250px",
                                          maxHeight: "300px",
                                          overflowY: "scroll",
                                          zIndex: "100",
                                        }}
                                      >
                                        <ul>
                                          {Object.keys(smsvars)
                                            .length > 0 &&
                                            Object.keys(
                                              smsvars
                                            ).map((obj, y) => {
                                              return (
                                                <li
                                                  onClick={(e) =>
                                                    onchangeVariable(
                                                      "{{" +
                                                      smsvars[
                                                      obj
                                                      ] +
                                                      "}}",
                                                      obj,
                                                      smsvars[
                                                      obj
                                                      ]
                                                    )
                                                  }
                                                  key={y}
                                                >
                                                  <a className="cursorpointer">
                                                    <span>
                                                      {
                                                       smsvars[
                                                        obj
                                                        ]
                                                      }
                                                    </span>
                                                  </a>
                                                </li>
                                              );
                                            })}

                                          {/* <li onClick={(e) => this.onchangeVariable('[[company]]')}>
                                            <a  href="javascript:void(0);">
                                                <span>Company</span>
                                            </a>
                                        </li>	 */}
                                        </ul>
                                      </div>
                                     
                                    </div>}
                                  
                                    {/* end varialbes */}
                                  </div>
                                  <div className='contenterr'>
                                  {txtlen>1500 && <label className='badge alert alert-danger'>Text characters count cannot exceed more than 1500.</label>}
                                  </div>
                                  <div style={{display:'none'}} id="nousediv"></div>
                                  <img id="fakeimg"  style={{display:'none'}}/>
                                  <br/>
                                  
                                  
                               
                                  <div className='row'>
                                    <div className='col-md-4'>
                                      <div class="upload-btn-wrapper text-center">
                                        <button style={{cursor:'pointer'}} class="btn btn linear-gradient yr-submitbtn btn-sm">Add Image</button>
                                        <input type="file" className='mmsfile' name="uploadtemplate" id="uploadtemplateID" onChange={(e) => onchangeTemplate(e)} accept="image/jpg,image/png,image/jpeg,image/gif" />
                                      </div>
                                      {mmsError && <p className="text-danger">{mmsError}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                      <div className='kt-input-icon kt-input-icon--right header-search kt-input-icon--right  imgbox'>
                                        
                                       {uploadtemplate && <div className='imgobj'><i title="Delete text image" aria-label="Close" className=' fas fa-trash-alt close closebtn' onClick={(e )=> onremoveTemplate(e)}></i>
                                       {(uploadtemplate != "") && <img src={uploadtemplate} className='img-fluid' />}</div>}
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <Modal
                  show={modalaltstatus}
                  footer={modalFooter(modalindex)}
                  onclose={modalClose}
                  title={modaltitle}
                  body={modalbody}
                  loading={modalaltloading}
                />
     </>

     
         
          
    
    )
}

export default SmsTemplate