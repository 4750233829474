import React from "react";
import { Segment } from "semantic-ui-react";

class NotFound extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <Segment>
              <h2>404 Page not found</h2>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
