import React from "react";
import { connect } from "react-redux";

// import DesignRules from './DesignRules';

import { Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
  toggleBreadcrum,

} from "../../../store/Page";

import DatatableList from "./DatatableList";
import DatatableListForTranScript from "../TranscriptChat/DatatableList"
import './ChatAttachement.css';
import DataTableLisForAIPhone from "../AI Phones/DataTableList";
const queryString = require("query-string");

class CompletedChats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activetab: "design",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      cchat:'active',
      tchat:''
    };
  }
  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      toggleBreadcrum,
      PageMessage,
      setMessage,
      setBreadCrum,
    } = this.props;
    setPageTitle({ title: "Your Chats",subHeaderTitle:'Conversations' });
    

    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    const params = queryString.parse(location.search);

    if (params && params.tab !== undefined) {
      this.setState({
        activetab: params.tab,
      });
    }
  }

  toggleTab = (tab) => {
    if (tab === "cchat") {
      this.setState({
        cchat: "active",
        tchat: "",
        vchat: ""
      });
    } else if (tab === "tchat") {
      this.setState({
        cchat: "",
        tchat: "active",
        vchat: ""
      });
    } else if (tab === "vchat") {
      this.setState({
        cchat: "",
        tchat: "",
        vchat: "active"
      });
    } 
  };

  render() {
    return (
      <div
        className="kt-container kt-body  kt-grid kt-grid--ver completedchatmain completedchatmaintestingbot"
        id="kt_body"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid yr-body-p">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="kt-portlet kt-portlet--mobile"
                  style={{ boxShadow: "none" }}
                >
                  <div class="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
                <div className="kt-portlet__head">
                      <div className="kt-portlet__head-toolbar">
                        <ul
                          className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={"nav-link " + this.state.cchat}
                              data-toggle="tab"
                              role="tab"
                              onClick={() => this.toggleTab("cchat")}
                              style={{ cursor: "pointer" }}
                            >
                              Automated Chats
                            </a>
                          </li>
                          
                          
                          <li className="nav-item">
                            <a
                              className={"nav-link " + this.state.tchat}
                              data-toggle="tab"
                              style={{ cursor: "pointer" }}
                              role="tab"
                              onClick={() => this.toggleTab("tchat")}
                            >
                              Live chats
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={"nav-link " + this.state.vchat}
                              data-toggle="tab"
                              style={{ cursor: "pointer" }}
                              role="tab"
                              onClick={() => this.toggleTab("vchat")}
                            >
                              AI Phone Calls
                            </a>
                          </li>
                        </ul>
                      </div>
                      
                    </div></div>
                  <div className="kt-portlet__body kt-portlet__body--fit">
                  <div className="tab-content">
                        <div
                          className={"tab-pane " + this.state.cchat}
                          id="kt_portlet_base_demo_1_1_tab_content"
                          role="tabpanel"
                        >
                    <DatatableList user={this.props.user} />
                    </div>
                        <div
                          className={"tab-pane " + this.state.tchat}
                          id="tranScriptChatList"
                          role="tabpanel"
                        >
                          <DatatableListForTranScript user={this.props.user} />
                    </div>
                    <div
                          className={"tab-pane " + this.state.vchat}
                          id="tranScriptChatList"
                          role="tabpanel"
                        >
                          <DataTableLisForAIPhone user={this.props.user} />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company ? state.user.company : null,

  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
  toggleBreadcrum
})(CompletedChats);
