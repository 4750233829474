import React from 'react';
import api from "../../../../api";
import "../../../Grid/grid.css";
import { Segment, Table } from 'semantic-ui-react';
import { DatatableTable } from './DatatableTable';
import { DatatableFilter } from './DatatableFilter';
import DialogBoxDetail from './DialogBoxDetail';
import DialogBoxAddEdit from './DialogBoxAddEdit';
import DialogBoxSetNotificationAndMiscSetting from './DialogBoxSetNotificationAndMiscSetting';
import { DatatableRow } from './DatatableRow';
import utilFunc from "../../../../util_funs";


const tableHeader = [{
  "title": 'Actions',
  "width": '1',
  "sort": '',
},
{
  "title": 'Email',
  "width": '3',
  "sort": 'email',
},
{
  "title": 'Phone',
  "width": '3',
  "sort": 'phone',
},
{
  "title": 'Full Name',
  "width": '2',
  "sort": 'name',
},
{
  "title": 'HIPAA Log',
  "width": '1',
  "sort": '',
},
{
  "title": 'Created On',
  "width": '2',
  "sort": 'created_date',
},

]
const queryParams = ['_limit', '_order', '_sort', 'q', '_page'];
const APIURL = 'http://localhost/zyratalk/api/premium/settings/manageuser/';
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: 'user_id',
      _page: 1,
      _order: 'desc',
      _limit: 10,
      q: '',
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalErrorMsgLegacy:false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      modalstatusNotiAandMisc:false,
      modalstatusNotiAandMiscData: {},
      ISDrenderOption1:[],
      ISDrenderOption2:[],
      notiAndSettingmodalData:{},
      loadNow:false,
      currentChecked:[],
      currentExpanded:[],
      hipaaLoginEmailList:[],
      hipaaLoginPhoneList:[]
    };
  }

  componentDidMount() {
    this.loadData({});
    this.loadDataHipaaLoginUser({});

    // this.getAccessModules();
    api.user
      .getProfile()
      .then((resp) => {
        if (resp.data && resp.data.data.profileDetails) {
          const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
          const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
         this.setState({ISDrenderOption1:defaultCountryCodeArr})
         this.setState({ISDrenderOption2:remailCountryCodeArr})
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static directionConverter(order) {
    if (order === 'asc') {
      return 'ascending';
    } else if (order === 'desc') {
      return 'descending';
    } else {
      return null;
    }
  }

  handleSort = clickedColumn => {
    const { _sort, _order } = this.state;

    let newOrder = _order === 'asc' ? 'desc' : 'asc';
    if (_sort !== clickedColumn) {
      newOrder = 'asc';
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = filter => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {

    if (data.modalType == 'Delete') {
      this.onDeleteHipaaAction();
    }
    // if (data.modalType == 'Popup') {
    //   this.setState({
    //     modalstatus: false,
    //     modaltitle: '',
    //     modalmessage: '',
    //     modalbuttonmsg: {},
    //     modalType: ''
    //   });
    // }
  };

  addHipaaUserSubAccounts = () => {
    document.getElementById('formManageHipaaLoginUser').reset();
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: 'Add Hipaa User',
      modalType: 'Add',
      modaldata: {},
      selectedOptions: [],
      currentChecked:[],
      currentExpanded:[]
    });
  };

  editHipaaUserSubAccounts = id => {
    document.getElementById('formManageHipaaLoginUser').reset();
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let modaldata = this.state.lists[recordlist];
    // here work by hilal
    // let editDateModule = [];
    // let moduleList = this.state.accessModuleList;
    // moduleList.forEach((item, index) => {
    //   Object.keys(item).forEach(function (key) {
    //     if (modaldata.linksetting.includes(key))
    //       editDateModule.push(key);
    //   });
    // });
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: 'Edit User',
      modalType: 'Edit',
      modaldata: modaldata,
      currentChecked:(modaldata.setting.access_module_lists==null)?[]:JSON.parse(modaldata.setting.access_module_lists),
      currentExpanded:[],
      // selectedOptions: editDateModule
    });
  };

  deleteHipaaUserSubAccounts = id => {
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: 'Confirm your action',
      modalmessage: 'Are you sure you want to delete?',
      modalbuttonmsg: { "submit": 'Yes', "cancel": 'No' },
      modalType: 'Delete',
      modaldata: modaldata
    });
  };

  setCurrentCheck = (checked) =>{
    this.setState({
      currentChecked:checked,
    })
  }
  setCurrentExpand = (checked)=>{
    this.setState({
      currentExpanded:checked
    })
  }

  onDeleteHipaaAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageUser.deleteHipaaUser({ "subaccount_id": this.state.modaldata.id }).then(data => {
      this.setState({ modalFormLoader: false });
      try {
        let recordDeleted = this.state.lists.findIndex(
          list => list.id === data.data.id
        );
        let oldArr = this.state.lists;
        oldArr.splice(recordDeleted, 1);
        this.setState({
          
          modalstatus: false,
          modaltitle: '',
          modalmessage: '',
          modalbuttonmsg: {},
          modalType: '',
          modalErrorMsg: false
        });
        this.props.showUserToaster('green', 'Hipaa user has been deleted successfully.');
        utilFunc.scrollTop(800);
        this.loadData({});
      } catch (err) {
        console.log(err);
      }
    });
  };

  preOpensetting = (id) => {
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let access_selected = this.state.lists[recordlist].setting.access_module_lists;
    // let access_selected_lists_html = '';
    // access_selected_lists_html = access_selected.map((item, value) =>
    //   <p key={item} style={{ textTransform: "capitalize", color: "#6c7293", fontWeight: "400"}}>{item.replace(/_/g, ' ')}</p>
    // );
    this.setState({
      modalstatus: true,
      modaltitle: 'Setting/Privileges',
      modalmessage: "tree",
      modalbuttonmsg: { "submit": 'Ok', "cancel": '' },
      modalType: 'Popup',
      currentChecked:(this.state.lists[recordlist].setting.access_module_lists==null)?[]:JSON.parse(this.state.lists[recordlist].setting.access_module_lists),
      currentExpanded:[],
    });
  };

  

  notiAndMiscmodalClose = (id) =>{

    this.setState({
      modalstatusNotiAandMisc:false,
      notiAndSettingmodalData:{}
    })
  }

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: '',
      modalmessage: '',
      modalbuttonmsg: {},
      modalType: '',
      modaldata: {},
      selectedOptions: []
    });
  };

  onValidSubmit = (email,name,password,type,accountType) => {
    let sendDate = { "email": email, "name": name, "password": password,type,accountType};
      this.setState({ modalFormLoader: true });
      api.manageUser.addHipaaUser(sendDate).then(data => {
        this.setState({ modalFormLoader: false });
        try {
          if (data.data.message=="Duplicate_Account") {
            this.setState({
              modalErrorMsg: true
            });
            setTimeout(
              function () {
                this.setState({ modalErrorMsg: false });
              }
                .bind(this),
              5000
            );
          
          } else {
            this.setState({
              
              totalCount: this.state.totalCount + 1,
              modalstatusAddEdit: false,
              modaltitle: '',
              modalmessage: '',
              modalbuttonmsg: {},
              modalType: '',
              modalErrorMsg: false,
              modaldata: {},
              selectedOptions: []
            });
            this.props.showUserToaster('green', 'Hipaa user has been added successfully.');
            utilFunc.scrollTop(800);
            this.loadData({});

          }
        } catch (err) {
          console.log(err);
        }
      });
    
  }

  loadDataHipaaLoginUser = e=>{

    api.manageUser.getHipaaLoginUserLists({}).then(data => {
      try {
       
        this.setState({
          hipaaLoginEmailList: data.data.data.list.emails,
          hipaaLoginPhoneList: data.data.data.list.phone_numbers,
        });

      } catch (err) {
        console.log(err);
      }
console.log("TEAMA",this.state)
     
    });
  }

  loadData = params => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');

    let totalCountQuery = '';
    if (params.q !== '') {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.manageUser.getHipaaLists(query).then(data => {
        try {
          // let accessModuleStore = data.user_access;
          // data.user_access.forEach((item, index) => {
          //   item.forEach((item2, index2) => {
          //     accessModuleStore.push(item2);
          //   });
          // });
          let datatableRowstemp; var isd1 = "+1";
          var phoneno = "";
          var is_recieve_text_message_on_livechat = "0";
          var is_recieve_email_on_livechat = "0";
          var jumpin_message = "";
          var is_recieve_text_message_on_completechat = "0";
          var is_recieve_email_on_completechat = "0";
          var is_leave_note = "0";
          if (data.data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (<DatatableRow key={index} list={list} editUserSubAccounts={this.editUserSubAccounts} deleteHipaaUserSubAccounts={this.deleteHipaaUserSubAccounts} preOpensetting={this.preOpensetting}  />));
          } else {
            datatableRowstemp = <Table.Row><Table.Cell colSpan={7} textAlign="center">No record found.</Table.Cell></Table.Row>;
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });

        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });

    });

  };

  onValidSubmitFirstCol = (notiAndSettingmodalData,id)=>{
    let index = this.state.lists.findIndex(
      list => list.id === id
    );
    this.state.lists[index].setting.isd =  notiAndSettingmodalData.isd;
    this.state.lists[index].setting.image =  notiAndSettingmodalData.image;
    this.state.lists[index].setting.phone =  notiAndSettingmodalData.phone;
    this.setState({
      lists:this.state.lists,
    })

  }
  onValidSubmitSecondCol = (notiAndSettingmodalData,id)=>{
    let index = this.state.lists.findIndex(
      list => list.id === id
    );
    this.state.lists[index].setting.jumpin_message =  notiAndSettingmodalData.jumpin_message;
    this.setState({
      lists:this.state.lists,
    })


  }

  onValidSubmitSwicth = (notiAndSettingmodalData,id)=>{
    let index = this.state.lists.findIndex(
      list => list.id === id
    );
    this.state.lists[index].setting.is_recieve_text_message_on_livechat =  notiAndSettingmodalData.is_recieve_text_message_on_livechat;
    this.state.lists[index].setting.is_recieve_email_on_livechat =  notiAndSettingmodalData.is_recieve_email_on_livechat;
    this.state.lists[index].setting.is_recieve_text_message_on_completechat =  notiAndSettingmodalData.is_recieve_text_message_on_completechat;
    this.state.lists[index].setting.is_recieve_email_on_completechat =  notiAndSettingmodalData.is_recieve_email_on_completechat;
    this.state.lists[index].setting.is_leave_note =  notiAndSettingmodalData.is_leave_note;
    this.state.lists[index].setting.is_revieve_notification_incompleted_chat =  notiAndSettingmodalData.is_revieve_notification_incompleted_chat;
    this.setState({
      lists:this.state.lists,
    })
  }
  

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
        />
        <DialogBoxDetail
          obj={this.state}
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
          currentChecked={this.state.currentChecked}
          currentExpanded = {this.state.currentExpanded}
          setCurrentCheck = {this.setCurrentCheck}
          setCurrentExpand = {this.setCurrentExpand}
        />
        <DialogBoxAddEdit
          obj={this.state}
          modalClose={this.modalClose}
          onValidSubmit={this.onValidSubmit}
          currentExpanded = {this.state.currentExpanded}
          setCurrentCheck = {this.setCurrentCheck}
          setCurrentExpand = {this.setCurrentExpand}
          hipaaLoginEmailList = {this.state.hipaaLoginEmailList}
          hipaaLoginPhoneList={this.state.hipaaLoginPhoneList}

        />
        <DialogBoxSetNotificationAndMiscSetting
          obj={this.state}
          notiAndMiscmodalClose={this.notiAndMiscmodalClose}
          onValidSubmitFirstCol={this.onValidSubmitFirstCol}
          onValidSubmitSecondCol = {this.onValidSubmitSecondCol}
          onValidSubmitSwicth = {this.onValidSubmitSwicth}
          sessionUser = {this.props.sessionUser}
          loadNow= {this.state.loadNow}
        />
      </Segment>
    );
  }
}
