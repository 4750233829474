import React, { useState } from 'react';
import ReactNotifications from 'react-browser-notifications';
import config from "../config";


    class LiveChatNotification extends React.Component {
        constructor(props){
            super(props)
            const {baseUrl} = config;
            this.state = {
                randomKey:Math.random() * 10000,
                baseUrl: baseUrl,
                title:"",
                message:""
            }
            
            this.showNotifications = this.showNotifications.bind(this);
            this.handleClick = this.handleClick.bind(this);
    // For sound notification
    //set key if it does not exist
    if (localStorage.getItem("soundNotification") === null) {
        localStorage.setItem("soundNotification", this.state.randomKey);
    }else{
        localStorage.setItem("soundNotification", this.state.randomKey);
    }
    console.log("notification set");
    // For sound notification 
    window.addEventListener('beforeunload', (event) => {
        localStorage.setItem("aaa", this.state.randomKey);
        if (localStorage.getItem("soundNotification") == this.state.randomKey) {
            localStorage.removeItem("soundNotification");
        }
    });
    // For sound notification
    window.addEventListener("storage", function(evt) {
        if (evt.key === "soundNotification" && evt.newValue === null) {
            if (localStorage.getItem("soundNotification") === null) {
                localStorage.setItem("soundNotification", this.state.randomKey);
            }
        }
    });
}

showNotifications() {
    
    if(this.n.supported()) this.n.show();
  }

  handleClick(event) {
    window.focus()
    this.n.close(event.target.tag);
    window.open(this.state.baseUrl + "/live-chat");
  }

    componentDidMount(){
        var ref = this;
        this.props.socket.on('refresh chatdata_agent_firsttime', function (msg) {
            var title = msg.name
            var message = msg.message.msgVal

            ref.setState({
                title:title,
                message:message
            })

            ref.showNotifications()
            if (ref.props.user.first_sound_notification == 1) {
                // alert("hello")
                var audio = new Audio(ref.props.user.assetPath+'/js/noti_new.mp3');
                // if (localStorage.getItem("soundNotification") == ref.state.randomKey) {
                    audio.play();
                // }
            }
        })
        this.props.socket.on('refresh chatdata_agent', function (msg) {
            console.log("hola1",localStorage.getItem("soundNotification"))
            console.log("hola1",ref.state.randomKey)
            console.log("hola1",ref.props.user.sound_notification)
            if (ref.props.user.sound_notification == 1) {
                var audio = new Audio(ref.props.user.assetPath+'/js/noti_new.mp3');
                // if (localStorage.getItem("soundNotification") == ref.state.randomKey) {
                    audio.play();
                // }
            }
        })
    }

    
    render() {
       
        return (
        <div><ReactNotifications
        onRef={ref => (this.n = ref)} // Required
        title={this.state.title} // Required
        body={this.state.message}
        icon={this.props.user.assetPath+"/layouts/layout2/img/zyra-new-logo.png"}
        onClick={event => this.handleClick(event)}
      />

      {/* <button onClick={this.showNotifications}>
        Notify Me!
      </button> */}
      
      </div>
        )
    };
}
    export default LiveChatNotification;

