import React from "react";
import "../../../Modal/dialog.css";

const DialogBox = (props) => {
	const { errors } = props.data;
	return (
		<React.Fragment>
			<div
				className={props.data.dialogShow ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: props.data.dialogShow ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {props.data.dialogLoading && <div className="ui loading form"></div>}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => props.actionButtonText('Close')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{props.data.dialogTitle=='Delete'? "Confirm your action" : props.data.dialogTitle}</h5>
						</div>
						<div className="modal-body">
							<div className="px-4">
							{props.data.dialogTitle=='Add' ?
							(<div className="form-group row"> 
							  <label className="col-lg-4 col-form-label">Button Text :</label>
							   <div className="col-lg-8">
								   <div className="field">
										<div className="ui fluid input">
											<input autocomplete="off" name="buttonTextName" placeholder="" type="text" 
											value={props.data.buttonTextName} 
											onChange={(e) => props.onchange(e)}
											/>
										</div>
										{errors && errors.hasOwnProperty('buttonTextName') && (
												<div className="red">{errors['buttonTextName']}</div>
										)}
								    </div>
							    </div>
							</div>
                            ):(props.data.dialogMessage)}
							</div>
						</div>
						{ props.data.dialogTitle=='Add' ? (
							<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => props.actionButtonText('Close')} data-dismiss="modal">
									<span>Cancel</span>
								</button>
								<button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={() => props.saveButtonText()} >
									Save
								</button>
							</div> ):(props.data.dialogTitle=='Alert' ? (<div className="modal-footer">
								<button type="button" className="btn linear-gradient yr-submitbtn btn-sm"  onClick={() => props.actionButtonText('Close')}>
									Ok
								</button>
							</div>):(<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => props.actionButtonText('Close')} data-dismiss="modal">
									<span>No</span>
								</button>
								<button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={() => props.deleteButtonText()}>
									Yes
								</button>
							</div>)
							 )}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBox;
