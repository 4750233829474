import React from "react";
import { connect } from "react-redux";

// import DesignRules from './DesignRules';

import { Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
} from "../../../../store/Page";

import DatatableList from "./DatatableList";
const queryString = require("query-string");

class SensitiveDataActivityLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activetab: "design",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
    };
  }
  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setBreadCrum,
    } = this.props;
    // setPageTitle({ title: "Completed Chats",subHeaderTitle:'Conversations' });
    

    // toggleNavigation({ toggle: true });
    // setBreadCrum({ breadcrum: null });
    // const params = queryString.parse(location.search);

    // if (params && params.tab !== undefined) {
    //   this.setState({
    //     activetab: params.tab,
    //   });
    // }
  }

  render() {
    return (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="kt-portlet kt-portlet--mobile"
                  style={{ boxShadow: "none" }}
                >
                  <div className="kt-portlet__body kt-portlet__body--fit">
                    <DatatableList userData = {this.props.user} />
                  </div>
                </div>
              </div>
            </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
})(SensitiveDataActivityLog);
