import React from "react";
import "./Popup.css";
import "semantic-ui-css/semantic.min.css";
import { Dropdown } from "semantic-ui-react";
import CustomErrorField from "../../../Form/CustomErrorField";
import api from "../../../../api";
import DOMPurify from 'dompurify';
import constants from "../../../../util_funs/constant";

class AddWorkspace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workspace_name: "",
      selected_template: "",
      active: "",
      wlist: [],
      bot_type: "",
      error: [],
      loading: false,
      notexisterror: false,
      notexistmessage: "",
      btnDisabled:false
    };
    console.log(this.props);
  }

  selectTemplate = (val) => {
    this.setState({ selected_template: val, active: val });
    // console.log(this.state);
  };
  selectTemplateDropdown = (e, data) => {
    this.setState({ selected_template: data.value, active: data.value });
  };

  selectBotType = (bot_type) => {
    let error_check = this.state.error;
    let index2 = error_check.indexOf("bottype");

    if (index2 > -1) {
      error_check.splice(index2, 1);
    }
    console.log(error_check);
    this.setState({ bot_type: bot_type, error: error_check });
  };

  createWorkspace = () => {
    let error_exist = false;
    if (this.state.workspace_name.trim() == "") {
      let error = this.state.error;
      const index2 = error.indexOf("bottype");
      if (index2 === -1) {
        error.push("name");
        this.setState({ error: error });
      }

      error_exist = true;
    }
    if (this.state.bot_type == "") {
      let error = this.state.error;

      const index3 = error.indexOf("bottype");
      if (index3 === -1) {
        error.push("bottype");
        this.setState({ error: error });
      }

      error_exist = true;
    }

    if (!error_exist) {
      this.setState({ 
        loading: true,
        btnDisabled:true
      });
      api.botbuilder
        .createworkspace({
          bot_type: this.state.bot_type,
          selected_template: this.state.selected_template,
          name: DOMPurify.sanitize(this.state.workspace_name.trim()),
        })
        .then((data) => {
          try {
            if (data.status) {
              this.setState(
                {
                  loading: false,
                  btnDisabled:true
                },
                function () {
                  this.props.history.push("/botbuilder/" + data.data.code);
                }
              );
            } else {
              this.setState({
                loading: false,
                notexisterror: true,
                btnDisabled:false,
                notexistmessage: data.message,
              });
            }
            // check if y
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  changeWorkspaceName = (e) => {
    let error_check = this.state.error;
    const index = error_check.indexOf("name");
    if (index > -1) {
      error_check.splice(index, 1);
    }

    this.setState({ workspace_name: e.target.value, error: error_check });
  };

  render() {
    let wpoptionstemp = [{ key: "", text: "Select template", value: "" }];
    this.props.list.forEach((obj) => {
      let options = {
        key: obj.wid,
        text: obj.name,
        value: obj.wid,
      };
      wpoptionstemp.push(options);
    });
    const wpoptions = wpoptionstemp;
    const { loading } = this.state;

    return (
      <div className="row" style={{ width: 100 + "%" }}>
        {loading && <div className="ui loading form"></div>}
        <div className="col-lg-12 yr-chat-box-row yr-autoPopup">
          <div
            className="yr-max-height kt-scroll col-12 rtBrd border-right-none"
            data-scroll="true"
          >
            <div className="box-title" style={{ sfontSize: 26 + "px" }}>
              Add Workspace
            </div>
            <div className="tab-content">
              {this.state.notexisterror && (
                <div
                  className="error"
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  {this.state.notexistmessage}
                </div>
              )}
              <div className="tab-pane active" id="a">
                <div className="yr-popBox-1" style={{ paddingBottom: 0 }}>
                  <div className="form-group row" style={{ marginBottom: 20 }}>
                    <label className="col-2" style={{ marginBottom: 10 }}>
                      Title
                    </label>
                    <div className="col-10">
                      <input
                        type="text"
                        className={
                          this.state.error.length > 0 &&
                          this.state.error.indexOf("name") !== -1
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Workspace Name"
                        onChange={(e) => this.changeWorkspaceName(e)}
                      />
                      {this.state.error.length > 0 &&
                        this.state.error.indexOf("name") !== -1 && (
                          <CustomErrorField
                            message="This field is required"
                            id="help-error"
                            className="error invalid-feedback"
                          />
                        )}
                    </div>
                  </div>
                  <div className="form-group" style={{ marginBottom: 20 }}>
                    <label>Conversation Type</label>
                  </div>
                  <div className="row">
                    {this.state.error.length > 0 &&
                      this.state.error.indexOf("bottype") !== -1 && (
                        <CustomErrorField
                          style={{
                            display:
                              this.state.error.length > 0 &&
                              this.state.error.indexOf("bottype") !== -1
                                ? "inline-block"
                                : "none",
                          }}
                          message="Please select al least one type"
                          id="help-error"
                          className="error invalid-feedback"
                        />
                      )}
                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "WEB"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("WEB")}
                        >
                          Web
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "FACEBOOK"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("FACEBOOK")}
                        >
                          Facebook enabled
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "SMS"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("SMS")}
                        >
                          Inbound SMS automation
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "GDF"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("GDF")}
                        >
                          Google My Business enabled
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "Voice"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("Voice")}
                        >
                          Voice
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "Voice_Turbo"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("Voice_Turbo")}
                        >
                          Voice Turbo 
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "GEN_WEB"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("GEN_WEB")}
                        >
                          {constants.web_general}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "GEN_VOICE"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("GEN_VOICE")}
                        >
                          {constants.voice_general}
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "OUTBOUNDSMS"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("OUTBOUNDSMS")}
                        >
                          Outbound SMS automation
                        </label>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <div className="form-group" style={{ marginBottom: 20 }}>
                        <label
                          className={
                            this.state.bot_type == "EMAIL"
                              ? "form-control active cursordiv"
                              : "form-control cursordiv"
                          }
                          onClick={() => this.selectBotType("EMAIL")}
                        >
                          Email automation
                        </label>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="form-group row" style={{ marginBottom: 20 }}>
                                    <label className="col-4" style={{ marginBottom: 10 }}>Search templates</label>
                                    <div className="col-8">
                                    <Dropdown onChange={this.selectTemplateDropdown} placeholder='Select template' search selection options={wpoptions} style={{border: "1px solid #ccc",
                                            borderRadius: "5px"
                                        }} value={this.state.selected_template}/>
                                       
                                    </div>
                                </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="yr-max-height pad0 col-4">
            <div className="box-title" style={{ borderBottom: 0 }}>
              Possible template
            </div>
            <ul className="nav nav-tabs yr-poupTab">
              <li
                className={this.state.active == "" ? "active" : ""}
                onClick={() => this.selectTemplate("")}
              >
                <a href="#a" data-toggle="tab" className="active">
                  Start from scratch
                </a>
              </li>
              {this.props.list.map((obj, index) => {
                return (
                  <li
                    key={index}
                    className={this.state.active == obj.wid ? "active" : ""}
                    onClick={() => this.selectTemplate(obj.wid)}
                    id="40"
                  >
                    <a href="#b" data-toggle="tab">
                      {obj.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div> */}
        </div>
        <div className="col-lg-12 yr-chat-box-row yr-autoPopup">
          <div className="col-8 rtBrd border-right-none"> &nbsp; </div>
          <div className="modelFooterBtn col-4">
            <button
              type="reset"
              className="btn linear-gradient yr-submitbtn linear-gradient-cancel mg-r"
              onClick={() => this.props.toggleModal()}
            >
              <span> Cancel </span>
            </button>
            <button
              type="submit"
              className="btn linear-gradient yr-submitbtn"
              onClick={() => this.createWorkspace()}
              disabled={this.state.btnDisabled}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddWorkspace;
