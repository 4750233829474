import React from "react";
import "../../../../../Modal/dialog.css";
import api from "../../../../../../api";

const TeamDeleteBox = ({ show, title, viewteam, deleteTeam,  modalCloseDeleteBox, buttonmsg, modalbuttofun, loading }) => {
	

 //console.log(buttonmsg);
  //let tm_id = buttonmsg.cltm_id;
   //console.log(title);
//@Rohit
	deleteTeam= (tm_id)=> {

		//alert(tm_id);
		//alert(call_notes_comment);
		// api.marketing.getContactDetails({'call_notes_id':call_notes_id}).then((data) => {
		api.marketing.deleteTeam({'cltm_id':tm_id}).then((data) => {
		console.log(data)
		try {   
		
			modalCloseDeleteBox(false, '')
			viewteam('Team member deleted successfully.');
			this.setState(
				{
				   Delmag: "Deleted successfully",
				});setTimeout(
					function () {
					this.setState({ Delmag: false });
					}.bind(this),
					5000
					);
			//yourNotes(ycall_list_id);
		} catch (err) {
		console.log(err);
		}
	  });
	  
	  let modalContactViaObject = {
		show: false,
		title: "Notes",
		loading: false
	  }
	  }

return (
	
		<React.Fragment>
			
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {loading && <div className="ui loading form"></div>}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseDeleteBox(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">Confirm your action</h5>
						</div>
						<div className="modal-body"><div className="px-4">Are you sure you want to delete?</div></div>
						<div className="modal-footer">
							<button type="button" onClick={() => modalCloseDeleteBox(false, '')} style={{ display: buttonmsg.cancel != '' ? "block" : "none" }} className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
								<span>No</span>
							</button>
							<button type="button" onClick={() => deleteTeam(title)} style={{ display: buttonmsg.submit != '' ? "block" : "none" }} className="btn linear-gradient yr-submitbtn btn-sm">
								Yes
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default TeamDeleteBox;
