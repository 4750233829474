import React, { Component } from "react";
const queryString = require("query-string");

class Redirect extends Component {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params) {
      if (params.tab !== undefined && params.tab === "support") {
        this.props.history.push("/settings?tab=support");
      }else if(params.tab !== undefined && params.tab === "design"){
        this.props.history.push("/chat-setup?tab=design");
      }

      else if(params.tab !== undefined && params.tab === "pagelocation"){
        this.props.history.push("/chat-setup?tab=pagelocation");
      }
      else if(params.tab !== undefined && params.tab === "pagerules"){
        this.props.history.push("/chat-setup?tab=pagerules");
      }

      if (params.tab !== undefined && params.tab === "manageuser") {
        this.props.history.push("/settings?tab=manageuser");
      }
      if (params.tab !== undefined && params.tab === "profile") {
        this.props.history.push("/settings?tab=profile");
      }

      if (params.tab !== undefined && params.tab === "sensitive") {
        this.props.history.push("/settings?tab=sensitive");
      }

      if (params.tab !== undefined && params.tab === "reviewchat") {
        this.props.history.push("/chat-setup?tab=reviewchat");
      }

    }
  }
  render() {
    return "";
  }
}

export default Redirect;
