import React, { useState } from 'react';
import InputColor from 'react-input-color';

const InputColorField = (data) => {     
    const [color, setColor] = useState({});
    
    function onchangeAll(e,name){
        data.onchangeColor(e,name);
        setColor(e);
    }

    return(
        <div className="yr-color-picker">
            <InputColor style={{width:"65%", height:"30px",padding: "0"}} initialValue={data.value}  onChange={(evt) => onchangeAll(evt,data.name)} />
            <span>
                {color.hex}
            </span>
        </div> 
    ) 
}


export default InputColorField;