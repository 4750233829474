import React from 'react';
import { Rating, Dropdown, Icon } from 'semantic-ui-react';

const ReviewsDetailsFromBackend = props => {
    //@Rohit
    const style = {
        marginBottom: '30px'
    }

    return props.data.data.map(function (row, row_index) {
        return (
            <div className="col-lg-12 review-border-top pr-lg-4">
                <div className="kt-widget3">
                    <div className="kt-widget3__item">
                        <div className="kt-widget3__header flex-header">
                            <div className="kt-widget3__user-img">
                                <img className="kt-widget3__img" src={row.reviews_img} alt="" />

                            </div>
                            <div className="kt-widget3__info">
                                <Rating maxRating={5} rating={row.rating} icon='star' />
                                <br />
                                <span className="kt-widget3__username ml-1">{row.customer_name}</span>
                                <span class="kt-widget3__time ml-2">{row.review_date}</span>
                                <br />
                                <span className="kt-widget3__time ml-2">
                                    {/* 10mins ago */}
                                </span>
                            </div>
                            <span className="kt-widget3__status">
                                Review generated by:
                                <span className="generatedBy">
                                    <Icon name="user outline" className="mx-2" />
                                    {props.data.reqData.sourceFilters === 1 ?
                                        row.member_name
                                        :
                                        <Dropdown text={row.member_name} icon='angle down'>
                                            <Dropdown.Menu>
                                                {props.data.TeamData.map((person, index) => (
                                                    <Dropdown.Item icon='user outline' onClick={() => props.AssignReviews(row.review_id, person['value'])} text={person['text']} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </span>
                            </span>
                        </div>

                        <div className="kt-widget3__body flex-body">
                            <p className="kt-widget3__text" style={style}>
                                {row.reviews}
                            </p>
                            {/* {props.data.reqData.sourceFilters == 1 &&
                    <div className="review-actions">
                        <Dropdown text='Actions' icon='angle down' className="reviewActions">
                            <Dropdown.Menu>                                                                
                                <Dropdown.Item icon='trash alternate outline' text='Remove' onClick={() => props.deleteReview(row.review_id)} />
                                <Dropdown.Item icon='edit outline' text='Edit' onClick={() => props.editReviews(row.review_id)} />                                                                                             
                            </Dropdown.Menu>
                        </Dropdown>
                        <button type="button"   className="btn zy-save-btn ml-2" onClick={() => props.ReplyReviews(row.review_id)}>Reply</button>
                    </div>
                      } */}
                        </div>

                    </div>
                </div>
            </div>
        )
    })

}

export default ReviewsDetailsFromBackend;