import { Table } from 'semantic-ui-react';
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


const DatePickerComp = (props) => {
  
  const setStartDatePre = (date)=>{
    props.changeDate(date)

  }
   return(
    <span><DatePicker
    selected={(props.scheduleStartDate=="")?"":new Date(props.scheduleStartDate)}
    onChange={date => setStartDatePre(date)}
    minDate={new Date()}
    showDisabledMonthNavigation
    dateFormat="MMMM d, yyyy h:mm aa"
    timeInputLabel="Time:"

    showTimeInput
    className="form-control"
    id = "scheduleDateTime"
    excludeOutOfBoundsTimes


  />
  <span style={{display:props.scheduledateEmpty,color:"red"}}>This field is required.</span></span>
   )
}

export default DatePickerComp;
