import React from "react";

import api from "../../../api";

import GridList from "../../Grid";

import ScreenShots from "./ScreenShots";
import { Segment, Message } from "semantic-ui-react";
import DialogBox from "../../Modal/DialogBox";
import ConfirmDialog from "../../Modal/ConfirmDialog";
import utilFunc from "../../../util_funs";

class SupportTickets extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      ticketLists: [],
      loading: false,
      modal: {
        screenshots: false,
        level: false,
        status: false,
        confirm: false,
        message: "",
      },
      messageModal: false,
      messageModalText: "",
      images: [],
      imagePreviewModal: false,
      columnLists: [
        { text: "S.No", key: "id", sort: false },
        { text: "Ticket ID", key: "ticket_no" },
        { text: "Name", key: "name" },
        { text: "Company", key: "company" },
        { text: "Summary", key: "summary" },
        { text: "Support Type", key: "help_with" },
        { text: "Ticket Type", key: "is_external" },
        {
          text: "Level",
          key: "lavel",
          event: {
            key: "changeTicketLevel",
            params: ["selectedValue", "ticketId"],
            func: (selectedValue, ticketId) =>
              this.changeTicketLevel(selectedValue, ticketId),
          },
        },
        { text: "Ticket Status", key: "total_comment" },
        {
          text: "Change Status",
          key: "status",
          event: {
            key: "changeTicketStatus",
            params: ["status", "ticketId"],
            func: (status, ticketId, prompt) =>
              this.showPrompt(status, ticketId, prompt),
          },
        },
        {
          text: "Images",
          key: "images",
          event: {
            key: "loadImageView",
            params: ["ticketId"],
            func: (ticketId) => this.loadImageView(ticketId),
          },
        },
        { text: "Created Date", key: "updated_date" },
        {
          text: "Action",
          key: "action",
          sort: false,
          event: {
            key: "loadComment",
            params: ["ticketId"],
            func: (ticketId) => this.loadComment(ticketId),
          },
        },
      ],
    };
  }

  loadComment = (ticketId) => {
    this.props.history.push("/support/comment/" + ticketId);
  };
  loadImageView = (ticketId) => {
    this.setState({ loading: true });
    api.ticket
      .getScreenShots({ ticketId })
      .then((resp) => {
        this.setState(
          {
            loading: false,
            images: resp.images,
          },
          () => {
            const { modal } = this.state;
            modal.screenshots = true;
            this.setState({ modal });
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };
  changeTicketLevel = (level, ticketId) => {
    let params = { level, ticketId };
    this.setState({ loading: true });
    api.ticket
      .changeTicketLevel(params)
      .then((resp) => {
        this.handleMessage(resp.message);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  removeMessage = () => {
    const { modal } = this.state;
    modal.level = false;
    //modal.message = "";
    this.setState({ modal });
  };

  showPrompt = (status, ticketId) => {
    const { modal } = this.state;
    modal.confirm = true;
    modal.message = "Do you really want to change the status?";
    modal.status = { status, ticketId };
    this.setState({ modal });
  };
  handleModalSubmit = (param) => {
    this.changeTicketStatus(this.state.modal.status);
    this.toggleModal(param);
  };
  handleMessage = (message) => {
    //this.timeout = null;
    clearTimeout(this.timeout);
    this.setState({ messageModal: false, messageModalText: "" }, () => {
      this.setState(
        {
          messageModal: true,
          messageModalText: message,
          loading: false,
        },
        () => {
          utilFunc.scrollTop(800);

          this.timeout = setTimeout(() => {
            this.setState({ messageModal: false, messageModalText: "" });
            //this.removeMessage();
          }, 5000);
        }
      );
    });
  };
  changeTicketStatus = (params) => {
    this.setState({ loading: true });
    api.ticket
      .changeTicketStatus(params)
      .then((resp) => {
        this.handleMessage(resp.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  getDataSource = (params) => api.ticket.getLists(params);
  toggleModal = (params) => {
    const { modal } = this.state;
    modal[params] = false;
    this.setState({ modal });
  };

  render() {
    return (
      <div className="row">
        {this.state.modal.screenshots && (
          <DialogBox
            show={this.state.modal.screenshots}
            toggleModal={(param) => this.toggleModal("screenshots")}
            title="Screenshots"
            width="600px"
            height="300px"
          >
            <ScreenShots images={this.state.images} />
          </DialogBox>
        )}

        {this.state.modal.confirm && (
          <ConfirmDialog
            show={this.state.modal.confirm}
            toggleModal={(param) => this.handleModalSubmit("confirm")}
            closeAction={(param) => this.toggleModal("confirm")}
            title="Confirm your action"
            message={this.state.modal.message}
          />
        )}

        <div className="col-lg-12 col-md-12 col-sm-12">
          {/*begin: Search Form */}
          {/*end: Search Form */}
          <div className="kt-portlet__body kt-portlet__body--fit">
            {/*begin: Datatable */}
            {this.state.messageModal && (
              <Message color={"green"} style={{ marginTop: 30 }}>
                {this.state.messageModalText}
              </Message>
            )}

            <Segment>
              <GridList
                dataSource={(params) => this.getDataSource(params)}
                dataColumns={this.state.columnLists}
                dataLoading={this.state.loading}
              />
            </Segment>
            {/*end: Datatable */}
          </div>
        </div>
      </div>
    );
  }
}

export default SupportTickets;
