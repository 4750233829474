import React from "react";
import CustomErrorField from "../../Form/CustomErrorField";

class CreateBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      branch_name: "",
      branch_question: "",
      errors: {},
    };
  }

  toggleModal = () => this.props.onclose();
  setValue = (e) => {
    this.setState({ [e.target.name]: e.target.value, loading:false  });
  };

  componentDidMount() {
    this.loadData();
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show != prevProps.show) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({ branch_name: "", branch_question: "", loading: false });
  };

  createBranch = () => {
   
    let errors = [];
    if (this.state.branch_name == "") {
      errors.branch_name = "This field is required";
    }
    if (this.state.branch_question == "") {
      errors.branch_question = "This field is required";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      this.setState({ errors: {}, loading: true }, function () {
        let data_set = {
          branch_name: this.state.branch_name,
          branch_question: this.state.branch_question,

        };
        
        this.props.saveNewBranch(data_set, this.props.show, () => {
          
        },function () {
          this.setState({ errors: {}, loading: false });
        });
      });
     
    }
  };
  render() {
    let { show, title, children, width, height, message } = this.props;
    if (!width) width = "500px";
    if (!height) height = "300px";
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div
          className={show != "" ? "modal fade show " : "modal fade"}
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ display: show != "" ? "block" : "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: width }}
          >
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.toggleModal}
              >
                X
              </button>
              <div className="modal-header">
                <h5 className="modal-title">Add Branch</h5>
              </div>
              {this.state.loading && <div className="ui loading form"></div>}
              <div
                className="modal-body"
                style={{ maxHeight: height, overflowX: "auto" }}
              >
                <div class="form-group">
                  <input
                    value={this.state.branch_name}
                    className={
                      Object.keys(errors).length > 0 &&
                      errors.hasOwnProperty("branch_name") &&
                      typeof errors.branch_name != "undefined"
                        ? "form-control is-invalid focusError"
                        : "form-control focusError"
                    }
                    name="branch_name"
                    placeholder="Branch Name"
                    onChange={(e) => this.setValue(e)}
                  />
                  {Object.keys(errors).length > 0 &&
                    errors.hasOwnProperty("branch_name") &&
                    typeof errors.branch_name != "undefined" && (
                      <CustomErrorField
                        message={errors.branch_name}
                        className="error invalid-feedback"
                      />
                    )}
                </div>
                <div class="form-group mt-20">
                  <textarea
                    value={this.state.branch_question}
                    className={
                      Object.keys(errors).length > 0 &&
                      errors.hasOwnProperty("branch_question") &&
                      typeof errors.branch_question != "undefined"
                        ? "form-control is-invalid focusError"
                        : "form-control focusError"
                    }
                    name="branch_question"
                    onChange={(e) => this.setValue(e)}
                    placeholder="Branch description"
                  >
                   
                  </textarea>
                  {Object.keys(errors).length > 0 &&
                    errors.hasOwnProperty("branch_question") &&
                    typeof errors.branch_question != "undefined" && (
                      <CustomErrorField
                        message={errors.branch_question}
                        className="error invalid-feedback"
                      />
                    )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="yr-cancelbtn linear-gradient-cancel -sm yr-btn-small"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.toggleModal}
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  onClick={() => this.createBranch()}
                  className="btn linear-gradient yr-submitbtn btn-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateBranch;
