import config from "../config";

const removeAuthTokens = () => {
  // Remove access token
  deleteCookie(config.access_token_name);
  deleteCookie(config.refresh_token_name);
  deleteCookie("user_subaccount_id");
};

const setAccessToken = (accessToken) =>
  setCookie(config.access_token_name, JSON.stringify(accessToken));
const setRefreshToken = (refreshToken) =>
  setCookie(config.refresh_token_name, JSON.stringify(refreshToken));

const setAuthTokens = (access_token, refresh_token, subid = null) => {
  // Removing any existing token
  removeAuthTokens();
  setCookie("user_subaccount_id", JSON.stringify(subid));
  // Set access_token in cookie
  if (access_token)
    setCookie(config.access_token_name, JSON.stringify(access_token));

  // Set resfresh_token in cookie
  if (refresh_token)
    setCookie(config.refresh_token_name, JSON.stringify(refresh_token));
};

const getAuthTokens = () => {
  // Get all authrization token
  let access_token = getCookie(config.access_token_name)
    ? JSON.parse(getCookie(config.access_token_name))
    : null;

  let refresh_token = getCookie(config.refresh_token_name)
    ? JSON.parse(getCookie(config.refresh_token_name))
    : null;

  let user_subaccount_id = getCookie("user_subaccount_id")
    ? JSON.parse(getCookie("user_subaccount_id"))
    : "";

  return {
    access_token,
    refresh_token,
    user_subaccount_id,
  };
};
const setCookie = (cname, cvalue, exdays = config.cookieExpirationDays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const deleteCookie = (cname) => {
  document.cookie = cname + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
const getTimeZone = () => {
  var u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
  var clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
  return clientTimeZone;
};

const phoneFormat = (phone) => {
  let p = phone;

  p = p.replace(/[^\d]*/gi, "");

  if (p.length < 3) {
    return p;
  } else if (p.length == 3) {
    let pp = p;
    let d4 = p.indexOf("(");
    let d5 = p.indexOf(")");
    if (d4 == -1) {
      pp = "(" + pp;
    }
    if (d5 == -1) {
      pp = pp + ")";
    }
    return pp;
  } else if (p.length > 3 && p.length < 7) {
    p = "(" + p;
    let l30 = p.length;
    let p30 = p.substring(0, 4);
    p30 = p30 + ")";

    let p31 = p.substring(4, l30);
    let pp = p30 + p31;

    return pp;
  } else if (p.length >= 7) {
    p = "(" + p;
    let l30 = p.length;
    let p30 = p.substring(0, 4);
    p30 = p30 + ")";

    let p31 = p.substring(4, l30);
    let pp = p30 + p31;

    let l40 = pp.length;
    let p40 = pp.substring(0, 8);
    p40 = p40 + "-";

    let p41 = pp.substring(8, l40);
    let ppp = p40 + p41;
    let maxphonelength = 13;
    let finalPhone = ppp.substring(0, maxphonelength);
    return finalPhone;
  }
};

const util = {
  removeAuthTokens,
  setAuthTokens,
  getAuthTokens,
  setAccessToken,
  setRefreshToken,
  getTimeZone,
  phoneFormat,
  setCookie,
  getCookie
};
export default util;
