import axios from "../config/axios";

const liveChat = {
  userChatList: (formData) => axios.post("/livechat/LiveChat/getChatList", formData), 
  joinIntoTheConversation: (formData) => axios.post("/livechat/LiveChat/joinIntoTheConversation", formData),      
  quickTextList: (formData) => axios.post("/livechat/LiveChat/quickTextList", formData),      
  addQuickText: (formData) => axios.post("/livechat/LiveChat/addQuickText", formData),      
  updateQuickText: (formData) => axios.post("/livechat/LiveChat/updateQuickText", formData),
  deleteQuickText: (formData) => axios.post("/livechat/LiveChat/deleteQuickText", formData),
  getQuickListForLiveChat: (formData) => axios.post("/livechat/LiveChat/getQuickListForLiveChat", formData),
  NotificationMemberList: (formData) => axios.post("/livechat/LiveChat/NotificationMemberList", formData),
  sendDataToWebhookLiveChat: (formData) => axios.post("/livechat/LiveChat/sendDataToWebhookLiveChat", formData),
  transferTheChat: (formData) => axios.post("/livechat/LiveChat/transferTheChat", formData),
  soundNotificationSettings: (formData) => axios.post("/livechat/LiveChat/soundNotificationSettings", formData),
  sendChatTranscript: (formData) => axios.post("/livechat/LiveChat/sendChatTranscript", formData),
  updateRead: (formData) => axios.post("/livechat/LiveChat/updateRead", formData),
  deleteSingleChat:(formData)=>axios.post("/livechat/LiveChat/hideLiveChat",formData),


  listSmsNumber: (params) =>
    axios
      .post(`/marketing/ConfigSetting/listSmsNumber`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  generateShortUrl: (params) =>
    axios
      .post(`/livechat/liveChat/generateShortUrl`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getChatListPhoneNumbers: (params) =>
    axios
      .post(`/livechat/liveChat/getChatListPhoneNumbers`, params),

  saveSendTextMessage: (params) =>
    axios
      .post(`/livechat/liveChat/saveSendTextMessage`, params),

  archiveSmsChat: (params) =>
    axios
      .post(`/livechat/liveChat/archiveSmsChat`, params),

  changeName: (params) =>
    axios
      .post(`/livechat/liveChat/changeFullName`, params),

  sendTextLiveTrumpia: (params) =>
    axios
      .post(`/sendTextLive/sendTextLiveTrumpia`, params),
};



export default liveChat;
