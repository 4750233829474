import React from 'react'

const DeliveryRates = ({analytics, onBoxClick}) => {
    const deliveredPer = ((analytics.delivered ? parseInt(analytics.delivered) : 0) * 100)/parseInt(analytics.totalEmail); 
    const softBouncePer = ((analytics.temporary_fail ? parseInt(analytics.temporary_fail) : 0) * 100)/parseInt(analytics.totalEmail);
    const hardBouncePer = ((analytics.permanent_fail ? parseInt(analytics.permanent_fail) : 0) * 100)/parseInt(analytics.totalEmail); 

    
    return (
        <div>
            <table className="border" style={{ minHeight: 264}}>
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div className="text-center pt-3">
                                Total email: <b>{analytics.totalEmail}</b>
                            </div>
                            <div className="yr-loader-area">
                                <div className='text-center'>
                                    <h1 className='my-0'>{deliveredPer.toFixed(2)}%</h1>
                                    <div>Delivered</div>
                                    <strong>{analytics.delivered}</strong> emails
                                </div>
                                {/* <hr className="" />
                                <div className="text-right">
                                    <strong>{analytics.delivered}</strong> emails delivered so far <strong>{analytics.totalEmail}</strong> total
                                </div> */}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-50 pointer' onClick={() => onBoxClick('Soft-bounced', analytics.temporary_fail)}>
                            <div className="yr-dr-box">
                                <h2 className='mb-0'>{softBouncePer.toFixed(2)}%</h2>
                                <div>Soft-bounced</div>
                                <h4 className='d-inline-block my-0'>{(analytics.temporary_fail == '')?0: analytics.temporary_fail} Emails </h4> <span className="info-icon"><i className="fas fa-question-circle"></i>
                                    <span className="clicktoshow" style={{ fontWeight: 600 }}>
                                        <strong>{(analytics.temporary_fail == '') ? 0 : analytics.temporary_fail}</strong> so far from <b>{analytics.totalEmail}</b> total
                                    </span>
                                </span>
                                
                                {/* <h3>{deliveredPer.toFixed(2)}%</h3>
                                <strong>Delivered</strong>
                                <p><b>{analytics.delivered}</b> emails</p> */}
                            </div>
                        </td>
                        <td className='w-50 pointer' onClick={() => onBoxClick('Hard-bounced', analytics.permanent_fail)}>
                            <div className="pd-15 text-center">
                                <h2 className='mb-0'>{hardBouncePer.toFixed(2)}%</h2>
                                <div>Hard-bounced</div>
                                <h4 className='d-inline-block my-0'>{(analytics.permanent_fail == '') ? 0 : analytics.permanent_fail} Emails </h4> <span className="info-icon"><i className="fas fa-question-circle"></i>
                                    <span className="clicktoshow" style={{ fontWeight: 600 }}>
                                        <strong>{(analytics.permanent_fail == '') ? 0 : analytics.permanent_fail}</strong> so far from <b>{analytics.totalEmail}</b> total
                                    </span>
                                </span>
                                {/* <p>{hardBouncePer.toFixed(2)}%  <span className="info-icon"><i className="fas fa-question-circle"></i>
                                    <span className="clicktoshow" style={{ fontWeight: 600 }}><strong><b>{analytics.permanent_fail}</b>  emails hard-bounced so far <b>{analytics.totalEmail}</b> total</strong></span>
                                </span>
                                </p> */}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DeliveryRates