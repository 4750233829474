import React from 'react';
import CustomErrorField from "../../../../Form/CustomErrorField";

class AddWebhook extends React.Component {
    constructor(props){
        super(props);
        this.state={
            webhooks:[""],
            errors:[],
            loading:false,
            edit_id:'',
            branchlist:[],
            branchids:[],
            disablebutton:false
           
        }
    }
    componentDidMount() {
        this.onLoad();
      }

      deleteQuestionResponse = (index) => {
      
        let ques = this.state.webhooks;
        console.log(index,"dif",ques)
        let activeindex = ques.findIndex((list) => list == index);
        console.log(activeindex,"dif")
          ques.splice(index, 1);
          this.setState({ webhooks: ques });
        
       
      };
    
    componentDidUpdate(prevProps) {
    if (this.props.button_action_id != prevProps.button_action_id) {
        this.onLoad();
    }
    }
    onLoad = () => {
      console.log(this.props.branchDetails.zapier_webhooks,'lisff')
      let dsf=this.props.branchDetails.zapier_webhooks.map((br)=>{
        return parseInt(br.branch);
      });
      this.setState({branchlist:this.props.branchList,branchids:dsf});
        if (this.props.button_action_id == "add") {
          this.setState({ webhooks: [""], errors: [] });
        } else {
          console.log(this.props.branchDetails.zapier_webhooks);
          let activeindex = this.props.branchDetails.zapier_webhooks.findIndex(
            (list) => list.id == this.props.button_action_id
          );
          this.setState({
            errors: [],
            webhooks: [
              this.props.branchDetails.zapier_webhooks[activeindex].branch,
            ],
            edit_id: this.props.branchDetails.zapier_webhooks[activeindex]
              .id,
          });
        }
      };

    testurl=(text)=>{
        var regexQuery = "^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$";
        var url = new RegExp(regexQuery,"i");
        return url.test(text);
    }
    
    changeWebhook=(e,key)=>{
      let webhooks=this.state.webhooks;
      let errors=this.state.errors;
      
      let val=e.target.value.trim();
      if(typeof webhooks[key]!=='undefined'){
        webhooks[key]=val;
      }else{
        webhooks.push(val);
      }
      // if(this.isduplicate(webhooks,val)){
      //   errors[errors.length]="This branch already exists.";
      //   this.setState({errors:errors});
      //   return false;
      // }
      this.setState({webhooks:webhooks})
    }
    addWebhook=(e)=>{
      let hooks=this.state.webhooks;
      hooks.push("");
      this.setState({webhooks:hooks});
    }
    isduplicate(hooks,hook){
      let filterdata=hooks.filter(function(r){
        return r==hook;
      });
      let blist=this.props.branchDetails.zapier_webhooks || [];
      let filterdata1=blist.filter(function(r){
        return r.branch==hook;
      })
      console.log(filterdata1)
      return filterdata.length>1 || filterdata1.length>0 ? true:false;
    }
    saveIt=(e)=>{
       this.setState({loading:true,errors:[],disablebutton:true});
        let err=false;
        let errs=[];
        const errors = [];

    new Promise((resolve) => {
        let hooks=this.state.webhooks;
      for(var i=0;i<hooks.length;i++){
        if(hooks[i]===""){
            err=true;
            errs[i]="This field is required.";
        }else if(this.isduplicate(hooks,hooks[i])){
          err=true;
          errs[i]="This branch already exists.";
        }
    
    }
      resolve(errs);
    }).then((data) => {
      if (data.length > 0) {
        this.setState({ errors: data,loading:false,disablebutton:false});
      } else {
        this.setState({ loading: true });
        let edit_number = "";
        if (this.state.edit_id != "") {
          edit_number = this.state.edit_id;
        }
        this.props.saveWebhook(
          this.state.webhooks,
          edit_number,
          (data) => {
            this.setState({ loading: false,disablebutton:false }, function () {
              this.props.popupClose();
              // let scrolltop =
              //   document.querySelector("#responsetoquestion").offsetTop -
              //   document.body.scrollTop;
              // window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
            });
          }
        );
      }
    });
  

    }
    render(){
    return(
        <div>
        <div className="yr-chat-round-box yr-closebtn">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
                    <div className="box-title">If a conversation goes through this branch, the chat will be sent to Zapier.</div>
                    {this.state.loading && <div className="ui loading form"></div>}
                    {this.state.webhooks.length>0 &&<>
                     {this.state.webhooks.map((branch,key)=>{
                      let errs=[]
                        return( <div class="row" un_key={key} key={key}>
                          <div className="col-md-11">
                          <div className="form-group" style={{ marginBottom: 10 }} key={key}>
                      
                      <select className={
                          this.state.errors.length > 0 &&
                          typeof this.state.errors[key] != "undefined"
                            ? "form-control is-invalid"
                            : "form-control"
                        } name="branhcs"  onChange={(e)=>this.changeWebhook(e,key)} value={branch}>
                          <option>Select Branch</option>
                          {this.state.branchlist.map((el,j)=>{
                            return(<option value={el.branch_id} key={j} disabled={  this.state.webhooks.includes(el.branch_id) || this.state.branchids.includes(parseInt(el.branch_id))}>{el.branch_name}</option>);
                          })
                          }
                        </select>
                        {this.state.errors.length > 0 &&
                        typeof this.state.errors[key] != "undefined" && (
                          <CustomErrorField
                            message={this.state.errors[key]}
                            className="error invalid-feedback"
                          />
                        )}  
                       
                    </div>
                    </div>
                    <div className="col-md-1">
                    {key > 0 && (
                      <button
                        type="button"
                        class="btn btn-icon"
                        title="Delete"
                        onClick={() => this.deleteQuestionResponse(key)}
                      >
                        <i class="la la-trash"></i>
                      </button>
                    )}
                  </div>
                    </div>)
                     })
                     }
                       
                    </>}

                    
                   
          {this.state.edit_id == "" && (          
            <a className="add-gradient form-group" href="javascript:void(0);" onClick={(e)=>this.addWebhook(e)}>+Add</a>)}
                    
        
        
    </div>
    <div className="row">
        <div className="col-12 d-flex justify-content-end">
        <button type="reset" class="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => this.props.popupClose()} ><span> Close </span></button>
     <button type="submit" disabled={this.state.disablebutton} className="btn linear-gradient yr-submitbtn" onClick={(e)=>this.saveIt(e)}>Save</button>
 </div>
    </div>
 </div>
    )
}
}


export default AddWebhook;