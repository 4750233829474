import React, { useState } from "react";
import { Segment, Message} from "semantic-ui-react";
import { Form, TextArea } from "formsy-semantic-ui-react";
import validationRule from "../../../../../validation";
import "../../../../Modal/dialog.css";
import { useEffect } from "react";
import api from "../../../../../api";

const DialogBoxDetailEdit = ({
  rowid,
  list,
  show,
  closeModal,
  reloadGrid
}) => {
  const errorLabel = <div className="red" />;
  const [color,setColor] = useState("red");
  // const [show,setShow] = useState(false);
  const [modeldata,setModeldata] = useState({});
  const [messageDisplay,setMessageDisplay] = useState("none");
  const [loading,setLoading] = useState(false);
  const [modalbuttonmsg,setModalbuttonmsg] = useState({});
  const [resMessage,setresMessage] = useState("");
  const [buttonDisable,setButtonDisable] = useState(false);
  
useEffect(()=>{
   // let recordlist = obj.findIndex((list) => list.id === rowid);
    let modaldata = {...list};
    console.log(modaldata,"modaldata x")
    modaldata.existing_email=modaldata.email;
    setModeldata(modaldata);
},[list]);
  


  const onchangeenable = (e) => {
  let memdata={...modeldata};
  console.log(memdata,'mem')
  memdata[e.target.name]=  e.target.value;
  setModeldata(memdata);
}
const  onValidSubmitMember = () => {
  let pararam={...modeldata};
  console.log(pararam,'param')
  if(pararam.email.trim()==""){
    setMessageDisplay("block");
    setColor("red");
    setresMessage("Email cannot be blank.");
    setTimeout(() => {
      setMessageDisplay("none");
      setColor("green");
      setresMessage("");
    }, 2000);
    return false;
  }
  if(pararam.name.trim()==""){
    setMessageDisplay("block");
    setColor("red");
    setresMessage("Name cannot be blank.");
    setTimeout(() => {
      setMessageDisplay("none");
      setColor("green");
      setresMessage("");
    }, 2000);
    return false;
  }
  setButtonDisable(true);
  api.manageUser.updateIncompChatMember(pararam).then((res) => {
    setLoading(false);
    setButtonDisable(false);
    if (res.data.status) {
      setMessageDisplay("block");
      setColor("green");
      setresMessage(res.data.message);
       //reloadGrid(rowid,pararam);
    } else {
      setMessageDisplay("block");
      setColor("red");
      setresMessage(res.data.message);
    }
    setTimeout(() => {
      setMessageDisplay("none");
      setColor("green");
      setresMessage("");
      if(res.data.error!=="duplicate" && res.data.status){
        //loadData({});
        closeModal();
        reloadGrid(rowid,pararam);
        setButtonDisable(false);
      }
     
    }, 4000);
  }).catch((err) => {
    console.log(err);
  })
  }
  

  return (
    <React.Fragment>
      <div
        className={show ? "modal fade show" : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: 600 }}
        >
          {loading && <div className="ui loading form"></div>}
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => closeModal(false, "")}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">Edit notification member details</h5>
            </div>
              
                <div className="modal-body">
                  <div className="kt-portlet__body py-0">
                    <div className="kt-section kt-section--first">
                      <div className="kt-section__body">
                        <div className="form-group row">
                          <div
                            className="col-lg-12 col-form-label"
                            style={{
                              overflow: "hidden",
                               marginTop: "10px",
                              paddingLeft: "0px",
                            }}
                          >
                            <div className="col-md-12">
    <Message
      color={color}
      style={{
        display: messageDisplay,
      }}
    >
      {resMessage}
    </Message>
    <Form
      noValidate
      autoComplete="off"
      className="manageNotificationIndiviNoti"
      id="manageNotificationIndiviNoti"
      // onValidSubmit={()=>onValidSubmitMember()}
      style={{marginBottom:'15px'}}
    >
      <div
        className="form-group"
        style={{ marginBottom: 10 }}
      >
        <label style={{ marginBottom: 0 }}>
          Name
        </label>
          <Form.Input
            type="text"
            className=""
            name="name"
            validations="isRequired"
            validationErrors={{
              isRequired: "This field is required.",
            }}
            errorLabel={errorLabel}
            onChange={(e) => {
              let memdata={...modeldata};
              console.log(memdata,'mem')
              memdata[e.target.name]=  e.target.value;
              setModeldata(memdata);
              setButtonDisable(false);
            }}
            id="name_noti"
            value={modeldata.name}
          />
        
      </div> 
    <div
        className="form-group"
        style={{ marginBottom: 10 }}
      >
        <label style={{ marginBottom: 0 }}>
          Email Address
        </label>
          <Form.Input
            type="email"
            className=""
            name="email"
            validations="isEmail,isRequired"
            validationErrors={{
              isEmail:
                "Please enter a valid email address.",
              isRequired: "This field is required.",
            }}
            errorLabel={errorLabel}
            onChange={(e) => {
              let memdata={...modeldata};
              console.log(memdata,'mem 2')
              memdata[e.target.name]=  e.target.value;
              setModeldata(memdata);
              setButtonDisable(false);
            }}
            id="emailIndiviNoti"
            value={modeldata.email}
          />
        
      </div>
      <div className="modal-footer" style={{marginTop:'15px',marginBottom:'10px'}}>
        <button
          type="button"
          onClick={() => closeModal()}
          style={{ display: modalbuttonmsg.cancel != "" ? "block" : "none" }}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>Close</span>
        </button>
        <button
          disabled={buttonDisable}
          type="submit"
          // onClick={() => this.modalbuttofun(this.state.modalType)}
          style={{ display: modalbuttonmsg.submit != "" ? "block" : "none" }}
          className="btn linear-gradient yr-submitbtn btn-sm"
            onClick={()=>onValidSubmitMember()}
        >
          Submit
        </button>
      </div>
  </Form>
  </div>             
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
            
                </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DialogBoxDetailEdit;
