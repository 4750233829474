import React from "react";
import api from "../../../../api";
import "../../../Grid/grid.css";

import { Segment, Table, Pagination } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import { CSVLink, CSVDownload } from "react-csv";
import { throwStatement } from "@babel/types";
import { Message } from "semantic-ui-react";


const tableHeader = [
  {
    title: "Tags",
    width: "1",
    sort: "",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  
  {
    title: "Date",
    width: "3",
    sort: "",
  },
  {
    title: "Job Description",
    width: "3",
    sort: "",
  },
  
  {
    title: "Name",
    width: "3",
    sort: "",
  },
  {
    title: "Zip Code",
    width: "1",
    sort: "",
  },
  {
    title: "Phone Number",
    width: "1",
    sort: "",
  },
  {
    title: "Email",
    width: "1",
    sort: "",
  },
  {
    title: "Site Url",
    width: "3",
    sort: "",
  },
  
  {
    title: "Utm Source",
    width: "1",
    sort: "",
  },
  {
    title: "Utm Medium",
    width: "1",
    sort: "",
  },
  {
    title: "Utm Campaign",
    width: "1",
    sort: "",
  },
  {
    title: "Utm Content",
    width: "1",
    sort: "",
  },
  
  {
    title: "Ip Address",
    width: "2",
    sort: "",
  },
  {
    title: "Ga Id",
    width: "1",
    sort: "",
  },
  
];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "chatstatus",
  "chatfrequency",
  "time_zone",
  "startdaterange",
  "enddaterange",
];

export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "auto_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      chatstatus: "",
      chatfrequency: "",
      startdaterange: "",
      enddaterange: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      modalfooter: "",
      modalBody: "",
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      auto_id: "",
      modalTranscript: "",
      isgpt:false,
      csvdata: [],
      hipaaValidateObject: {},
      modalstatusHIPAA:false,
      user: props.user,

     
      

    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
    console.log("statattata", this.state);
    if (filter == "chatfrequency") {
      this.setState({
        startdaterange: "",
        enddaterange: "",
      });
    }
    //if (filter !== this.state.q) {
    this.loadData({ [filter]: val, _page: 1 });
    // }
  };

  onSubmitFilterDateReange = (parmas) => {
    this.loadData({
      chatfrequency: parmas["chatfrequency"],
      startdaterange: parmas["startdaterange"],
      enddaterange: parmas["enddaterange"],
      _page: 1,
    });
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
    });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    
    query = query+`&button_id=${this.props.btn_id}`;
    
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.chatsetup.getBtnLog(query).then((data) => {
       
        try {
          let datatableRowstemp;
          if (data?.status && data?.data.length > 0) {
            let logdata=data.data;
            console.log("listdatat",logdata );
           
            this.setState({ lists: logdata });
            console.log(this.state.lists);
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                key={index}
                list={list}

              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.totalrecord,
            lists: data.data,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };


  downloadCSV = (event, done) => {
    let params = {};
    params["chatstatus"] = this.state.chatstatus;
    params["chatfrequency"] = this.state.chatfrequency;
    params["strsearch"] = this.state.q;
    params["startdaterange"] = this.state.startdaterange;
    params["enddaterange"] = this.state.enddaterange;

    let list = this.state.lists;
    let selected_arr = [];
    list.filter(function (key) {
      if (key["checked"] == true) {
        selected_arr.push(key["auto_id"]);
      }
    });

    this.setState({ loading: true });

    params["selectallcheckbox"] = selected_arr.length > 0 ? "false" : "true";
    params["chatidscheck"] = JSON.stringify(selected_arr);
    api.chatlist.getCSVData(params).then((data) => {
      try {
        this.setState(
          {
            csvdata: data.data.data,
          },
          function () {
            setTimeout(
              function () {
                document.querySelector("#chatcsv").click();
                this.setState({ loading: false });
              }.bind(this),
              1000
            );
          }
        );
      } catch (err) {}
    });
  };

  checkAll = (e) => {
    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list["checked"] = e.target.checked;
    });
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          openHipaaRow = {this.openHipaaRow}

        />
      )),
      lists: datalist,
    });
  };

  checkboxChangeEvent = (auto_id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

    if (datalist[recordlist]["checked"]) {
      datalist[recordlist]["checked"] = false;
    } else {
      datalist[recordlist]["checked"] = true;
    }

    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
          openHipaaRow = {this.openHipaaRow}

        />
      )),
      lists: datalist,
    });


    //  let array=this.state.checkedboxes;
    // const index = this.state.checkedboxes.indexOf(auto_id);
    // if (index > -1) {
    //   array.splice(index, 1);
    // }else{
    //   array.push(auto_id)
    // }

    // this.setState({
    //   checkedboxes:array
    // });
  };

  openHipaaRow = (auto_id) => {
    this.setState({loading:true})
    api.manageUser.checkHipaaAccount({user_id:this.props.user.user_id,email:this.props.user.email}).then((data) => {
      try {
        if(data.data.data.subaccount_id=="found"){
          var type = "validate";
        } else {
          var type = "register";
        }
        this.setState({
          loading:false,
          modalstatusHIPAA: true,
          modaltitle:
            "To view sensitive chat information, please enter your authorize email and PIN",
          hipaaValidateObject: {
            userId: this.props.user.user_id,
            userType: "User",
            via: "user",
            auto_id: auto_id,
            pin_email: this.props.user.email,
            refId: auto_id,
            chat_section:"chatlist",
            type:type
            
          },
        });
      } catch (err) {}
    });
    
    
  };

  openHipaaAfterRegister = auto_id =>{
    this.setState({
      loading:false,
      modalstatusHIPAA: true,
      modaltitle:
        "To view sensitive chat information, please enter your authorize email and PIN",
      hipaaValidateObject: {
        userId: this.props.user.user_id,
        userType: "User",
        via: "user",
        auto_id: auto_id,
        pin_email: this.props.user.email,
        refId: auto_id,
        chat_section:"chatlist",
        type:"validate"
        
      },
    });
  }

  onValidHipaaSubmit = (auto_id)=>{
    let datalist = this.state.lists;

    let recordlist = this.state.lists.findIndex(
      (list) => list.auto_id === auto_id
    );


    datalist[recordlist]["hippaOn"] = "none";
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          key={index}
          list={list}
          viewAdditionalCaptured={this.viewAdditionalCaptured}
          viewDetails={this.viewDetails}
          viewNotes={this.viewNotes}
          openHipaaRow = {this.openHipaaRow}
        />
      )),
      lists: datalist,
    });
    this.setState({
      chat: this.state.chat,
      chatLeftList:this.state.chatLeftList,
      modalstatusHIPAA: false,
      name: this.state.name,
    });
  }

  hipaaModalClose = e =>{
    this.setState({
      modalstatusHIPAA: false,
    });
  }

  
  
 
  

  render() {
    return (
      <div className="completedchats">
        {/* <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            chatfrequency: this.state.chatfrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
          onSubmitFilterDateReange={this.onSubmitFilterDateReange}
          csvdata={this.state.csvdata}
          dowloadcsv={this.downloadCSV}
        /> */}
        <Segment style={{ width:"100%", maxWidth:"calc(96vw)"  }} >
          {this.state.loading && <div className="ui loading form"></div>}

          <DatatableTable
            lists={this.state.lists}
            datatableHeader={tableHeader}
            datatableRows={this.state.datatableRows}
            totalCount={this.state.totalCount}
            totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
            currentPage={this.state._page}
            onChangePage={this.onChangePage}
            column={this.state._sort}
            direction={DatatableList.directionConverter(this.state._order)}
            handleSort={this.handleSort}
            onChangeLimit={this.onChangeLimit}
            limit={this.state._limit.toString()}
            checkAll={this.checkAll}
          />
        </Segment>
        {this.state.totalCount>0 &&<><DatatablePageSizeSelect
          limit={this.state._limit.toString()}
          onChangeLimit={this.onChangeLimit}
        />{" "}
        Showing 1 - {this.state._limit.toString()} of {this.state.totalCount} </>}
        {this.state.totalCount>0 && <Pagination
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          activePage={this.state._page}
          onPageChange={this.onChangePage}
        />}
        
       
        
      </div>
    );
  }
}
