import React from "react";
import { Link, withRouter } from "react-router-dom";

class AutomatedMarketing extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <div className="kt-header__bottom">
        <div className="kt-container" style={{ backgroundColor: "#fff" }}>
          <div
            className="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className="kt-header-menu kt-header-menu-mobile "
            >
              <ul className="kt-menu__nav " style={{ padding: 22 }}>

                <li
                  className={`kt-menu__item ${(location.pathname == "/marketing/contacts" || location.pathname.includes("marketing/contactlist")
                  || location.pathname.includes("contacts/datasource")
                  )
                    ? "kt-menu__item--active linear-gradient-a"
                    : location.pathname.includes("marketing/contactlist") == true ? "kt-menu__item--active linear-gradient-a" : ""
                    }`}
                  aria-haspopup="true"
                >
                  <Link to="/marketing/contacts" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-clone" aria-hidden="true"></i>
                      Contacts
                    </span>
                  </Link>
                </li>

                <li
                  className={`kt-menu__item ${(location.pathname == "/marketing/email"
                    || location.pathname.includes("marketing/playbook")
                    || location.pathname.includes("marketing/playBookBranchWiseStats")
                    || location.pathname.includes("marketing/PlayBookAnalytics")

                  )
                    ? "kt-menu__item--active linear-gradient-a"
                    : ""
                    }`}
                  aria-haspopup="true"
                >
                  <Link to="/marketing/email" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      Playbooks
                    </span>
                  </Link>
                </li>

                <li
                  className={`kt-menu__item ${location.pathname == "/marketing/postcard"
                    ? "kt-menu__item--active linear-gradient-a"
                    : ""
                    }`}
                  aria-haspopup="true"
                >
                  <Link to="/marketing/postcard" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      Direct Mail
                    </span>
                  </Link>
                </li>

                <li
                  className={`kt-menu__item ${(location.pathname == "/marketing/callList" || location.pathname.includes("marketing/callList"))
                    ? "kt-menu__item--active linear-gradient-a"
                    : location.pathname.includes("marketing/callList") == true ? "kt-menu__item--active" : ""
                    }`}
                  aria-haspopup="true"
                >
                  <Link to="/marketing/callList" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-list" aria-hidden="true"></i>
                      Your Call List
                    </span>
                  </Link>
                </li>
                <li
                  className={`kt-menu__item ${(location.pathname == "/marketing/adddomain" || location.pathname.includes("marketing/configurationsetting") || location.pathname.includes("marketing/listdomain"))
                    ? "kt-menu__item--active linear-gradient-a"
                    : location.pathname.includes("marketing/listdomain") == true ? "kt-menu__item--active" : ""
                    }`}
                  aria-haspopup="true"
                >
                  <Link to="/marketing/listdomain" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                     <i className="fa fa-cog" aria-hidden="true"></i>
                      Configuration Setting
                    </span>
                  </Link>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AutomatedMarketing);
